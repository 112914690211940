import { createSlice } from "@reduxjs/toolkit";
import { AccessDto, ProducerDto } from "../models/ApiSchema";

export interface IUser {
  organizations: ProducerDto[];
  organizationOptions: ProducerDto[];
  access?: AccessDto;
}

const initialState: IUser = {
  organizations: [],
  organizationOptions: [],
  access: undefined
};

const slice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    userOrganizationsReceived: (state, action) => {
      state.organizations = action.payload.data || [];
    },
    userOrganizationOptionsReceived: (state, action) => {
      state.organizationOptions = action.payload.data || [];
    },
    userAccessReceived: (state, action) => {
      state.access = action.payload.data;
    }
  },
});

export const { userOrganizationsReceived, userOrganizationOptionsReceived, userAccessReceived } = slice.actions;

export default slice.reducer;

export const getOrganizationByProducerNumber = (
  state: { user: { organizations: ProducerDto[]; }; },
  producerNumber: any
) =>
  state.user.organizations?.filter(
    (o: ProducerDto) => o.producerNumber === producerNumber
  )[0] || {};
