import { Button } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import React from "react";
import { SnackbarUtilsConfigurator } from "../../helpers/snackbarHelper";

const Snackbar: React.FC = ({ children }) => {

  const notistackRef = React.createRef<any>();

  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      autoHideDuration={6000}
      preventDuplicate={false}
      dense={false}
      action={(key) => (
        <Button size="small" style={{ color: 'white' }} onClick={onClickDismiss(key)}>Sulje</Button>
      )}
    >
      <SnackbarUtilsConfigurator />
      {children}
    </SnackbarProvider>
  );
}

export default Snackbar;
