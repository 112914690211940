import React, { Fragment, useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { IconButton } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import { IRootState } from "../../../shared/store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../shared/store/apiActions";
import ApprovalStatusSetMenu from "../../../shared/components/ApprovalStatus/ApprovalStatusSetMenu";
import { ViewName } from "../../../shared/models/ApiSchema";
import CheckIcon from '@material-ui/icons/Check';
import agGridHelper from "../../../shared/helpers/agGridHelper";
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import SalmonellaSampleApprovalModal from "./SalmonellaSampleApprovalModal";
import { approvalStatusText } from "../../../shared/helpers/statusHelper";
import moment from "moment";
import { ColDef } from "ag-grid-community";

interface SalmonellaSampleApprovalProps {

}

const SalmonellaSampleApproval: React.FC<SalmonellaSampleApprovalProps> = () => {

  const access = useSelector((state: IRootState) => state.user.access);
  const status = useSelector((state: IRootState) => state.approval.status);
  const startDate = useSelector((state: IRootState) => state.approval.startDate);
  const endDate = useSelector((state: IRootState) => state.approval.endDate);
  const salmonellaSamples = useSelector((state: IRootState) => state.approval.salmonellaSampleList);
  const dispatch = useDispatch();

  const [openApprovalModal, setApprovalModalOpen] = useState<boolean>(false);
  const handleApprovalModalClose = (newStatus) => {
    setApprovalModalOpen(false);
    if (newStatus) {
      dispatch(api.approval.getSalmonellaSampleList(startDate, endDate, /*status ||*/ undefined));
    }
  };

  useEffect(() => {
    dispatch(api.approval.getSalmonellaSampleList(startDate, endDate, /*status ||*/ undefined));
  }, [status, startDate, endDate, dispatch]);

  const openApproval = (id: string) => {
    dispatch(api.approval.getSalmonellaSampleApproval(id));
    setApprovalModalOpen(true);
  }

  const redraw = (e) => {
    e.api.redrawRows();
  }

  const timeDiffFormatter = (v: any) => {
    const sampleDate = moment(v.data.sampleDate).startOf('day');
    const slaughterDate = moment(v.data.slaughterDate).startOf('day');
    const diff = slaughterDate.diff(sampleDate, "hours");
    return Math.round(diff / 24).toFixed()
  };

  const defaultColDef: ColDef = {
    cellStyle: { textAlign: "center" },
    resizable: true,
    sortable: true,
    editable: false,
    suppressMenu: true,
    suppressMovable: true,
    suppressNavigable: true
  };

  return (
    <Fragment>
      <div className="diary-grid ag-theme-balham" style={{height: "74vh", width: "99.99%"}}>
        <AgGridReact
          rowData={salmonellaSamples}
          defaultColDef={defaultColDef}
          suppressCellSelection={true}
          applyColumnDefOrder={true}
          immutableData={true}
          getRowNodeId={(row) => row.id}
          onRowDataChanged={redraw}
        >
          <AgGridColumn headerName="Salmonellanäyte">
            <AgGridColumn
              field="breedingId"
              headerName="Erä id"
              width={80}
              pinned="left"
            />
            <AgGridColumn
              field="producerName"
              headerName="Tuottaja"
              minWidth={160}
              maxWidth={320}
              pinned="left"
            />
            <AgGridColumn
              field="hallNumber"
              headerName="Halli"
              width={60}
              cellStyle={{fontWeight:600}}
              pinned="left"
            />
            {access?.approvalStatusEnabled?.SalmonellaSample && <AgGridColumn
              field="approvalStatus"
              headerName="Status"
              width={100}
              pinned="left"
              valueFormatter={v => approvalStatusText(v.value)}
            />}
            {access?.approvalStatusEnabled?.SalmonellaSample && access?.hasApprovalRole && <AgGridColumn
              width={48}
              sortable={false}
              pinned="left"
              cellRendererFramework={params => <ApprovalStatusSetMenu current={params.data.approvalStatus} view={ViewName.SalmonellaSample} id={params.data.id} />}
            />}
          </AgGridColumn>
          <AgGridColumn headerName="Salmonellanäyte">
            <AgGridColumn
              field="sampleDate"
              headerName="Näytteenottopäivä"
              width={130}
              valueFormatter={agGridHelper.dateFormatter}
            />
            <AgGridColumn
              field="isOfficialSample"
              headerName="Tyyppi"
              width={120}
              valueGetter={p => p.data.isOfficialSample ? 'Virallinen' : 'Rutiini'}
            />
            <AgGridColumn
              headerName="Päivää teurastukseen"
              width={140}
              valueFormatter={timeDiffFormatter}
            />
          </AgGridColumn>
          <AgGridColumn
            field="slaughterDate"
            headerName="Teurastuspäivä"
            width={120}
            valueFormatter={agGridHelper.dateFormatter}
          />
          <AgGridColumn
            field="notes"
            headerName="Huomioita"
            minWidth={100}
            sortable={false}
            cellRendererFramework={params => params.data.notes?.length > 0 ? <CheckIcon /> : null}
          />
          <AgGridColumn headerName="0-7 pvä poistuma">
            <AgGridColumn
              field="sevenDayLoss"
              headerName="Yhteensä kpl"
              width={120}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Kokonaispoistuma">
            <AgGridColumn
              field="totalLoss"
              headerName="Yhteensä kpl"
              width={120}
            />
            <AgGridColumn
              field="totalLoss"
              headerName="Yhteensä %"
              width={120}
              valueGetter={p => p.data.totalLoss / p.data.startQuantity * 100}
              valueFormatter={agGridHelper.decimalFormatter}
            />
            <AgGridColumn
              field="lossDate"
              headerName="Mennessä"
              width={120}
              valueFormatter={agGridHelper.dateFormatter}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Lähetetty">
            <AgGridColumn
              field="createdBy"
              headerName="Nimi"
              minWidth={180}
            />
            <AgGridColumn
              field="createdDate"
              headerName="Aika"
              width={120}
              valueFormatter={agGridHelper.dateTimeFormatter}
            />
          </AgGridColumn>
          <AgGridColumn
            width={48}
            sortable={false}
            pinned="right"
            cellRendererFramework={params => <IconButton size="small" onClick={() => openApproval(params.data.id)}><DescriptionIcon style={{ fontSize: 18 }} /></IconButton>}
          />
        </AgGridReact>
      </div>
      <SalmonellaSampleApprovalModal open={openApprovalModal} onClose={handleApprovalModalClose} />
    </Fragment>
  );
};

export default SalmonellaSampleApproval;