import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    height: "100%",
    minHeight: "calc(100vh - 328px)",
    backgroundColor: "#E1E0DC",
    backgroundSize: "cover",
    backgroundPosition: "top center"
  },
  widthContainer: {
    padding: "0",
    margin: "0 auto",
    height: "100%",
    minHeight: "100%",
  },
  gridContainer: {
    [theme.breakpoints.down("xs")] : {
      padding: "0",
    },
    padding: "1.75% 1.75%",
    minHeight: "100%",
    height: "100%",
    width: "100%"
  },
  content: {
    minHeight: "200px",
    height: "100%",
    width: "100%"
  },
  container: {
    [theme.breakpoints.down("xs")] : {
      marginTop: "0px",
    },
    height: "100%",
    minHeight: "100%",
    // marginTop: "20px",
    marginBottom: "20px",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
}));

export default useStyles;