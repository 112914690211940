import { createSlice } from "@reduxjs/toolkit";

export interface IUserInterface {
  refreshTimestamp: number,
  pendingActions: number,
  routeLeavingGuard: boolean,
  routeLeavingGuardForced: number
}

const initialState: IUserInterface = {
  refreshTimestamp: (new Date()).getTime(),
  pendingActions: 0,
  routeLeavingGuard: false,
  routeLeavingGuardForced: 0
};

const slice = createSlice({
  name: "userInterface",
  initialState: initialState,
  reducers: {
    loadingBegan: (state) => {
      state.pendingActions += 1;
    },
    loadingEnded: (state) => {
      state.pendingActions -= 1;
    },
    routeLeavingGuardActivated: (state) => {
      state.routeLeavingGuard = true;
    },
    routeLeavingGuardDeactivated: (state) => {
      state.routeLeavingGuard = false;
      state.routeLeavingGuardForced = 2;
    },
    routeLeavingGuardForced: (state) => {
      state.routeLeavingGuardForced = 1;
    },
    routeLeavingGuardUnforced: (state) => {
      state.routeLeavingGuardForced = 0;
    },
    contentRefreshed: (state) => {
      state.refreshTimestamp = (new Date()).getTime();
    }
  }
});

export const {
  loadingBegan,
  loadingEnded,
  routeLeavingGuardActivated,
  routeLeavingGuardDeactivated,
  routeLeavingGuardForced,
  routeLeavingGuardUnforced,
  contentRefreshed
} = slice.actions;

export default slice.reducer;

export const getIsLoading = state => state.ui.common.pendingActions > 0;
