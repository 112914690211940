import moment from "moment";
import React from "react";

interface DateProps {
  value: moment.Moment | Date | string | number | null | undefined;
  time?: boolean;
  year?: boolean;
  empty?: string | '';
}

const _Date: React.FC<DateProps> = ({value, year = true, time = false, empty = ''}) => {
  return (
    <span>{value === null || value === undefined || value === "" || value === 0
      ? empty
      : moment(value).format(
        year
          ? (time ? 'D.M.YYYY H:mm' : 'D.M.YYYY')
          : (time ? 'D.M. H:mm' : 'D.M.')
      )}</span>
  );
};

export const Date = React.memo(_Date);
export default Date;
