import { BatchDatesUpdateDto, BatchDeliveryDto, BatchDeliveryUpdateDto, BatchDetailsDto, BatchFetchResultDto, FeedInfoDto, HallInfoDto, HealthInfoDto, PaginatedListBreedingBatchDto, PoultryDiaryDto } from "../models/ApiSchema";
import http from "./httpService";

const API_URL = `breedingbatches`;

export const listBreedingBatches = async (page: number = 1, size: number = 100) => {
  const resp = await http.get<PaginatedListBreedingBatchDto>(`${API_URL}?page=${page}&size=${size}`);
  return resp;
};

export const listActiveBatches = async () => {
  const resp = await http.get<PaginatedListBreedingBatchDto>(`${API_URL}?status[0]=Active`);
  return resp;
};

export const getBreedingBatch = async (id: string) => {
  const resp = await http.get<BatchDetailsDto>(`${API_URL}/${id}`);
  return resp;
};

export const getPoultryDiaries = async (id: string) => {
  const resp = await http.get<PoultryDiaryDto[]>(`${API_URL}/${id}/diaries`);
  return resp;
};

export const activateBatch = async (id: string, data: BatchDeliveryUpdateDto) => {
  const resp = await http.post(`${API_URL}/${id}/start`, data);
  return resp;
};

export const checkFinishBatch = async (id: string) => {
  const resp = await http.get(`${API_URL}/${id}/end`);
  return resp;
};

export const finishBatch = async (id: string) => {
  const resp = await http.post(`${API_URL}/${id}/end`);
  return resp;
};

export const updateBatchDates = async (id: string, data: BatchDatesUpdateDto) => {
  const resp = await http.put<void>(`${API_URL}/${id}/dates`, data);
  return resp;
};

export const addBatchDelivery = async (id: string, data: BatchDeliveryUpdateDto) => {
  const resp = await http.post<BatchDeliveryDto>(`${API_URL}/${id}/delivery`, data);
  return resp;
};

export const updateBatchDelivery = async (id: string, deliveryId: string, data: BatchDeliveryUpdateDto) => {
  const resp = await http.put<BatchDeliveryDto>(`${API_URL}/${id}/delivery/${deliveryId}`, data);
  return resp;
};

export const deleteBatchDelivery = async (id: string, deliveryId: string) => {
  const resp = await http.delete<void>(`${API_URL}/${id}/delivery/${deliveryId}`);
  return resp;
};

export const updateFeedInfo = async (id: string, data: FeedInfoDto) => {
  const resp = await http.put<FeedInfoDto>(`${API_URL}/${id}/feedinfo`, data);
  return resp;
};

export const updateHallInfo = async (id: string, data: HallInfoDto) => {
  const resp = await http.put<HallInfoDto>(`${API_URL}/${id}/hallinfo`, data);
  return resp;
};

export const updateHealthInfo = async (id: string, data: HealthInfoDto) => {
  const resp = await http.put<HealthInfoDto>(`${API_URL}/${id}/healthinfo`, data);
  return resp;
};

export const initHealthInfo = async (data: HealthInfoDto, overwrite: boolean = false) => {
  if (overwrite) {
    const resp = await http.post<number>(`${API_URL}/init/healthinfo?overwrite=true`, data);
    return resp;
  } else {
    const resp = await http.post<number>(`${API_URL}/init/healthinfo`, data);
    return resp;
  }
};

export const fetchBreedingBatches = async () => {
  const resp = await http.post<BatchFetchResultDto>(`${API_URL}/fetchfromportal`);
  return resp;
};

const breedingBatchService = {
  listBreedingBatches,
  listActiveBatches,
  getBreedingBatch,
  getPoultryDiaries,
  activateBatch,
  checkFinishBatch,
  finishBatch,
  updateBatchDates,
  addBatchDelivery,
  updateBatchDelivery,
  deleteBatchDelivery,
  updateFeedInfo,
  updateHallInfo,
  updateHealthInfo,
  initHealthInfo,
  fetchBreedingBatches
};
export default breedingBatchService;
