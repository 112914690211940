import { Grid, InputAdornment, TextField as MuiTextField } from '@material-ui/core';
import { Formik, Form, Field, FastField } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { BatchDeliveryDto, BreedingBatchDto } from '../../../../shared/models/ApiSchema';
import { numberEmptyToNull, numberEmptyToZero, truncateSeconds } from '../../../../shared/helpers/formHelper';
import { Autocomplete } from 'formik-material-ui-lab';
import FormikDisabler from '../../../../shared/components/FormikDisabler/FormikDisabler';
import DatePicker from '../../../../shared/components/DatePicker/DatePicker';
import TimePicker from '../../../../shared/components/TimePicker/TimePicker';
import moment from 'moment';
import { useConfirm } from 'material-ui-confirm';

interface BatchDeliveryFormProps {
  formId: string;
  batch: BreedingBatchDto;
  delivery?: BatchDeliveryDto | null;
  onSubmit: any;
  activation?: boolean;
  preFeed?: boolean;
  message?: string | null;
}

const BatchDeliveryForm: React.FC<BatchDeliveryFormProps> = ({formId, batch, onSubmit, delivery, activation = false, preFeed = false, message = null}) => {

  const confirm = useConfirm();
  const [formValues, setFormValues] = useState<any>(null);

  useEffect(() => {
    const initialValues = {
      arrivalDate: null,
      actualArrivalTime: null,
      sentQuantity: "",
      transportMortality: "",
      breed: "ROSS308",
      hatchery: "Mynämäki",
      flockType: "Broileri",
      parentFlocks: [],
      parentFlockAvgAge: "",
      deliveryNotes: "",
      feedBeforeArrivalKg: ""
    };

    const data: any = { ...(delivery || initialValues) };
    data.plannedQuantity = batch.plannedQuantity;
    if (preFeed) {
      data.feedBeforeArrivalKg = batch.feedBeforeArrivalKg;
    }
    if (delivery?.actualArrivalTime) {
      data.arrivalDate = data.actualArrivalTime;
      data.chickQuantity = batch.chickQuantity || batch.plannedQuantity;
    } else {
      data.arrivalDate = activation ? moment(batch.arrivalDate) : moment().toISOString();
      data.sentQuantity = "";
      data.transportMortality = "";
      data.chickQuantity = "";
    }
    setFormValues(data);
  }, [activation, batch, delivery, preFeed]);

  const _schema: any = {
    arrivalDate: Yup
      .date()
      .required(),
    actualArrivalTime: Yup
      .date()
      .transform(truncateSeconds)
      .required(),
    sentQuantity: Yup
      .number()
      .transform(numberEmptyToZero)
      .integer()
      .required()
      .min(1).max(1000000),
    transportMortality: Yup
      .number()
      .transform(numberEmptyToZero)
      .integer()
      .required()
      .min(0).max(100000),
    breed: Yup
      .string()
      .required()
      .max(100),
    hatchery: Yup
      .string()
      .required()
      .max(100),
    flockType: Yup
      .string()
      .required()
      .max(100),
    parentFlocks: Yup.array(
        Yup.string().required().max(100)
      ).min(1, "Syötä vähintään yksi alkuperätila"),
    parentFlockAvgAge: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .required()
      .positive()
      .max(100),
    deliveryNotes: Yup
      .string()
      .nullable()
      .max(4000)
  };

  if (activation || preFeed) {
    _schema.feedBeforeArrivalKg = Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .required()
      .min(0)
      .max(100000)
  }

  const formSchema = Yup.object().shape(_schema);

  const handleSentQuantityChange = (handler, values) => (e) => {
    const { target } = e;
    const { value } = target;
    const chickQuantity = (value || 0) - (values.transportMortality || 0);
    handler({ target });
    handler({ target: { name: 'chickQuantity', value: chickQuantity } });
  };

  const handleTransportMortalityChange = (handler, values) => (e) => {
    const { target } = e;
    const { value } = target;
    const chickQuantity = (values.sentQuantity || 0) - (value || 0)
    handler({ target });
    handler({ target: { name: 'chickQuantity', value: chickQuantity } });
  };

  if (formValues == null) return null;

  return (
      <Formik
        initialValues={formValues}
        validationSchema={formSchema}
        enableReinitialize
        onSubmit={(values, actions) => {
          if (message) {
            confirm({ description: message }).then(() => {
              const data = formSchema.cast(values, { stripUnknown: true });
              actions.setSubmitting(false);
              onSubmit(data);
            }, () => {
              actions.setSubmitting(false);
            });
          } else {
            const data = formSchema.cast(values, { stripUnknown: true });
            actions.setSubmitting(false);
            onSubmit(data);
          }
        }}
      >
        {({ submitForm, isSubmitting, handleChange, values, errors }) => (
          <Form id={formId}>
            <FormikDisabler disabled={delivery?.isFinal === true} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FastField
                  component={DatePicker}
                  label="Saapumispäivä"
                  name="arrivalDate"
                  fullWidth
                  margin="dense"
                  disabled={activation || delivery != null}
                  minDate={moment(batch.arrivalDate).startOf('day').toDate()}
                  maxDate={moment(batch.slaughterDate).startOf('day').toDate()}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastField
                  component={TimePicker}
                  label="Saapumisaika"
                  name="actualArrivalTime"
                  fullWidth
                  margin="dense"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FastField
                  component={TextField}
                  label="Suunniteltu määrä"
                  name="plannedQuantity"
                  value={batch.plannedQuantity}
                  margin="dense"
                  disabled
                  fullWidth
                  InputProps={{
                    type: "text",
                    inputMode: "numeric",
                    endAdornment: <InputAdornment position="end">lintua</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  label="Hautomon toimittama määrä"
                  name="sentQuantity"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    type: "text",
                    inputMode: "numeric",
                    autoComplete: "off",
                    endAdornment: <InputAdornment position="end">lintua</InputAdornment>,
                  }}
                  onChange={handleSentQuantityChange(handleChange, values)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  label="Kuljetuksessa kuolleet"
                  name="transportMortality"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    type: "text",
                    inputMode: "numeric",
                    autoComplete: "off",
                    endAdornment: <InputAdornment position="end">lintua</InputAdornment>,
                  }}
                  onChange={handleTransportMortalityChange(handleChange, values)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastField
                  component={TextField}
                  label="Aloittava määrä"
                  name="chickQuantity"
                  margin="dense"
                  fullWidth
                  disabled
                  InputProps={{
                    type: "text",
                    inputMode: "numeric",
                    endAdornment: <InputAdornment position="end">lintua</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FastField
                  component={TextField}
                  label="Rotu"
                  name="breed"
                  fullWidth
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastField
                  component={TextField}
                  label="Parvityyppi"
                  name="flockType"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FastField
                  component={TextField}
                  label="Hautomo"
                  name="hatchery"
                  fullWidth
                  margin="dense"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FastField
                  component={TextField}
                  label="Emoparvien keski-ikä"
                  name="parentFlockAvgAge"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    type: "text",
                    inputMode: "numeric",
                    autoComplete: "off",
                    endAdornment: <InputAdornment position="end">viikkoa</InputAdornment>
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  component={Autocomplete}
                  name="parentFlocks"
                  options={[]}
                  fullWidth
                  freeSolo
                  multiple
                  autoSelect
                  renderInput={(params) => (
                    <MuiTextField {...params} label="Alkuperätilat (erota pilkulla)" margin="dense"
                      error={!!errors.parentFlocks} helperText={errors.parentFlocks} />
                  )}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue.endsWith(',')) {
                      event.target.blur()
                      event.target.focus()
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {(activation || preFeed) && <Grid item xs={12}>
                <FastField
                  component={TextField}
                  label="Rehun määrä hallissa ennen lintujen saapumista"
                  name="feedBeforeArrivalKg"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    type: "text",
                    inputMode: "numeric",
                    autoComplete: "off",
                    endAdornment: <InputAdornment position="end">kg</InputAdornment>
                  }}
                />
              </Grid>}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FastField
                  component={TextField}
                  label="Huomioita toimituksesta"
                  name="deliveryNotes"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    multiline: true,
                    maxRows: 8
                  }}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
  );
};

export default BatchDeliveryForm;
