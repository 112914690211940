import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React from "react";
import { ChainInfoSupplementApprovalDto, ChainInfoSupplementDataDto } from "../../../../shared/models/ApiSchema";
import Date from "../../../../shared/components/Formatters/Date";
import Percent from "../../../../shared/components/Formatters/Percent";
import Number from "../../../../shared/components/Formatters/Number";
import ChickQuality from "../../../../shared/components/Statuses/ChickQuality";
import GrainTable from "../../../../shared/components/GrainTable/GrainTable";
import { Alert } from "@material-ui/lab";

interface ChainInfoSupplementBatchProps {
  data: ChainInfoSupplementDataDto | ChainInfoSupplementApprovalDto;
}

const ChainInfoSupplementBatch: React.FC<ChainInfoSupplementBatchProps> = ({data}) => {
  return (
    <Box my={3}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">Tuottaja</TableCell>
                  <TableCell>{data.batch?.hall?.producer?.name || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Teurastuspäivä</TableCell>
                  <TableCell><Date value={data.batch?.slaughterDate} empty="-" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Hallinumero</TableCell>
                  <TableCell>{data.batch?.hall?.number || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Untuvikkoja</TableCell>
                  <TableCell><Number value={data.batch?.chickQuantity} digits={0} empty="-" unit="kpl" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Tulopäivä(t)</TableCell>
                  <TableCell>{(data.deliveries || []).map((d, i) => <span key={i}>{i > 0 && ", "}<Date value={d.actualArrivalTime} empty="-" /></span>)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Untuvikkolaatu</TableCell>
                  <TableCell>{data.sevenDayLoss?.chickQuality ? <ChickQuality status={data.sevenDayLoss.chickQuality} /> : "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Broilereiden jaloste</TableCell>
                  <TableCell>{Array.from(new Set((data.deliveries || []).map(d => d.breed || '-'))).join(', ')}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          {data.sevenDayLoss
            ? <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">7 vrk poistuma kpl</TableCell>
                      <TableCell><Number value={data.sevenDayLoss.birdsDead + data.sevenDayLoss.birdsCulled} digits={0} empty="-" unit="kpl" /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">7 vrk poistuma %</TableCell>
                      <TableCell><Percent value={(data.sevenDayLoss.birdsDead + data.sevenDayLoss.birdsCulled) / data.sevenDayLoss.birdsLeft} digits={3} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">7 vrk paino g</TableCell>
                      <TableCell><Number value={data.sevenDayLoss.liveWeightGrams} digits={1} empty="-" unit="g" /></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            : <Box mb={3}><Alert severity="error">7 päivän poistumailmoitus puuttuu</Alert></Box>
          }
          {data.feedInfo?.grains
            ? <GrainTable grains={data.feedInfo.grains} />
            : <Box mb={3}><Alert severity="error">Viljatiedot puuttuvat</Alert></Box>
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChainInfoSupplementBatch;
