import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApprovalStatus, ViewName } from "../../models/ApiSchema";
import { IRootState } from "../../store/rootReducer";
import ApprovalStatusValue from "../Statuses/ApprovalStatus";

interface ApprovalStatusSelectProps {
  current: ApprovalStatus,
  view: ViewName,
  onChange: any,
}

const ApprovalStatusSelect: React.FC<ApprovalStatusSelectProps> = ({ current, view, onChange }) => {

  const access = useSelector((state: IRootState) => state.user.access);

  const [states, setStates] = useState<ApprovalStatus[]>([]);
  const [selected, setSelected] = useState<ApprovalStatus | "">("");

  const handleChange = (event) => {
    const value = event.target.value;
    setSelected(value);
    onChange(value);
  };

  useEffect(() => {
    if (current && access?.approvalStatusTransitions) {
      setStates(access.approvalStatusTransitions[view][current] || []);
    } else {
      setStates([]);
    }
  }, [current, view, access]);

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel id="approval-status-label">Uusi tila</InputLabel>
      <Select
        labelId="approval-status-label"
        id="approval-status"
        value={selected}
        onChange={handleChange}
        autoWidth
        label="Uusi tila"
      >
        {states.map((state, index) => (
          <MenuItem key={index} value={state}><ApprovalStatusValue status={state} chip={false} /></MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ApprovalStatusSelect;
