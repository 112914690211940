import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import useStyles from "./HallListStyles";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import { BreedingBatchStatus, HallBatchDto } from "../../../../shared/models/ApiSchema";
import Date from "../../../../shared/components/Formatters/Date";

interface HallListProps {
  halls: HallBatchDto[]
}

const HallList: React.FC<HallListProps> = ({halls}) => {
  const classes = useStyles();

  const history = useHistory();

  const avatarClasses = (h: HallBatchDto) => {
    if (h.batchStatus) {
      if (h.batchStatus === BreedingBatchStatus.Planned) {
        return classes.planned;
      }
      else if (h.batchStatus === BreedingBatchStatus.Active) {
        return classes.active;
      }
      else if (h.batchStatus === BreedingBatchStatus.Completed) {
        return classes.completed;
      }
      else {
        return classes.noBatch;
      }
    }
    else {
      return classes.noBatch;
    }
  }

  const displayDays = (h: HallBatchDto) => {
    const arrival = moment(h.arrivalDate).startOf('day');
    const slaughter = moment(h.slaughterDate).endOf('day');
    const now = moment();

    if (slaughter.isBefore(now, 'day')) {
      return "tyhjennetty"
    } else if (slaughter.isSame(now, 'day')) {
      return "teuraspäivä";
    } else if (arrival.isSame(now, 'day')) {
      return "saapumispäivä";
    } else if (arrival.isAfter(now, 'day')) {
      return "tulossa";
    } else {
      return `ikä ${now.diff(arrival, 'days')}`;
    }
  }

  const statusText = (h: HallBatchDto) => {
    switch (h.batchStatus) {
      case BreedingBatchStatus.Planned:
        return "Tulossa";
      case BreedingBatchStatus.Active:
        return "Käynnissä";
      case BreedingBatchStatus.Completed:
        return "Päättynyt";
      default:
        return "Tuntematon";
    }
  }

  const navigateBatch = (batchId: any) => {
    if (batchId) history.push(`/batch/${batchId}`);
  }

  if (halls.length === 0) {
    return <Alert severity="info">Ei hallitietoja saatavilla, kokeile päivittää erätiedot.</Alert>
  }

  return (
    <List>
      {halls.map((h) =>
        <ListItem button={(h.batchId != null) as false} key={h.hallNumber} onClick={(event) => navigateBatch(h.batchId)}>
          <ListItemAvatar>
            <Avatar className={avatarClasses(h)}>
              <strong>{h.hallNumber}</strong>
            </Avatar>
          </ListItemAvatar>
          {h.breedingId ?
            <ListItemText
              primary={<span>Erä {h.breedingId}: {statusText(h)} <small>({h.chickQuantity} lintua)</small></span>}
              secondary={<span>
                <Date value={h.arrivalDate} /> - <Date value={h.slaughterDate} />{" "}
                {h.batchStatus === BreedingBatchStatus.Active ? `(${displayDays(h)})` : ""}
              </span>}
            /> :
            <ListItemText
              primary={"Ei nykyistä tai tulevaa erää tiedossa"}
            />
          }
          {h.breedingId &&
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="Halli" onClick={(event) => navigateBatch(h.batchId)}>
                <NavigateNextIcon />
              </IconButton>
            </ListItemSecondaryAction>
          }
        </ListItem>
      )}
    </List>
  );
};

export default HallList;