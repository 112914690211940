/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccessDto {
  roles?: string[] | null;
  hasApprovalRole: boolean;
  isActualProducer: boolean;
  approvalStatusAccess?: StatusAccessApprovalStatus | null;
  approvalStatusTransitions?: {
    ChainInfo: {
      Open: ApprovalStatus[];
      Sent: ApprovalStatus[];
      Inspected: ApprovalStatus[];
      Approved: ApprovalStatus[];
      Returned: ApprovalStatus[];
      Rejected: ApprovalStatus[];
      Superceded: ApprovalStatus[];
      Received: ApprovalStatus[];
    };
    ChainInfoSupplement: {
      Open: ApprovalStatus[];
      Sent: ApprovalStatus[];
      Inspected: ApprovalStatus[];
      Approved: ApprovalStatus[];
      Returned: ApprovalStatus[];
      Rejected: ApprovalStatus[];
      Superceded: ApprovalStatus[];
      Received: ApprovalStatus[];
    };
    SevenDayLoss: {
      Open: ApprovalStatus[];
      Sent: ApprovalStatus[];
      Inspected: ApprovalStatus[];
      Approved: ApprovalStatus[];
      Returned: ApprovalStatus[];
      Rejected: ApprovalStatus[];
      Superceded: ApprovalStatus[];
      Received: ApprovalStatus[];
    };
    SalmonellaSample: {
      Open: ApprovalStatus[];
      Sent: ApprovalStatus[];
      Inspected: ApprovalStatus[];
      Approved: ApprovalStatus[];
      Returned: ApprovalStatus[];
      Rejected: ApprovalStatus[];
      Superceded: ApprovalStatus[];
      Received: ApprovalStatus[];
    };
    Notification: {
      Open: ApprovalStatus[];
      Sent: ApprovalStatus[];
      Inspected: ApprovalStatus[];
      Approved: ApprovalStatus[];
      Returned: ApprovalStatus[];
      Rejected: ApprovalStatus[];
      Superceded: ApprovalStatus[];
      Received: ApprovalStatus[];
    };
  };
  approvalStatusEnabled?: {
    ChainInfo: boolean;
    ChainInfoSupplement: boolean;
    SevenDayLoss: boolean;
    SalmonellaSample: boolean;
    Notification: boolean;
  };
}

export enum ApprovalStatus {
  Open = "Open",
  Sent = "Sent",
  Inspected = "Inspected",
  Approved = "Approved",
  Returned = "Returned",
  Rejected = "Rejected",
  Superceded = "Superceded",
  Received = "Received",
}

export interface ApprovalStatusAuditDto {
  /** @format uuid */
  id: string;

  /** @format uuid */
  objectId: string;
  viewName: ViewName;

  /** @format date-time */
  occurredAt: string;
  username?: string | null;
  fromStatus: ApprovalStatus;
  toStatus: ApprovalStatus;
  comment?: string | null;
}

export interface ApprovalStatusUpdateDto {
  toStatus: ApprovalStatus;
  comment?: string | null;
}

export interface BatchDatesDto {
  /** @format date-time */
  sevenDayLossStart: string;

  /** @format date-time */
  sevenDayLossEnd: string;

  /** @format date-time */
  salmonellaSampleStart: string;

  /** @format date-time */
  salmonellaSampleStartException: string;

  /** @format date-time */
  salmonellaSampleEnd: string;

  /** @format date-time */
  chainInfoStart: string;

  /** @format date-time */
  chainInfoEnd: string;

  /** @format date-time */
  chainInfoSupplementStart: string;

  /** @format date-time */
  chainInfoSupplementEnd: string;
}

export interface BatchDatesUpdateDto {
  /** @format date-time */
  arrivalDate: string;

  /** @format date-time */
  slaughterDate: string;
}

export interface BatchDeliveryDto {
  /** @format uuid */
  id?: string | null;

  /** @format date-time */
  actualArrivalTime?: string | null;

  /** @format int32 */
  sentQuantity: number;

  /** @format int32 */
  transportMortality: number;
  breed?: string | null;
  hatchery?: string | null;
  flockType?: string | null;
  parentFlocks: string[];

  /**
   * @format double
   * @min 0
   */
  parentFlockAvgAge?: number | null;
  deliveryNotes?: string | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  isFinal?: boolean | null;
}

export interface BatchDeliveryUpdateDto {
  /** @format date-time */
  arrivalDate: string;

  /** @format date-time */
  actualArrivalTime: string;
  breed?: string | null;
  hatchery: string;
  flockType: string;

  /**
   * @format int32
   * @min 0
   * @max 1000000
   */
  sentQuantity: number;

  /**
   * @format int32
   * @min 0
   * @max 100000
   */
  transportMortality: number;
  parentFlocks: string[];

  /**
   * @format double
   * @min 0
   */
  parentFlockAvgAge?: number | null;
  deliveryNotes?: string | null;

  /**
   * @format double
   * @min 0
   * @max 100000
   */
  feedBeforeArrivalKg?: number | null;
}

export interface BatchDetailsDto {
  batch?: BreedingBatchDto | null;
  deliveries?: BatchDeliveryDto[] | null;
  dates?: BatchDatesDto | null;
  reports?: BatchReportsDto | null;
  hallInfo?: HallInfoDto | null;
  feedInfo?: FeedInfoDto | null;
  healthInfo?: HealthInfoDto | null;
  diaries?: PoultryDiaryDto[] | null;
}

export interface BatchFetchResultDto {
  /** @format int32 */
  batchesCreated: number;

  /** @format int32 */
  batchesUpdated: number;

  /** @format int32 */
  activeUpdated: number;
}

export interface BatchNotificationDto {
  hall?: string | null;

  /** @format int32 */
  breedingId: number;

  /** @format uuid */
  breedingBatchId: string;

  /** @format date-time */
  date: string;
  description?: string | null;
}

export interface BatchReportsDto {
  sevenDayLoss?: SevenDayLossDto | null;
  salmonellaSample?: SalmonellaSampleDto | null;
  chainInfo?: ChainInfoDto | null;
  chainInfoDraft?: ChainInfoDraftDto | null;
  chainInfoSupplement?: ChainInfoSupplementDto | null;
}

export interface BatchTaskDto {
  hall?: string | null;

  /** @format int32 */
  breedingId: number;

  /** @format uuid */
  breedingBatchId: string;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate: string;
  description?: string | null;
}

export interface BreedingBatchDto {
  /** @format uuid */
  id: string;
  status: BreedingBatchStatus;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  isFinal?: boolean | null;

  /** @format int32 */
  breedingId: number;

  /** @format int32 */
  producerNumber: number;
  hall?: HallDto | null;

  /** @format date-time */
  arrivalDate: string;

  /** @format int32 */
  plannedQuantity: number;

  /** @format int32 */
  sentQuantity?: number | null;

  /** @format int32 */
  transportMortality?: number | null;

  /** @format int32 */
  chickQuantity: number;

  /** @format int32 */
  birdsLeft: number;

  /** @format int32 */
  slaughterAge: number;

  /** @format date-time */
  slaughterDate: string;

  /** @format double */
  feedBeforeArrivalKg?: number | null;
}

export enum BreedingBatchStatus {
  Planned = "Planned",
  Active = "Active",
  Completed = "Completed",
}

export interface ChainInfoApprovalDto {
  chainInfo?: ChainInfoDto | null;
  audits?: ApprovalStatusAuditDto[] | null;
}

export interface ChainInfoCreateDto {
  /**
   * @format int32
   * @min 0
   */
  estimatedSlaughterCount: number;

  /**
   * @format double
   * @min 0
   */
  estimatedSlaughterWeightGrams: number;

  /** @format date-time */
  slaughterStartTime: string;

  /** @format date-time */
  lastOfficialSalmonellaSampleDate: string;
  lastOfficialSalmonellaSamplePositive: boolean;
  approveLocation: string;
  approveName: string;
}

export interface ChainInfoDataDto {
  chainInfo?: ChainInfoDto | null;
  audits?: ApprovalStatusAuditDto[] | null;
}

export interface ChainInfoDiseaseDto {
  /** @format date-time */
  dateDetected: string;
  samplesTaken: string;
  foodAgencyDiagnosis?: string | null;
  veterinarianDiagnosis?: string | null;
}

export interface ChainInfoDraftDto {
  /** @format uuid */
  id: string;

  /** @format int32 */
  estimatedSlaughterCount?: number | null;

  /** @format double */
  estimatedSlaughterWeightGrams?: number | null;

  /** @format date-time */
  slaughterStartTime?: string | null;

  /** @format date-time */
  lastOfficialSalmonellaSampleDate?: string | null;
  lastOfficialSalmonellaSamplePositive?: boolean | null;
  approveLocation?: string | null;
  approveName?: string | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
}

export interface ChainInfoDraftUpdateDto {
  /**
   * @format int32
   * @min 0
   */
  estimatedSlaughterCount?: number | null;

  /**
   * @format double
   * @min 0
   */
  estimatedSlaughterWeightGrams?: number | null;

  /** @format date-time */
  slaughterStartTime?: string | null;

  /** @format date-time */
  lastOfficialSalmonellaSampleDate?: string | null;
  lastOfficialSalmonellaSamplePositive?: boolean | null;
  approveLocation?: string | null;
  approveName?: string | null;
}

export interface ChainInfoDto {
  /** @format uuid */
  id?: string | null;

  /** @format int32 */
  breedingId: number;

  /** @format date-time */
  reportCreateTime: string;
  producerName: string;
  hallNumber: string;

  /** @format date-time */
  arrivalDate: string;

  /** @format date-time */
  slaughterDate: string;
  hatchery: string;
  parentFlocks: string;

  /** @format int32 */
  chickQuantity: number;

  /** @format int32 */
  sevenDaysLoss: number;

  /** @format int32 */
  totalLossCountAtCreate: number;

  /** @format double */
  totalLossPercentAtCreate: number;

  /** @format date-time */
  salmonellaSampleDate: string;
  salmonellaSamplePositive: boolean;

  /** @format int32 */
  estimatedSlaughterCount?: number | null;

  /** @format double */
  estimatedSlaughterWeightGrams?: number | null;

  /** @format date-time */
  slaughterStartTime?: string | null;
  feedFactory: string;
  noAnimalProtectionRestrictions: boolean;
  noFoodSafetyIssuesDetected: boolean;
  noMedicationsUsed: boolean;
  healthAnomaliesDetected: boolean;
  healthAnomalies?: string | null;

  /** @format date-time */
  lastOfficialSalmonellaSampleDate?: string | null;
  lastOfficialSalmonellaSamplePositive?: boolean | null;
  approveLocation?: string | null;

  /** @format date-time */
  approveTime?: string | null;
  approveName?: string | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  approvalStatus: ApprovalStatus;
  feeds: ChainInfoFeedDto[];
  diseases?: ChainInfoDiseaseDto[] | null;
  medications?: ChainInfoMedicationDto[] | null;
}

export interface ChainInfoFeedDto {
  type: FeedType;
  name: string;
  coccidiostat?: string | null;

  /** @format int32 */
  withholdingPeriod?: number | null;

  /** @format date-time */
  date: string;
}

export interface ChainInfoListDto {
  /** @format uuid */
  id: string;

  /** @format int32 */
  breedingId: number;
  producerName?: string | null;
  hallNumber?: string | null;
  approvalStatus: ApprovalStatus;
  approvalAuditUser?: string | null;

  /** @format date-time */
  approvalAuditTime?: string | null;
  approveName?: string | null;

  /** @format date-time */
  reportCreateTime: string;

  /** @format date-time */
  slaughterDate: string;

  /** @format date-time */
  slaughterStartTime: string;

  /** @format int32 */
  totalLossCountAtCreate: number;

  /** @format double */
  totalLossPercentAtCreate: number;

  /** @format double */
  estimatedSlaughterWeightGrams: number;

  /** @format date-time */
  grow2FeedEnd?: string | null;
  hasHealthAnomalies: boolean;
  hasNotice: boolean;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
}

export interface ChainInfoMedicationDto {
  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate?: string | null;
  medicine: string;

  /** @format int32 */
  withholdingPeriod: number;
}

export interface ChainInfoPreviewDto {
  chainInfo?: ChainInfoDto | null;
  errors?: string[] | null;
}

export interface ChainInfoSupplementApprovalDto {
  chainInfoSupplement?: ChainInfoSupplementDto | null;
  chainInfo?: ChainInfoDto | null;
  batch?: BreedingBatchDto | null;
  deliveries?: BatchDeliveryDto[] | null;
  feedInfo?: FeedInfoDto | null;
  sevenDayLoss?: SevenDayLossDto | null;
  diaries?: PoultryDiaryDto[] | null;
  audits?: ApprovalStatusAuditDto[] | null;
}

export interface ChainInfoSupplementCreateDto {
  /** @format date-time */
  fastingStartTime: string;

  /**
   * @format int32
   * @min 0
   */
  loadingMortality: number;
  notes?: string | null;
}

export interface ChainInfoSupplementDataDto {
  chainInfoSupplement?: ChainInfoSupplementDto | null;
  chainInfo: ChainInfoDto;
  batch?: BreedingBatchDto | null;
  deliveries: BatchDeliveryDto[];
  feedInfo: FeedInfoDto;
  sevenDayLoss: SevenDayLossDto;
  diaries?: PoultryDiaryDto[] | null;
  audits?: ApprovalStatusAuditDto[] | null;
}

export interface ChainInfoSupplementDto {
  /** @format uuid */
  id?: string | null;

  /** @format int32 */
  breedingId: number;

  /** @format date-time */
  reportCreateTime: string;
  reportCreateName?: string | null;

  /** @format date-time */
  fastingStartTime?: string | null;

  /** @format int32 */
  cumulativeLossTotal: number;

  /** @format double */
  cumulativeLossPercent: number;

  /** @format int32 */
  loadingMortality?: number | null;

  /** @format double */
  loadingMortalityPercent?: number | null;

  /** @format double */
  feedAndGrainConsumptionKg: number;

  /** @format double */
  grainPercent: number;

  /** @format double */
  waterConsumptionLiter: number;
  notes?: string | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  approvalStatus: ApprovalStatus;
}

export interface ChainInfoSupplementListDto {
  /** @format uuid */
  id: string;

  /** @format int32 */
  breedingId: number;

  /** @format date-time */
  reportCreateTime: string;
  reportCreateName?: string | null;
  producerName?: string | null;
  hallNumber?: string | null;
  approvalAuditUser?: string | null;

  /** @format date-time */
  approvalAuditTime?: string | null;
  approvalStatus: ApprovalStatus;

  /** @format int32 */
  chickQuantity: number;

  /** @format int32 */
  birdsLeft: number;

  /** @format int32 */
  cumulativeLossTotal: number;

  /** @format double */
  cumulativeLossPercent?: number | null;

  /** @format double */
  estimatedSlaughterWeightGrams?: number | null;

  /** @format int32 */
  slaughterSentQuantity: number;

  /** @format date-time */
  slaughterDate: string;

  /** @format date-time */
  slaughterStartTime: string;

  /** @format date-time */
  fastingStartTime: string;

  /** @format int32 */
  loadingMortality: number;

  /** @format double */
  loadingMortalityPercent: number;

  /** @format double */
  feedAndGrainConsumptionKg: number;

  /** @format double */
  grainPercent: number;

  /** @format double */
  waterConsumptionLiter: number;
  hasNotes: boolean;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
}

export interface ChainInfoSupplementPreviewDto {
  chainInfoSupplementData?: ChainInfoSupplementDataDto | null;
  errors?: string[] | null;
}

export enum ChickQuality {
  Good = "Good",
  Fair = "Fair",
  Poor = "Poor",
}

export interface ErrorsDto {
  errors?: string[] | null;
}

export interface FeedInfoDto {
  feedFactory?: string | null;
  feedProgram?: string | null;
  startFeeds?: FeedInfoRowDto[] | null;
  grow1Feeds?: FeedInfoRowDto[] | null;
  grow2Feeds?: FeedInfoRowDto[] | null;
  endFeeds?: FeedInfoRowDto[] | null;
  grains?: FeedInfoGrainDto[] | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  isFinal?: boolean | null;
}

export interface FeedInfoGrainDto {
  /** @format uuid */
  id?: string | null;
  strain?: string | null;

  /**
   * @format double
   * @min 0
   * @max 100
   */
  proteinPercent?: number | null;

  /**
   * @format double
   * @min 0
   * @max 100000
   */
  hectoLiterWeight?: number | null;

  /** @format date-time */
  date?: string | null;

  /**
   * @format int32
   * @min 2000
   * @max 3000
   */
  treshingYear?: number | null;

  /**
   * @format int32
   * @min 0
   * @max 100
   */
  percentOfTotal?: number | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
}

export interface FeedInfoRowDto {
  /** @format uuid */
  id?: string | null;
  type: FeedType;
  name?: string | null;
  coccidiostat?: string | null;

  /** @format date-time */
  date?: string | null;

  /**
   * @format double
   * @min 0
   */
  co2?: number | null;

  /** @format int32 */
  withholdingPeriod?: number | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
}

export interface FeedTemplateDto {
  feedFactory?: string | null;
  feedProgram?: string | null;
  startFeeds?: FeedTemplateRowDto[] | null;
  grow1Feeds?: FeedTemplateRowDto[] | null;
  grow2Feeds?: FeedTemplateRowDto[] | null;
  endFeeds?: FeedTemplateRowDto[] | null;
  grains?: FeedTemplateGrainDto[] | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
}

export interface FeedTemplateGrainDto {
  /** @format uuid */
  id?: string | null;
  strain?: string | null;

  /**
   * @format double
   * @min 0
   * @max 100
   */
  proteinPercent?: number | null;

  /**
   * @format double
   * @min 0
   * @max 100000
   */
  hectoLiterWeight?: number | null;

  /** @format date-time */
  date?: string | null;

  /**
   * @format int32
   * @min 2000
   * @max 3000
   */
  treshingYear?: number | null;

  /**
   * @format int32
   * @min 0
   * @max 100
   */
  percentOfTotal?: number | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
}

export interface FeedTemplateRowDto {
  /** @format uuid */
  id?: string | null;
  type: FeedType;
  name?: string | null;
  coccidiostat?: string | null;

  /** @format date-time */
  date?: string | null;

  /**
   * @format double
   * @min 0
   */
  co2?: number | null;

  /** @format int32 */
  withholdingPeriod?: number | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
}

export enum FeedType {
  Start = "Start",
  Grow1 = "Grow1",
  Grow2 = "Grow2",
  End = "End",
}

export interface HallBatchDto {
  hallNumber?: string | null;

  /** @format uuid */
  batchId?: string | null;

  /** @format int32 */
  breedingId?: number | null;
  batchStatus?: BreedingBatchStatus | null;

  /** @format int32 */
  chickQuantity?: number | null;

  /** @format date-time */
  arrivalDate?: string | null;

  /** @format date-time */
  slaughterDate?: string | null;
}

export interface HallDto {
  /** @format uuid */
  id: string;
  number?: string | null;
  producer?: ProducerDto | null;
}

export interface HallInfoDto {
  litterMaterial?: string | null;

  /** @format date-time */
  litterDeployedDate?: string | null;
  litterSupplier?: string | null;

  /** @format date-time */
  hallEmpty?: string | null;

  /** @format date-time */
  hallCleaned?: string | null;
  detergent?: string | null;

  /** @format double */
  detergentAmount?: number | null;

  /** @format date-time */
  detergentDate?: string | null;
  disinfectant?: string | null;

  /** @format double */
  disinfectantAmount?: number | null;

  /** @format date-time */
  disinfectantDate?: string | null;
  nippleLineDetergent?: string | null;

  /** @format double */
  nippleLineDetergentAmount?: number | null;

  /** @format date-time */
  nippleLineCleaned?: string | null;
  nippleLineDisinfectant?: string | null;

  /** @format double */
  nippleLineDisinfectantAmount?: number | null;

  /** @format date-time */
  nippleLineDisinfected?: string | null;
  feedSiloDetergent?: string | null;

  /** @format double */
  feedSiloDetergentAmount?: number | null;

  /** @format date-time */
  feedSiloCleaned?: string | null;

  /** @format date-time */
  rodentControl?: string | null;

  /** @format date-time */
  beetleControl?: string | null;
  pesticide?: string | null;

  /** @format date-time */
  heating?: string | null;

  /** @format date-time */
  airConditioning?: string | null;

  /** @format date-time */
  water?: string | null;

  /** @format date-time */
  aggregate?: string | null;
  visitors?: string | null;
  notes?: string | null;

  /** @format date-time */
  waterSampleTaken?: string | null;
  manureDisposal?: string | null;

  /** @format date-time */
  maintenanceRoomCleaned?: string | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  isFinal?: boolean | null;
}

export interface HallTemplateDto {
  litterMaterial?: string | null;

  /** @format date-time */
  litterDeployedDate?: string | null;
  litterSupplier?: string | null;

  /** @format date-time */
  hallEmpty?: string | null;

  /** @format date-time */
  hallCleaned?: string | null;
  detergent?: string | null;

  /** @format double */
  detergentAmount?: number | null;

  /** @format date-time */
  detergentDate?: string | null;
  disinfectant?: string | null;

  /** @format double */
  disinfectantAmount?: number | null;

  /** @format date-time */
  disinfectantDate?: string | null;
  nippleLineDetergent?: string | null;

  /** @format double */
  nippleLineDetergentAmount?: number | null;

  /** @format date-time */
  nippleLineCleaned?: string | null;
  nippleLineDisinfectant?: string | null;

  /** @format double */
  nippleLineDisinfectantAmount?: number | null;

  /** @format date-time */
  nippleLineDisinfected?: string | null;
  feedSiloDetergent?: string | null;

  /** @format double */
  feedSiloDetergentAmount?: number | null;

  /** @format date-time */
  feedSiloCleaned?: string | null;

  /** @format date-time */
  rodentControl?: string | null;

  /** @format date-time */
  beetleControl?: string | null;
  pesticide?: string | null;

  /** @format date-time */
  heating?: string | null;

  /** @format date-time */
  airConditioning?: string | null;

  /** @format date-time */
  water?: string | null;

  /** @format date-time */
  aggregate?: string | null;
  visitors?: string | null;
  notes?: string | null;

  /** @format date-time */
  waterSampleTaken?: string | null;
  manureDisposal?: string | null;

  /** @format date-time */
  maintenanceRoomCleaned?: string | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
}

export interface HealthDiseaseDto {
  /** @format uuid */
  id?: string | null;

  /** @format date-time */
  dateDetected: string;
  samplesTaken: string;
  foodAgencyDiagnosis?: string | null;
  veterinarianDiagnosis?: string | null;
}

export interface HealthInfoDto {
  noAnimalProtectionRestrictions?: boolean | null;
  noFoodSafetyIssuesDetected?: boolean | null;
  healthAnomaliesDetected?: boolean | null;
  healthAnomalies?: string | null;
  noMedicationsUsed?: boolean | null;
  salmonellaSamplePositive?: boolean | null;
  diseases?: HealthDiseaseDto[] | null;
  medications?: HealthMedicationDto[] | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  isFinal?: boolean | null;
}

export interface HealthMedicationDto {
  /** @format uuid */
  id?: string | null;

  /** @format date-time */
  startDate: string;

  /** @format date-time */
  endDate?: string | null;
  medicine: string;

  /**
   * @format int32
   * @min 0
   */
  withholdingPeriod?: number | null;
}

export interface NotificationApprovalDto {
  notification?: NotificationDto | null;
  audits?: ApprovalStatusAuditDto[] | null;
}

export interface NotificationCreateDto {
  type: NotificationType;
  notes?: string | null;
}

export interface NotificationDto {
  /** @format uuid */
  id: string;

  /** @format uuid */
  breedingBatchId: string;

  /** @format int32 */
  breedingId: number;

  /** @format int32 */
  producerNumber: number;
  producerName?: string | null;
  hallNumber?: string | null;

  /** @format uuid */
  poultryDiaryId?: string | null;

  /** @format date-time */
  diaryDate?: string | null;

  /** @format int32 */
  diaryDay?: number | null;
  type: NotificationType;
  message?: string | null;
  notes?: string | null;

  /** @format date-time */
  slaughterDate: string;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  approvalStatus: ApprovalStatus;
}

export enum NotificationType {
  General = "General",
  DailyLoss = "DailyLoss",
  ConsecutiveLoss = "ConsecutiveLoss",
  LightDimming = "LightDimming",
}

export interface OptionDto {
  /** @format uuid */
  id: string;

  /** @format uuid */
  parentId?: string | null;
  value?: string | null;
  text?: string | null;
  group?: string | null;
}

export interface PaginatedListBreedingBatchDto {
  items?: BreedingBatchDto[] | null;

  /** @format int32 */
  pageIndex: number;

  /** @format int32 */
  totalPages: number;

  /** @format int32 */
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface PoultryDiaryDto {
  /** @format uuid */
  id: string;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;

  /** @format uuid */
  breedingBatchId: string;

  /** @format int32 */
  breedingId: number;

  /** @format int32 */
  producerNumber: number;
  isFinal: boolean;
  isLossFinal: boolean;

  /** @format date-time */
  date: string;

  /** @format int32 */
  day: number;

  /** @format int32 */
  birdsLeft: number;

  /** @format int32 */
  birdsDead: number;

  /** @format int32 */
  birdsCulled: number;

  /** @format double */
  feedConsumptionKg?: number | null;

  /** @format double */
  grainConsumptionKg?: number | null;

  /** @format double */
  waterConsumptionLiter?: number | null;

  /** @format double */
  liveWeightGrams?: number | null;

  /** @format double */
  temperature?: number | null;

  /** @format double */
  co2?: number | null;

  /** @format double */
  humidity?: number | null;
  addedVitaminC: boolean;
  addedPotassiumChloride: boolean;
  otherSupplements?: string | null;
  notes?: string | null;
  notificationTypes?: NotificationType[] | null;
  poultryLosses?: PoultryLossDto[] | null;
}

export interface PoultryDiaryUpdateDto {
  /**
   * @format double
   * @min 0
   */
  feedConsumptionKg?: number | null;

  /**
   * @format double
   * @min 0
   */
  grainConsumptionKg?: number | null;

  /**
   * @format double
   * @min 0
   */
  waterConsumptionLiter?: number | null;

  /**
   * @format double
   * @min 0
   */
  liveWeightGrams?: number | null;

  /**
   * @format double
   * @min -50
   * @max 50
   */
  temperature?: number | null;

  /**
   * @format double
   * @min 0
   * @max 1000000
   */
  co2?: number | null;

  /**
   * @format double
   * @min 0
   * @max 100
   */
  humidity?: number | null;
  addedVitaminC?: boolean | null;
  addedPotassiumChloride?: boolean | null;
  otherSupplements?: string | null;
  notes?: string | null;
}

export interface PoultryLossAddDto {
  /** @format int32 */
  dead?: number | null;

  /** @format int32 */
  culled?: number | null;
}

export interface PoultryLossDto {
  /** @format uuid */
  id: string;
  isFinal: boolean;

  /** @format date-time */
  date: string;

  /** @format int32 */
  dead: number;

  /** @format int32 */
  culled: number;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
}

export interface PoultryLossSetDto {
  /** @format int32 */
  dead?: number | null;

  /** @format int32 */
  culled?: number | null;
}

export interface ProducerDto {
  /** @format uuid */
  id: string;

  /** @format int32 */
  producerNumber: number;
  name?: string | null;
  businessId?: string | null;
}

export interface SalmonellaSampleApprovalDto {
  salmonellaSample?: SalmonellaSampleDto | null;
  audits?: ApprovalStatusAuditDto[] | null;
}

export interface SalmonellaSampleBatchPreviewDto {
  salmonellaSamplePreview?: SalmonellaSamplePreviewDto | null;
  breedingBatch?: BreedingBatchDto | null;
}

export interface SalmonellaSampleCoveringDto {
  producerName?: string | null;
  hatchery?: string | null;
  feedFactory?: string | null;

  /** @format date-time */
  sampleDate: string;
  halls?: SalmonellaSampleCoveringHallDto[] | null;
}

export interface SalmonellaSampleCoveringHallDto {
  hall?: string | null;
  isOfficialSample: boolean;

  /** @format date-time */
  sampleDate: string;

  /** @format int32 */
  totalLoss: number;

  /** @format date-time */
  lossDate: string;
}

export interface SalmonellaSampleCreateBatchDto {
  /** @format date-time */
  sampleDate: string;
  breedingBatchIds: string[];
  isOfficialSample: boolean;
  notes?: string | null;
}

export interface SalmonellaSampleCreateDto {
  /** @format date-time */
  sampleDate: string;
  isOfficialSample: boolean;
  notes?: string | null;
}

export interface SalmonellaSampleDto {
  /** @format uuid */
  id: string;

  /** @format int32 */
  producerNumber: number;
  producerName?: string | null;

  /** @format int32 */
  breedingId: number;
  hallNumber?: string | null;
  hatchery?: string | null;
  feedFactory?: string | null;

  /** @format date-time */
  sampleDate: string;

  /** @format date-time */
  lossDate: string;

  /** @format int32 */
  totalLoss: number;
  isOfficialSample: boolean;
  notes?: string | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  approvalStatus: ApprovalStatus;
}

export interface SalmonellaSampleListDto {
  /** @format uuid */
  id: string;

  /** @format int32 */
  breedingId: number;
  producerName?: string | null;
  hallNumber?: string | null;
  approvalStatus: ApprovalStatus;
  approvalAuditUser?: string | null;

  /** @format date-time */
  approvalAuditTime?: string | null;
  hatchery?: string | null;
  feedFactory?: string | null;

  /** @format date-time */
  sampleDate: string;

  /** @format date-time */
  lossDate: string;

  /** @format int32 */
  totalLoss: number;

  /** @format int32 */
  sevenDayLoss?: number | null;

  /** @format int32 */
  startQuantity: number;
  isOfficialSample: boolean;
  notes?: string | null;

  /** @format date-time */
  slaughterDate: string;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
}

export interface SalmonellaSamplePreviewDto {
  salmonellaSample?: SalmonellaSampleDto | null;
  hasExisting: boolean;
  errors?: string[] | null;
}

export interface SevenDayLossApprovalDto {
  sevenDayLoss?: SevenDayLossDto | null;
  deliveries?: BatchDeliveryDto[] | null;
  audits?: ApprovalStatusAuditDto[] | null;
}

export interface SevenDayLossCreateDto {
  chickQuality: ChickQuality;
  notes?: string | null;
}

export interface SevenDayLossDto {
  /** @format uuid */
  id: string;

  /** @format int32 */
  producerNumber: number;
  producerName?: string | null;

  /** @format int32 */
  breedingId: number;
  hallNumber?: string | null;

  /** @format date-time */
  reportDate: string;

  /** @format int32 */
  reportDay: number;

  /** @format int32 */
  startQuantity: number;

  /** @format double */
  liveWeightGrams: number;

  /** @format int32 */
  birdsLeft: number;

  /** @format int32 */
  birdsDead: number;

  /** @format int32 */
  birdsCulled: number;

  /** @format int32 */
  totalLoss: number;
  chickQuality?: ChickQuality | null;
  notes?: string | null;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
  approvalStatus: ApprovalStatus;
}

export interface SevenDayLossListDto {
  /** @format uuid */
  id: string;

  /** @format int32 */
  breedingId: number;
  producerName?: string | null;
  hallNumber?: string | null;
  approvalStatus: ApprovalStatus;
  approvalAuditUser?: string | null;

  /** @format date-time */
  approvalAuditTime?: string | null;

  /** @format date-time */
  reportDate: string;

  /** @format int32 */
  reportDay: number;

  /** @format int32 */
  startQuantity: number;

  /** @format double */
  liveWeightGrams: number;

  /** @format int32 */
  birdsLeft: number;

  /** @format int32 */
  birdsDead: number;

  /** @format int32 */
  birdsCulled: number;

  /** @format int32 */
  totalLoss: number;
  chickQuality?: ChickQuality | null;
  notes?: string | null;

  /** @format int32 */
  sentQuantity: number;

  /** @format int32 */
  transportMortality: number;
  arrivalDates?: string[] | null;
  parentFlocks?: string[] | null;

  /** @format date-time */
  slaughterDate: string;

  /** @format date-time */
  createdDate?: string | null;

  /** @format date-time */
  modifiedDate?: string | null;
  createdBy?: string | null;
  modifiedBy?: string | null;
}

export interface SevenDayLossPreviewDto {
  sevenDayLoss?: SevenDayLossDto | null;
  errors?: string[] | null;
}

export interface StatusAccessApprovalStatus {
  views?: ViewName[] | null;
  read?: ApprovalStatus[] | null;
  write?: ApprovalStatus[] | null;
}

export interface ValidationProblemDetails {
  errors?: Record<string, string[]>;
  type?: string | null;
  title?: string | null;

  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
}

export enum ViewName {
  ChainInfo = "ChainInfo",
  ChainInfoSupplement = "ChainInfoSupplement",
  SevenDayLoss = "SevenDayLoss",
  SalmonellaSample = "SalmonellaSample",
  Notification = "Notification",
}
