import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(({
  menuButton: {
    verticalAlign: "baseline",
    padding: "0",
    "& > * > *": {
      textTransform: "none",
      fontWeight: "500",
      lineHeight: "0px",
    }
  }
}));

export default useStyles;