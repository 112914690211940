import { FeedTemplateDto, HallTemplateDto } from "../models/ApiSchema";
import http from "./httpService";

const API_URL = `templates`;

export const getFeedTemplate = async () => {
  const resp = await http.get<FeedTemplateDto>(`${API_URL}/feed`);
  return resp;
};

export const updateFeedTemplate = async (data: FeedTemplateDto) => {
  const resp = await http.put<FeedTemplateDto>(`${API_URL}/feed`, data);
  return resp;
};

export const getHallTemplate = async () => {
  const resp = await http.get<HallTemplateDto>(`${API_URL}/hall`);
  return resp;
};

export const updateHallTemplate = async (data: HallTemplateDto) => {
  const resp = await http.put<HallTemplateDto>(`${API_URL}/hall`, data);
  return resp;
};

const templateService = {
  getFeedTemplate,
  updateFeedTemplate,
  getHallTemplate,
  updateHallTemplate
};
export default templateService;