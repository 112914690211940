import { ApprovalStatus, BreedingBatchStatus, ChickQuality, NotificationType } from "../models/ApiSchema";

export const approvalStatusText = (status: ApprovalStatus | null | undefined): string => {
  if (!status) return "";
  switch (status) {
    case ApprovalStatus.Open: return "Luonnos";
    case ApprovalStatus.Sent: return "Lähetetty";
    case ApprovalStatus.Inspected: return "Tarkastettu";
    case ApprovalStatus.Approved: return "Hyväksytty";
    case ApprovalStatus.Returned: return "Palautettu";
    case ApprovalStatus.Rejected: return "Hylätty";
    case ApprovalStatus.Superceded: return "Korvattu";
    case ApprovalStatus.Received: return "Vastaanotettu";
    default: return status;
  }
};

export const batchStatusText = (status: BreedingBatchStatus | null | undefined): string => {
  if (!status) return "";
  switch (status) {
    case BreedingBatchStatus.Planned: return "Tulossa";
    case BreedingBatchStatus.Active: return "Käynnissä";
    case BreedingBatchStatus.Completed: return "Päätetty";
    default: return status;
  }
};

export const chickQualityText = (status: ChickQuality | null | undefined): string => {
  if (!status) return "";
  switch (status) {
    case ChickQuality.Good: return "Hyvä";
    case ChickQuality.Fair: return "Normaali";
    case ChickQuality.Poor: return "Huono";
    default: return status;
  }
};

export const notificationTypeText = (status: NotificationType | null | undefined): string => {
  if (!status) return "";
  switch (status) {
    case NotificationType.General: return "Yleinen ilmoitus";
    case NotificationType.LightDimming: return "Valaistuksen himmentäminen";
    case NotificationType.DailyLoss: return "Yli 0,5 % päiväkohtainen poistuma";
    case NotificationType.ConsecutiveLoss: return "Yli 0,2 % poistuma 3 pvä peräkkäin";
    default: return status;
  }
};

const statusHelper = {
  approvalStatusText: approvalStatusText,
  batchStatusText: batchStatusText,
  chickQualityText: chickQualityText
};
export default statusHelper;
