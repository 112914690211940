import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import HallInfoForm from "../HallInfoForm/HallInfoForm";
import { BreedingBatchDto, HallInfoDto } from "../../../../shared/models/ApiSchema";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useConfirm } from "material-ui-confirm";
import * as api from "../../../../shared/store/apiActions";
import { useDispatch } from "react-redux";
import BatchHeader from "../BatchHeader/BatchHeader";

interface HallInfoModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
  hall: HallInfoDto | null;
}

const HallInfoModal: React.FC<HallInfoModalProps> = ({ open, onClose, batch, hall }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const [changed, setChanged] = useState(false);

  const handleLoad = () => {
    confirm({ description: "Lomakkeen kentät ylikirjoitetaan yleisten hallitietojen tiedoilla." }).then(() => {
      dispatch(api.batch.loadFromHallTemplate());
      setChanged(true);
    });
  };

  const handleCancel = () => {
    onClose(null, changed);
  };

  const handleSubmit = (data) => {
    onClose(data, false);
  };

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="md"
      open={open}
      TransitionProps={{onEnter: () => setChanged(false)}}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <Box mb={-2} display="flex" alignItems="center" justifyContent="space-between">
          <PageHeader level="2" component="span">Erän {batch.breedingId} hallitiedot</PageHeader>
          {!hall?.isFinal && <IconButton onClick={handleLoad}>
            <CloudDownloadIcon fontSize="large" />
          </IconButton>}
        </Box>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        <HallInfoForm
          formId="hallInfoForm"
          onSubmit={handleSubmit}
          values={hall}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>{hall?.isFinal ? "Sulje" : "Peruuta"}</Button>
        {!hall?.isFinal && <Button variant="text" type="submit" form="hallInfoForm">Tallenna</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default HallInfoModal;
