import { useEffect, useState } from "react";
import { BreedingBatchDto, PoultryDiaryDto } from "../../models/ApiSchema";
import { getCumulativeLossPercentage, getDailyLossPercentage, getDiaryLabels } from "../../helpers/charthelper";

import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface BatchLossChartProps {
  diaries: PoultryDiaryDto[];
  batch: BreedingBatchDto;
}

const BatchLossChart: React.FC<BatchLossChartProps> = ({ diaries, batch }) => {

  const options = {
    locale: 'fi',
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          font: { size: 11 },
        }
      },
      tooltip: {
        position: 'nearest' as const,
        callbacks: {
          title: (tooltipItem: any) => `Kasvatusikä ${tooltipItem[0].label} vrk`,
        }
      }
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,    
    scales: {
      x: {
          ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          font: { size: 11 },
        },
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        min: 0,
        suggestedMax: 0.7,
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Päiväkoht. kuolleisuusaste (%)',
          font: { size: 11 },
        },
        ticks: {
          font: { size: 11 },
        },
        grid: {
          drawOnChartArea: true,
        },
      },
      y2: {
        beginAtZero: true,
        min: 0,
        suggestedMax: 6,
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Kumulatiivinen poistuma (%)',
          font: { size: 11 },
        },
        ticks: {
          font: { size: 11 },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const [labels, setLabels] = useState<number[]>([]);
  const [dailyLoss, setDailyLoss] = useState<(number|null)[]>([]);
  const [cumulativeLoss, setCumulativeLoss] = useState<(number|null)[]>([]);

  useEffect(() => {
    if (!diaries.length) return;
    setLabels(getDiaryLabels(diaries));
    setDailyLoss(getDailyLossPercentage(diaries));
    setCumulativeLoss(getCumulativeLossPercentage(diaries));
  }, [diaries]);

  return (
    <Chart type='bar' height={100} width={100} options={options} data={{
      labels: labels,
      datasets: [
        {
          type: 'bar' as const,
          order: 2,
          label: 'Päiväkoht. poistuma (%)',
          barPercentage: 0.5,
          borderColor: '#4472c4',
          backgroundColor: '#4472c4',
          data: dailyLoss,
          yAxisID: 'y',
        },
        {
          type: 'line' as const,
          order: 1,
          label: 'Kumulat. poistuma (%)',
          pointRadius: 1.5,
          borderWidth: 2.5,
          borderColor: '#ed7d31',
          backgroundColor: '#ed7d31',
          data: cumulativeLoss,
          yAxisID: 'y2',          
        },
      ]
    }} />
  )
};

export default BatchLossChart;
