import React from "react";
import useStyles from "./PrintStyles";
import { Route, Switch } from "react-router-dom";
import Content from "../../shared/components/Content/Content";
import SalmonellaSample from "./SalmonellaSample/SalmonellaSample";
import PrintList from "./PrintList/PrintList";

const Print = () => {
  const classes = useStyles();

  return (
    <Content maxWidth="lg" backGroundStyles={classes.backGroundStyles}>
      <Switch>
        <Route path="/print/salmonella/:date?" component={SalmonellaSample} />
        <Route path="/print" component={PrintList} />
      </Switch>
    </Content>
  );
};

export default Print;