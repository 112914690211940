import { NotificationType, PoultryDiaryDto } from "../models/ApiSchema";

export const calcLoss = (diary: PoultryDiaryDto) : number => {
  return diary.birdsDead + diary.birdsCulled;
};

export const calcBirds = (diary: PoultryDiaryDto) : number => {
  return diary.birdsLeft + diary.birdsDead + diary.birdsCulled;
};

export const calcLossPercent = (diary: PoultryDiaryDto) : number => {
  const loss = calcLoss(diary);
  return loss / (diary.birdsLeft + loss);
};

export const lossWarning = (diary: PoultryDiaryDto) : boolean => {
  return calcLossPercent(diary) > 0.002;
};

export const lossDanger = (diary: PoultryDiaryDto) : boolean => {
  return calcLossPercent(diary) > 0.005;
};

export const calcTotalWeightKg = (diary: PoultryDiaryDto) : number => {
  return (diary.liveWeightGrams || 0) * diary.birdsLeft / 1000;
};

export const calcFeedPerBirdGram = (diary: PoultryDiaryDto) : number => {
  return (diary.feedConsumptionKg || 0) / (diary.birdsLeft || 1) * 1000;
};

export const calcGrainPerBirdGram = (diary: PoultryDiaryDto) : number => {
  return (diary.grainConsumptionKg || 0) / (diary.birdsLeft || 1) * 1000;
};

export const calcWaterPerBirdMl = (diary: PoultryDiaryDto) : number => {
  return (diary.waterConsumptionLiter || 0) / (diary.birdsLeft || 1) * 1000;
};

export const calcFeedTotalKg = (diary: PoultryDiaryDto) : number | null => {
  if (diary.feedConsumptionKg || diary.grainConsumptionKg) {
    return (diary.feedConsumptionKg || 0) + (diary.grainConsumptionKg || 0);
  }
  return null;
};

export const calcFeedTotalPerBirdGram = (diary: PoultryDiaryDto) : number | null => {
  const total = calcFeedTotalKg(diary);
  if (total) {
    return total * 1000 / (diary.birdsLeft || 1);
  }
  return null;
};

export const calcGrainPercent = (diary: PoultryDiaryDto) : number | null => {
  const total = calcFeedTotalKg(diary);
  if (total) {
    return (diary.grainConsumptionKg || 0) / total;
  }
  return null;
};

export const cumulativeLoss = (diaries: PoultryDiaryDto[]): number => {
  return diaries.map(d => calcLoss(d) || 0).reduce((p, c) => p + c, 0);
};

export const cumulativeLossPercent = (diaries: PoultryDiaryDto[]): number => {
  return diaries.map(d => calcLossPercent(d) || 0).reduce((p, c) => p + c, 0);
};

export const cumulativeFeedTotalPerBirdGram = (diaries: PoultryDiaryDto[], initialFeed: number = 0, initialBirds: number = 1): number => {
  const initialPerBird = initialFeed * 1000 / initialBirds;
  return diaries.map(d => calcFeedTotalPerBirdGram(d) || 0).reduce((p, c) => p + c, 0) + initialPerBird;
};

export const cumulativeFeedTotalKg = (diaries: PoultryDiaryDto[], initialFeed: number = 0): number => {
  return diaries.map(d => calcFeedTotalKg(d) || 0).reduce((p, c) => p + c, 0) + initialFeed;
};

export const cumulativeGrainPercent = (diaries: PoultryDiaryDto[], initialFeed: number = 0): number => {
  const totalFeed = diaries.map(d => d.feedConsumptionKg || 0).reduce((p, c) => p + c, 0) + initialFeed;
  const totalGrain = diaries.map(d => d.grainConsumptionKg || 0).reduce((p, c) => p + c, 0);
  return totalGrain / ((totalFeed + totalGrain) || 1);
};

export const cumulativeWaterLiter = (diaries: PoultryDiaryDto[]): number => {
  return diaries.map(d => d.waterConsumptionLiter || 0).reduce((p, c) => p + c, 0);
};

export const cumulativeWaterPerBirdMl = (diaries: PoultryDiaryDto[]): number => {
  return diaries.map(d => calcWaterPerBirdMl(d) || 0).reduce((p, c) => p + c, 0);
};

export const cumulativeTotal = (diaries: PoultryDiaryDto[], initialFeed: number = 0): any => {
  const first = diaries[0];
  const last = diaries[diaries.length - 1];
  return {
    id: "TOTAL",
    isFinal: true,
    birdsDead: diaries.map(d => d.birdsDead).reduce((p, c) => p + c, 0),
    birdsCulled: diaries.map(d => d.birdsCulled).reduce((p, c) => p + c, 0),
    birdsLeft: last.birdsLeft,
    totalLossPercent: cumulativeLossPercent(diaries),
    feedConsumptionKg: diaries.map(d => d.feedConsumptionKg || 0).reduce((p, c) => p + c, 0) + initialFeed,
    grainConsumptionKg: diaries.map(d => d.grainConsumptionKg || 0).reduce((p, c) => p + c, 0),
    feedTotalPerBirdKg: cumulativeFeedTotalPerBirdGram(diaries, initialFeed, calcBirds(first)) / 1000,
    waterConsumptionLiter: diaries.map(d => d.waterConsumptionLiter || 0).reduce((p, c) => p + c, 0)
  };
};

export const getLossStyle = (diary: PoultryDiaryDto): any => {
  const style: any = {};
  if (lossDanger(diary)) {
    style.color = 'white';
    style.backgroundColor = '#ff6666';
  }
  else if (lossWarning(diary)) {
    style.color = 'black';
    style.backgroundColor = 'yellow';
  }
  return style;
}

export const getExceededDailyLoss = (diaries: PoultryDiaryDto[]): PoultryDiaryDto[] => {
  return diaries.filter(d => lossDanger(d) && !d.notificationTypes?.includes(NotificationType.DailyLoss));
}

export const getExceededConsecutiveLoss = (diaries: PoultryDiaryDto[]): PoultryDiaryDto | null => {
  let _diaries = diaries.filter(d => lossWarning(d) && !lossDanger(d) && !d.notificationTypes?.includes(NotificationType.DailyLoss)).reverse();

  if (_diaries.length < 3) return null;

  let candidate = _diaries[0];
  if (candidate.notificationTypes?.includes(NotificationType.ConsecutiveLoss))
  {
    return null;
  }

  let consecutive = 1;
  for (let i = 1; i < _diaries.length; i++) {
    const d = _diaries[i];

    if (d.notificationTypes?.includes(NotificationType.ConsecutiveLoss))
    {
      return null;
    }

    if (d.day === candidate.day - consecutive) {
      consecutive++;
    }
    else {
      candidate = d;
      consecutive = 1;
    }

    if (consecutive >= 3) {
      return candidate;
    }
  }

  return null;
}

const diaryHelper = {
  calcLoss,
  calcLossPercent,
  lossWarning,
  lossDanger,
  calcTotalWeightKg,
  calcFeedPerBirdGram,
  calcGrainPerBirdGram,
  calcWaterPerBirdMl,
  calcFeedTotalKg,
  calcFeedTotalPerBirdGram,
  calcGrainPercent,
  cumulativeLoss,
  cumulativeLossPercent,
  cumulativeFeedTotalPerBirdGram,
  cumulativeFeedTotalKg,
  cumulativeGrainPercent,
  cumulativeWaterLiter,
  cumulativeWaterPerBirdMl,
  getLossStyle,
  getExceededDailyLoss,
  getExceededConsecutiveLoss
};
export default diaryHelper;