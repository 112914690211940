import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import { BreedingBatchDto, PoultryDiaryDto } from "../../../../shared/models/ApiSchema";
import DiaryHeader from "../DiaryHeader/DiaryHeader";
import NotesForm from "../NotesForm/NotesForm";
// import DaySwitcher from "../DaySwitcher/DaySwitcher";

interface NotesModalProps {
  open: boolean;
  onClose: any;
  batch?: BreedingBatchDto | null;
  diary?: PoultryDiaryDto | null;
}

const NotesModal: React.FC<NotesModalProps> = ({
  open,
  onClose,
  batch,
  diary
}) => {
  const classes = useStyles();

  const handleCancel = () => {
    onClose(null);
  };

  const handleSubmit = (data) => {
    onClose(data);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle className={classes.title}>
        <DiaryHeader title="Huomioita" batch={batch} diary={diary} />
        {/* <DaySwitcher /> */}
      </DialogTitle>
      <DialogContent>
        <NotesForm
          formId="notesForm"
          onSubmit={handleSubmit}
          values={diary}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel} tabIndex={-1}>Peruuta</Button>
        <Button variant="text" type="submit" form="notesForm">Tallenna</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotesModal;
