import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from "react";
import { useHistory } from 'react-router-dom';
import { BatchNotificationDto } from '../../../../shared/models/ApiSchema';
import useStyles from './NotificationsListStyles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Date from "../../../../shared/components/Formatters/Date";

interface NotificationsListProps {
  notifications: BatchNotificationDto[]
}

const NotificationsList: React.FC<NotificationsListProps> = ({notifications}) => {

  const classes = useStyles();
  const history = useHistory();

  const avatarClasses = (n: BatchNotificationDto) => {
    return classes.returned;
  }

  const navigateBatch = (batchId: any) => {
    if (batchId) history.push(`/batch/${batchId}`);
  }

  if (!notifications.length) return (
    <Box py={1}>
      <Alert severity="success">Ei ilmoituksia</Alert>
    </Box>
  );

  return (
    <List>
      {notifications.map((n, index) =>
        <ListItem key={index} button onClick={(event) => navigateBatch(n.breedingBatchId)}>
          <ListItemAvatar>
            <Avatar className={avatarClasses(n)}>
              <strong>{n.hall}</strong>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<span>{n.description} <small>(<Date value={n.date} />)</small></span>}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="Ilmoitus" onClick={(event) => navigateBatch(n.breedingBatchId)}>
              <NavigateNextIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </List>
  );

};
export default NotificationsList;