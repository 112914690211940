import React from "react";
import useStyles from "./TemplatesStyles";
import { Route, Switch } from "react-router-dom";
import Content from "../../shared/components/Content/Content";
import FeedTemplate from "./FeedTemplate/FeedTemplate";
import HallTemplate from "./HallTemplate/HallTemplate";
import TemplateList from "./TemplateList/TemplateList";
import HealthInit from "./HealthInit/HealthInit";
import SalmonellaSample from "./SalmonellaSample/SalmonellaSample";

const Templates = () => {
  const classes = useStyles();

  return (
    <Content maxWidth="lg" backGroundStyles={classes.backGroundStyles}>
      <Switch>
        <Route path="/templates/feed" component={FeedTemplate} />
        <Route path="/templates/hall" component={HallTemplate} />
        <Route path="/templates/health" component={HealthInit} />
        <Route path="/templates/salmonella" component={SalmonellaSample} />
        <Route path="/templates" component={TemplateList} />
      </Switch>
    </Content>
  );
};

export default Templates;