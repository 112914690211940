import { Box, Grid, IconButton, InputAdornment, MenuItem, Paper } from '@material-ui/core';
import { Formik, Form, FieldArray, FastField, FieldArrayRenderProps, Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import DatePicker from '../../../../shared/components/DatePicker/DatePicker';
import { HealthInfoDto, SalmonellaSampleDto } from '../../../../shared/models/ApiSchema';
import PageHeader from '../../../../shared/components/PageHeader/PageHeader';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { useConfirm } from 'material-ui-confirm';
import { dateOnly, numberEmptyToNull } from '../../../../shared/helpers/formHelper';
import FormikDisabler from '../../../../shared/components/FormikDisabler/FormikDisabler';
import { Alert } from '@material-ui/lab';
import Date from "../../../../shared/components/Formatters/Date";

interface HealthInfoFormProps {
  formId: string;
  values: HealthInfoDto | null;
  salmonella?: SalmonellaSampleDto | null;
  onSubmit: any;
}

const HealthInfoForm: React.FC<HealthInfoFormProps> = ({formId, values, salmonella, onSubmit}) => {
  const confirm = useConfirm();

  const makeDiseaseRow = () => {
    return { id: null, dateDetected: null, samplesTaken: "", foodAgencyDiagnosis: "", veterinarianDiagnosis: "" };
  };

  const makeMedicationRow = () => {
    return { id: null, startDate: null, endDate: null, medicine: "", withholdingPeriod: "" };
  };

  const initialValues: HealthInfoDto = {
    noAnimalProtectionRestrictions: false,
    noFoodSafetyIssuesDetected: false,
    healthAnomaliesDetected: false,
    healthAnomalies: "",
    noMedicationsUsed: true,
    diseases: [],
    medications: [],
    // @ts-expect-error
    salmonellaSamplePositive: ""
  };

  const diseaseSchema = Yup.object().shape({
    id: Yup
      .string()
      .nullable(),
    dateDetected: Yup
      .date()
      .transform(dateOnly)
      .required()
      .nullable(),
    samplesTaken: Yup
      .string()
      .required()
      .nullable()
      .max(100),
    foodAgencyDiagnosis: Yup
      .string()
      .nullable()
      .max(4000),
    veterinarianDiagnosis: Yup
      .string()
      .nullable()
      .max(4000),
  });

  const medicationSchema = Yup.object().shape({
    id: Yup
      .string()
      .nullable(),
    startDate: Yup
      .date()
      .transform(dateOnly)
      .required()
      .nullable(),
    endDate: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    medicine: Yup
      .string()
      .required()
      .nullable()
      .max(100),
    withholdingPeriod: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(10000),
  });

  const formSchema = Yup.object().shape({
    noAnimalProtectionRestrictions: Yup
      .boolean()
      .nullable(),
    noFoodSafetyIssuesDetected: Yup
      .boolean()
      .nullable(),
    healthAnomaliesDetected: Yup
      .boolean()
      .nullable(),
    healthAnomalies: Yup
      .string()
      .max(4000),
    noMedicationsUsed: Yup
      .boolean()
      .nullable(),
    salmonellaSamplePositive: Yup
      .string()
      .oneOf(["", "true", "false"]),
    diseases: Yup
      .array().of(diseaseSchema),
    medications: Yup
      .array().of(medicationSchema),
  });

  const removeDiseaseRow = (arrayHelpers: FieldArrayRenderProps, index: number) => {
    confirm({ description: "Valittu tautirivi poistetaan, mutta poisto on lopullinen vasta kun lomake tallennetaan." }).then(() => {
      arrayHelpers.remove(index);
    });
  }

  const removeMedicationRow = (arrayHelpers: FieldArrayRenderProps, index: number) => {
    confirm({ description: "Valittu lääkitysrivi poistetaan, mutta poisto on lopullinen vasta kun lomake tallennetaan." }).then(() => {
      arrayHelpers.remove(index);
    });
  }

  return (
      <Formik
        initialValues={values || initialValues}
        enableReinitialize={true}
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          const data = formSchema.cast(values, { stripUnknown: true });
          actions.setSubmitting(false);
          actions.resetForm({ values: initialValues });
          onSubmit(data);
        }}
      >
        {({ values, submitForm, isSubmitting }) => (
          <Form id={formId}>
            <FormikDisabler disabled={values?.isFinal === true} />
            <Box my={2}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="noAnimalProtectionRestrictions"
                Label={{ label: "Tilalla ei ole eläintautilain nojalla annettuja rajoittavia määräyksiä." }}
                margin="dense"
              />
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="noFoodSafetyIssuesDetected"
                Label={{ label: "Kasvatusaikana ei ole todettu elintarviketurvallisuuteen vaikuttavia tauteja." }}
                margin="dense"
              />
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="healthAnomaliesDetected"
                Label={{ label: "Tuottajan / hoitajan havaitsemat poikkeamat lintujen terveydessä ja kunnossa, sekä muut huomiot:" }}
                margin="dense"
              />
              <FastField
                component={TextField}
                label="Kuvaus havainnoista"
                name="healthAnomalies"
                fullWidth
                InputProps={{
                  multiline: true,
                  maxRows: 8
                }}
                margin="dense"
              />
              <Box my={2}>
                <PageHeader level="3" component="div">Salmonellatestin tulos</PageHeader>
                <Box mt={1}>
                  {salmonella?.sampleDate != null
                    ? <strong>Näytteenottopäivä <Date value={salmonella.sampleDate} />{" "}
                        ({salmonella.isOfficialSample ? "virallinen näyte" : "rutiininäyte"}):</strong>
                    : <Alert severity="info">Salmonellanäytteen tuloksen voi kirjata kun lähete on luotu.</Alert>
                  }
                </Box>
                {salmonella?.sampleDate != null &&
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        select
                        component={TextField}
                        label="Salmonellatestin tulos"
                        name="salmonellaSamplePositive"
                        margin="dense"
                        fullWidth
                      >
                        <MenuItem value="false">NEGATIIVINEN</MenuItem>
                        <MenuItem value="true">POSITIIVINEN</MenuItem>
                      </Field>
                    </Grid>
                  </Grid>
                }
              </Box>
              <FieldArray
                name="diseases"
                render={arrayHelpers => (
                  <Box my={2}>
                    <Box display="flex" alignItems="center">
                      <PageHeader level="3" component="div">Eläinlääkärin / ruokaviraston / laboratorion toteamat taudit ja otetut näytteet</PageHeader>
                      {!values?.isFinal && <IconButton onClick={() => arrayHelpers.push(makeDiseaseRow())}>
                        <AddCircleIcon fontSize="small" />
                      </IconButton>}
                    </Box>
                    {values.diseases && values.diseases.length > 0 && values.diseases.map((disease, index) => (
                      <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                        <Box my={1} flexGrow={1}>
                          <Paper variant="outlined" style={{padding: "1em"}}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={4}>
                                <FastField
                                  name={`diseases[${index}].dateDetected`}
                                  label="Toteamispäivä"
                                  component={DatePicker}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={8}>
                                <FastField
                                  component={TextField}
                                  label="Otetut näytteet"
                                  name={`diseases[${index}].samplesTaken`}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FastField
                                  component={TextField}
                                  label="Ruokaviraston diagnoosi"
                                  name={`diseases[${index}].foodAgencyDiagnosis`}
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    multiline: true,
                                    maxRows: 8
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FastField
                                  component={TextField}
                                  label="Suullinen lausunto/ELL"
                                  name={`diseases[${index}].veterinarianDiagnosis`}
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    multiline: true,
                                    maxRows: 8
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Box>
                        {!values?.isFinal && <IconButton onClick={() => removeDiseaseRow(arrayHelpers, index)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>}
                      </Box>
                    ))}
                  </Box>
                )}
              />
              <FieldArray
                name="medications"
                render={arrayHelpers => (
                  <Box my={2}>
                    <Box display="flex" alignItems="center">
                      <PageHeader level="3" component="div">Lääkitykset</PageHeader>
                      {!values?.isFinal && <IconButton onClick={() => arrayHelpers.push(makeMedicationRow())}>
                        <AddCircleIcon fontSize="small" />
                      </IconButton>}
                    </Box>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="noMedicationsUsed"
                      Label={{ label: "Lintuja ei ole lääkitty kasvatuskaudella." }}
                    />
                    {values.medications && values.medications.length > 0 && values.medications.map((medication, index) => (
                      <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                        <Box my={1} flexGrow={1}>
                          <Paper variant="outlined" style={{padding: "1em"}}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  component={TextField}
                                  label="Lääkeaine"
                                  name={`medications[${index}].medicine`}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  name={`medications[${index}].startDate`}
                                  label="Aloitus"
                                  component={DatePicker}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  name={`medications[${index}].endDate`}
                                  label="Lopetus"
                                  component={DatePicker}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  component={TextField}
                                  label="Varoaika"
                                  name={`medications[${index}].withholdingPeriod`}
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric",
                                    endAdornment: <InputAdornment position="end">vuorokautta</InputAdornment>
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Box>
                        {!values?.isFinal && <IconButton onClick={() => removeMedicationRow(arrayHelpers, index)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>}
                      </Box>
                    ))}
                  </Box>
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
  );
};

export default HealthInfoForm;
