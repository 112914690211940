import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BatchDeliveryDto, BreedingBatchDto } from "../../../../shared/models/ApiSchema";
import useStyles from "../../../../shared/themes/modalStyles";
import BatchDeliveryForm from "../BatchDeliveryForm/BatchDeliveryForm";
import BatchHeader from "../BatchHeader/BatchHeader";

interface ActivateBatchModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
  delivery: BatchDeliveryDto | null;
}

const ActivateBatchModal: React.FC<ActivateBatchModalProps> = ({ open, onClose, batch, delivery }) => {
  const classes = useStyles();

  const handleCancel = () => {
    onClose(null);
  };

  const handleSubmit = (data) => {
    onClose(data);
  };

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="sm"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">Erän {batch.breedingId} aloitus</PageHeader>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        <BatchDeliveryForm
          formId="activateBatchForm"
          batch={batch}
          delivery={delivery}
          onSubmit={handleSubmit}
          activation={true}
          preFeed={true}
          message="Erän aloitusta ei voi peruuttaa, oletko varma?"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>Peruuta</Button>
        <Button variant="text" type="submit" form="activateBatchForm">Aloita</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivateBatchModal;
