import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import FeedInfoForm from "../FeedInfoForm/FeedInfoForm";
import { BreedingBatchDto, FeedInfoDto } from "../../../../shared/models/ApiSchema";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useConfirm } from "material-ui-confirm";
import * as api from "../../../../shared/store/apiActions";
import { useDispatch } from "react-redux";
import { Alert } from "@material-ui/lab";
import BatchHeader from "../BatchHeader/BatchHeader";

interface FeedInfoModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
  feed: FeedInfoDto | null;
}

const FeedInfoModal: React.FC<FeedInfoModalProps> = ({ open, onClose, batch, feed }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const [changed, setChanged] = useState(false);

  const handleLoad = () => {
    confirm({ description: "Lomakkeen kentät ylikirjoitetaan yleisten rehutietojen tiedoilla." }).then(() => {
      dispatch(api.batch.loadFromFeedTemplate());
      setChanged(true);
    });
  };

  const handleCancel = () => {
    onClose(null, changed);
  };

  const handleSubmit = (data) => {
    onClose(data, false);
  };

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="lg"
      open={open}
      TransitionProps={{onEnter: () => setChanged(false)}}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
      aria-labelledby="feedinfo-modal-title"
      aria-describedby="feedinfo-modal-description">
      <DialogTitle id="feedinfo-modal-title">
        <Box mb={-2} display="flex" alignItems="center" justifyContent="space-between">
          <PageHeader level="2" component="div">Erän {batch.breedingId} rehutiedot</PageHeader>
          {!feed?.isFinal && <IconButton onClick={handleLoad}>
            <CloudDownloadIcon fontSize="large" />
          </IconButton>}
        </Box>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        <Box mt={2}>
          <Alert severity="info">
            Varoaika on valmistajan ilmoittama varoaika vuorokausina.<br/><br/>
            Syötä tietoihin rehujen hiilijalanjälki (CO<sub>2</sub>e) tehtaalla jos se on saatavilla pakkauksen etiketistä (kg CO<sub>2</sub> ekv/kg).
          </Alert>
        </Box>
        <FeedInfoForm
          formId="feedInfoForm"
          onSubmit={handleSubmit}
          values={feed}
          batch={batch}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>{feed?.isFinal ? "Sulje" : "Peruuta"}</Button>
        {!feed?.isFinal && <Button variant="text" type="submit" form="feedInfoForm">Tallenna</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default FeedInfoModal;
