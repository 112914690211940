import { userOrganizationsReceived, userOrganizationOptionsReceived, userAccessReceived } from "./user";
import userService from "../services/userService";
import hallService from "../services/hallsService";
import batchService from "../services/breedingBatchService";
import poultryService from "../services/poultryDiaryService";
import templateService from "../services/templateService";
import {
  HallInfoDto, FeedInfoDto, PoultryDiaryUpdateDto, PoultryLossAddDto, PoultryLossSetDto,
  BatchDeliveryUpdateDto, FeedTemplateDto, HallTemplateDto, BatchDatesUpdateDto,
  SevenDayLossCreateDto, HealthInfoDto, SalmonellaSampleCreateDto, ChainInfoCreateDto,
  ChainInfoSupplementCreateDto, ApprovalStatus, SalmonellaSampleCreateBatchDto, NotificationCreateDto, ChainInfoDraftUpdateDto } from "../models/ApiSchema";
import { requested } from "./api";
import { hallsReceived, notificationsReceived, tasksReceived } from "./home";
import { batchDetailsReceived, diaryUpdated, hallInfoUpdated, feedInfoUpdated,
  lossAdded, lossDeleted, feedInfoLoaded, hallInfoLoaded,
  healthInfoUpdated, sevenDayLossCreated, diariesReceived, chainInfoCreated, chainInfoDraftUpdated } from "./batch";
import { feedTemplateReceived, hallTemplateReceived, healthInfoInitialized, salmonellaSampleInitialized } from "./templates";
import reportsService from "../services/reportsService";
import breedingBatchService from "../services/breedingBatchService";
import approvalService from "../services/approvalService";
import { chainInfoApprovalReceived, chainInfoListReceived, chainInfoSupplementApprovalReceived, chainInfoSupplementListReceived,
  notificationApprovalReceived,
  notificationsListReceived,
  salmonellaSampleApprovalReceived, salmonellaSampleListReceived, sevenDayLossApprovalReceived, sevenDayLossListReceived } from "./approval";
import { producerNumberUpdated } from "./ui/settings";

const fetchOrganizations = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await userService.fetchOrganizations();
      dispatch({
        type: userOrganizationsReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const fetchAccess = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await userService.fetchAccess();
      dispatch({
        type: userAccessReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getUserFilteredOrganizations = (
  authenticationSource: string,
  filter?: string,
  updateProducerNumber?: Function
) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await userService.getUserFilteredOrganization(
        authenticationSource,
        filter
      );
      if (filter && updateProducerNumber) {
        updateProducerNumber(filter);
      }
      dispatch({
        type: userOrganizationsReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getUserAutocompleteOrganizations = (
  authenticationSource: string,
  filter?: string
) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await userService.getUserFilteredOrganization(
        authenticationSource,
        filter
      );
      dispatch({
        type: userOrganizationOptionsReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const setProducerNumberUpdated = (producerNumber: number) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await userService.setLastVisitedOrganization(producerNumber)
      dispatch({
        type: producerNumberUpdated.type,
        payload: { producerNumber: producerNumber },
      });
      return resp;
    },
  });
};


export const user = {
  fetchOrganizations,
  fetchAccess,
  getUserFilteredOrganizations,
  getUserAutocompleteOrganizations,
  setProducerNumberUpdated
};

const getHallsWithCurrentBatch = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await hallService.getHallsWithCurrentBatch();
      dispatch({
        type: hallsReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const fetchBatchTasks = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await hallService.fetchBatchTasks();
      dispatch({
        type: tasksReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const fetchBatchNotifications = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await hallService.fetchBatchNotifications();
      dispatch({
        type: notificationsReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

export const home = {
  getHallsWithCurrentBatch,
  fetchBatchTasks,
  fetchBatchNotifications
};

const getBreedingBatch = (id: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await batchService.getBreedingBatch(id);
      dispatch({
        type: batchDetailsReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const activateBreedingBatch = (id: string, data: BatchDeliveryUpdateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await batchService.activateBatch(id, data);
      const resp2 = await batchService.getBreedingBatch(id);
      dispatch({
        type: batchDetailsReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const finishBreedingBatch = (id: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await batchService.finishBatch(id);
      const resp2 = await batchService.getBreedingBatch(id);
      dispatch({
        type: batchDetailsReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const updateBatchDates = (id: string, data: BatchDatesUpdateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await batchService.updateBatchDates(id, data);
      const resp2 = await batchService.getBreedingBatch(id);
      dispatch({
        type: batchDetailsReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const addBatchDelivery = (id: string, data: BatchDeliveryUpdateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await batchService.addBatchDelivery(id, data);
      const resp2 = await batchService.getBreedingBatch(id);
      dispatch({
        type: batchDetailsReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const updateBatchDelivery = (id: string, deliveryId: string, data: BatchDeliveryUpdateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await batchService.updateBatchDelivery(id, deliveryId, data);
      const resp2 = await batchService.getBreedingBatch(id);
      dispatch({
        type: batchDetailsReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const deleteBatchDelivery = (id: string, deliveryId: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await batchService.deleteBatchDelivery(id, deliveryId);
      const resp2 = await batchService.getBreedingBatch(id);
      dispatch({
        type: batchDetailsReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const updateFeedInfo = (id: string, data: FeedInfoDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await batchService.updateFeedInfo(id, data);
      dispatch({
        type: feedInfoUpdated.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const loadFromFeedTemplate = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await templateService.getFeedTemplate();
      dispatch({
        type: feedInfoLoaded.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const updateHallInfo = (id: string, data: HallInfoDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await batchService.updateHallInfo(id, data);
      dispatch({
        type: hallInfoUpdated.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const loadFromHallTemplate = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await templateService.getHallTemplate();
      dispatch({
        type: hallInfoLoaded.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const updateHealthInfo = (id: string, data: HealthInfoDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await batchService.updateHealthInfo(id, data);
      dispatch({
        type: healthInfoUpdated.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const fetchFromPortal = () => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await batchService.fetchBreedingBatches();
      const resp2 = await hallService.getHallsWithCurrentBatch();
      dispatch({
        type: hallsReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

export const batch = {
  getBreedingBatch,
  activateBreedingBatch,
  finishBreedingBatch,
  updateBatchDates,
  addBatchDelivery,
  updateBatchDelivery,
  deleteBatchDelivery,
  updateFeedInfo,
  loadFromFeedTemplate,
  updateHallInfo,
  loadFromHallTemplate,
  updateHealthInfo,
  fetchFromPortal
};

const updatePoultryDiary = (diaryId: string, diary: PoultryDiaryUpdateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await poultryService.updatePoultryDiary(diaryId, diary);
      dispatch({
        type: diaryUpdated.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const addPoultryLoss = (diaryId: string, loss: PoultryLossAddDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await poultryService.addPoultryLoss(diaryId, loss);
      dispatch({
        type: lossAdded.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const setPoultryLoss = (breedingBatchId: string, diaryId: string, loss: PoultryLossSetDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await poultryService.setPoultryLoss(diaryId, loss);
      const resp2 = await batchService.getPoultryDiaries(breedingBatchId);
      dispatch({
        type: diariesReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const deletePoultryLoss = (lossId: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await poultryService.deletePoultryLoss(lossId);
      dispatch({
        type: lossDeleted.type,
        payload: { lossId: lossId },
      });
      return resp;
    },
  });
};

const addDiaryNotification = (breedingBatchId: string, diaryId: string, notification: NotificationCreateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await poultryService.addNotification(diaryId, notification);
      const resp2 = await batchService.getPoultryDiaries(breedingBatchId);
      dispatch({
        type: diariesReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

export const diary = {
  updatePoultryDiary, addPoultryLoss, setPoultryLoss, deletePoultryLoss, addDiaryNotification
};

const getFeedTemplate = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await templateService.getFeedTemplate();
      dispatch({
        type: feedTemplateReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const updateFeedTemplate = (data: FeedTemplateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await templateService.updateFeedTemplate(data);
      dispatch({
        type: feedTemplateReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getHallTemplate = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await templateService.getHallTemplate();
      dispatch({
        type: hallTemplateReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const updateHallTemplate = (data: HallTemplateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await templateService.updateHallTemplate(data);
      dispatch({
        type: hallTemplateReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const initHealthInfo = (data: HealthInfoDto, overwrite: boolean = false) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await breedingBatchService.initHealthInfo(data, overwrite);
      dispatch({
        type: healthInfoInitialized.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const initSalmonellaSampleBatch = (data: SalmonellaSampleCreateBatchDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await reportsService.createSalmonellaSampleBatchReport(data);
      dispatch({
        type: salmonellaSampleInitialized.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

export const templates = {
  getFeedTemplate, updateFeedTemplate, getHallTemplate, updateHallTemplate, initHealthInfo, initSalmonellaSampleBatch
};

const createSevenDayLossReport = (batchId: string, data: SevenDayLossCreateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await reportsService.createSevenDayLossReport(batchId, data);
      dispatch({
        type: sevenDayLossCreated.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const createSalmonellaSampleReport = (batchId: string, data: SalmonellaSampleCreateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await reportsService.createSalmonellaSampleReport(batchId, data);
      const resp2 = await batchService.getBreedingBatch(batchId);
      dispatch({
        type: batchDetailsReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const updateChainInfoDraft = (batchId: string, data: ChainInfoDraftUpdateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await reportsService.updateChainInfoDraft(batchId, data);
      dispatch({
        type: chainInfoDraftUpdated.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const createChainInfoReport = (batchId: string, data: ChainInfoCreateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await reportsService.createChainInfoReport(batchId, data);
      dispatch({
        type: chainInfoCreated.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const createChainInfoSupplementReport = (batchId: string, data: ChainInfoSupplementCreateDto) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await reportsService.createChainInfoSupplementReport(batchId, data);
      const resp2 = await batchService.getBreedingBatch(batchId);
      dispatch({
        type: batchDetailsReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

export const reports = {
  createSevenDayLossReport,
  createSalmonellaSampleReport,
  updateChainInfoDraft,
  createChainInfoReport,
  createChainInfoSupplementReport
};

const getChainInfoList = (start?: moment.Moment, end?: moment.Moment, status?: ApprovalStatus) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await approvalService.listChainInfo(start, end, status)
      dispatch({
        type: chainInfoListReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getChainInfoApproval = (chainInfoId: string) => {
  return requested({
    fn: async (dispatch) => {
      dispatch({
        type: chainInfoApprovalReceived.type,
        payload: { data: null },
      });
      const resp = await approvalService.getChainInfo(chainInfoId)
      dispatch({
        type: chainInfoApprovalReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const updateChainInfoApprovalStatus = (chainInfoId: string, status: ApprovalStatus, comment?: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await approvalService.updateChainInfo(chainInfoId, { toStatus: status, comment: comment });
      const resp2 = await approvalService.getChainInfo(chainInfoId);
      dispatch({
        type: chainInfoApprovalReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const updateChainInfoApprovalStatusOnGrid = (chainInfoId: string, toStatus: ApprovalStatus, start: moment.Moment, end: moment.Moment, status?: ApprovalStatus) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await approvalService.updateChainInfo(chainInfoId, { toStatus: toStatus });
      const resp2 = await approvalService.listChainInfo(start, end, status);
      dispatch({
        type: chainInfoListReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const getChainInfoSupplementList = (start?: moment.Moment, end?: moment.Moment, status?: ApprovalStatus) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await approvalService.listChainInfoSupplement(start, end, status)
      dispatch({
        type: chainInfoSupplementListReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getChainInfoSupplementApproval = (chainInfoSupplementId: string) => {
  return requested({
    fn: async (dispatch) => {
      dispatch({
        type: chainInfoSupplementApprovalReceived.type,
        payload: { data: null },
      });
      const resp = await approvalService.getChainInfoSupplement(chainInfoSupplementId)
      dispatch({
        type: chainInfoSupplementApprovalReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const updateChainInfoSupplementApprovalStatus = (chainInfoSupplementId: string, status: ApprovalStatus, comment?: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await approvalService.updateChainInfoSupplement(chainInfoSupplementId, { toStatus: status, comment: comment });
      const resp2 = await approvalService.getChainInfoSupplement(chainInfoSupplementId);
      dispatch({
        type: chainInfoSupplementApprovalReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const updateChainInfoSupplementApprovalStatusOnGrid = (chainInfoSupplementId: string, toStatus: ApprovalStatus, start: moment.Moment, end: moment.Moment, status?: ApprovalStatus) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await approvalService.updateChainInfoSupplement(chainInfoSupplementId, { toStatus: toStatus });
      const resp2 = await approvalService.listChainInfoSupplement(start, end, status);
      dispatch({
        type: chainInfoSupplementListReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const getSalmonellaSampleList = (start?: moment.Moment, end?: moment.Moment, status?: ApprovalStatus) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await approvalService.listSalmonellaSample(start, end, status)
      dispatch({
        type: salmonellaSampleListReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getSalmonellaSampleApproval = (salmonellaSampleId: string) => {
  return requested({
    fn: async (dispatch) => {
      dispatch({
        type: salmonellaSampleApprovalReceived.type,
        payload: { data: null },
      });
      const resp = await approvalService.getSalmonellaSample(salmonellaSampleId)
      dispatch({
        type: salmonellaSampleApprovalReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const updateSalmonellaSampleApprovalStatus = (salmonellaSampleId: string, status: ApprovalStatus, comment?: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await approvalService.updateSalmonellaSample(salmonellaSampleId, { toStatus: status, comment: comment });
      const resp2 = await approvalService.getSalmonellaSample(salmonellaSampleId);
      dispatch({
        type: salmonellaSampleApprovalReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const updateSalmonellaSampleApprovalStatusOnGrid = (salmonellaSampleId: string, toStatus: ApprovalStatus, start: moment.Moment, end: moment.Moment, status?: ApprovalStatus) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await approvalService.updateSalmonellaSample(salmonellaSampleId, { toStatus: toStatus });
      const resp2 = await approvalService.listSalmonellaSample(start, end, status);
      dispatch({
        type: salmonellaSampleListReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const getSevenDayLossList = (start?: moment.Moment, end?: moment.Moment, status?: ApprovalStatus) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await approvalService.listSevenDayLoss(start, end, status)
      dispatch({
        type: sevenDayLossListReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getSevenDayLossApproval = (sevenDayLossId: string) => {
  return requested({
    fn: async (dispatch) => {
      dispatch({
        type: sevenDayLossApprovalReceived.type,
        payload: { data: null },
      });
      const resp = await approvalService.getSevenDayLoss(sevenDayLossId)
      dispatch({
        type: sevenDayLossApprovalReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const updateSevenDayLossApprovalStatus = (sevenDayLossId: string, status: ApprovalStatus, comment?: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await approvalService.updateSevenDayLoss(sevenDayLossId, { toStatus: status, comment: comment });
      const resp2 = await approvalService.getSevenDayLoss(sevenDayLossId);
      dispatch({
        type: sevenDayLossApprovalReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const updateSevenDayLossApprovalStatusOnGrid = (sevenDayLossId: string, toStatus: ApprovalStatus, start: moment.Moment, end: moment.Moment, status?: ApprovalStatus) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await approvalService.updateSevenDayLoss(sevenDayLossId, { toStatus: toStatus });
      const resp2 = await approvalService.listSevenDayLoss(start, end, status);
      dispatch({
        type: sevenDayLossListReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const getNotificationsList = (start?: moment.Moment, end?: moment.Moment, status?: ApprovalStatus) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await approvalService.listNotifications(start, end, status)
      dispatch({
        type: notificationsListReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getNotificationApproval = (notificationId: string) => {
  return requested({
    fn: async (dispatch) => {
      dispatch({
        type: notificationApprovalReceived.type,
        payload: { data: null },
      });
      const resp = await approvalService.getNotification(notificationId)
      dispatch({
        type: notificationApprovalReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const updateNotificationApprovalStatus = (notificationId: string, status: ApprovalStatus, comment?: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await approvalService.updateNotification(notificationId, { toStatus: status, comment: comment });
      const resp2 = await approvalService.getNotification(notificationId);
      dispatch({
        type: notificationApprovalReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};

const updateNotificationApprovalStatusOnGrid = (notificationId: string, toStatus: ApprovalStatus, start: moment.Moment, end: moment.Moment, status?: ApprovalStatus) => {
  return requested({
    fn: async (dispatch) => {
      const resp1 = await approvalService.updateNotification(notificationId, { toStatus: toStatus });
      const resp2 = await approvalService.listNotifications(start, end, status);
      dispatch({
        type: notificationsListReceived.type,
        payload: { data: resp2.data },
      });
      return resp1;
    },
  });
};


export const approval = {
  getChainInfoList,
  getChainInfoApproval,
  updateChainInfoApprovalStatus,
  updateChainInfoApprovalStatusOnGrid,
  getChainInfoSupplementList,
  getChainInfoSupplementApproval,
  updateChainInfoSupplementApprovalStatus,
  updateChainInfoSupplementApprovalStatusOnGrid,
  getSalmonellaSampleList,
  getSalmonellaSampleApproval,
  updateSalmonellaSampleApprovalStatus,
  updateSalmonellaSampleApprovalStatusOnGrid,
  getSevenDayLossList,
  getSevenDayLossApproval,
  updateSevenDayLossApprovalStatus,
  updateSevenDayLossApprovalStatusOnGrid,
  getNotificationsList,
  getNotificationApproval,
  updateNotificationApprovalStatus,
  updateNotificationApprovalStatusOnGrid
};
