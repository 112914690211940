import React, { Fragment, useEffect } from "react";
import PageHeader from "../../../shared/components/PageHeader/PageHeader";
import FeedTemplateForm from "./FeedTemplateForm/FeedTemplateForm";
import { useSnackbar } from "notistack";
import { Box, Button } from "@material-ui/core";
import { IRootState } from "../../../shared/store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../shared/store/apiActions";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { successHandled } from "../../../shared/store/api";
import Date from "../../../shared/components/Formatters/Date";

const FeedTemplate = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const feed = useSelector((state: IRootState) => state.templates.feed);

  const { success } = useSelector((state: IRootState) => state.api);

  useEffect(() => {
    dispatch(api.templates.getFeedTemplate());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      if (success.url?.indexOf('templates/feed') > -1 && success.method === "put")
      {
        enqueueSnackbar(`Yleiset rehutiedot on päivitetty`, { variant: 'success' });
        dispatch(successHandled());
        history.push('/templates');
      }
    }
  }, [dispatch, enqueueSnackbar, history, success]);


  const handleSubmit = async (data) => {
    if (data) {
      dispatch(api.templates.updateFeedTemplate(data));
    }
  };

  const handleCancel = () => history.push('/templates');

  return (
    <Fragment>
      <PageHeader level="1" component="div">Yleiset rehutiedot</PageHeader>
      {feed && <Fragment>
        <p>Viimeksi päivitetty: <Date value={feed.modifiedDate} time={true} /> ({feed.modifiedBy || 'tuntematon'})</p>
        <Alert severity="info">
          Tämän lomakkeen tiedot tallennetaan aina edellisten päälle eivätkä ne siirry automaattisesti eräkohtaisiin tietoihin.
          Eräkohtaisten tietojen lomakkeessa voi hakea ja täyttää automaattisesti tähän lomakkeeseen kulloinkin tallennetut tiedot.
          <br/><br/>
          Varoaika = Valmistajan ilmoittama varoaika vuorokausina<br/>
          CO2e = Hiilijalanjälki (kg CO2-ekv/kg)
        </Alert>
        <Box mt={2}>
          <FeedTemplateForm formId="feedTemplateForm" values={feed} onSubmit={handleSubmit} />
        </Box>
        <Box mt={4} mb={1}>
          <Button variant="contained" color="primary" type="submit" form="feedTemplateForm">Tallenna muutokset</Button>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Button variant="contained" color="secondary" onClick={handleCancel}>Peruuta</Button>
        </Box>
      </Fragment>}
    </Fragment>
  );
};

export default FeedTemplate;