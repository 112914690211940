import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "#fff",
    height: "100%",
    minHeight: "calc(100vh - 320px)"
  },
}));

export default useStyles;