import React, { Fragment, useEffect, useState } from "react";
import PageHeader from "../../../shared/components/PageHeader/PageHeader";
import { useSnackbar } from "notistack";
import { Box, Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { IRootState } from "../../../shared/store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../shared/store/apiActions";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { successHandled } from "../../../shared/store/api";
import HealthInitForm from "./HealthInitForm/HealthInitForm";

const HealthInit = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [overwrite, setOverwrite] = useState<boolean>(false);

  const { success } = useSelector((state: IRootState) => state.api);

  useEffect(() => {
    if (success) {
      if (success.url?.indexOf('init/healthinfo') > -1 && success.method === "post")
      {
        if (success.data === 0) {
          enqueueSnackbar(`Kaikilla kasvatuserillä on jo terveystiedot`, { variant: 'info' });
        } else {
          enqueueSnackbar(`Terveystiedot (${success.data}) on alustettu`, { variant: 'success' });
        }
        dispatch(successHandled());
        history.push('/templates');
      }
    }
  }, [dispatch, enqueueSnackbar, history, success]);

  const handleSubmit = async (data) => {
    if (data) {
      dispatch(api.templates.initHealthInfo(data, overwrite));
    }
  };

  const handleCancel = () => history.push('/templates');

  return (
    <Fragment>
      <PageHeader level="1" component="div">Terveystietojen alustus</PageHeader>
      <Box mt={2}>
        <Alert severity="info">
          Tämän lomakkeen tiedoilla alustetaan käynnissä olevien kasvatuserien eräkohtaiset terveystiedot jos niitä ei vielä ole kertaakaan tallennettu.<br/>
          <FormControlLabel
            control={<Checkbox checked={overwrite} onChange={(e) => setOverwrite(e.target.checked)} name="overwrite" />}
            label="Ylikirjoita myös jo kertaalleen tallennetut terveystiedot"
          />
        </Alert>
      </Box>
      <Box mt={2}>
        <HealthInitForm formId="healthInitForm" onSubmit={handleSubmit} />
      </Box>
      <Box mt={4} mb={1}>
        <Button variant="contained" color="primary" type="submit" form="healthInitForm">Alusta terveystiedot</Button>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Button variant="contained" color="secondary" onClick={handleCancel}>Peruuta</Button>
      </Box>
    </Fragment>
  );
};

export default HealthInit;