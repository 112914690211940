import { Chip } from "@material-ui/core";
import React, { Fragment } from "react";
import { approvalStatusText } from "../../helpers/statusHelper";
import { ApprovalStatus as Approval } from "../../models/ApiSchema";

interface ApprovalStatusProps {
  status: Approval | null | undefined;
  chip?: boolean;
}

const _ApprovalStatus: React.FC<ApprovalStatusProps> = ({status, chip = true}) => {
  return (
    <Fragment>
      {!chip && <span>{approvalStatusText(status)}</span>}
      {chip && <Fragment>
        { status === Approval.Open && <Chip size="small" label={approvalStatusText(status)} color="secondary" component="span" />}
        { status === Approval.Sent && <Chip size="small" label={approvalStatusText(status)} color="default" component="span" />}
        { status === Approval.Inspected && <Chip size="small" label={approvalStatusText(status)} color="primary" component="span" />}
        { status === Approval.Approved && <Chip size="small" label={approvalStatusText(status)} color="primary" component="span" />}
        { status === Approval.Returned && <Chip size="small" label={approvalStatusText(status)} color="secondary" component="span" />}
        { status === Approval.Rejected && <Chip size="small" label={approvalStatusText(status)} color="secondary" component="span" />}
        { status === Approval.Superceded && <Chip size="small" label={approvalStatusText(status)} color="secondary" component="span" />}
        { status === Approval.Received && <Chip size="small" label={approvalStatusText(status)} color="primary" component="span" />}
      </Fragment>}
    </Fragment>
  );
};

export const ApprovalStatus = React.memo(_ApprovalStatus);
export default ApprovalStatus;
