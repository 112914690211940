import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    height: "100%",
    color: "#fff",
    fontSize: "0.9em",
    paddingBottom: "10px"
  },
  logoContainer: {
    paddingTop: "60px",
    paddingBottom: "20px",
    display: "block",
    whiteSpace: "nowrap",
    minWidth: "120px",
  },
  logo: {
    height: "24px",
    pointerEvents: "none",
    "& > *": {
      fill: "#fff !important"
    }
  },
  hkscanEnvironmentLeft: {
    paddingTop: "10px",
    transform: "scale(0.6)",
    position: "relative",
    right: "-33%",
    "& > *": {
      fill: "#C9C8C6"
    }
  },
  hkscanEnvironmentRight: {
    paddingTop: "10px",
    transform: "scale(0.6)",
    position: "relative",
    left: "-33%",
    "& > *": {
      fill: "#C9C8C6"
    }
  }
}));

export default useStyles;