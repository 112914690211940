import { Chip } from "@material-ui/core";
import React, { Fragment } from "react";
import { chickQualityText } from "../../helpers/statusHelper";
import { ChickQuality as ChickQualityEnum } from "../../models/ApiSchema";

interface ChickQualityProps {
  status: ChickQualityEnum;
  chip?: boolean;
}

const _ChickQuality: React.FC<ChickQualityProps> = ({status, chip = true}) => {
  return (
    <Fragment>
      {!chip && <span>{chickQualityText(status)}</span>}
      {chip && <Fragment>
        { status === ChickQualityEnum.Good && <Chip size="small" label={chickQualityText(status)} color="primary" component="span" />}
        { status === ChickQualityEnum.Fair && <Chip size="small" label={chickQualityText(status)} color="secondary" component="span" />}
        { status === ChickQualityEnum.Poor && <Chip size="small" label={chickQualityText(status)} color="default" component="span" />}
      </Fragment>}
    </Fragment>
  );
};

export const ChickQuality = React.memo(_ChickQuality);
export default ChickQuality;
