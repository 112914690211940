import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BatchDeliveryDto, BreedingBatchDto } from "../../../../shared/models/ApiSchema";
import Date from "../../../../shared/components/Formatters/Date";
import BatchDeliveryForm from "../BatchDeliveryForm/BatchDeliveryForm";
import { useConfirm } from "material-ui-confirm";

interface BatchInfoModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
  delivery: BatchDeliveryDto | null;
  preFeed?: boolean;
  enableDelete?: boolean;
}

const BatchInfoModal: React.FC<BatchInfoModalProps> = ({ open, onClose, batch, delivery, preFeed = false, enableDelete = false }) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const handleDelete = () => {
    confirm({ description: "Toimituksen poistoa ei voi peruuttaa, oletko varma?" }).then(() => {
      onClose(null, true);
    });
  };

  const handleCancel = () => {
    onClose(null);
  };

  const handleSubmit = (data) => {
    onClose(data);
  };

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="sm"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">
          {delivery
            ? <span>Muokkaa toimitusta</span>
            : <span>Lisää uusi toimitus</span>
          }
        </PageHeader>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <PageHeader level="3" component="span">
            Halli {batch.hall?.number}{" - "}
            {delivery == null
              ? <span>Erä {batch.breedingId}</span>
              : <span><Date value={delivery.actualArrivalTime} time /></span>
            }
          </PageHeader>
        </DialogContentText>
        <BatchDeliveryForm
          formId="batchDeliveryForm"
          batch={batch}
          delivery={delivery}
          preFeed={preFeed}
          onSubmit={handleSubmit}
          message={delivery ? null : "Toimituksen lisäämistä ei voi peruuttaa, oletko varma?"}
        />
      </DialogContent>
      <DialogActions>
        {enableDelete && delivery != null && <Button variant="text" color="secondary" onClick={handleDelete}>Poista toimitus</Button>}
        <div style={{flex: '1 0 0'}} />
        <Button variant="text" onClick={handleCancel}>{delivery?.isFinal ? "Sulje" : "Peruuta"}</Button>
        {!delivery?.isFinal && <Button variant="text" type="submit" form="batchDeliveryForm">Tallenna</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default BatchInfoModal;
