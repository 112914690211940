import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { ApprovalStatusAuditDto, BreedingBatchDto, SalmonellaSampleDto, ViewName } from "../../../../shared/models/ApiSchema";
import SalmonellaSample from "../../../../shared/components/Data/SalmonellaSample/SalmonellaSample";
import approvalService from "../../../../shared/services/approvalService";
import ApprovalStatusAuditList from "../../../../shared/components/ApprovalStatus/ApprovalStatusAuditList";
import BatchHeader from "../BatchHeader/BatchHeader";

interface SalmonellaSampleModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
  report: SalmonellaSampleDto;
}

const SalmonellaSampleModal: React.FC<SalmonellaSampleModalProps> = ({ open, onClose, batch, report }) => {
  const classes = useStyles();

  const [audits, setAudits] = useState<ApprovalStatusAuditDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open && batch && report) {
      setLoading(true);
      const fetchData = async () => {
        const resp = await approvalService.getAudits(report.id, ViewName.SalmonellaSample);
        setAudits(resp.data);
        setLoading(false);
      };
      fetchData();
    }
  }, [open, batch, report]);

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="md"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">Erä {batch.breedingId}: Salmonellanäyte</PageHeader>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        <SalmonellaSample salmonellaSample={report} />
        {loading ?
          <Box my={4} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box> :
          <Box mt={4}>
            <ApprovalStatusAuditList audits={audits} />
          </Box>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>Sulje</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SalmonellaSampleModal;