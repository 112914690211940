import React, { Fragment, useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { IconButton } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import { IRootState } from "../../../shared/store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../shared/store/apiActions";
import ApprovalStatusSetMenu from "../../../shared/components/ApprovalStatus/ApprovalStatusSetMenu";
import { ViewName } from "../../../shared/models/ApiSchema";
import agGridHelper from "../../../shared/helpers/agGridHelper";
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { approvalStatusText, notificationTypeText } from "../../../shared/helpers/statusHelper";
import { ColDef } from "ag-grid-community";
import NotificationsApprovalModal from "./NotificationsApprovalModal";

interface NotificationsApprovalProps {

}

const NotificationsApproval: React.FC<NotificationsApprovalProps> = () => {

  const access = useSelector((state: IRootState) => state.user.access);
  const status = useSelector((state: IRootState) => state.approval.status);
  const startDate = useSelector((state: IRootState) => state.approval.startDate);
  const endDate = useSelector((state: IRootState) => state.approval.endDate);
  const notifications = useSelector((state: IRootState) => state.approval.notificationsList);
  const dispatch = useDispatch();

  const [openApprovalModal, setApprovalModalOpen] = useState<boolean>(false);
  const handleApprovalModalClose = (newStatus) => {
    setApprovalModalOpen(false);
    if (newStatus) {
      dispatch(api.approval.getNotificationsList(startDate, endDate, status || undefined));
    }
  };

  useEffect(() => {
    dispatch(api.approval.getNotificationsList(startDate, endDate, status || undefined));
  }, [status, startDate, endDate, dispatch]);

  const openApproval = (id: string) => {
    dispatch(api.approval.getNotificationApproval(id));
    setApprovalModalOpen(true);
  }

  const redraw = (e) => {
    e.api.redrawRows();
  }

  const defaultColDef: ColDef = {
    cellStyle: { textAlign: "center" },
    resizable: true,
    sortable: true,
    editable: false,
    suppressMenu: true,
    suppressMovable: true,
    suppressNavigable: true
  };

  return (
    <Fragment>
      <div className="diary-grid ag-theme-balham" style={{height: "74vh", width: "99.99%"}}>
        <AgGridReact
          rowData={notifications}
          defaultColDef={defaultColDef}
          suppressCellSelection={true}
          applyColumnDefOrder={true}
          immutableData={true}
          getRowNodeId={(row) => row.id}
          onRowDataChanged={redraw}
        >
          <AgGridColumn headerName="Ilmoitukset">
            <AgGridColumn
              field="breedingId"
              headerName="Erä id"
              width={80}
              pinned="left"
            />
            <AgGridColumn
              field="producerName"
              headerName="Tuottaja"
              minWidth={160}
              maxWidth={320}
              pinned="left"
            />
            <AgGridColumn
              field="hallNumber"
              headerName="Halli"
              width={60}
              cellStyle={{fontWeight:600}}
              pinned="left"
            />
            {access?.approvalStatusEnabled?.Notification && <AgGridColumn
              field="approvalStatus"
              headerName="Status"
              width={100}
              pinned="left"
              valueFormatter={v => approvalStatusText(v.value)}
            />}
            {access?.approvalStatusEnabled?.Notification && access?.hasApprovalRole && <AgGridColumn
              width={48}
              sortable={false}
              pinned="left"
              cellRendererFramework={params => <ApprovalStatusSetMenu current={params.data.approvalStatus} view={ViewName.Notification} id={params.data.id} />}
            />}
          </AgGridColumn>
          <AgGridColumn headerName="Kasvatuspäivä">
            <AgGridColumn
              field="diaryDay"
              headerName="Ikä"
              width={60}
            />
            <AgGridColumn
              field="diaryDate"
              headerName="Päivä"
              width={100}
              valueFormatter={agGridHelper.dateFormatter}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Ilmoitus">
            <AgGridColumn
              field="type"
              headerName="Tapahtuma"
              minWidth={240}
              valueFormatter={v => notificationTypeText(v.value)}
            />
            <AgGridColumn
              field="message"
              headerName="Kuvaus"
              minWidth={440}
              cellStyle={{ textAlign: "left" }}
            />
          </AgGridColumn>
          <AgGridColumn
            field="notes"
            headerName="Lähettäjän selvitys"
            minWidth={300}
            cellStyle={{ textAlign: "left" }}
          />
          <AgGridColumn headerName="Lähettäjä">
            <AgGridColumn
              field="createdBy"
              headerName="Nimi"
              minWidth={180}
            />
            <AgGridColumn
              field="createdDate"
              headerName="Aika"
              width={120}
              valueFormatter={agGridHelper.dateTimeFormatter}
            />
          </AgGridColumn>
          <AgGridColumn
            width={48}
            sortable={false}
            pinned="right"
            cellRendererFramework={params => <IconButton size="small" onClick={() => openApproval(params.data.id)}><DescriptionIcon style={{ fontSize: 18 }} /></IconButton>}
          />
        </AgGridReact>
      </div>
      <NotificationsApprovalModal open={openApprovalModal} onClose={handleApprovalModalClose} />
    </Fragment>
  );
};

export default NotificationsApproval;