import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import useStyles from "./ApprovalStyles";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import Content from "../../shared/components/Content/Content";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import ChainInfoApproval from "./ChainInfoApproval/ChainInfoApproval";
import { Box, Tab, Tabs, Grid, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import ChainInfoSupplementApproval from "./ChainInfoSupplementApproval/ChainInfoSupplementApproval";
import { DatePicker } from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../shared/store/rootReducer";
import { dateFilterChanged, statusFilterChanged } from "../../shared/store/approval";
import ApprovalStatusValue from "../../shared/components/Statuses/ApprovalStatus";
import { ApprovalStatus, ViewName } from "../../shared/models/ApiSchema";
import SalmonellaSampleApproval from "./SalmonellaSample/SalmonellaSampleApproval";
import SevenDayLossApproval from "./SevenDayLoss/SevenDayLossApproval";
import { canAccessApprovalView } from "../../shared/helpers/accessHelper";
import NotificationsApproval from "./Notifications/NotificationsApproval";

const Approval = () => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const access = useSelector((state: IRootState) => state.user.access);
  const status = useSelector((state: IRootState) => state.approval.status);
  const startDate = useSelector((state: IRootState) => state.approval.startDate);
  const endDate = useSelector((state: IRootState) => state.approval.endDate);
  const dispatch = useDispatch();

  const routes = useMemo(() => {
    const routes = {};
    routes[ViewName.ChainInfo] = '/approval/chaininfo';
    routes[ViewName.ChainInfoSupplement] = '/approval/chaininfosupplement';
    routes[ViewName.SalmonellaSample] = '/approval/salmonellasample';
    routes[ViewName.SevenDayLoss] = '/approval/sevendayloss';
    routes[ViewName.Notification] = '/approval/notifications';
    return routes;
  }, []);

  const isRoute = useMemo(() =>
    (pathname: string) => Object.values(routes).findIndex(r => r === pathname) >= 0
  , [routes]);

  const [showStatus, setShowStatus] = useState<boolean>(true);
  const [dateTitle, setDateTitle] = useState<string>("");

  const [selectedState, setSelectedState] = useState<ApprovalStatus | "">(status || "");

  const changeState = (e) => {
    setSelectedState(e.target.value);
    dispatch({ type: statusFilterChanged.type, payload: { status: e.target.value === "" ? null : e.target.value }})
  };

  useEffect(() => {
    setShowStatus([
      routes[ViewName.ChainInfo],
      routes[ViewName.ChainInfoSupplement],
      routes[ViewName.Notification]
    ].includes(location.pathname));
    if (location.pathname === routes[ViewName.ChainInfo]) setDateTitle("Teuraspäivä");
    else if (location.pathname === routes[ViewName.ChainInfoSupplement]) setDateTitle("Teuraspäivä");
    else if (location.pathname === routes[ViewName.SalmonellaSample]) setDateTitle("Teuraspäivä");
    else if (location.pathname === routes[ViewName.SevenDayLoss]) setDateTitle("Teuraspäivä");
    else if (location.pathname === routes[ViewName.Notification]) setDateTitle("Lähetyspäivä");
  }, [location.pathname, routes]);

  useEffect(() => {
    if (!access) return;
    if (isRoute(location.pathname)) return;

    if (access.isActualProducer) {
      history.push(routes[ViewName.ChainInfo]);
      return;
    }

    for (const [view, route] of Object.entries(routes)) {
      if (canAccessApprovalView(access, view as ViewName)) {
        history.push(route as string);
        break;
      }
    }
  }, [access, history, isRoute, location.pathname, routes]);

  return (
    <Content backGroundStyles={classes.backGroundStyles}>
      <Grid container spacing={0}>
        <Grid item sm={4} xs={12}>
          <PageHeader level="1" component="div">Lähetetyt tiedot</PageHeader>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Box
            display="flex"
            justifyContent="right"
            alignItems="right"
          >
            {showStatus && <FormControl style={{width:200, marginTop:5}}>
              <InputLabel shrink id="approval-status-filter-label">Status</InputLabel>
              <Select
                labelId="approval-status-filter-label"
                id="approval-status-filter"
                displayEmpty
                value={selectedState}
                onChange={changeState}
                autoWidth
              >
                <MenuItem value="">Kaikki statukset</MenuItem>
                {Object.values(ApprovalStatus).map((state, index) => (
                  <MenuItem key={index} value={state}><ApprovalStatusValue status={state} chip={false} /></MenuItem>
                ))}
              </Select>
            </FormControl>}
            <DatePicker
              variant="inline"
              margin="dense"
              format="D.M.yyyy"
              autoOk
              id="start-date"
              label={dateTitle + " alkaen"}
              value={startDate}
              onChange={(d) => dispatch({ type: dateFilterChanged.type, payload: { startDate: moment(d), endDate: moment(endDate) }})}
            />
            <DatePicker
              variant="inline"
              margin="dense"
              format="D.M.yyyy"
              autoOk
              id="end-date"
              label={dateTitle + " päättyen"}
              value={endDate}
              onChange={(d) => dispatch({ type: dateFilterChanged.type, payload: { startDate: moment(startDate), endDate: moment(d) }})}
            />
          </Box>
        </Grid>
      </Grid>

      <Box my={2}>
        <Tabs
          indicatorColor="secondary"
          textColor="primary"
          value={isRoute(location.pathname) ? location.pathname : false}
          variant="scrollable"
          scrollButtons="auto"
        >
          {(canAccessApprovalView(access, ViewName.ChainInfo) || access?.isActualProducer) && <Tab
            to={routes[ViewName.ChainInfo]}
            component={Link}
            label={'Ketjuinformaatiolomake'}
            value={routes[ViewName.ChainInfo]}
          ></Tab>}
          {(canAccessApprovalView(access, ViewName.ChainInfoSupplement) || access?.isActualProducer) && <Tab
            to={routes[ViewName.ChainInfoSupplement]}
            component={Link}
            label={'Ketjuinformaation lisäsivu'}
            value={routes[ViewName.ChainInfoSupplement]}
          ></Tab>}
          {(canAccessApprovalView(access, ViewName.SevenDayLoss) || access?.isActualProducer) && <Tab
            to={routes[ViewName.SevenDayLoss]}
            component={Link}
            label={'7 päivän poistumailmoitus'}
            value={routes[ViewName.SevenDayLoss]}
          ></Tab>}
          {(canAccessApprovalView(access, ViewName.SalmonellaSample) || access?.isActualProducer) && <Tab
            to={routes[ViewName.SalmonellaSample]}
            component={Link}
            label={'Salmonellanäyte'}
            value={routes[ViewName.SalmonellaSample]}
          ></Tab>}
          {(canAccessApprovalView(access, ViewName.Notification) || access?.isActualProducer) && <Tab
            to={routes[ViewName.Notification]}
            component={Link}
            label={'Ilmoitukset'}
            value={routes[ViewName.Notification]}
          ></Tab>}
        </Tabs>
      </Box>

      <Switch>
        {(canAccessApprovalView(access, ViewName.ChainInfo) || access?.isActualProducer) &&
          <Route path={routes[ViewName.ChainInfo]} component={() => <ChainInfoApproval/>} />}
        {(canAccessApprovalView(access, ViewName.ChainInfoSupplement) || access?.isActualProducer) &&
          <Route path={routes[ViewName.ChainInfoSupplement]} component={() => <ChainInfoSupplementApproval/>} />}
        {(canAccessApprovalView(access, ViewName.SevenDayLoss) || access?.isActualProducer) &&
          <Route path={routes[ViewName.SevenDayLoss]} component={() => <SevenDayLossApproval/>} />}
        {(canAccessApprovalView(access, ViewName.SalmonellaSample) || access?.isActualProducer) &&
          <Route path={routes[ViewName.SalmonellaSample]} component={() => <SalmonellaSampleApproval/>} />}
        {(canAccessApprovalView(access, ViewName.Notification) || access?.isActualProducer) &&
          <Route path={routes[ViewName.Notification]} component={() => <NotificationsApproval/>} />}
        <Route path="/approval" component={() => null} />
      </Switch>
    </Content>
  );
};

export default Approval;