import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, SalmonellaSamplePreviewDto } from "../../../../shared/models/ApiSchema";
import moment from "moment";
import reportsService from "../../../../shared/services/reportsService";
import { Alert } from "@material-ui/lab";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { RadioGroup, TextField } from "formik-material-ui";
import { useConfirm } from "material-ui-confirm";
import { DatePicker } from "@material-ui/pickers";
import { localizeDate } from "../../../../shared/helpers/formHelper";
import Date from "../../../../shared/components/Formatters/Date";
import BatchHeader from "../BatchHeader/BatchHeader";

interface SalmonellaSamplePreviewModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
  start: moment.Moment;
  startException: moment.Moment;
  end: moment.Moment;
}

const SalmonellaSamplePreviewModal: React.FC<SalmonellaSamplePreviewModalProps> = ({ open, onClose, batch, start, startException, end }) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const [salmonellaPreview, setSalmonellaPreview] = useState<SalmonellaSamplePreviewDto | null>(null);
  const [sampleDate, setSampleDate] = useState<moment.Moment | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setSalmonellaPreview(null);
    if (open && batch && sampleDate) {
      setLoading(true);
      const fetchData = async () => {
        const resp = await reportsService.getSalmonellaSamplePreview(batch.id, sampleDate);
        setSalmonellaPreview(resp.data);
        setLoading(false);
      };
      fetchData();
    }
  }, [open, batch, sampleDate]);

  const handleSampleDateChange = (date) => {
    setSampleDate(date);
  }

  const handleCancel = () => {
    onClose(null, null);
  };

  const handleOnEnter = () => {
    setSampleDate(null);
    setSalmonellaPreview(null);
  };

  const formSchema = Yup.object().shape({
    sampleDate: Yup
      .date()
      .nullable(),
    notes: Yup
      .string()
      .nullable()
      .max(4000),
    isOfficialSample: Yup
      .string()
      .oneOf(["true", "false"])
  });

  const initialValues = {
    notes: "",
    isOfficialSample: "false",
    confirmLoss: false
  }

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="sm"
      open={open}
      TransitionProps={{onEnter: handleOnEnter}}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">Erä {batch.breedingId}: Salmonellanäyte</PageHeader>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        <Box my={3}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Valitse näytteenottopäivä"
                minDate={moment(batch.arrivalDate).startOf('day')}
                maxDate={moment(batch.slaughterDate).startOf('day')}
                variant="dialog"
                format="D.M.yyyy"
                autoOk
                invalidDateMessage="Virheellinen päivämäärä"
                cancelLabel="Peruuta"
                okLabel="Aseta"
                value={sampleDate ? sampleDate.toISOString() : null}
                onChange={handleSampleDateChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>

        {loading &&
          <Box my={6} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {sampleDate == null &&
          <Alert severity="info">Valitse ensin näytteenottopäivä</Alert>
        }
        {sampleDate && sampleDate.isBefore(start, 'day') &&
          <Alert severity="error">
            Huomio! Valittu näytteenottopäivä on aikaisempi kuin 18 vrk ennen teurastusta.
            Tämän päivän saa valita vain arkipyhän tai muun poikkeustilanteen sattuessa.
          </Alert>
        }
        {!loading && salmonellaPreview != null &&
          <Fragment>
            {salmonellaPreview.errors && salmonellaPreview.errors.length > 0 && <Box my={2}>
              <Alert severity="error">
                {salmonellaPreview.errors.map((e, index) => <div key={index}>{e}</div>)}
              </Alert>
            </Box>}
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Osaston numero
                    </TableCell>
                    <TableCell>
                      {batch.hall?.number || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Näytteenottopäivä
                    </TableCell>
                    <TableCell>
                      <Date value={salmonellaPreview.salmonellaSample?.sampleDate} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Hautomo
                    </TableCell>
                    <TableCell>
                      {salmonellaPreview.salmonellaSample?.hatchery || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Rehun toimittaja
                    </TableCell>
                    <TableCell>
                      {salmonellaPreview.salmonellaSample?.feedFactory || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Poistuma <Date value={salmonellaPreview.salmonellaSample?.lossDate} /> mennessä
                    </TableCell>
                    <TableCell>
                      {salmonellaPreview.salmonellaSample?.totalLoss}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box my={2}>
              <Formik
                initialValues={initialValues}
                validationSchema={formSchema}
                onSubmit={(values, actions) => {
                  if (!sampleDate) return;
                  confirm({ description: "Salmonellanäytteen kirjaus kirjataan hallille/erälle, oletko varma?" }).then(() => {
                    const data = formSchema.cast(values, { stripUnknown: true });
                    data.sampleDate = localizeDate(sampleDate.toDate());
                    actions.setSubmitting(false);
                    onClose(data);
                  }, () => {
                    actions.setSubmitting(false);
                  });
                }}
              >
                {({ submitForm, isSubmitting, errors }) => (
                  <Form id="salmonellaSampleForm">
                    <Box display="flex" flexDirection="column">
                      <Field component={RadioGroup} name="isOfficialSample">
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Rutiininäyte (2 tossuparia / osasto)"
                        />
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Virallinen näyte (1 tossunäytepari + sivelypölynäyte / osasto)"
                        />
                      </Field>
                      <Field
                        component={TextField}
                        label="Huomioita"
                        name="notes"
                        fullWidth
                        margin="normal"
                        InputProps={{
                          multiline: true,
                          maxRows: 8
                        }}
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>Peruuta</Button>
        <Button variant="text" type="submit" form="salmonellaSampleForm"
          disabled={salmonellaPreview == null || (salmonellaPreview.errors?.length || 0) > 0}>Tallenna</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SalmonellaSamplePreviewModal;