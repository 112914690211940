import { AccessDto, ViewName } from "../models/ApiSchema";

export const canAccessApprovalView = (access: AccessDto | undefined | null, view: ViewName): boolean => {
  if (!access) return false;
  return (access.approvalStatusAccess?.views || []).findIndex(v => v === view) >= 0;
};

const accessHelper = {
  canAccessApprovalView: canAccessApprovalView
};
export default accessHelper;
