import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backGroundStyles: {
    backgroundImage: `url(${theme.custom.images.Broileri})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "cover"
  },
  gridContainerStyles: {
    [theme.breakpoints.down("xs")] : {
      padding: "0",
    },
    padding: "2%",
    height: "100%",
    width: "100%"
  }
}));

export default useStyles;