import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, ChainInfoDataDto } from "../../../../shared/models/ApiSchema";
import ChainInfo from "../../../../shared/components/Data/ChainInfoData/ChainInfo";
import ChainInfoFeed from "../../../../shared/components/Data/ChainInfoData/ChainInfoFeed";
import ChainInfoHealth from "../../../../shared/components/Data/ChainInfoData/ChainInfoHealth";
import Date from "../../../../shared/components/Formatters/Date";
import reportsService from "../../../../shared/services/reportsService";
import ApprovalStatusAuditList from "../../../../shared/components/ApprovalStatus/ApprovalStatusAuditList";
import ApprovalStatusChip from "../../../../shared/components/Statuses/ApprovalStatus";
import BatchHeader from "../BatchHeader/BatchHeader";

interface ChainInfoModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
  chainInfoId: string | null | undefined;
}

const ChainInfoModal: React.FC<ChainInfoModalProps> = ({ open, onClose, batch, chainInfoId }) => {
  const classes = useStyles();

  const [chainInfoData, setChainInfoData] = useState<ChainInfoDataDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setChainInfoData(null);
    if (open && batch && chainInfoId != null) {
      setLoading(true);
      const fetchData = async () => {
        const resp = await reportsService.getChainInfoData(chainInfoId);
        setChainInfoData(resp.data);
        setLoading(false);
      };
      fetchData();
    }
  }, [open, batch, chainInfoId]);

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="md"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">
          Erä {batch.breedingId}: Ketjuinformaatiolomake{" "}
          {chainInfoData != null && <ApprovalStatusChip status={chainInfoData?.chainInfo?.approvalStatus} />}
        </PageHeader>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        {loading &&
          <Box my={6} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!loading && chainInfoData != null &&
          <Fragment>
            <Box my={2}>
              <ChainInfo chainInfo={chainInfoData.chainInfo} />
              <ChainInfoFeed chainInfo={chainInfoData.chainInfo} />
              <ChainInfoHealth chainInfo={chainInfoData.chainInfo} />
            </Box>
            <Box mt={4}>
              <strong>
                Ketjuinformaatiolomake lähetetty:{" "}
                <span>{chainInfoData.chainInfo?.approveName || "[ei nimeä]"}</span>{", "}
                <span>{chainInfoData.chainInfo?.approveLocation || "[ei paikkaa]"}</span>{", "}
                <Date value={chainInfoData.chainInfo?.approveTime} empty="[ei päiväystä]" />
              </strong>
            </Box>
            <Box mt={4}>
              <ApprovalStatusAuditList audits={chainInfoData.audits} />
            </Box>
          </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>Sulje</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChainInfoModal;