import { useEffect, useState } from "react";
import { BreedingBatchDto, PoultryDiaryDto } from "../../models/ApiSchema";
import { getCumulativeFeedTotalPerBird, getDiaryLabels } from "../../helpers/charthelper";
import { calcFeedTotalPerBirdGram, calcWaterPerBirdMl } from "../../helpers/diaryHelper";

import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface BatchConsumptionChartProps {
  diaries: PoultryDiaryDto[];
  batch: BreedingBatchDto;
}

const BatchConsumptionChart: React.FC<BatchConsumptionChartProps> = ({ diaries, batch }) => {

  const options = {
    locale: 'fi',
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          font: { size: 11 },
        }
      },
      tooltip: {
        position: 'nearest' as const,
        callbacks: {
          title: (tooltipItem: any) => `Kasvatusikä ${tooltipItem[0].label} vrk`,
        }
      }
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,    
    scales: {
      x: {
          ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          font: { size: 11 },
        },
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        min: 0,
        suggestedMax: 500,
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Päiväkoht. kulutus/lintu (g/ml)',
          font: { size: 11 },
        },
        ticks: {
          font: { size: 11 },
        },
        grid: {
          drawOnChartArea: true,
        },
      },
      y2: {
        beginAtZero: true,
        min: 0,
        suggestedMax: 3500,
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Kumulat. rehunkulutus / lintu (g)',
          font: { size: 11 },
        },
        ticks: {
          font: { size: 11 },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const [labels, setLabels] = useState<number[]>([]);
  const [dailyWater, setDailyWater] = useState<(number|null)[]>([]);
  const [dailyFeed, setDailyFeed] = useState<(number|null)[]>([]);
  const [cumulativeFeed, setCumulativeFeed] = useState<(number|null)[]>([]);

  useEffect(() => {
    if (!diaries.length) return;
    setLabels(getDiaryLabels(diaries));
    setDailyWater(diaries.map((diary) => diary.waterConsumptionLiter == null ? null : calcWaterPerBirdMl(diary)));
    setDailyFeed(diaries.map((diary) => calcFeedTotalPerBirdGram(diary)));
    
    const init = (batch.feedBeforeArrivalKg || 0) * 1000 / batch.chickQuantity;
    setCumulativeFeed(getCumulativeFeedTotalPerBird(diaries, init));
  }, [diaries, batch]);

  return (
    <Chart type='bar' height={100} width={100} options={options} data={{
      labels: labels,
      datasets: [
        {
          type: 'bar' as const,
          order: 2,
          label: 'Kumul. R+V/lintu (g)',
          barPercentage: 0.5,
          borderColor: '#4472c4',
          backgroundColor: '#4472c4',
          data: cumulativeFeed,
          yAxisID: 'y2',
        },
        {
          type: 'line' as const,
          order: 0,
          label: 'Rehu+vilja/lintu (g)',
          pointRadius: 1.5,
          borderWidth: 2.5,
          borderColor: '#ed7d31',
          backgroundColor: '#ed7d31',
          data: dailyFeed,
          yAxisID: 'y',          
        },
        {
          type: 'line' as const,
          order: 1,
          label: 'Vesi/lintu (ml)',
          pointRadius: 1.5,
          borderWidth: 2.5,
          borderColor: '#999',
          backgroundColor: '#999',
          data: dailyWater,
          yAxisID: 'y',          
        },
      ]
    }} />
  )
};

export default BatchConsumptionChart;
