import { Box, Chip, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import moment from "moment";
import { BreedingBatchDto, BreedingBatchStatus, NotificationType, PoultryDiaryDto } from "../../../../shared/models/ApiSchema";
import LossModal from "../LossModal/LossModal";
import WeightModal from "../WeightModal/WeightModal";
import FeedingModal from "../FeedingModal/FeedingModal";
import EnviromentModal from "../EnviromentModal/EnviromentModal";
import * as api from "../../../../shared/store/apiActions";
import Decimal from "../../../../shared/components/Formatters/Number";
import { useSnackbar } from "notistack";
import { IRootState } from "../../../../shared/store/rootReducer";
import diaryHelper from "../../../../shared/helpers/diaryHelper";
import Percent from "../../../../shared/components/Formatters/Percent";
import TableChartIcon from '@material-ui/icons/TableChart';
import { successHandled } from "../../../../shared/store/api";
import NotesModal from "../NotesModal/NotesModal";
import Date from "../../../../shared/components/Formatters/Date";
import DaySwitcher from "../DaySwitcher/DaySwitcher";
import NotificationModal from "../NotificationModal/NotificationModal";
import TaskMenu from "../../../../shared/components/TaskMenu/TaskMenu";

interface BatchDiaryProps {
  batch: BreedingBatchDto;
  diary: PoultryDiaryDto;
  openGrid?: any;
}

const BatchDiary: React.FC<BatchDiaryProps> = ({batch, diary, openGrid}) => {
  const dispatch = useDispatch();

  const { success } = useSelector((state: IRootState) => state.api);
  const { enqueueSnackbar } = useSnackbar();

  const [isLocked, setIsLocked] = useState(false);

  const [openLossModal, setLossModalOpen] = useState(false);
  const handleLossModalClose = (hasChanges) => {
    setLossModalOpen(false);
    if (hasChanges && batch.id) {
      dispatch(api.batch.getBreedingBatch(batch.id));
    }
  };

  const [openWeightModal, setWeightModalOpen] = useState(false);
  const handleWeightModalClose = (data) => {
    setWeightModalOpen(false);
    if (data) {
      dispatch(api.diary.updatePoultryDiary(diary.id, data));
    }
  };

  const [openFeedingModal, setFeedingModalOpen] = useState(false);
  const handleFeedingModalClose = (data) => {
    setFeedingModalOpen(false);
    if (data) {
      dispatch(api.diary.updatePoultryDiary(diary.id, data));
    }
  };

  const [openEnviromentModal, setEnviromentModalOpen] = useState(false);
  const handleEnviromentModalClose = (data) => {
    setEnviromentModalOpen(false);
    if (data) {
      dispatch(api.diary.updatePoultryDiary(diary.id, data));
    }
  };

  const [openNotesModal, setNotesModalOpen] = useState(false);
  const handleNotesModalClose = (data) => {
    setNotesModalOpen(false);
    if (data) {
      dispatch(api.diary.updatePoultryDiary(diary.id, data));
    }
  };

  const handleGridOpen = () => {
    if (openGrid) openGrid();
  }

  const [openNotificationModal, setNotificationModalOpen] =
    useState<{ open: boolean, type: NotificationType | null}>({ open: false, type: null });
  const handleNotificationModalClose = (data) => {
    const type = openNotificationModal.type;
    setNotificationModalOpen({ open: false, type: null });
    if (diary && type && data) {
      dispatch(api.diary.addDiaryNotification(batch.id, diary.id, { type, notes: data.notes }));
    }
  };

  const handleEnvironmentMenu = (action?: string | null | undefined) => {
    if (action === "edit") setEnviromentModalOpen(true);
    if (action === "lights") setNotificationModalOpen({ open: true, type: NotificationType.LightDimming })
  };

  useEffect(() => {
    const _isLocked = (loss: boolean = false): boolean => {
      if (batch.status !== BreedingBatchStatus.Active) return true;
      if (diary.isFinal) return true;
      if (loss && diary.isLossFinal) return true;
      if (moment(diary.date).isAfter(moment(), 'day')) return true;
      return false;
    }

    if (diary) {
      setIsLocked(_isLocked(true));
    }
  }, [batch.status, diary]);

  useEffect(() => {
    if (success) {
      if (success.url?.indexOf('/loss') > -1) {
        if (success.method === "put") {
          if (!openGrid)
            enqueueSnackbar(`Päivän poistuma on asetettu`, { variant: 'success' });
          dispatch(successHandled());
        }
      }
      else if (success.url?.indexOf('diaries/') > -1) {
        if (success.method === "put") {
          if (!openGrid)
            enqueueSnackbar(`Päiväkirja ${moment(success.data.date).format("D.M.YYYY")} on päivitetty`, { variant: 'success' });
          dispatch(successHandled());
        }
      }
    }
  }, [dispatch, enqueueSnackbar, openGrid, success]);

  const supplements= () => {
    let str = "";
    if (diary.addedVitaminC) str += "C-vitamiini";
    if (diary.addedPotassiumChloride) str += `${str !== "" ? ", " : ""}Kaliumkloridi`;
    if (diary.otherSupplements) str += `${str !== "" ? ", " : ""}${diary.otherSupplements}`;
    return str;
  };

  return (
    <Box component={Paper} p={2} pb={1}>
      <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
        <PageHeader level="3" component="div">
          Kasvatuspäiväkirja
        </PageHeader>
        {batch.status !== BreedingBatchStatus.Planned &&
          <span>{ moment(diary.date).isSame(moment(), "day")
            ? <Chip size="small" label="Tänään" color="primary" />
            : <Chip size="small" label={<Date value={diary.date} />} color="secondary" />
          }</span>
        }
      </Box>
      <DaySwitcher />
      <List dense disablePadding>
        <ListItem key="modified" disableGutters>
          <ListItemText
            primary="Viimeksi päivitetty"
            secondary={<Fragment>
              <Date value={diary.modifiedDate} time={true}/> ({diary.modifiedBy || 'tuntematon'})
            </Fragment>}
          />
          {openGrid &&
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="Lisää" onClick={handleGridOpen}>
                <TableChartIcon color="secondary" />
              </IconButton>
            </ListItemSecondaryAction>
          }
        </ListItem>
        <ListItem key="loss" style={diaryHelper.getLossStyle(diary)} disableGutters>
          <ListItemText
            primary={<span>Poistuma <Percent value={diaryHelper.calcLossPercent(diary)} digits={3} /></span>}
            secondary={<Fragment>
              {diary.poultryLosses && diary.poultryLosses.length
                ? <span style={diaryHelper.lossWarning(diary) ? diaryHelper.getLossStyle(diary) : {}}>
                    Kuolleet {diary.birdsDead}, karsitut {diary.birdsCulled}, yht. {diaryHelper.calcLoss(diary)}
                  </span>
                : "Ei kirjattu"
              }
            </Fragment>}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="Lisää" disabled={isLocked} onClick={() => setLossModalOpen(true)}>
              {isLocked ? <LockIcon /> : <EditIcon />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem key="weight" disableGutters>
          <ListItemText
            primary="Lintujen paino"
            secondary={<Fragment>
              {diary.liveWeightGrams
                ? <span>Keskipaino {diary.liveWeightGrams} g/lintu</span>
                : "Ei kirjattu"
              }
            </Fragment>}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="Aseta" disabled={isLocked} onClick={() => setWeightModalOpen(true)}>
              {isLocked ? <LockIcon /> : <EditIcon />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem key="fodder" disableGutters>
          <ListItemText
            primary="Ruokinta"
            secondary={
              <Fragment>
                <span>Rehu: {diary.feedConsumptionKg != null
                  ? <span><Decimal value={diary.feedConsumptionKg} digits={1} unit="kg" />&nbsp;
                    (<Decimal value={diaryHelper.calcFeedPerBirdGram(diary)} digits={1} unit="g/lintu" />)</span>
                  : <span>ei kirjattu</span>}
                </span><br/>
                <span>Vilja: {diary.grainConsumptionKg != null
                  ? <span><Decimal value={diary.grainConsumptionKg} digits={1} unit="kg" />&nbsp;
                    (<Decimal value={diaryHelper.calcGrainPerBirdGram(diary)} digits={1} unit="g/lintu" />)</span>
                  : <span>ei kirjattu</span>}
                </span><br/>
                <span>Vesi: {diary.waterConsumptionLiter != null
                  ? <span><Decimal value={diary.waterConsumptionLiter} digits={1} unit="l" />&nbsp;
                    (<Decimal value={diaryHelper.calcWaterPerBirdMl(diary)} digits={1} unit="ml/lintu" />)</span>
                  : <span>ei kirjattu</span>}
                </span><br/>
                <span>Lisät: {supplements() || "ei kirjattu"}</span>
              </Fragment>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="Aseta" disabled={isLocked} onClick={() => setFeedingModalOpen(true)}>
              {isLocked ? <LockIcon /> : <EditIcon />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem key="enviroment" disableGutters>
          <ListItemText
            primary="Olosuhteet"
            secondary={
              <Fragment>
                <span>Lämpötila: <Decimal value={diary.temperature} digits={1} unit="&deg;C" empty="ei kirjattu" /></span><br/>
                <span>Ilmankosteus: <Decimal value={diary.humidity} digits={1} unit="%" empty="ei kirjattu" /></span><br/>
                <span>Hiilidioksidi: <Decimal value={diary.co2} digits={1} unit="ppm" empty="ei kirjattu" /></span>
                {diary.notificationTypes?.includes(NotificationType.LightDimming) && <span><br/>Valaistusta on himmennetty</span>}
              </Fragment>
            }
          />
          <ListItemSecondaryAction>
            {isLocked && <IconButton edge="end" aria-label="Aseta" disabled onClick={() => false}>
              <LockIcon />
            </IconButton>}
            {!isLocked && <TaskMenu id="environmentmenu" onClick={handleEnvironmentMenu}
                items={[
                  { action: "edit", label: "Muokkaa olosuhdetietoja" },
                  { action: "lights", label: "Ilmoita valaistuksen himmentämisestä" },
                ]}
            />}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem key="notes" disableGutters>
          <ListItemText
            primary="Huomiot/vierailijat"
            secondary={diary.notes ? (diary.notes.substring(0, 100) + "...") : "Ei kirjattu"}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="Aseta" disabled={isLocked} onClick={() => setNotesModalOpen(true)}>
              {isLocked ? <LockIcon /> : <EditIcon />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <LossModal open={openLossModal} onClose={handleLossModalClose} batch={batch} diary={diary} />
      <WeightModal open={openWeightModal} onClose={handleWeightModalClose} batch={batch} diary={diary} />
      <FeedingModal open={openFeedingModal} onClose={handleFeedingModalClose} batch={batch} diary={diary} />
      <EnviromentModal open={openEnviromentModal} onClose={handleEnviromentModalClose} batch={batch} diary={diary} />
      <NotesModal open={openNotesModal} onClose={handleNotesModalClose} batch={batch} diary={diary} />
      <NotificationModal open={openNotificationModal.open} onClose={handleNotificationModalClose}
        diary={diary} type={openNotificationModal.type} />

    </Box>
  );
};

export default BatchDiary;
