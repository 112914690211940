import axios from "axios";
import authenticator from "../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

let store: any

export const injectStore = (_store) => {
  store = _store
}

// Headers for non-dispatch api calls
axios.interceptors.request.use(
  (config) => {
    if (store) {
      const state = store.getState();
      return authenticator.getAccessTokenResponse(state?.auth.account)
        .then(tokenResp => {
          config.headers.authorization = `Bearer ${tokenResp.accessToken}`;
          config.headers["x-karinetti-currentproducer"] = state?.ui.settings.producerNumber;
          return Promise.resolve(config);
        });
    }
    return config;
  },
  err => Promise.reject(err)
);

axios.interceptors.response.use(
  (resp) => resp,
  (err) => {
    if (err?.response?.status === 401) {
      authenticator.logout();
    }
    console.error("Api error-response", err);
    return Promise.reject(err);
  }
);

const setRequestHeaders = (headers: any) => {
  axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    ...headers,
  };
};

const http = {
    setRequestHeaders,
    get: axios.get,
    put: axios.put,
    post: axios.post,
    delete: axios.delete,
    request: axios.request
};
export default http;