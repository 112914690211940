import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, ChainInfoSupplementDataDto } from "../../../../shared/models/ApiSchema";
import reportsService from "../../../../shared/services/reportsService";
import ChainInfoSupplementBatch from "../../../../shared/components/Data/ChainInfoSupplementData/ChainInfoSupplementBatch";
import ChainInfoSupplementDiary from "../../../../shared/components/Data/ChainInfoSupplementData/ChainInfoSupplementDiary";
import ChainInfoSupplement from "../../../../shared/components/Data/ChainInfoSupplementData/ChainInfoSupplement";
import ApprovalStatusAuditList from "../../../../shared/components/ApprovalStatus/ApprovalStatusAuditList";
import ApprovalStatusChip from "../../../../shared/components/Statuses/ApprovalStatus";
import BatchHeader from "../BatchHeader/BatchHeader";

interface ChainInfoSupplementModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
}

const ChainInfoSupplementModal: React.FC<ChainInfoSupplementModalProps> = ({ open, onClose, batch }) => {
  const classes = useStyles();

  const [supplementData, setSupplementData] = useState<ChainInfoSupplementDataDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setSupplementData(null);
    if (open && batch) {
      setLoading(true);
      const fetchData = async () => {
        const resp = await reportsService.getChainInfoSupplementData(batch.id);
        setSupplementData(resp.data);
        setLoading(false);
      };
      fetchData();
    }
  }, [open, batch]);

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="lg"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}>
      <DialogTitle>
        <PageHeader level="2" component="span">
          Erä {batch.breedingId}: Ketjuinformaatiolomakkeen lisäsivu{" "}
          {supplementData != null && <ApprovalStatusChip status={supplementData?.chainInfoSupplement?.approvalStatus} />}
        </PageHeader>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        {loading &&
          <Box my={6} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!loading && supplementData != null &&
          <Fragment>
            <Box my={2}>
              <ChainInfoSupplementBatch data={supplementData} />
              <ChainInfoSupplementDiary data={supplementData} />
              <ChainInfoSupplement data={supplementData} />
              {supplementData.chainInfoSupplement?.notes && <Box my={3}>
                <strong>Huomioita:</strong><br/>{supplementData.chainInfoSupplement?.notes}
              </Box>}
            </Box>
            <Box mt={4}>
              <ApprovalStatusAuditList audits={supplementData.audits} />
            </Box>
          </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>Sulje</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChainInfoSupplementModal;
