import React, { Fragment, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import PageHeader from "../../../shared/components/PageHeader/PageHeader";
import { ApprovalStatus, ViewName } from "../../../shared/models/ApiSchema";
import ApprovalStatusAuditList from "../../../shared/components/ApprovalStatus/ApprovalStatusAuditList";
import ApprovalStatusSet from "../../../shared/components/ApprovalStatus/ApprovalStatusSetForm";
import { IRootState } from "../../../shared/store/rootReducer";
import { useSelector } from "react-redux";
import ApprovalStatusChip from "../../../shared/components/Statuses/ApprovalStatus";
import Date from "../../../shared/components/Formatters/Date";
import { notificationTypeText } from "../../../shared/helpers/statusHelper";
import { useReactToPrint } from "react-to-print";
import useStyles from "../../../shared/themes/modalStyles";

interface NotificationApprovalModalProps {
  open: boolean;
  onClose: any;
}

const NotificationApprovalModal: React.FC<NotificationApprovalModalProps> = ({ open, onClose }) => {
  const classes = useStyles();

  const access = useSelector((state: IRootState) => state.user.access);
  const approval = useSelector((state: IRootState) => state.approval.notificationApproval);

  const [newState, setNewState] = useState<ApprovalStatus | null>(null);

  const handleNewState = (state: ApprovalStatus) => {
    setNewState(state);
  };

  const handleClose = () => {
    onClose(newState);
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current || null
  });

  return (
    <Dialog
      ref={printRef}
      scroll="body"
      fullWidth
      maxWidth="md"
      open={open}
      TransitionProps={{onEnter: () => setNewState(null)}}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">
          Erä {approval?.notification?.breedingId}: Ilmoitus{" "}
          {approval != null && <ApprovalStatusChip status={approval?.notification?.approvalStatus} />}
        </PageHeader>
      </DialogTitle>
      <DialogContent>
        {(approval != null && approval.notification != null && <Fragment>
          <PageHeader level="3" component="span">{notificationTypeText(approval.notification.type)}</PageHeader>
          <Box my={2}>
            {approval.notification && <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">Tuottaja</TableCell>
                    <TableCell>{approval.notification.producerName} ({approval.notification.producerNumber})</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Halli</TableCell>
                    <TableCell>{approval.notification.hallNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Erätunnus</TableCell>
                    <TableCell>{approval.notification.breedingId}</TableCell>
                  </TableRow>
                  {approval.notification.diaryDate && <TableRow>
                    <TableCell component="th" scope="row">Kasvatuspäivä</TableCell>
                    <TableCell><Date value={approval.notification.diaryDate} /> (ikä {approval.notification.diaryDay} vrk)</TableCell>
                  </TableRow>}
                  <TableRow>
                    <TableCell component="th" scope="row">Lähetetty</TableCell>
                    <TableCell><Date value={approval.notification.createdDate} time /> ({approval.notification.createdBy || '-'})</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Kuvaus</TableCell>
                    <TableCell>{approval.notification.message || '-'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Lähettäjän selvitys</TableCell>
                    <TableCell><span style={{whiteSpace: 'pre-wrap'}}>{approval.notification.notes || '-'}</span></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>}
          </Box>
          <Box mt={3}>
            {access?.approvalStatusEnabled?.Notification &&
              <PageHeader level="2" component="div">Hyväksyntä</PageHeader>}
            <Box my={2}>
              <ApprovalStatusAuditList audits={approval.audits} />
            </Box>
            {access?.approvalStatusEnabled?.Notification && <Box mt={3} mb={1}>
              <ApprovalStatusSet view={ViewName.Notification} current={approval.notification.approvalStatus} id={approval.notification.id} onChange={handleNewState} />
            </Box>}
          </Box>
        </Fragment>)}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handlePrint}>Tulosta</Button>
        <Button variant="text" onClick={handleClose}>Sulje</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationApprovalModal;