import { SalmonellaSampleCoveringDto } from "../models/ApiSchema";
import http from "./httpService";

const API_URL = `print`;

export const listSalmonellaSampleDates = async () => {
  const resp = await http.get<string[]>(`${API_URL}/salmonellasampledates`);
  return resp;
};

export const getSalmonellaSampleCovering = async (date: moment.Moment) => {
  const resp = await http.get<SalmonellaSampleCoveringDto>(`${API_URL}/salmonellasamplecovering/${date.format('YYYY-MM-DD')}`);
  return resp;
};

const printService = {
  listSalmonellaSampleDates,
  getSalmonellaSampleCovering
};
export default printService;