import { Box } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { diaryFormData } from '../../../../shared/helpers/formHelper';

interface NotesFormProps {
  formId: string;
  values?: any;
  onSubmit: any;
}

const NotesForm: React.FC<NotesFormProps> = ({formId, values, onSubmit}) => {

  const initialValues = {
    notes: ""
  }

  const formSchema = Yup.object().shape({
    notes: Yup
      .string()
      .nullable()
      .max(4000)
  });

  return (
      <Formik
        enableReinitialize
        initialValues={values ? diaryFormData({...values}) : initialValues}
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          const data = formSchema.cast(values, { stripUnknown: true });
          actions.setSubmitting(false);
          actions.resetForm({ values: initialValues });
          onSubmit(data);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form id={formId}>
            <Box display="flex" flexDirection="column">
              <Field
                component={TextField}
                label="Huomioita"
                name="notes"
                margin="normal"
                autoFocus={true}
                InputProps={{
                  multiline: true,
                  maxRows: 20
                }}
              />
            </Box>
          </Form>
        )}
      </Formik>
  );
};

export default NotesForm;
