import React from "react";
import { Paper } from "@material-ui/core";
import { Switch, Route } from "react-router-dom";
import useStyles from "./MainStyles";
import Home from "../../../views/Home/Home";
import Batch from "../../../views/Batch/Batch";
import Templates from "../../../views/Templates/Templates";
import Print from "../../../views/Print/Print";
import Archive from "../../../views/Archive/Archive";
import Approval from "../../../views/Approval/Approval";

const Main = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.content} elevation={0}>
      <Switch>
        <Route path="/batch/:batchId?" component={Batch} />
        <Route path="/templates" component={Templates} />
        <Route path="/print" component={Print} />
        <Route path="/archive" component={Archive} />
        <Route path="/approval" component={Approval} />
        <Route path="/" component={Home} />
      </Switch>
    </Paper>
  );
};

export default Main;
