import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "./NavigationStyles";
import ToggleMenu from "../../ToggleMenu/ToggleMenu";
// import { ReactComponent as Logo } from "../../../../assets/images/HKScan.svg";
import Logo from "../../../../assets/images/hkfoods.png";
import ArrowMenu from "../../ArrowMenu/ArrowMenu";
import { IRootState } from "../../../store/rootReducer";
import { useSelector } from "react-redux";

interface INavigationSubItem {
  name: string;
  link: string;
  productionLines?: any;
  operationUnits?: any;
  absolutePath?: boolean;
  hide?: boolean;
}
interface INavigationItem {
  name: string;
  link?: string;
  productionLines?: any;
  operationUnits?: any;
  absolutePath?: boolean;
  items?: INavigationSubItem[];
  hide?: boolean;
}

const defaultNavigationItems: INavigationItem[] = [
  {
    name: "Hallit & erät",
    link: "/",
  },
  {
    name: "Yhteiset tiedot",
    link: "/templates"
  },
  {
    name: "Tulostus",
    link: "/print",
  },
  {
    name: "Lähetetyt tiedot",
    link: "/approval"
  },
  {
    name: "Arkisto",
    link: "/archive"
  }
];

interface NavigationProps {}

const Navigation: React.FC<NavigationProps> = () => {
  const classes = useStyles();

  const access = useSelector((state: IRootState) => state.user.access);
  const [navigationMenuItems, setNavigationMenuItems] = useState<any[]>([]);

  useEffect(() => {
    if (access) {
      if (!access.isActualProducer && !access?.hasApprovalRole) {
        const index = defaultNavigationItems.findIndex(i => i.link === '/approval');
        if (index >= 0) defaultNavigationItems.splice(index, 1);
      }
      setNavigationMenuItems([...defaultNavigationItems]);
    }
  }, [access]);

  const logo = (<img src={Logo} className={classes.logo} alt="HK Foods" />);

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      className={classes.appBar}>
      <Toolbar>
        <div className={classes.logoContainer}>
          <Link to={"/"}>{logo}</Link>
        </div>
        <div className={classes.titleContainer}>
          <Typography variant="h6" className={classes.title}>
            <span>
              <span className={classes.titleSeparator}>|</span>
              <span className={classes.subTitle}>Karinetti</span>
            </span>
          </Typography>
        </div>
        <div className={classes.menuItemContainer}>
          {navigationMenuItems.map(
            (item, index) =>
              ((item.link || item.link === "") && (
                <Link key={index} to={item.link} className={classes.link}>
                  {item.name}
                </Link>
              )) || (
                <ArrowMenu
                  key={index}
                  menuItems={item.items}
                  label={item.name}
                  menuButtonProps={{
                    classes: { root: classes.menuButton },
                  }}
                  menuPaperStyle={{
                    backgroundColor: "#152B62",
                    color: "#fff",
                    padding: "10px 5px",
                  }}
                />
              )
          )}
        </div>
        <div className={classes.toggleMenu}>
          <ToggleMenu
            menuItems={navigationMenuItems}
            menuPaperProps={{
              style: {
                marginTop: "40px",
              },
            }}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
