import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React from "react";
import { ChainInfoSupplementApprovalDto, ChainInfoSupplementDataDto } from "../../../../shared/models/ApiSchema";
import Number from "../../../../shared/components/Formatters/Number";
import Date from "../../../../shared/components/Formatters/Date";

interface ChainInfoSupplementProps {
  data: ChainInfoSupplementDataDto | ChainInfoSupplementApprovalDto;
  isCreate?: boolean;
}

const ChainInfoSupplement: React.FC<ChainInfoSupplementProps> = ({data, isCreate = false}) => {
  return (
    <Box my={3}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">Kumulatiivinen poistuma</TableCell>
                  <TableCell><Number value={data.chainInfoSupplement?.cumulativeLossTotal} digits={0} empty="-" unit="kpl" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Kumulatiivinen päiväkohtainen kuolleisuusaste</TableCell>
                  <TableCell><Number value={data.chainInfoSupplement?.cumulativeLossPercent} empty="-" unit="%" /></TableCell>
                </TableRow>
                {!isCreate && data.chainInfoSupplement?.loadingMortality != null && <TableRow>
                  <TableCell component="th" scope="row">Lastauksen aikana kuolleet</TableCell>
                  <TableCell><Number value={data.chainInfoSupplement?.loadingMortality} digits={0} empty="-" unit="kpl" /></TableCell>
                </TableRow>}
                {!isCreate && data.chainInfoSupplement?.loadingMortalityPercent != null && <TableRow>
                  <TableCell component="th" scope="row">Lastauksen kuolleisuusaste</TableCell>
                  <TableCell><Number value={data.chainInfoSupplement?.loadingMortalityPercent} digits={3} empty="-" unit="%" /></TableCell>
                </TableRow>}
                {!isCreate && data.batch && data.chainInfoSupplement?.loadingMortality != null && <TableRow>
                  <TableCell component="th" scope="row">Teurastukseen lähetetty määrä</TableCell>
                  <TableCell><Number value={data.batch.birdsLeft - data.chainInfoSupplement?.loadingMortality} digits={0} empty="-" unit="kpl" /></TableCell>
                </TableRow>}
             </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">Kokonaisrehunkulutus (rehu+vilja)</TableCell>
                  <TableCell><Number value={data.chainInfoSupplement?.feedAndGrainConsumptionKg} digits={0} empty="-" unit="kg" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Tilalla lisätyn viljan osuus</TableCell>
                  <TableCell><Number value={data.chainInfoSupplement?.grainPercent} digits={2} empty="-" unit="%" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Kokonaisvedenkulutus</TableCell>
                  <TableCell><Number value={data.chainInfoSupplement?.waterConsumptionLiter} digits={0} empty="-" unit="litraa" /></TableCell>
                </TableRow>
                {!isCreate && data.chainInfoSupplement?.fastingStartTime != null && <TableRow>
                  <TableCell component="th" scope="row">Paaston aloitusaika</TableCell>
                  <TableCell><Date value={data.chainInfoSupplement?.fastingStartTime} empty="-" time /></TableCell>
                </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChainInfoSupplement;
