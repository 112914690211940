import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React, { Fragment } from "react";
import { ChainInfoDto, FeedType } from "../../../models/ApiSchema";
import Number from "../../Formatters/Number";
import Date from "../../Formatters/Date";
import Time from "../../Formatters/Time";
import { lastFeed } from "../../../helpers/feedHelper";

interface ChainInfoProps {
  chainInfo: ChainInfoDto | null | undefined;
  isCreate?: boolean;
}

const ChainInfo: React.FC<ChainInfoProps> = ({chainInfo, isCreate = false}) => {
  if (!chainInfo) return <Fragment></Fragment>;

  return (
    <Fragment>
      <Box my={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">Tuottaja / halli</TableCell>
                <TableCell>{chainInfo.producerName}, halli {chainInfo.hallNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Untuvikkojen saapumispäivä</TableCell>
                <TableCell><Date value={chainInfo.arrivalDate} empty="-" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Hautomo</TableCell>
                <TableCell>{chainInfo.hatchery || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Alkuperätilat</TableCell>
                <TableCell>{chainInfo.parentFlocks || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Aloittanut untuvikkomäärä</TableCell>
                <TableCell>{chainInfo.chickQuantity} kpl</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Ilmoitettu poistuma 0-7 päivän aikana</TableCell>
                <TableCell>{chainInfo.sevenDaysLoss == null ? '-' : chainInfo.sevenDaysLoss + ' kpl'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Kokonaispoistuma lomakkeen täyttöhetkellä</TableCell>
                <TableCell>{chainInfo.totalLossCountAtCreate} kpl</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Kokonaispoistuma-% lomakkeen täyttöhetkellä</TableCell>
                <TableCell><Number value={chainInfo.totalLossPercentAtCreate} unit="%" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Salmonellanäytteen ottopäivä</TableCell>
                <TableCell><Date value={chainInfo.salmonellaSampleDate} empty="-" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Salmonellatestin tulos</TableCell>
                <TableCell>{chainInfo.salmonellaSamplePositive == null
                  ? "-" : (chainInfo.salmonellaSamplePositive ? "POSITIIVINEN" : "NEGATIIVINEN")
                }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Rehutehdas</TableCell>
                <TableCell>{chainInfo.feedFactory || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Starttirehun viimeinen käyttöpäivä</TableCell>
                <TableCell><Date value={lastFeed(chainInfo.feeds, FeedType.Start)?.date} empty="-" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">1. kasvatusrehun viimeinen käyttöpäivä</TableCell>
                <TableCell><Date value={lastFeed(chainInfo.feeds, FeedType.Grow1)?.date} empty="-" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">2. kasvatusrehun viimeinen käyttöpäivä ja aika</TableCell>
                <TableCell><Date value={lastFeed(chainInfo.feeds, FeedType.Grow2)?.date} empty="-" time={true} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">Loppurehun aloittamispäivä</TableCell>
                <TableCell><Date value={lastFeed(chainInfo.feeds, FeedType.End)?.date} empty="-" /></TableCell>
              </TableRow>
              {!isCreate && chainInfo.estimatedSlaughterCount !== null && <TableRow>
                <TableCell component="th" scope="row">Teuraaksi tulevien arvoitu määrä</TableCell>
                <TableCell>{chainInfo.estimatedSlaughterCount || "-"} kpl</TableCell>
              </TableRow>}
              {!isCreate && chainInfo.estimatedSlaughterWeightGrams !== null && <TableRow>
                <TableCell component="th" scope="row">Arvioitu teuraspaino</TableCell>
                <TableCell>{chainInfo.estimatedSlaughterWeightGrams || "-"} g</TableCell>
              </TableRow>}
              <TableRow>
                <TableCell component="th" scope="row">Teurastuspäivämäärä</TableCell>
                <TableCell><Date value={chainInfo.slaughterDate} empty="-" /></TableCell>
              </TableRow>
              {!isCreate && chainInfo.slaughterStartTime !== null && <TableRow>
                <TableCell component="th" scope="row">Teurastuksen alkamisaika</TableCell>
                <TableCell><Time value={chainInfo.slaughterStartTime} empty="-" /></TableCell>
              </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Fragment>
  );
};

export default ChainInfo;
