import React, { useState, useEffect } from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import { ReactComponent as User } from "../../../../assets/images/iconmonstr-user-2.svg";
import ArrowMenu from "../../ArrowMenu/ArrowMenu";
import useStyles from "./OptionsStyles";
import { useSelector } from "react-redux";
import { IRootState } from "../../../store/rootReducer";
import { persistor } from "../../../../index";
import auth from "../../../services/authService";

interface OptionsProps {
  setProducerSelectionModalOpen: any;
}

const Options: React.FC<OptionsProps> = ({ setProducerSelectionModalOpen }) => {
  const classes = useStyles();

  const producerNumber = useSelector((state: IRootState) => state.ui.settings.producerNumber);
  const organizations = useSelector((state: IRootState) => state.user.organizations);
  const currentUser = useSelector((state: IRootState) => state.auth.currentUser);

  const [producer, setProducer] = useState<any>(null);
  // const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    if (organizations) {
      const producerTmp = organizations.find(org => org.producerNumber === producerNumber);
      setProducer(producerTmp);
    }
  }, [organizations, producerNumber]);

  const enableFarmChange = () => organizations?.length > 1 || currentUser.authenticationSource === "HKScanAzureAD";

  const handleClose = (value, itemId) => {
    if (itemId) {
      switch (itemId) {
        case "currentUser":
          if (value === "logout") {
            persistor.purge();
            auth.logout();
          }
          break;
        case "producer":
          if (value === "change") {
            setProducerSelectionModalOpen(true);
          }
          break;
      }
    }
  };

  return (
    <AppBar position="static" elevation={0} className={classes.optionsBar}>
      <Toolbar>
        <a
          href="https://sinetti.hkscanagri.fi"
          className={`${classes.link} ${classes.collapsingLink}`}>
          &lt; Takaisin Sinettiin
        </a>
        <div className={classes.options}>
          <ArrowMenu
            key="producer"
            label={producer?.name}
            disabled={!enableFarmChange()}
            menuItems={[
              { name: "Vaihda tilaa", value: "change" }
            ]}
            arrowIcon={enableFarmChange()}
            onClose={(value, itemId) =>
              handleClose(value, itemId || "producer")
            }
            menuButtonProps={{
              classes: {
                root: `${classes.button}`
              }
            }}
            menuPaperStyle={{
              backgroundColor: "#fff",
              color: "#152B62",
              fontSize: "0.2em",
            }}
          />
          <ArrowMenu
            key="user"
            label={currentUser.name}
            menuItems={[
              { value: "logout", name: "Kirjaudu ulos" }
            ]}
            icon={<User viewBox={"0 -10 40 40"} />}
            arrowIcon={true}
            onClose={(value, itemId) =>
              handleClose(value, itemId || "currentUser")
            }
            menuButtonProps={{
              classes: {
                root: `
                  ${classes.button}
                  ${classes.highlighted}
                `
              }
            }}
            menuPaperStyle={{
              backgroundColor: "#fff",
              color: "#152B62",
              fontSize: "0.2em",
            }}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Options;
