import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, ChainInfoSupplementPreviewDto } from "../../../../shared/models/ApiSchema";
import reportsService from "../../../../shared/services/reportsService";
import { Alert } from "@material-ui/lab";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { TextField } from "formik-material-ui";
import { useConfirm } from "material-ui-confirm";
import { numberEmptyToNull } from "../../../../shared/helpers/formHelper";
import DateTimePicker from "../../../../shared/components/DateTimePicker/DateTimePicker";
import ChainInfoSupplementBatch from "../../../../shared/components/Data/ChainInfoSupplementData/ChainInfoSupplementBatch";
import ChainInfoSupplementDiary from "../../../../shared/components/Data/ChainInfoSupplementData/ChainInfoSupplementDiary";
import ChainInfoSupplement from "../../../../shared/components/Data/ChainInfoSupplementData/ChainInfoSupplement";
import BatchHeader from "../BatchHeader/BatchHeader";
import moment from "moment";

interface ChainInfoSupplementPreviewModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
}

const ChainInfoSupplementPreviewModal: React.FC<ChainInfoSupplementPreviewModalProps> = ({ open, onClose, batch }) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const initialValues = {
    fastingStartTime: null,
    loadingMortality: "",
    notes: ""
  }

  const [values, setValues] = useState<object>(initialValues);

  const [supplementPreview, setSupplementPreview] = useState<ChainInfoSupplementPreviewDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setSupplementPreview(null);
    if (open && batch) {
      setLoading(true);
      const fetchData = async () => {
        const resp = await reportsService.getChainInfoSupplementPreview(batch.id);
        setSupplementPreview(resp.data);
        setValues({
          fastingStartTime: resp.data.chainInfoSupplementData?.chainInfoSupplement?.fastingStartTime || null,
          loadingMortality: resp.data.chainInfoSupplementData?.chainInfoSupplement?.loadingMortality || "",
          notes: resp.data.chainInfoSupplementData?.chainInfoSupplement?.notes || ""
        });
        setLoading(false);
      };
      fetchData();
    }
  }, [open, batch]);

  const handleCancel = () => {
    onClose(null, null);
  };

  const formSchema = Yup.object().shape({
    loadingMortality: Yup
      .number()
      .required()
      .transform(numberEmptyToNull)
      .min(0)
      .max(100000),
    fastingStartTime: Yup
      .date()
      .required(),
    notes: Yup
      .string()
      .nullable()
      .max(4000)
  });


  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="lg"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}>
      <DialogTitle>
        <PageHeader level="2" component="span">Erä {batch.breedingId}: Ketjuinformaatiolomakkeen lisäsivu</PageHeader>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        {loading &&
          <Box my={6} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!loading && supplementPreview != null &&
          <Fragment>
            {supplementPreview.errors && supplementPreview.errors.length > 0 && <Box my={2}>
              <Alert severity="error">
                {supplementPreview.errors.map((e, index) => <div key={index}>{e}</div>)}
              </Alert>
            </Box>}
            <Box my={2}>
              {supplementPreview.chainInfoSupplementData != null &&
                <Fragment>
                  <ChainInfoSupplementBatch data={supplementPreview.chainInfoSupplementData} />
                  <ChainInfoSupplementDiary data={supplementPreview.chainInfoSupplementData} />
                  <ChainInfoSupplement data={supplementPreview.chainInfoSupplementData} isCreate={true} />

                  <Formik
                    initialValues={values}
                    enableReinitialize
                    validationSchema={formSchema}
                    onSubmit={(values, actions) => {
                      confirm({ description: "Ketjuinformaatiolomakkeen lisäsivu lähetetään hyväksyttäväksi, oletko varma?" }).then(() => {
                        const data = formSchema.cast(values, { stripUnknown: true });
                        actions.setSubmitting(false);
                        onClose(data);
                      }, () => {
                        actions.setSubmitting(false);
                      });
                    }}
                  >
                    {({ submitForm, isSubmitting }) => (
                      <Form id="chainInfoSupplementForm">
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={3}>
                            <Field
                              component={DateTimePicker}
                              label="Paaston alkamisaika"
                              name="fastingStartTime"
                              fullWidth
                              margin="dense"
                              minDate={moment(batch.arrivalDate).startOf('day').toDate()}
                              maxDate={moment(batch.slaughterDate).startOf('day').toDate()}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <Field
                              component={TextField}
                              label="Lastauksen aikana kuolleet"
                              name="loadingMortality"
                              fullWidth
                              margin="dense"
                              InputProps={{
                                type: "text",
                                inputMode: "numeric",
                                endAdornment: <InputAdornment position="end">kpl</InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <Field
                              component={TextField}
                              label="Huomioita"
                              name="notes"
                              margin="dense"
                              fullWidth
                              InputProps={{
                                multiline: true,
                                maxRows: 20
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Fragment>
              }
            </Box>
            <Alert severity="info">
              Ketjuinformaation lisäsivun hyväksyntä lukitsee erän päiväkohtaiset kirjaukset, niitä ei enää voi sen jälkeen muokata.
            </Alert>
          </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>Peruuta</Button>
        <Button variant="text" type="submit" form="chainInfoSupplementForm"
          disabled={supplementPreview?.errors == null || supplementPreview?.errors?.length > 0}>Hyväksy</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChainInfoSupplementPreviewModal;
