import React, { Fragment, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import PageHeader from "../../../shared/components/PageHeader/PageHeader";
import { ApprovalStatus, ViewName } from "../../../shared/models/ApiSchema";
import ApprovalStatusAuditList from "../../../shared/components/ApprovalStatus/ApprovalStatusAuditList";
import ApprovalStatusSet from "../../../shared/components/ApprovalStatus/ApprovalStatusSetForm";
import { useSelector } from "react-redux";
import { IRootState } from "../../../shared/store/rootReducer";
import ChainInfoSupplementBatch from "../../../shared/components/Data/ChainInfoSupplementData/ChainInfoSupplementBatch";
import ChainInfoSupplementDiary from "../../../shared/components/Data/ChainInfoSupplementData/ChainInfoSupplementDiary";
import ChainInfoSupplement from "../../../shared/components/Data/ChainInfoSupplementData/ChainInfoSupplement";
import ApprovalStatusChip from "../../../shared/components/Statuses/ApprovalStatus";
import { useReactToPrint } from "react-to-print";
import useStyles from "../../../shared/themes/modalStyles";

interface ChainInfoSupplementApprovalModalProps {
  open: boolean;
  onClose: any;
}

const ChainInfoSupplementApprovalModal: React.FC<ChainInfoSupplementApprovalModalProps> = ({ open, onClose }) => {
  const classes = useStyles();

  const access = useSelector((state: IRootState) => state.user.access);
  const approval = useSelector((state: IRootState) => state.approval.chainInfoSupplementApproval);

  const [newState, setNewState] = useState<ApprovalStatus | null>(null);

  const handleNewState = (state: ApprovalStatus) => {
    setNewState(state);
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current || null
  });

  const handleClose = () => {
    onClose(newState);
  };

  return (
    <Dialog
      ref={printRef}
      scroll="body"
      fullWidth
      maxWidth="lg"
      open={open}
      TransitionProps={{onEnter: () => setNewState(null)}}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">
          Erä {approval?.chainInfo?.breedingId}: Ketjuinformaation lisäsivu{" "}
          {approval != null && <ApprovalStatusChip status={approval?.chainInfoSupplement?.approvalStatus} />}
        </PageHeader>
      </DialogTitle>
      <DialogContent>
        {(approval != null && approval.chainInfoSupplement != null && <Fragment>
            <ChainInfoSupplementBatch data={approval} />
            <ChainInfoSupplementDiary data={approval} />
            <ChainInfoSupplement data={approval} />
            {approval.chainInfoSupplement?.notes && <Box my={3}>
              <strong>Huomioita:</strong><br/>{approval.chainInfoSupplement?.notes}
            </Box>}
            <Box mt={3}>
              {access?.approvalStatusEnabled?.ChainInfoSupplement &&
                <PageHeader level="2" component="div">Hyväksyntä</PageHeader>}
              <Box my={2}>
                <ApprovalStatusAuditList audits={approval.audits} />
              </Box>
              {access?.approvalStatusEnabled?.ChainInfoSupplement && <Box mt={3} mb={1}>
                <ApprovalStatusSet view={ViewName.ChainInfoSupplement} current={approval.chainInfoSupplement.approvalStatus} id={approval.chainInfoSupplement.id} onChange={handleNewState} />
              </Box>}
            </Box>
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handlePrint}>Tulosta</Button>
        <Button variant="text" onClick={handleClose}>Sulje</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChainInfoSupplementApprovalModal;