import React, {
  Fragment,
  useState
} from "react";
import {Link} from "react-router-dom";
import {
  Button,
  Menu,
  MenuItem
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import useStyles from "./ToggleMenuStyles";
import NestedMenuItem from "./NestedMenuItem/NestedMenuItem";
import {Divider} from "@material-ui/core";

export const ToggleMenu = ({menuItems, onClose = (value, itemId) => {}, labelComponent = {}, menuPaperProps = {}}) => {
    const classes = useStyles();
    const [menuPosition, setMenuPosition] = useState<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setMenuPosition({
        top: e.pageY,
        left: e.pageX
      });
      setAnchorEl(e.currentTarget);
    };

    const handleClose = (value, itemId) => {
      setMenuPosition(null);
      setAnchorEl(null);
      if (value)
        onClose(value, itemId);
    };

    return (
      <Fragment>
        <Button
          className={classes.menuButton}
          aria-controls="toggle-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          { (Object.keys(labelComponent).length > 0) ? labelComponent : <MenuIcon /> }
        </Button>

        <Menu
          PaperProps={menuPaperProps}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleClose("", "")}
        >
          {menuItems.map((rootItem, index) => (
            ((rootItem.link || rootItem.link === "") && (
              <div key={index}>
                <MenuItem
                  onClick={() => handleClose("", "")}
                  component={Link}
                  to={rootItem.link}
                >
                  {rootItem.name}
                </MenuItem>
                {rootItem.divider ? <Divider /> : null}
              </div>
              ||
              <div key={index}>
                <NestedMenuItem
                  label={rootItem.name}
                  parentMenuOpen={!!menuPosition}
                >
                  {rootItem.items.map((item, index) => (
                    <MenuItem
                      onClick={() => handleClose(item.value, rootItem.id)}
                      key={index}
                      component={Link}
                      to={item.link}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </NestedMenuItem>
                {rootItem.divider ? <Divider /> : null}
              </div>))
          ))}
        </Menu>
     </Fragment>
  );
};

export default ToggleMenu;