import { Container } from '@material-ui/core';
import React from 'react';
import useStyles from './AppStyles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ConfirmProvider } from 'material-ui-confirm';
import MomentUtils from "@date-io/moment";
import AppInit from "./shared/components/AppInit/AppInit";
import Footer from './shared/components/Footer/Footer';
import Header from './shared/components/Header/Header';
import Main from './shared/components/Main/Main';
import moment from 'moment';

const App = () => {
  const classes = useStyles();

  return (
    <AppInit>
      <Container maxWidth={"xl"} className={classes.container}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="fi">
          <ConfirmProvider defaultOptions={{
            title: "Oletko varma?",
            confirmationText: "Vahvista",
            cancellationText: "Peruuta",
            dialogProps: { maxWidth: "xs" }
          }}>
            <Header />
            <div className={classes.main}>
              <Main />
            </div>
            <Footer />
          </ConfirmProvider>
        </MuiPickersUtilsProvider>
      </Container>
    </AppInit>
  );
}

export default App;
