import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { PoultryDiaryDto } from "../../../../shared/models/ApiSchema";
import moment from "moment";
import Decimal from "../../../../shared/components/Formatters/Number";
import Percent from "../../../../shared/components/Formatters/Percent";
import diaryHelper from "../../../../shared/helpers/diaryHelper";
import { findDiaryIndexByDate } from "../../../../shared/store/batch";

interface BatchTrendProps {
  diaries: PoultryDiaryDto[];
  days: number;
  current: moment.Moment;
  initialFeed?: number;
  initialBirds?: number;
}

const BatchStats: React.FC<BatchTrendProps> = ({ diaries, days, current, initialFeed = 0, initialBirds = 1 }) => {

  const [table, setTable] = useState<PoultryDiaryDto[]>([]);
  const [cumulative, setCumulative] = useState<PoultryDiaryDto[]>([]);

  const now = moment();

  useEffect(() => {
    if (current && diaries.length) {
      const index = findDiaryIndexByDate(diaries, current);
      if (index >= 0) {
        let start = index - 2;
        if (start < 0) start = 0;
        let end = start + 3;
        if (end > diaries.length) end = diaries.length;
        setTable(diaries.slice(start, end));
        setCumulative(diaries.slice(0, index + 1));
      }
      else {
        setTable([]);
        setCumulative([]);
      }
    }
  }, [diaries, current]);

  const summaryStyle = {
    fontWeight: "bold",
    backgroundColor: "#efefff"
  } as React.CSSProperties;

  const headerStyle = {
    fontWeight: "bold"
  } as React.CSSProperties;

  if (table.length === 0) {
    return <Fragment></Fragment>
  }

  return (
    <Box p={2} pt={1}>
      <TableContainer>
        <Table size="small">
          <TableHead style={{ whiteSpace: 'nowrap'}}>
            <TableRow>
              <TableCell align="center" style={headerStyle} width={100}>Päivä</TableCell>
              <TableCell align="center" style={headerStyle} width={75}>Ikä</TableCell>
              <TableCell align="center" style={headerStyle}>Kuollut</TableCell>
              <TableCell align="center" style={headerStyle}>Karsittu</TableCell>
              <TableCell align="center" style={headerStyle}>Poistuma</TableCell>
              <TableCell align="center" style={headerStyle}>Vesi</TableCell>
              <TableCell align="center" style={headerStyle}>Vesi/lintu</TableCell>
              <TableCell align="center" style={headerStyle}>Rehu+vilja</TableCell>
              <TableCell align="center" style={headerStyle}>R+V/lintu</TableCell>
              <TableCell align="center" style={headerStyle}>Vilja %</TableCell>
              <TableCell align="center" style={headerStyle}>Paino</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ whiteSpace: 'nowrap'}}>
            {table.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  {moment(row.date).isSame(now, 'day') ? "Tänään" : moment(row.date).format('D.M.')}
                </TableCell>
                <TableCell align="center"><strong>{row.day}</strong></TableCell>
                <TableCell align="center">{row.poultryLosses && row.poultryLosses.length ? row.birdsDead : "-"}</TableCell>
                <TableCell align="center">{row.poultryLosses && row.poultryLosses.length ? row.birdsCulled : "-"}</TableCell>
                <TableCell align="center" style={diaryHelper.getLossStyle(row)}>
                  {row.poultryLosses && row.poultryLosses.length
                    ? <span>{diaryHelper.calcLoss(row)} (<Percent value={diaryHelper.calcLossPercent(row)} digits={3} />)</span>
                    : <span>-</span>
                  }
                </TableCell>
                <TableCell align="center">
                  <Decimal value={row.waterConsumptionLiter} empty="-" unit="l" digits={0} />
                </TableCell>
                <TableCell align="center">
                  {row.waterConsumptionLiter != null
                    ? <Decimal value={diaryHelper.calcWaterPerBirdMl(row)} empty="-" unit="ml/lintu" digits={0} />
                    : <span>-</span>
                  }
                </TableCell>
                <TableCell align="center">
                  <Decimal value={diaryHelper.calcFeedTotalKg(row)} empty="-" unit="kg" digits={0} />
                </TableCell>
                <TableCell align="center">
                  <Decimal value={diaryHelper.calcFeedTotalPerBirdGram(row)} empty="-" unit="g/lintu" digits={0} />
                </TableCell>
                <TableCell align="center"><Percent value={diaryHelper.calcGrainPercent(row)} empty="-" /></TableCell>
                <TableCell align="center"><Decimal value={row.liveWeightGrams} empty="-" unit="g" digits={0} /></TableCell>
              </TableRow>
            ))}
            <TableRow key="summary">
              <TableCell align="center" style={summaryStyle}>
                Kumulatiivinen
              </TableCell>
              <TableCell align="center" style={summaryStyle}>
                {cumulative[cumulative.length - 1].day}
              </TableCell>
              <TableCell align="center" style={summaryStyle}>
                {cumulative.map(d => d.birdsDead).reduce((p, c) => p + c, 0)}
              </TableCell>
              <TableCell align="center" style={summaryStyle}>
                {cumulative.map(d => d.birdsCulled).reduce((p, c) => p + c, 0)}
              </TableCell>
              <TableCell align="center" style={summaryStyle}>
                {diaryHelper.cumulativeLoss(cumulative)} (<Percent value={diaryHelper.cumulativeLossPercent(cumulative)} />)
              </TableCell>
              <TableCell align="center" style={summaryStyle}>
                <Decimal value={diaryHelper.cumulativeWaterLiter(cumulative)} unit="l" digits={0} />
              </TableCell>
              <TableCell align="center" style={summaryStyle}>
                <Decimal value={diaryHelper.cumulativeWaterPerBirdMl(cumulative)} unit="ml/lintu" digits={0} />
              </TableCell>
              <TableCell align="center" style={summaryStyle}>
                <Decimal value={diaryHelper.cumulativeFeedTotalKg(cumulative, initialFeed)} unit="kg" digits={0} />
              </TableCell>
              <TableCell align="center" style={summaryStyle}>
                <Decimal value={diaryHelper.cumulativeFeedTotalPerBirdGram(cumulative, initialFeed, initialBirds)} unit="g/lintu" digits={0} />
              </TableCell>
              <TableCell align="center" style={summaryStyle}>
                <Percent value={diaryHelper.cumulativeGrainPercent(cumulative, initialFeed)} />
              </TableCell>
              <TableCell align="center" style={summaryStyle}>

              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BatchStats;
