import React, { Fragment, useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, ChainInfoPreviewDto } from "../../../../shared/models/ApiSchema";
import moment from "moment";
import reportsService from "../../../../shared/services/reportsService";
import { Alert } from "@material-ui/lab";
import { Field, Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from 'yup';
import { TextField } from "formik-material-ui";
import { useConfirm } from "material-ui-confirm";
import ChainInfo from "../../../../shared/components/Data/ChainInfoData/ChainInfo";
import ChainInfoFeed from "../../../../shared/components/Data/ChainInfoData/ChainInfoFeed";
import ChainInfoHealth from "../../../../shared/components/Data/ChainInfoData/ChainInfoHealth";
import { dateOnly, numberEmptyToNull, truncateSeconds } from "../../../../shared/helpers/formHelper";
import DatePicker from "../../../../shared/components/DatePicker/DatePicker";
import BatchHeader from "../BatchHeader/BatchHeader";
import TimePicker from "../../../../shared/components/TimePicker/TimePicker";

interface ChainInfoPreviewModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
}

const FormikSubmitEffect = ({ onSubmitError }) => {
  const { isSubmitting, submitCount, isValid } = useFormikContext()
  const [lastHandled, setLastHandled] = useState(0)
  useEffect(() => {
    if (submitCount > lastHandled && !isSubmitting && !isValid) {
      onSubmitError()
      setLastHandled(submitCount)
    }
  }, [submitCount, isValid, onSubmitError, lastHandled, isSubmitting])
  return null
}

const ChainInfoPreviewModal: React.FC<ChainInfoPreviewModalProps> = ({ open, onClose, batch }) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const formikRef = useRef<FormikProps<object>>(null);

  const _initialValues = {
    estimatedSlaughterCount: "",
    estimatedSlaughterWeightGrams: "",
    slaughterStartTime: null,
    lastOfficialSalmonellaSampleDate: null,
    lastOfficialSalmonellaSamplePositive: "",
    approveName: "",
    approveLocation: ""
  }

  const [chainInfoPreview, setChainInfoPreview] = useState<ChainInfoPreviewDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<object>(_initialValues);
  const [submit, setSubmit] = useState<null | 'draft' | 'send'>(null);

  const [isEarly, setIsEarly] = useState<boolean>(false);
  useEffect(() => {
    const now = moment().startOf('day');
    const start = moment(batch.slaughterDate).startOf('day').subtract(3, 'days');
    setIsEarly(now.isBefore(start));
  }, [batch]);

  useEffect(() => {
    setChainInfoPreview(null);
    if (open && batch) {
      setLoading(true);
      const fetchData = async () => {
        const resp = await reportsService.getChainInfoPreview(batch.id);
        setChainInfoPreview(resp.data);
        const _values = {
          estimatedSlaughterCount: (resp.data?.chainInfo?.estimatedSlaughterCount || "").toString(),
          estimatedSlaughterWeightGrams: resp.data?.chainInfo?.estimatedSlaughterWeightGrams || "",
          slaughterStartTime: resp.data?.chainInfo?.slaughterStartTime || null,
          lastOfficialSalmonellaSampleDate: resp.data?.chainInfo?.lastOfficialSalmonellaSampleDate || null,
          lastOfficialSalmonellaSamplePositive: resp.data?.chainInfo?.lastOfficialSalmonellaSamplePositive === true ? "true"
            : resp.data?.chainInfo?.lastOfficialSalmonellaSamplePositive === false ? "false" : "",
          approveName: resp.data?.chainInfo?.approveName || "",
          approveLocation: resp.data?.chainInfo?.approveLocation || ""
        };
        setInitialValues(_values);
        setLoading(false);
      };
      fetchData();
    }
  }, [open, batch]);

  useEffect(() => {
    if (submit) {
      if (!formikRef.current?.isSubmitting) {
        formikRef.current?.submitForm();
      }
    }
  }, [submit]);

  const handleCancel = () => {
    onClose(null, null);
  };

  const formSchema = Yup.object().shape({
    estimatedSlaughterCount: Yup
      .number()
      .required()
      .transform(numberEmptyToNull)
      .min(1)
      .max(1000000),
    estimatedSlaughterWeightGrams: Yup
      .number()
      .required()
      .transform(numberEmptyToNull)
      .min(1)
      .max(100000),
    slaughterStartTime: Yup
      .date()
      .transform(truncateSeconds)
      .required(),
    lastOfficialSalmonellaSampleDate: Yup
      .date()
      .transform(dateOnly)
      .nullable()
      .required(),
    lastOfficialSalmonellaSamplePositive: Yup
      .string()
      .required()
      .oneOf(["true", "false"]),
    approveName: Yup
      .string()
      .required()
      .max(100),
    approveLocation: Yup
      .string()
      .required()
      .max(100)
  });

  const formSchemaDraft = Yup.object().shape({
    estimatedSlaughterCount: Yup
      .number()
      .nullable()
      .transform(numberEmptyToNull)
      .min(1)
      .max(1000000),
    estimatedSlaughterWeightGrams: Yup
      .number()
      .nullable()
      .transform(numberEmptyToNull)
      .min(1)
      .max(100000),
    slaughterStartTime: Yup
      .date()
      .nullable()
      .transform(truncateSeconds),
    lastOfficialSalmonellaSampleDate: Yup
      .date()
      .nullable()
      .transform(dateOnly),
    lastOfficialSalmonellaSamplePositive: Yup
      .string()
      .oneOf(["", "true", "false"]),
    approveName: Yup
      .string()
      .max(100),
    approveLocation: Yup
      .string()
      .max(100)
  });

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="md"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">Erä {batch.breedingId}: Ketjuinformaatiolomake</PageHeader>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        {loading &&
          <Box my={6} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!loading && chainInfoPreview != null &&
          <Fragment>
            {chainInfoPreview.errors && chainInfoPreview.errors.length > 0 && <Box my={2}>
              <Alert severity="error">
                {chainInfoPreview.errors.map((e, index) => <div key={index}>{e}</div>)}
              </Alert>
            </Box>}
            <Box my={2}>
              {chainInfoPreview.chainInfo != null &&
                <Formik
                  innerRef={formikRef}
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={() => submit === 'send' ? formSchema : formSchemaDraft}
                  onSubmit={(values, actions) => {
                    if (submit === 'draft') {
                      const data = formSchemaDraft.cast(values, { stripUnknown: true });
                      if (values['estimatedSlaughterCount'] === initialValues['estimatedSlaughterCount']) {
                        data.estimatedSlaughterCount = null;
                      }
                      actions.setSubmitting(false);
                      setSubmit(null);
                      onClose(data, true);
                    }
                    if (submit === 'send') {
                      confirm({ description: "Ketjuinformaatiolomake lähetetään hyväksyttäväksi, oletko varma?" }).then(() => {
                        const data = formSchema.cast(values, { stripUnknown: true });
                        actions.setSubmitting(false);
                        setSubmit(null);
                        onClose(data, false);
                      }, () => {
                        actions.setSubmitting(false);
                        setSubmit(null);
                      });
                    }
                  }}
                >
                  {({ submitForm, isSubmitting }) => (
                    <Form id="chainInfoForm">
                      <FormikSubmitEffect onSubmitError={() => setSubmit(null)} />
                      <ChainInfo chainInfo={chainInfoPreview.chainInfo} isCreate={true} />
                      <Alert severity="info">
                        Arvioitu määrä on laskettu vähentämällä jäljellä olevien määrästä lukema, joka on neljän edellisen päivän poistuman keskiarvo kerrottuna jäljellä olevilla kasvatuspäivillä plus yksi päivä.
                      </Alert>
                      <Box my={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <Field
                              component={TextField}
                              label="Teuraaksi tulevien arvioitu määrä"
                              name="estimatedSlaughterCount"
                              fullWidth
                              margin="dense"
                              InputProps={{
                                type: "text",
                                inputMode: "numeric",
                                endAdornment: <InputAdornment position="end">kpl</InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Field
                              component={TextField}
                              label="Arvioitu teuraspaino"
                              name="estimatedSlaughterWeightGrams"
                              fullWidth
                              margin="dense"
                              InputProps={{
                                type: "text",
                                inputMode: "numeric",
                                endAdornment: <InputAdornment position="end">grammaa</InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Field
                              component={TimePicker}
                              label="Teurastuksen alkamisaika"
                              name="slaughterStartTime"
                              fullWidth
                              margin="dense"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <ChainInfoFeed chainInfo={chainInfoPreview.chainInfo} />
                      <ChainInfoHealth chainInfo={chainInfoPreview.chainInfo} />
                      <Box my={2}>
                        <PageHeader level="3" component="div">Viimeinen virallinen salmonellanäyte</PageHeader>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <Field
                              name="lastOfficialSalmonellaSampleDate"
                              label="Virallisen näytteen ottopäivä"
                              component={DatePicker}
                              fullWidth
                              margin="dense"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Field
                              select
                              component={TextField}
                              label="Virallisen näytteen testin tulos"
                              name="lastOfficialSalmonellaSamplePositive"
                              margin="dense"
                              fullWidth
                            >
                              <MenuItem value="">-</MenuItem>
                              <MenuItem value="false">NEGATIIVINEN</MenuItem>
                              <MenuItem value="true">POSITIIVINEN</MenuItem>
                            </Field>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box mt={4} mb={2}>
                        <PageHeader level="3" component="div">Lähettäjän tiedot</PageHeader>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <Field
                              component={TextField}
                              label="Paikka"
                              name="approveLocation"
                              fullWidth
                              margin="dense"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Field
                              component={TextField}
                              label="Lähettäjän nimi"
                              name="approveName"
                              fullWidth
                              margin="dense"
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      {isEarly && <Alert severity="warning">
                        Huomio! Jos olet lähettämässä ketjuinformaatiotietoja aiemmin kuin 3 vrk ennen teurastusta, varmista että se on tarkoituksellista. Luonnoksen voit tallentaa milloin tahansa.
                      </Alert>}
                    </Form>
                  )}
                </Formik>
              }
            </Box>
          </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>Peruuta</Button>
        <Button variant="text" type="button" onClick={() => setSubmit('draft')} disabled={!chainInfoPreview}>Tallenna luonnos</Button>
        <Button variant="text" type="button" onClick={() => setSubmit('send')} disabled={!chainInfoPreview || !!chainInfoPreview?.errors?.length}>Hyväksy</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChainInfoPreviewModal;