import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import React, { Fragment } from "react";
import PageHeader from "../../../shared/components/PageHeader/PageHeader";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from "react-router-dom";

const TemplateList = () => {

  const history = useHistory();

  const navigateHall = () => history.push('/templates/hall');
  const navigateFeed = () => history.push('/templates/feed');
  const navigateHealth = () => history.push('/templates/health');
  const navigateSalmonella = () => history.push('/templates/salmonella');

  return (
    <Fragment>
      <PageHeader level="1" component="div">Hallien ja erien yhteiset tiedot</PageHeader>
      <Box mt={2}>
        <List>
          <ListItem button key="hall" onClick={navigateHall}>
            <ListItemAvatar>
              <Avatar>
                <strong>H</strong>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<strong>Yleiset hallitiedot</strong>}
              secondary="Pohjatiedot kopioitavaksi eräkohtaisiin hallitietoihin"
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={navigateHall}>
                <NavigateNextIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button key="feed" onClick={navigateFeed}>
            <ListItemAvatar>
              <Avatar>
                <strong>R</strong>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<strong>Yleiset rehutiedot</strong>}
              secondary="Pohjatiedot kopioitavaksi eräkohtaisiin rehutietoihin"
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={navigateFeed}>
                <NavigateNextIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button key="health" onClick={navigateHealth}>
            <ListItemAvatar>
              <Avatar>
                <strong>T</strong>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<strong>Terveystietojen alustus</strong>}
              secondary="Alusta käynnissä olevien kasvatuserien terveystiedot"
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={navigateHealth}>
                <NavigateNextIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button key="salmonella" onClick={navigateSalmonella}>
            <ListItemAvatar>
              <Avatar>
                <strong>S</strong>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<strong>Salmonellanäytteen kirjaus</strong>}
              secondary="Kirjaa salmonellanäyte käynnissä oleville kasvatuserille"
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={navigateSalmonella}>
                <NavigateNextIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Box>
    </Fragment>
  );
};

export default TemplateList;