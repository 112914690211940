import { combineReducers } from "redux";
import uiReducer, { IUi } from "./ui/ui";
import authReducer, { IAutheticate } from "./auth";
import userReducer, { IUser } from "./user";
import apiReducer, { IError } from "./api";
import homeReducer, { IHome } from "./home";
import batchReducer, { IBatch } from "./batch";
import templatesReducer, { ITemplates } from "./templates";
import approvalReducer, { IApproval } from "./approval";

export interface IRootState {
  ui: IUi;
  auth: IAutheticate;
  user: IUser;
  api: IError;
  home: IHome;
  batch: IBatch;
  templates: ITemplates;
  approval: IApproval;
}

// global state
export default combineReducers({
  ui: uiReducer,
  auth: authReducer,
  user: userReducer,
  api: apiReducer,
  home: homeReducer,
  batch: batchReducer,
  templates: templatesReducer,
  approval: approvalReducer
});
