import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, HealthInfoDto, SalmonellaSampleDto } from "../../../../shared/models/ApiSchema";
import HealthInfoForm from "../HealthInfoForm/HealthInfoForm";
import BatchHeader from "../BatchHeader/BatchHeader";

interface HealthInfoModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
  health: HealthInfoDto | null;
  salmonella?: SalmonellaSampleDto | null
}

const HealthInfoModal: React.FC<HealthInfoModalProps> = ({ open, onClose, batch, health, salmonella }) => {
  const classes = useStyles();

  const handleCancel = () => {
    onClose(null);
  };

  const handleSubmit = (data) => {
    onClose(data);
  };

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="md"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
      aria-labelledby="healthinfo-modal-title"
      aria-describedby="healthinfo-modal-description">
      <DialogTitle id="healthinfo-modal-title">
        <PageHeader level="2" component="div">Erän {batch.breedingId} terveystiedot</PageHeader>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        <HealthInfoForm
          formId="healthInfoForm"
          onSubmit={handleSubmit}
          values={health}
          salmonella={salmonella}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>{health?.isFinal ? "Sulje" : "Peruuta"}</Button>
        {!health?.isFinal && <Button variant="text" type="submit" form="healthInfoForm">Tallenna</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default HealthInfoModal;
