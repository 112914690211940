import { PoultryDiaryDto } from "../models/ApiSchema"
import { calcFeedTotalPerBirdGram, calcLoss, calcLossPercent } from "./diaryHelper";

export const getDiaryLabels = (diaries: PoultryDiaryDto[]): number[] => {
  return diaries.map(diary => diary.day).sort((a, b) => a - b);
}

export const getCumulativeLossPercentage = (diaries: PoultryDiaryDto[]): (number|null)[] => {
  let loss = 0;
  let values: (number|null)[] = []
  for (let i = 0; i < diaries.length; i++) {
    const diary = diaries[i];
    loss += calcLoss(diary);
    values.push(loss / (diary.birdsLeft + loss) * 100);
  }
  return values;
}

export const getCumulativeFeedTotalPerBird = (diaries: PoultryDiaryDto[], init: number = 0): (number|null)[] => {
  let total = init;
  let values: (number|null)[] = []
  for (let i = 0; i < diaries.length; i++) {
    const diary = diaries[i];
    const feed = calcFeedTotalPerBirdGram(diary);
    if (feed !== null) {
      total += feed;
      values.push(total);
    } else {
      values.push(null);
    }
  }
  return values;
}

export const getDailyLossPercentage = (diaries: PoultryDiaryDto[]): (number|null)[] => {
  return diaries.map((diary) => !diary.poultryLosses?.length ? null : calcLossPercent(diary) * 100);
}
