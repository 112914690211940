import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  noBatch: {
    color: theme.palette.getContrastText(theme.palette.grey["500"]),
    backgroundColor: theme.palette.grey["500"]
  },
  planned: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main
  },
  active: {
    color: theme.palette.getContrastText("#009900"),
    backgroundColor: "#009900"
  },
  completed: {
    color: theme.palette.getContrastText("#000000"),
    backgroundColor: "#000000"
  }
}));

export default useStyles;