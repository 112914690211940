import { Box, InputAdornment } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { diaryFormData, numberEmptyToNull } from '../../../../shared/helpers/formHelper';

interface EnviromentFormProps {
  formId: string;
  values?: any;
  onSubmit: any;
}

const EnviromentForm: React.FC<EnviromentFormProps> = ({formId, values, onSubmit}) => {

  const initialValues = {
    temperature: "",
    co2: "",
    humidity: ""
  }

  const formSchema = Yup.object().shape({
    temperature: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(-50)
      .max(50),
    co2: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(1000000),
    humidity: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(100),
  });

  return (
      <Formik
        enableReinitialize
        initialValues={values ? diaryFormData({...values}) : initialValues}
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          const data = formSchema.cast(values, { stripUnknown: true });
          actions.setSubmitting(false);
          actions.resetForm({ values: initialValues });
          onSubmit(data);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form id={formId}>
            <Box display="flex" flexDirection="column">
              <Field
                component={TextField}
                label="Lämpötila"
                name="temperature"
                margin="normal"
                autoFocus={true}
                InputProps={{
                  type: "text",
                  inputMode: "numeric",
                  autoComplete: "off",
                  endAdornment: <InputAdornment position="end">&deg;C</InputAdornment>,
                }}
              />
              <Field
                component={TextField}
                label="Ilmankosteus"
                name="humidity"
                margin="normal"
                InputProps={{
                  type: "text",
                  inputMode: "numeric",
                  autoComplete: "off",
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
              <Field
                component={TextField}
                label="Hiilidioksidi"
                name="co2"
                margin="normal"
                InputProps={{
                  type: "text",
                  inputMode: "numeric",
                  autoComplete: "off",
                  endAdornment: <InputAdornment position="end">ppm</InputAdornment>,
                }}
              />
            </Box>
          </Form>
        )}
      </Formik>
  );
};

export default EnviromentForm;
