import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import moment from "moment";
import { ApprovalStatus, BatchDatesDto, BatchReportsDto, BreedingBatchDto, BreedingBatchStatus, NotificationType, PoultryDiaryDto } from "../../../../shared/models/ApiSchema";
import * as api from "../../../../shared/store/apiActions";
import { useSnackbar } from "notistack";
import { IRootState } from "../../../../shared/store/rootReducer";
import { successHandled } from "../../../../shared/store/api";
import LockIcon from '@material-ui/icons/Lock';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SevenDayLossPreviewModal from "../SevenDayLossPreviewModal/SevenDayLossPreviewModal";
import SevenDayLossModal from "../SevenDayLossModal/SevenDayLossModal";
import SalmonellaSamplePreviewModal from "../SalmonellaSamplePreviewModal/SalmonellaSamplePreviewModal";
import SalmonellaSampleModal from "../SalmonellaSampleModal/SalmonellaSampleModal";
import ChainInfoPreviewModal from "../ChainInfoPreviewModal/ChainInfoPreviewModal";
import ChainInfoModal from "../ChainInfoModal/ChainInfoModal";
import ChainInfoSupplementPreviewModal from "../ChainInfoSupplementPreviewModal/ChainInfoSupplementPreviewModal";
import ChainInfoSupplementModal from "../ChainInfoSupplementModal/ChainInfoSupplementModal";
import FinishBatchModal from "../FinishBatchModal/FinishBatchModal";
import ApprovalStatusChip from "../../../../shared/components/Statuses/ApprovalStatus";
import { useHistory } from "react-router-dom";
import TaskMenu from "../../../../shared/components/TaskMenu/TaskMenu";
import Date from "../../../../shared/components/Formatters/Date";
import Percent from "../../../../shared/components/Formatters/Percent";
import { highlight } from "../../../../shared/helpers/guiHelper";
import { calcLossPercent, getExceededConsecutiveLoss, getExceededDailyLoss, getLossStyle } from "../../../../shared/helpers/diaryHelper";
import NotificationModal from "../NotificationModal/NotificationModal";

interface BatchTasksProps {
  batch: BreedingBatchDto;
  dates: BatchDatesDto;
  reports: BatchReportsDto;
}

const BatchTasks: React.FC<BatchTasksProps> = ({batch, dates, reports}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { success } = useSelector((state: IRootState) => state.api);
  const { enqueueSnackbar } = useSnackbar();

  const { diaries, deliveries } = useSelector((state: IRootState) => state.batch);
  const [dailyLosses, setDailyLosses] = useState<PoultryDiaryDto[]>([]);
  const [consecutiveLoss, setConsecutiveLoss] = useState<PoultryDiaryDto | null>(null);

  useEffect(() => {
    if (diaries.length) {
      setDailyLosses(getExceededDailyLoss(diaries));
      setConsecutiveLoss(getExceededConsecutiveLoss(diaries));
    }
    else {
      setDailyLosses([]);
      setConsecutiveLoss(null);
    }
  }, [diaries]);

  const [batchStart, setBatchStart] = useState<moment.Moment>(moment(batch.arrivalDate));

  const [sevenDayLossStart, setSevenDayLossStart] = useState<moment.Moment>(moment(dates.sevenDayLossStart));
  const [sevenDayLossEnd, setSevenDayLossEnd] = useState<moment.Moment>(moment(dates.sevenDayLossEnd));

  const [salmonellaSampleStart, setSalmonellaSampleStart] = useState<moment.Moment>(moment(dates.salmonellaSampleStart));
  const [salmonellaSampleStartException, setSalmonellaSampleStartException] = useState<moment.Moment>(moment(dates.salmonellaSampleStartException));
  const [salmonellaSampleEnd, setSalmonellaSampleEnd] = useState<moment.Moment>(moment(dates.salmonellaSampleEnd));

  const [chainInfoStart, setChainInfoStart] = useState<moment.Moment>(moment(dates.chainInfoStart));
  const [chainInfoEnd, setChainInfoEnd] = useState<moment.Moment>(moment(dates.chainInfoEnd));

  const [supplementStart, setSupplementStart] = useState<moment.Moment>(moment(dates.chainInfoSupplementStart));
  const [supplementEnd, setSupplementEnd] = useState<moment.Moment>(moment(dates.chainInfoSupplementEnd));

  const now = moment();

  useEffect(() => {
    setBatchStart(moment(batch.arrivalDate));
    if (dates) {
      setSevenDayLossStart(moment(dates.sevenDayLossStart));
      setSevenDayLossEnd(moment(dates.sevenDayLossEnd));
      setSalmonellaSampleStart(moment(dates.salmonellaSampleStart));
      setSalmonellaSampleStartException(moment(dates.salmonellaSampleStartException));
      setSalmonellaSampleEnd(moment(dates.salmonellaSampleEnd));
      setChainInfoStart(moment(dates.chainInfoStart));
      setChainInfoEnd(moment(dates.chainInfoEnd));
      setSupplementStart(moment(dates.chainInfoSupplementStart));
      setSupplementEnd(moment(dates.chainInfoSupplementEnd));
   }
  }, [dates, batch]);

  useEffect(() => {
    const snackbar = (message: string) => {
      enqueueSnackbar(message, { variant: 'success' });
      dispatch(successHandled());
    };

    if (success) {
      if (success.url?.indexOf('/notification') > -1 && success.method === "post") {
        snackbar(`Ilmoitus on lähetetty`);
      }
      if (success.url?.indexOf('/sevendayloss') > -1 && success.method === "post") {
        snackbar(`Erän ${batch.breedingId} 7 päivän poistumailmoitus on tehty`);
      }
      if (success.url?.indexOf('/salmonellasample') > -1 && success.method === "post") {
        snackbar(`Erän ${batch.breedingId} salmonellanäyte on kirjattu`);
      }
      if (success.url?.indexOf('/chaininfo') > -1 && success.method === "put") {
        if (success.url?.indexOf('/draft') > -1) {
          snackbar(`Erän ${batch.breedingId} ketjuinformaatiolomake on tallennettu luonnoksena`);
        }
      }
      if (success.url?.indexOf('/chaininfo') > -1 && success.method === "post") {
        snackbar(`Erän ${batch.breedingId} ketjuinformaatiolomake on lähetetty`);
      }
      if (success.url?.indexOf('/supplement') > -1 && success.method === "post") {
        snackbar(`Erän ${batch.breedingId} ketjuinformaatiolomakkeen lisäsivu on lähetetty`);
      }
      if (success.url?.indexOf('/end') > -1 && success.method === "post") {
        snackbar(`Erän ${batch.breedingId} on päätetty ja siirretty arkistoon`);
      }
    }
  }, [batch.breedingId, dispatch, enqueueSnackbar, success]);

  const [openNotificationModal, setNotificationModalOpen] =
    useState<{ open: boolean, diary: PoultryDiaryDto | null, type: NotificationType | null}>({ open: false, diary: null, type: null });
  const handleNotificationModalClose = (data) => {
    const diary = openNotificationModal.diary;
    const type = openNotificationModal.type;
    setNotificationModalOpen({ open: false, diary: null, type: null });
    if (diary && type && data) {
      dispatch(api.diary.addDiaryNotification(batch.id, diary.id, { type, notes: data.notes }));
    }
  };

  const [openSevenDayLossPreviewModal, setSevenDayLossPreviewModalOpen] = useState(false);
  const handleSevenDayLossPreviewModalClose = (data: any | null) => {
    setSevenDayLossPreviewModalOpen(false);
    if (data !== null) {
      dispatch(api.reports.createSevenDayLossReport(batch.id, data));
    }
  };

  const [openSevenDayLossModal, setSevenDayLossModalOpen] = useState(false);
  const handleSevenDayLossModalClose = (ok: boolean) => {
    setSevenDayLossModalOpen(false);
  };

  const handleSevenDayLossMenu = (action?: string | null | undefined) => {
    if (action === "create") setSevenDayLossPreviewModalOpen(true);
    if (action === "show") setSevenDayLossModalOpen(true);
  };

  const [openSalmonellaSamplePreviewModal, setSalmonellaSamplePreviewModalOpen] = useState(false);
  const handleSalmonellaSamplePreviewModalClose = (data: any | null) => {
    setSalmonellaSamplePreviewModalOpen(false);
    if (data !== null) {
      dispatch(api.reports.createSalmonellaSampleReport(batch.id, data));
    }
  };

  const [openSalmonellaSampleModal, setSalmonellaSampleModalOpen] = useState(false);
  const handleSalmonellaSampleModalClose = (ok: boolean) => {
    setSalmonellaSampleModalOpen(false);
  };

  const handleSalmonellaSampleMenu = (action?: string | null | undefined) => {
    if (action === "create") setSalmonellaSamplePreviewModalOpen(true);
    if (action === "show") setSalmonellaSampleModalOpen(true);
    if (action === "print") history.push("/print/salmonella/" + moment(reports.salmonellaSample?.sampleDate).format("YYYY-MM-DD"));
  };

  const [openChainInfoPreviewModal, setChainInfoPreviewModalOpen] = useState(false);
  const handleChainInfoPreviewModalClose = (data: any | null, draft: boolean) => {
    setChainInfoPreviewModalOpen(false);
    if (data !== null) {
      if (draft) {
        dispatch(api.reports.updateChainInfoDraft(batch.id, data));
      } else {
        dispatch(api.reports.createChainInfoReport(batch.id, data));
      }
    }
  };

  const [openChainInfoModal, setChainInfoModalOpen] = useState(false);
  const handleChainInfoModalClose = (data: any | null) => {
    setChainInfoModalOpen(false);
  };

  const handleChainInfoMenu = (action?: string | null | undefined) => {
    if (action === "create") setChainInfoPreviewModalOpen(true);
    if (action === "show") setChainInfoModalOpen(true);
  };

  const [openSupplementPreviewModal, setSupplementPreviewModalOpen] = useState(false);
  const handleSupplementPreviewModalClose = (data: any | null) => {
    setSupplementPreviewModalOpen(false);
    if (data !== null) {
      dispatch(api.reports.createChainInfoSupplementReport(batch.id, data));
    }
  };

  const [openSupplementModal, setSupplementModalOpen] = useState(false);
  const handleSupplementModalClose = (data) => {
    setSupplementModalOpen(false);
  };

  const handleSupplementMenu = (action?: string | null | undefined) => {
    if (action === "create") setSupplementPreviewModalOpen(true);
    if (action === "show") setSupplementModalOpen(true);
  };

  const [openFinishBatchModal, setFinishBatchModalOpen] = useState(false);
  const handleFinishBatchModalClose = (data) => {
    setFinishBatchModalOpen(false);
    if (data === true) {
      dispatch(api.batch.finishBreedingBatch(batch.id));
    }
  };

  return (
    <Box component={Paper} p={2} pb={0}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <PageHeader level="3" component="div">Erän tehtävät ja ilmoitukset</PageHeader>
      </Box>
      <List dense>
        {dailyLosses.map((d, i) =>
          <ListItem key={'dailyloss' + i} style={getLossStyle(d)} disableGutters>
            <ListItemText
              primary="Ilmoita 0,5 % ylittävästä poistumasta"
              secondary={<span style={getLossStyle(d)}>{d.day}. kasvatuspäivä  (<Date value={d.date} />): <Percent value={calcLossPercent(d)} digits={3} /></span>}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="Poistuma" onClick={() => setNotificationModalOpen({ open: true, diary: d, type: NotificationType.DailyLoss })}>
                <PlayCircleFilledWhiteIcon color="primary" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )}
        {consecutiveLoss != null &&
          <ListItem key="consecutiveloss" style={getLossStyle(consecutiveLoss)} disableGutters>
            <ListItemText
              primary="Ilmoita 3 peräkkäisestä > 0,2 % poistumasta"
              secondary={<span style={getLossStyle(consecutiveLoss)}>{consecutiveLoss.day}. kasvatuspäivä (<Date value={consecutiveLoss.date} />): <Percent value={calcLossPercent(consecutiveLoss)} digits={3} /></span>}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="Poistuma" onClick={() => setNotificationModalOpen({ open: true, diary: consecutiveLoss, type: NotificationType.ConsecutiveLoss })}>
                <PlayCircleFilledWhiteIcon color="primary" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        }

        {reports.sevenDayLoss == null &&
          <ListItem key="sevendayloss" style={highlight(now.isSameOrAfter(sevenDayLossStart, 'day'))} disableGutters>
            <ListItemText
              primary="7 päivän poistumailmoitus"
              secondary={`Suorita ajalla ${sevenDayLossStart.format('D.M.')} - ${sevenDayLossEnd.format('D.M.YYYY')}`}
            />
            {now.isBefore(sevenDayLossStart, 'day')
              ? <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="Poistuma" disabled={true}>
                    <LockIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              : <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="Poistuma" onClick={() => setSevenDayLossPreviewModalOpen(true)}>
                    <PlayCircleFilledWhiteIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
            }
          </ListItem>
        }
        {reports.sevenDayLoss != null &&
          <ListItem key="sevendayloss" disableGutters>
            <ListItemText
              primary="7 päivän poistumailmoitus"
              secondary={<Fragment>
                <ApprovalStatusChip status={reports.sevenDayLoss?.approvalStatus} />{" "}
                <Date value={reports.sevenDayLoss.createdDate} time={true} />
              </Fragment>}
            />
            {batch.status === BreedingBatchStatus.Active
              ? <ListItemSecondaryAction>
                  <TaskMenu id="sevendayloss-menu" onClick={handleSevenDayLossMenu}
                    items={[{ action: "show", label: "Näytä nykyinen" }, { action: "create", label: "Muokkaa ja lähetä uusi" }]}
                  />
                </ListItemSecondaryAction>
              : <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="Poistuma" onClick={() => setSevenDayLossModalOpen(true)}>
                  <VisibilityIcon />
                </IconButton>
              </ListItemSecondaryAction>
            }
          </ListItem>
        }

        {reports.salmonellaSample == null &&
          <ListItem key="salmonellasample" style={highlight(now.isSameOrAfter(salmonellaSampleStart, 'day'))} disableGutters>
            <ListItemText
              primary="Salmonellanäyte"
              secondary={salmonellaSampleStart.day() === 4
                ? `Suorita ${salmonellaSampleStart.format('D.M.YYYY')} tai ${salmonellaSampleEnd.format('D.M.YYYY')}`
                : `Suorita ajalla ${salmonellaSampleStart.format('D.M.')} - ${salmonellaSampleEnd.format('D.M.YYYY')}`
              }
            />
            {now.isBefore(batchStart, 'day')
              ? <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="Salmonella" disabled={true}>
                    <LockIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              : <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="Salmonella" onClick={() => setSalmonellaSamplePreviewModalOpen(true)}>
                    <PlayCircleFilledWhiteIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
            }
          </ListItem>
        }
        {reports.salmonellaSample != null &&
          <ListItem key="salmonellasample" disableGutters>
            <ListItemText
              primary={<span>Salmonellanäyte <Date value={reports.salmonellaSample.sampleDate} /></span>}
              secondary={<Fragment>
                <ApprovalStatusChip status={reports.salmonellaSample?.approvalStatus} />{" "}
                <Date value={reports.salmonellaSample.createdDate} time={true} />
              </Fragment>}
            />
            {batch.status === BreedingBatchStatus.Active
              ? <ListItemSecondaryAction>
                  <TaskMenu id="salmonellasample-menu" onClick={handleSalmonellaSampleMenu}
                    items={[
                      { action: "show", label: "Näytä nykyinen" },
                      { action: "create", label: "Korvaa vanha" },
                      { action: "print", label: "Tulosta lähete" }
                    ]}
                  />
                </ListItemSecondaryAction>
              : <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="Salmonella" onClick={() => setSalmonellaSampleModalOpen(true)}>
                    <VisibilityIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              }
          </ListItem>
        }

        {reports.chainInfo == null &&
          <ListItem key="chainInfo" style={highlight(now.isSameOrAfter(chainInfoStart, 'day'))} disableGutters>
            <ListItemText
              primary={`Ketjuinformaatiolomake${reports.chainInfoDraft ? ' (luonnos)' : ''}`}
              secondary={`Suorita ajalla ${chainInfoStart.format('D.M.')} - ${chainInfoEnd.format('D.M.YYYY HH:mm')}`}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="Ketjuinformaatio" onClick={() => setChainInfoPreviewModalOpen(true)}>
                <PlayCircleFilledWhiteIcon color="primary" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        }
        {reports.chainInfo != null &&
          <ListItem key="chainInfo" style={highlight(reports.chainInfo.approvalStatus === ApprovalStatus.Returned)} disableGutters>
            <ListItemText
              primary={`Ketjuinformaatiolomake${reports.chainInfoDraft ? ' (luonnos)' : ''}`}
              secondary={<Fragment>
                <ApprovalStatusChip status={reports.chainInfo?.approvalStatus} />{" "}
                <Date value={reports.chainInfo.modifiedDate} time={true} />
              </Fragment>}
            />
            {batch.status === BreedingBatchStatus.Active
              ? <ListItemSecondaryAction>
                  <TaskMenu id="chaininfo-menu" onClick={handleChainInfoMenu}
                    items={[{ action: "show", label: "Näytä nykyinen" }, { action: "create", label: "Muokkaa ja lähetä uusi" }]}
                  />
                </ListItemSecondaryAction>
              : <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="Ketjuinformaatio" onClick={() => setChainInfoModalOpen(true)}>
                    <VisibilityIcon />
                  </IconButton>
                </ListItemSecondaryAction>
            }
          </ListItem>
        }

        {reports.chainInfoSupplement == null &&
          <ListItem key="supplement" style={highlight(now.isSameOrAfter(supplementStart, 'day'))} disableGutters>
            <ListItemText
              primary="Ketjuinformaation lisäsivu"
              secondary={`Suorita ajalla ${supplementStart.format('D.M.')} - ${supplementEnd.format('D.M.YYYY HH:mm')}`}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="Ketjuinformaation lisäsivu" onClick={() => setSupplementPreviewModalOpen(true)}>
                <PlayCircleFilledWhiteIcon color="primary" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        }
        {reports.chainInfoSupplement != null &&
          <ListItem key="supplement" style={highlight(reports.chainInfoSupplement.approvalStatus === ApprovalStatus.Returned)} disableGutters>
            <ListItemText
              primary="Ketjuinformaation lisäsivu"
              secondary={<Fragment>
                <ApprovalStatusChip status={reports.chainInfoSupplement?.approvalStatus} />{" "}
                <Date value={reports.chainInfoSupplement.modifiedDate} time={true} />
              </Fragment>}
            />
            {batch.status === BreedingBatchStatus.Active
              ? <ListItemSecondaryAction>
                  <TaskMenu id="chaininfosupplement-menu" onClick={handleSupplementMenu}
                    items={[{ action: "show", label: "Näytä nykyinen" }, { action: "create", label: "Muokkaa ja lähetä uusi" }]}
                  />
                </ListItemSecondaryAction>
              : <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="Ketjuinformaation lisäsivu" onClick={() => setSupplementModalOpen(true)}>
                    <VisibilityIcon />
                  </IconButton>
                </ListItemSecondaryAction>
            }
          </ListItem>
        }

        {batch.status === BreedingBatchStatus.Active && reports.chainInfo != null && reports.chainInfoSupplement != null &&
          <ListItem key="finish" disableGutters>
            <ListItemText
              primary="Erän päättäminen"
              secondary="Lukitse ja siirrä arkistoon"
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="Erän päättäminen" onClick={() => setFinishBatchModalOpen(true)}>
                <PlayCircleFilledWhiteIcon color="primary" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        }
      </List>

      <NotificationModal open={openNotificationModal.open} onClose={handleNotificationModalClose}
        diary={openNotificationModal.diary} type={openNotificationModal.type} />

      <SevenDayLossPreviewModal open={openSevenDayLossPreviewModal} onClose={handleSevenDayLossPreviewModalClose} batch={batch} />
      {reports.sevenDayLoss != null &&
        <SevenDayLossModal open={openSevenDayLossModal} onClose={handleSevenDayLossModalClose} batch={batch} deliveries={deliveries} report={reports.sevenDayLoss} />
      }

      <SalmonellaSamplePreviewModal open={openSalmonellaSamplePreviewModal} onClose={handleSalmonellaSamplePreviewModalClose} batch={batch} start={salmonellaSampleStart} startException={salmonellaSampleStartException} end={salmonellaSampleEnd} />
      {reports.salmonellaSample != null &&
        <SalmonellaSampleModal open={openSalmonellaSampleModal} onClose={handleSalmonellaSampleModalClose} batch={batch} report={reports.salmonellaSample} />
      }

      <ChainInfoPreviewModal open={openChainInfoPreviewModal} onClose={handleChainInfoPreviewModalClose} batch={batch} />
      {reports.chainInfo != null &&
        <ChainInfoModal open={openChainInfoModal} onClose={handleChainInfoModalClose} batch={batch} chainInfoId={reports.chainInfo.id} />
      }

      <ChainInfoSupplementPreviewModal open={openSupplementPreviewModal} onClose={handleSupplementPreviewModalClose} batch={batch} />
      {reports.chainInfoSupplement != null &&
        <ChainInfoSupplementModal open={openSupplementModal} onClose={handleSupplementModalClose} batch={batch} />
      }

      {batch.status === BreedingBatchStatus.Active && reports.chainInfo != null && reports.chainInfoSupplement != null &&
        <FinishBatchModal open={openFinishBatchModal} onClose={handleFinishBatchModalClose} batch={batch} />
      }
    </Box>
  );
};

export default BatchTasks;
