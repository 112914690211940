import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@material-ui/core";
import useStyles from "./ProducerSelectionModalStyles";
import PageHeader from "../../PageHeader/PageHeader";
import Button from "../../Button/Button";
import { useHistory } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { IRootState } from "../../../store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import DropdownMenu, { DropdownMenuOption } from "../../DropdownMenu/DropdownMenu";
import { stateReseted } from "../../../store/createStore";
import { contentRefreshed } from "../../../store/ui/common";
import * as api from "../../../../shared/store/apiActions";

interface FarmSelectionModalProps {
  open: boolean;
  onClose: any;
}

const FarmSelectionModal: React.FC<FarmSelectionModalProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector((state: IRootState) => state.auth.currentUser);
  const producerNumber = useSelector((state: IRootState) => state.ui.settings.producerNumber);
  const organizations = useSelector((state: IRootState) => state.user.organizations);
  const organizationOptions = useSelector((state: IRootState) => state.user.organizationOptions);

  const [loading, setLoading] = useState(false);
  const [noResultsText, setNoResultsText] = useState("");
  const [autoCompleteFilter, setAutoCompleteFilter] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState<DropdownMenuOption[]>([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState<DropdownMenuOption[]>([]);

  const [selectedProducerNumber, setSelectedProducerNumber] = useState(producerNumber);

  useEffect(() => {
    setDropdownOptions(
      organizations.map((p) => { return {
        name: String(p.producerNumber || 0),
        text: p.name || "",
        value: p.producerNumber || 0,
      }})
    );
  }, [organizations]);

  useEffect(() => {
    if (organizationOptions.length === 0) {
      setNoResultsText("Haulla ei löytynyt tiloja");
    }
    setAutocompleteOptions(
      organizationOptions.map((p) => { return {
        name: String(p.producerNumber || 0),
        text: p.name || "",
        value: p.producerNumber || 0,
      }})
    );
    setLoading(false);
  }, [organizationOptions]);

  useEffect(() => {
    if (autoCompleteFilter) {
      const delayDebounceFn = setTimeout(() => {
        setLoading(true);
        dispatch(api.user.getUserAutocompleteOrganizations(currentUser.authenticationSource, autoCompleteFilter));
      }, 1000);
      return () => clearTimeout(delayDebounceFn)
    }
    else {
      setAutocompleteOptions([]);
      setNoResultsText("Hae tilan nimellä...");
    }
  }, [autoCompleteFilter, currentUser.authenticationSource, dispatch]);

  const handleChange = (value) => {
    if (value) {
      setSelectedProducerNumber(value);
    }
  };

  const handleAutocompleteChange = (value) => {
    if (value) {
      setSelectedProducerNumber(value);
    }
  };

  const handleFilterChange = (value, reason) => {
    if (reason === "input") {
      setAutocompleteOptions([]);
      setNoResultsText("Hae tilan nimellä...");
      setAutoCompleteFilter(value);
    }
  };

  const handleClose = async (ok: boolean) => {
    if (!selectedProducerNumber) return;
    onClose(ok);
    if (ok) {
      if (selectedProducerNumber) {
        if(currentUser.authenticationSource === "HKScanAzureAD") {
          await dispatch(api.user.getUserFilteredOrganizations("HKScanAzureAD", selectedProducerNumber.toString()));
        }
        updateOrganizationSettings();
      }
    }
    else {
      setSelectedProducerNumber(producerNumber);
    }
  };

  const updateOrganizationSettings = () => {
    if (selectedProducerNumber) {
      dispatch(api.user.setProducerNumberUpdated(selectedProducerNumber));
      dispatch(stateReseted());
      dispatch(contentRefreshed());
      history.push("/");
    }
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
      onClose={() => handleClose(false)}
      aria-labelledby="farm-selection-modal-title"
      aria-describedby="farm-selection-modal-description">
      <DialogTitle id="farm-selection-modal-title">
        <PageHeader level="2" component="div">
          Vaihda tilaa
        </PageHeader>
      </DialogTitle>
      <DialogContent>
        {currentUser.authenticationSource !== "HKScanAzureAD" &&
          <DropdownMenu
            onChange={handleChange}
            options={dropdownOptions}
            defaultValue={selectedProducerNumber}
            fullWidth={true}
          />
        }
        {currentUser.authenticationSource === "HKScanAzureAD" &&
          <Autocomplete
            onInputChange={(event, value, reason) => handleFilterChange(value, reason)}
            onChange={(event, value) => handleAutocompleteChange(value?.name)}
            options={autocompleteOptions}
            loading={loading}
            noOptionsText={noResultsText}
            loadingText="Noudetaan tilatietoja..."
            getOptionLabel={(option) => option?.name ? `${option.text} ${option.name}` : ""}
            getOptionSelected={(option, value) => Number(option.value) === Number(value.value)}
            defaultValue={dropdownOptions.find(o => Number(o.value) === Number(selectedProducerNumber))}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} type={"modal"} autoFocus>Peruuta</Button>
        <Button onClick={() => handleClose(true)} type={"modal"} disabled={selectedProducerNumber === producerNumber}>Vaihda</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FarmSelectionModal;
