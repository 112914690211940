import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      paddingRight: "0"
    },
  },
  main: {
    height: "100%",
    minHeight: "calc(100vh - 320px)",
    backgroundColor: "#E1E0DC"
  },
  printInfo: {
    display: "none"
  },
  '@global': {
    '#breeding-batch .MuiList-root .MuiListItem-container': {
      borderBottom: '1px solid #dfdfdf'
    },
    '#breeding-batch .MuiList-root .MuiListItem-container:last-child': {
      borderBottom: 'none'
    }
  },
}));

export default useStyles;
