import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {Button as MuiButton} from "@material-ui/core";
import {
    ReactComponent as RemoveIcon
} from "../../../assets/images/iconmonstr-x-mark-1.svg";
import useStyles from "./ButtonStyles";

interface ButtonProps {
    type: "ok" | "cancel" | "add" | "table" | "modal" | "link",
    fn?: "remove" | "action",
    to?: string,
    [x:string]: any;
}

const Button: React.FC<ButtonProps> = ({type, children, fn, to, ...rest}) => {
    const classes = useStyles();
    return (
        <Fragment>
        {
            (type === "ok") &&
            <MuiButton
                variant={"contained"}
                color={"primary"}
                {...rest}
                className={`${classes.button} ${classes.okButton}`}
            >
                {children}
            </MuiButton>
        }
        {
            (type === "cancel") &&
            <MuiButton
                variant={"contained"}
                {...rest}
                className={classes.button}
            >
                {children}
            </MuiButton>
        }
        {
            (type === "add") &&
            <MuiButton
                variant={"contained"}
                color={"primary"}
                {...rest}
                className={`${classes.button} ${classes.addButton}`}
            >
                {children}
            </MuiButton>
        }
        {
            (type === "table" && fn === "remove") &&
            <MuiButton
                variant={"text"}
                {...rest}
                className={`${classes.button} ${classes.tableButton}`}
                classes={{startIcon: classes.iconClass}}
                startIcon={<RemoveIcon height={7} />}
            >
                {children}
            </MuiButton>
        }
        {
            (type === "table" && fn === "action") &&
            <MuiButton
                variant={"text"}
                {...rest}
                className={`${classes.button} ${classes.tableButton}`}
                style={{backgroundColor: "#fff", padding: "4px 6px"}}
            >
                {children}
            </MuiButton>
        }
        {
            (type === "modal") &&
            <MuiButton
                variant={"text"}
                {...rest}
                className={`${classes.button} ${classes.modalButton}`}
            >
                {children}
            </MuiButton>
        }
        {
            (type === "link") &&
            <Link to={to || ""} className={classes.linkButton}>
                <Button type="ok" {...rest}>
                    {children}
                </Button>
            </Link>
        }
        </Fragment>
    );
};

export default Button;