import moment from "moment";

export const dateFormatter = (v: any) => {
  if (!v?.value) return "";
  return moment(v.value).format("D.M.YYYY");
};

export const dateListFormatter = (v: any) => {
  if (!v?.value) return "";
  return (v.value || []).map((d: moment.MomentInput) => moment(d).format("D.M.YYYY")).join(", ");
};

export const dayFormatter = (v: any) => {
  if (!v?.value) return "";
  return moment(v.value).format("D.M.");
};

export const timeFormatter = (v: any) => {
  if (!v?.value) return "";
  return moment(v.value).format("HH:mm");
};

export const dateTimeFormatter = (v: any) => {
  if (!v?.value) return "";
  return moment(v.value).format("D.M.YYYY HH:mm");
};

export const decimalFormatter = (v: any, decimals: number | null | undefined = null, unit: string = "") => {
  if (v.value == null || isNaN(v.value)) return "";
  if (decimals == null || decimals === undefined)
    return v.value.toLocaleString('fi-FI') + unit;
  else
    return v.value.toLocaleString('fi-FI', { maximumFractionDigits: decimals, minimumFractionDigits: decimals }) + unit;
};

const agGridHelper = {
  dateFormatter,
  dateListFormatter,
  dayFormatter,
  timeFormatter,
  dateTimeFormatter,
  decimalFormatter
};
export default agGridHelper;
