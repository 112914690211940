import { BatchNotificationDto, BatchTaskDto, HallBatchDto } from "../models/ApiSchema";
import http from "./httpService";

const API_URL = `halls`;

export const getHallsWithCurrentBatch = async () => {
  const resp = await http.get<HallBatchDto[]>(`${API_URL}/batches`);
  return resp;
};

export const fetchBatchTasks = async () => {
  const resp = await http.get<BatchTaskDto[]>(`${API_URL}/tasks`);
  return resp;
};

export const fetchBatchNotifications = async () => {
  const resp = await http.get<BatchNotificationDto[]>(`${API_URL}/notifications`);
  return resp;
};

const hallService = {
  getHallsWithCurrentBatch,
  fetchBatchTasks,
  fetchBatchNotifications
};
export default hallService;