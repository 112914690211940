import { useFormikContext } from "formik";
import { useEffect } from "react";

interface FormikDisablerProps {
  disabled: boolean;
}

const FormikDisabler: React.FC<FormikDisablerProps> = ({disabled}) => {
  const { setSubmitting } = useFormikContext();

  useEffect(() => {
    if (disabled) {
      setSubmitting(true);
    } else {
      setSubmitting(false);
    }
  }, [disabled, setSubmitting]);

  return null;
};

export default FormikDisabler;