import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header1: {
    fontFamily: "Avenir Next W1G Bold",
    fontSize: "2.3rem",
    fontWeight: "bolder",
  },
  header2: {
    fontFamily: "Avenir Next W1G Bold",
    fontSize: "1.4rem",
    fontWeight: "bolder",
  },
  header3: {
    fontFamily: "Avenir Next W1G Bold",
    fontSize: "1.2rem",
    fontWeight: "bolder",
  },
  header4: {
    fontFamily: "Avenir Next W1G Bold",
    fontSize: "1.1rem",
    fontWeight: "bold",
  }

}));

export default useStyles;