import { Box, Chip } from "@material-ui/core";
import React from "react";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, PoultryDiaryDto } from "../../../../shared/models/ApiSchema";
import Date from "../../../../shared/components/Formatters/Date";
import moment from "moment";

interface DiaryHeaderProps {
  title?: string;
  batch: BreedingBatchDto | null | undefined;
  diary: PoultryDiaryDto | null | undefined;
}

const DiaryHeader: React.FC<DiaryHeaderProps> = ({title, batch, diary}) => {

  if (batch && diary) {
    return (
      <Box mb={1}>
        <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
          <PageHeader level="2" component="div">
            {title}
          </PageHeader>
          <span>{ moment(diary.date).isSame(moment(), "day")
            ? <Chip size="small" label="Tänään" color="primary" />
            : <Chip size="small" label={<Date value={diary.date} />} color="secondary" />
          }</span>
        </Box>
        <PageHeader level="3" component="div">
          Halli {batch?.hall?.number}, kasvatuspäivä {diary?.day}
        </PageHeader>
      </Box>
    );
  }
  else {
    return (<span></span>);
  }
};

export default DiaryHeader;
