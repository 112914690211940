import { Box, InputAdornment } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { diaryFormData, numberEmptyToNull } from '../../../../shared/helpers/formHelper';

interface FeedingFormProps {
  formId: string;
  values?: any;
  onSubmit: any;
}

const FeedingForm: React.FC<FeedingFormProps> = ({formId, values, onSubmit}) => {

  const initialValues = {
    waterConsumptionLiter: "",
    feedConsumptionKg: "",
    grainConsumptionKg: "",
    otherSupplements: "",
    addedVitaminC: false,
    addedPotassiumChloride: false
  };

  const formSchema = Yup.object().shape({
    waterConsumptionLiter: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(500000),
    feedConsumptionKg: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(500000),
    grainConsumptionKg: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(500000),
    addedVitaminC: Yup
      .boolean(),
    addedPotassiumChloride: Yup
      .boolean(),
    otherSupplements: Yup
      .string()
      .nullable()
      .max(100)
  });

  return (
      <Formik
        enableReinitialize
        initialValues={values ? diaryFormData({...values}) : initialValues}
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          const data = formSchema.cast(values, { stripUnknown: true });
          actions.setSubmitting(false);
          actions.resetForm({ values: initialValues });
          onSubmit(data);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form id={formId}>
            <Box display="flex" flexDirection="column">
              <Field
                component={TextField}
                label="Rehunkulutus"
                name="feedConsumptionKg"
                margin="normal"
                autoFocus={true}
                InputProps={{
                  type: "text",
                  inputMode: "numeric",
                  autoComplete: "off",
                  endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                }}
              />
              <Field
                component={TextField}
                label="Viljan kulutus"
                name="grainConsumptionKg"
                margin="normal"
                InputProps={{
                  type: "text",
                  inputMode: "numeric",
                  autoComplete: "off",
                  endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                }}
              />
              <Field
                component={TextField}
                label="Vedenkulutus"
                name="waterConsumptionLiter"
                margin="normal"
                InputProps={{
                  type: "text",
                  inputMode: "numeric",
                  autoComplete: "off",
                  endAdornment: <InputAdornment position="end">litraa</InputAdornment>,
                }}
              />
              <Box my={2} display="flex" flexDirection="column">
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="addedVitaminC"
                  Label={{ label: "Lisätty C-vitamiinia" }}
                  margin="dense"
                />
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="addedPotassiumChloride"
                  Label={{ label: "Lisätty kaliumkloridia" }}
                  margin="dense"
                />
                <Field
                  component={TextField}
                  label="Muut ravintolisät"
                  name="otherSupplements"
                  margin="dense"
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
  );
};

export default FeedingForm;
