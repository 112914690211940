import { Chip } from "@material-ui/core";
import React, { Fragment } from "react";
import { batchStatusText } from "../../helpers/statusHelper";
import { BreedingBatchStatus } from "../../models/ApiSchema";

interface BatchStatusProps {
  status: BreedingBatchStatus;
  chip?: boolean;
}

const _BatchStatus: React.FC<BatchStatusProps> = ({status, chip = true}) => {
  return (
    <Fragment>
      {!chip && <span>{batchStatusText(status)}</span>}
      {chip && <Fragment>
        { status === BreedingBatchStatus.Planned && <Chip size="small" label={batchStatusText(status)} color="default" component="span" />}
        { status === BreedingBatchStatus.Active && <Chip size="small" label={batchStatusText(status)} color="primary" component="span" />}
        { status === BreedingBatchStatus.Completed && <Chip size="small" label={batchStatusText(status)} color="secondary" component="span" />}
      </Fragment>}
    </Fragment>
  );
};

export const BatchStatus = React.memo(_BatchStatus);
export default BatchStatus;
