import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React, { Fragment } from "react";
import { SalmonellaSampleDto } from "../../../models/ApiSchema";
import Date from "../../Formatters/Date";

interface SalmonellaSampleProps {
  salmonellaSample: SalmonellaSampleDto | null | undefined;
}

const SalmonellaSample: React.FC<SalmonellaSampleProps> = ({salmonellaSample}) => {
  if (!salmonellaSample) return <Fragment></Fragment>;

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Tuottaja
            </TableCell>
            <TableCell>
              {salmonellaSample.producerName || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Osaston numero
            </TableCell>
            <TableCell>
              {salmonellaSample.hallNumber || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Näytteenottopäivä
            </TableCell>
            <TableCell>
              <Date value={salmonellaSample.sampleDate} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Näytetyyppi
            </TableCell>
            <TableCell>
              {salmonellaSample.isOfficialSample ? "Virallinen näyte" : "Rutiininäyte"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Hautomo
            </TableCell>
            <TableCell>
              {salmonellaSample.hatchery || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Rehun toimittaja
            </TableCell>
            <TableCell>
              {salmonellaSample.feedFactory || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Poistuma <Date value={salmonellaSample.lossDate} /> asti
            </TableCell>
            <TableCell>
              {salmonellaSample.totalLoss} kpl
            </TableCell>
          </TableRow>
          {!!salmonellaSample.notes && <TableRow>
            <TableCell colSpan={2}>
              <strong>Huomioita:</strong><br/>
              {salmonellaSample.notes}
            </TableCell>
          </TableRow>}
          <TableRow>
            <TableCell component="th" scope="row">
              Lähete luotu
            </TableCell>
            <TableCell>
              <Date value={salmonellaSample.createdDate} time={true} /> ({salmonellaSample.createdBy || 'tuntematon'})
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SalmonellaSample;
