import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { Fragment } from "react";
import MoreVertIcon from '@material-ui/icons/MoreVert';

interface TaskMenuProps {
  id: string;
  items: any[];
  onClick: any;
}

const TaskMenu: React.FC<TaskMenuProps> = ({id, items, onClick}) => {

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const menuOpen = Boolean(menuAnchorEl);

  const openMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (event, action?: string | null | undefined) => {
    setMenuAnchorEl(null);
    if (action) {
      onClick(action);
    }
  };

  return (
    <Fragment>
      <IconButton edge="end" onClick={(e) => openMenu(e)}>
        <MoreVertIcon color="secondary" />
      </IconButton>
      <Menu id={id}
        anchorEl={menuAnchorEl}
        keepMounted
        open={menuOpen}
        onClick={(event) => handleMenuClick(event)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} onClick={(event) => handleMenuClick(event, item.action)}>{item.label}</MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default TaskMenu;
