import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import React, { Fragment } from "react";
import PageHeader from "../../../shared/components/PageHeader/PageHeader";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from "react-router-dom";

const PrintList = () => {

  const history = useHistory();

  const navigateSalmonella = () => history.push('/print/salmonella');

  return (
    <Fragment>
      <PageHeader level="1" component="div">Tulostettavat dokumentit</PageHeader>
      <Box mt={2}>
        <List>
          <ListItem button key="salmonella" onClick={navigateSalmonella}>
            <ListItemAvatar>
              <Avatar>
                <strong>S</strong>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<strong>Salmonellanäytelähete</strong>}
              secondary="Tulosta salmonellanäytelähete valitun päivän näytteistä"
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={navigateSalmonella}>
                <NavigateNextIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Box>
    </Fragment>
  );
};

export default PrintList;