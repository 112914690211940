import React, { Fragment, useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { IconButton } from "@material-ui/core";
import ChainInfoApprovalModal from "./ChainInfoApprovalModal";
import DescriptionIcon from '@material-ui/icons/Description';
import { IRootState } from "../../../shared/store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../shared/store/apiActions";
import ApprovalStatusSetMenu from "../../../shared/components/ApprovalStatus/ApprovalStatusSetMenu";
import { ViewName } from "../../../shared/models/ApiSchema";
import CheckIcon from '@material-ui/icons/Check';
import agGridHelper from "../../../shared/helpers/agGridHelper";
import moment from "moment";
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { approvalStatusText } from "../../../shared/helpers/statusHelper";
import { ColDef } from "ag-grid-community";

interface ChainInfoApprovalProps {

}

const ChainInfoApproval: React.FC<ChainInfoApprovalProps> = () => {

  const access = useSelector((state: IRootState) => state.user.access);
  const status = useSelector((state: IRootState) => state.approval.status);
  const startDate = useSelector((state: IRootState) => state.approval.startDate);
  const endDate = useSelector((state: IRootState) => state.approval.endDate);
  const chainInfos = useSelector((state: IRootState) => state.approval.chainInfoList);
  const dispatch = useDispatch();

  const [openApprovalModal, setApprovalModalOpen] = useState<boolean>(false);
  const handleApprovalModalClose = (newStatus) => {
    setApprovalModalOpen(false);
    if (newStatus) {
      dispatch(api.approval.getChainInfoList(startDate, endDate, status || undefined));
    }
  };

  useEffect(() => {
    dispatch(api.approval.getChainInfoList(startDate, endDate, status || undefined));
  }, [status, startDate, endDate, dispatch]);

  const openApproval = (id: string) => {
    dispatch(api.approval.getChainInfoApproval(id));
    setApprovalModalOpen(true);
  }

  const redraw = (e) => {
    e.api.redrawRows();
  }

  const timeDiffFormatter = (v: any) => {
    if (!v.data.grow2FeedEnd) return "Rehutieto puuttuu!"
    const grow2FeedEnd = moment(v.data.grow2FeedEnd);
    const slaughterStartTime = moment(v.data.slaughterStartTime);
    const diff = slaughterStartTime.diff(grow2FeedEnd, "minutes");

    const days = Math.floor(diff / 1440);
    const hours = Math.floor((diff % 1440) / 60);
    const minutes = Math.floor((diff % 60));

    return `${days === 0 ? "" : days + " vrk"} ${hours === 0 ? "" : hours + " h"} ${minutes === 0 ? "" : minutes + " min"}`;
  };

  const defaultColDef: ColDef = {
    cellStyle: { textAlign: "center" },
    resizable: true,
    sortable: true,
    editable: false,
    suppressMenu: true,
    suppressMovable: true,
    suppressNavigable: true
  };

  return (
    <Fragment>
      <div className="diary-grid ag-theme-balham" style={{height: "74vh", width: "99.99%"}}>
        <AgGridReact
          rowData={chainInfos}
          defaultColDef={defaultColDef}
          suppressCellSelection={true}
          applyColumnDefOrder={true}
          immutableData={true}
          getRowNodeId={(row) => row.id}
          onRowDataChanged={redraw}
        >
          <AgGridColumn headerName="Ketjuinformaatiolomake">
            <AgGridColumn
              field="breedingId"
              headerName="Erä id"
              width={80}
              pinned="left"
            />
            <AgGridColumn
              field="producerName"
              headerName="Tuottaja"
              minWidth={160}
              maxWidth={320}
              pinned="left"
            />
            <AgGridColumn
              field="hallNumber"
              headerName="Halli"
              width={60}
              cellStyle={{fontWeight:600}}
              pinned="left"
            />
            {access?.approvalStatusEnabled?.ChainInfo && <AgGridColumn
              field="approvalStatus"
              headerName="Status"
              width={100}
              pinned="left"
              valueFormatter={v => approvalStatusText(v.value)}
            />}
            {access?.approvalStatusEnabled?.ChainInfo && access?.hasApprovalRole && <AgGridColumn
              width={48}
              sortable={false}
              pinned="left"
              cellRendererFramework={p => <ApprovalStatusSetMenu current={p.data.approvalStatus} view={ViewName.ChainInfo} id={p.data.id} />}
            />}
          </AgGridColumn>
          <AgGridColumn headerName="Tiedot">
            <AgGridColumn
              field="approvalAuditUser"
              headerName="Lähettäjä / tarkastaja / hyväksyjä"
              minWidth={240}
              valueGetter={(p) => p.data.approvalAuditUser || p.data.createdBy}
            />
            <AgGridColumn
              field="reportCreateTime"
              headerName="Lähetetty"
              valueFormatter={agGridHelper.dateTimeFormatter}
              width={120}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Teurastus">
            <AgGridColumn
              field="slaughterDate"
              headerName="Päivä"
              width={100}
              valueFormatter={agGridHelper.dateFormatter}
            />
            <AgGridColumn
              field="slaughterStartTime"
              headerName="Aika"
              width={100}
              valueFormatter={agGridHelper.timeFormatter}
            />
            <AgGridColumn
              field="estimatedSlaughterWeightGrams"
              headerName="Arv. paino"
              width={100}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Kokonaispoistuma lähetettäessä">
            <AgGridColumn
              field="totalLossPercentAtCreate"
              headerName="%"
              width={100}
              valueFormatter={agGridHelper.decimalFormatter}
            />
            <AgGridColumn
              field="totalLossCountAtCreate"
              headerName="Kpl"
              width={100}
            />
          </AgGridColumn>
          <AgGridColumn
            headerName="Toteutuva varoaika"
            valueFormatter={timeDiffFormatter}
            width={160}
            sortable={false}
          />
          <AgGridColumn headerName="Lisätietoja">
            <AgGridColumn
              headerName="Terveys"
              width={80}
              sortable={false}
              cellRendererFramework={p => p.data.hasHealthAnomalies ? <CheckIcon /> : null}
            />
            <AgGridColumn
              headerName="Kuvaus"
              width={80}
              sortable={false}
              cellRendererFramework={p => p.data.hasNotice ? <CheckIcon /> : null}
            />
          </AgGridColumn>
          <AgGridColumn
            width={48}
            sortable={false}
            pinned="right"
            cellRendererFramework={p => <IconButton size="small" onClick={() => openApproval(p.data.id)}><DescriptionIcon style={{ fontSize: 18 }} /></IconButton>}
          />
        </AgGridReact>
      </div>
      <ChainInfoApprovalModal open={openApprovalModal} onClose={handleApprovalModalClose} />
    </Fragment>
  );
};

export default ChainInfoApproval;