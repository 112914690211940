import React from "react";
import Decimal from "./Number";

interface PercentProps {
  value: number | null | undefined;
  digits?: number
  empty?: string | '';
  unit?: string | '';
}

const _Percent: React.FC<PercentProps> = ({value, digits = 2, empty = '', unit = "%"}) => {
  return (
    <span><Decimal value={value ? (value * 100) : value} digits={digits} empty={empty} unit={unit} /></span>
  );
};

export const Percent = React.memo(_Percent);
export default Percent;
