const styles: any = props => ({
  button: {
    height: "100%",
    padding: "6px 8px"
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-1.5em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 2em 2em 2em',
        borderColor: `transparent transparent ${props.menuPaperStyle.backgroundColor} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-1.5em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '2em 2em 0 2em',
        borderColor: `${props.menuPaperStyle.backgroundColor} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-1.5em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '2em 2em 2em 0',
        borderColor: `transparent ${props.menuPaperStyle.backgroundColor} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-1.5em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '2em 0 2em 2em',
        borderColor: `transparent transparent transparent ${props.menuPaperStyle.backgroundColor}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  iconContainer: {
    position: "relative",
    left: "-10px",
    maxHeight: "22px",
    "& > * > *": {
      fill: props.menuPaperStyle.color
    }
  },
  toolBarIconContainer: {
    maxHeight: "22px",
    "& > * > *": {
      fill: props.menuPaperStyle.backgroundColor
    }
  },
  svgIcon: {
    "& > * > *": {
      fill: "#fff"
    }
  }
});

export default styles;