import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PageHeader from "../../../shared/components/PageHeader/PageHeader";
import { ReactComponent as Logo } from "../../../assets/images/HKScan.svg";
import { SalmonellaSampleCoveringDto, SalmonellaSampleCoveringHallDto } from "../../../shared/models/ApiSchema";
import Date from "../../../shared/components/Formatters/Date";

interface SalmonellaSamplePrintProps {
  data: SalmonellaSampleCoveringDto;
}

const SalmonellaSamplePrint: React.FC<SalmonellaSamplePrintProps> = ({ data }) => {

  const [routine, setRoutine] = useState<SalmonellaSampleCoveringHallDto[]>([]);
  const [official, setOfficial] = useState<SalmonellaSampleCoveringHallDto[]>([]);

  useEffect(() => {
    if (data.halls) {
      setRoutine(data.halls.filter(h => !h.isOfficialSample));
      setOfficial(data.halls.filter(h => h.isOfficialSample));
    }
  }, [data]);

  const logo = (<Logo style={{width: "12em"}} />);

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <PageHeader level="3" component="div">BROILERIN SALMONELLANÄYTELÄHETE</PageHeader>
        {logo}
      </Box>

      <Box mt={4}>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Typography variant="subtitle1"><strong>Broileriuntuvikkojen hautomo:</strong></Typography>
          </Grid>
          <Grid item xs={7}>
            {data.hatchery || ""}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Typography variant="subtitle1"><strong>Tuottajan virallinen nimi:</strong></Typography>
          </Grid>
          <Grid item xs={7}>
            {data.producerName || ""}
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          Palautetaan HKScan Finland Oy:n laboratorioon Vantaalle salmonellanäytteiden yhteydessä,<br/>
          lisätietoja puh. 050 395 1935 tai laboratorio@hkscan.com.
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="body2">
          Salmonellanäyte otetaan 14 – 18 vrk ennen broilerien teurastusta.<br/>
          Näytteet toimitetaan HKScan Finland Oy:n laboratorioon voimassa olevien ohjeiden mukaisesti.<br/>
          Näytteitä ei saa postittaa perjantaina.
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Typography variant="subtitle1"><strong>Näytteenottopäivämäärä:</strong></Typography>
          </Grid>
          <Grid item xs={7}>
            <Date value={data.sampleDate} />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={5}>
            <Typography variant="subtitle1"><strong>Rehun toimittajat:</strong></Typography>
          </Grid>
          <Grid item xs={7}>
            {data.feedFactory || ""}
          </Grid>
        </Grid>
      </Box>

      {official.length > 0 && <Box mt={2}>
        <Box mb={1}>
          <Typography variant="subtitle1"><strong>Virallinen näyte</strong> (1 tossunäytepari + sivelypölynäyte / osasto)</Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table size="small" padding="normal">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" width={100}>
                  <strong><small>Osastojen<br/>numerot</small></strong>
                </TableCell>
                {official.map((l, i) =>
                  <TableCell key={i} align="center" style={{ padding: "6px" }}><strong>{l.hall}</strong></TableCell>
                )}
              </TableRow>
              {/* <TableRow>
                <TableCell component="th" scope="row">
                  <strong><small>Poistuma yht.<br/>pvä mennessä</small></strong>
                </TableCell>
                {official.map((l, i) => <TableCell key={i} align="center">
                  {l.totalLoss}<br/>
                  <small>{moment(l.lossDate).format('D.M.')}</small>
                </TableCell>)}
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>}

      {routine.length > 0 && <Box mt={2}>
        <Box mb={1}>
          <Typography variant="subtitle1"><strong>Rutiininäyte</strong> (2 tossuparia / osasto)</Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table size="small" >
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <strong><small>Osaston nro</small></strong>
                </TableCell>
                {routine.map((l, i) => <TableCell key={i} align="center"><strong>{l.hall}</strong></TableCell>)}
              </TableRow>
              {/* <TableRow>
                <TableCell component="th" scope="row">
                  <strong><small>Poistuma yht.<br/>pvä mennessä</small></strong>
                </TableCell>
                {routine.map((l, i) => <TableCell key={i} align="center">
                  {l.totalLoss}<br/>
                  <small>{moment(l.lossDate).format('D.M.')}</small>
                </TableCell>)}
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>}

      <Box mt={2}>
        <Grid container>
          {official.length > 0 && <Grid item xs={5}>
            <Typography variant="subtitle1"><strong>Eläinlääkärin ottama näyte</strong></Typography>
            <Box mt={6} borderBottom={1}/>
            <Typography variant="subtitle2">Eläinlääkärin allekirjoitus</Typography>
            <Box mt={6} borderBottom={1}/>
            <Typography variant="subtitle2">Eläinlääkärin nimenselvennys</Typography>
          </Grid>}
          {official.length > 0 && <Grid item xs={2}></Grid>}
          {routine.length > 0 && <Grid item xs={5}>
            <Typography variant="subtitle1"><strong>Tuottajan ottama näyte</strong></Typography>
            <Box mt={6} borderBottom={1}/>
            <Typography variant="subtitle2">Tuottajan allekirjoitus</Typography>
            <Box mt={6} borderBottom={1}/>
            <Typography variant="subtitle2">Tuottajan nimenselvennys</Typography>
          </Grid>}
        </Grid>
      </Box>

      <Box mt={2}>
        <Typography variant="subtitle1"><strong>Muuta huomautettavaa</strong></Typography>
        <Box mt={4} borderBottom={1}/>
        <Box mt={4} borderBottom={1}/>
        <Box mt={4} borderBottom={1}/>
      </Box>

      <Box mt={5}>
        <Grid container>
          <Grid item xs={5}>
            <Box display="flex" flexDirection="row">
              <Typography variant="subtitle1" align="right"><strong>Saapui laboratorioon:</strong></Typography>
              <Box mt={3} borderBottom={1} flexGrow={1} />
            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={5}>
            <Box display="flex" flexDirection="row">
              <Typography variant="subtitle1" align="right"><strong>LIMS-erä:</strong></Typography>
              <Box mt={3} borderBottom={1} flexGrow={1} />
            </Box>
          </Grid>
        </Grid>
      </Box>

    </Box>
  );
};

export default SalmonellaSamplePrint;