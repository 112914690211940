import { Box, Button, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import Content from "../../shared/components/Content/Content";
import TasksList from "./components/TasksList/TasksList";
import useStyles from "./HomeStyles";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../shared/store/rootReducer";
import * as api from "../../shared/store/apiActions";
import HallList from "./components/HallList/HallList";
import { useSnackbar } from 'notistack';
import { successHandled } from "../../shared/store/api";
import NotificationsList from "./components/NotificationsList/NotificationsList";
import { BatchFetchResultDto } from "../../shared/models/ApiSchema";

const Main = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const producerNumber = useSelector((state: IRootState) => state.ui.settings.producerNumber);
  const home = useSelector((state: IRootState) => state.home);
  const { success } = useSelector((state: IRootState) => state.api);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (producerNumber) {
      dispatch(api.home.getHallsWithCurrentBatch());
      dispatch(api.home.fetchBatchTasks());
      dispatch(api.home.fetchBatchNotifications());
    }
  }, [dispatch, producerNumber]);

  useEffect(() => {
    if (success) {
      if (success.url?.indexOf('fetchfromportal') > -1)
      {
        let data = success.data as BatchFetchResultDto;
        if (data.batchesCreated === 0 && data.activeUpdated === 0) {
          enqueueSnackbar('Ei uusia tai muuttuneita erätietoja', { variant: 'info' });
        } else {
          if (data.batchesCreated > 0)
            enqueueSnackbar(`Noudettiin ${data.batchesCreated} uuden erän tiedot`, { variant: 'success' });
          if (data.activeUpdated > 0)
            enqueueSnackbar(`Päivitettiin ${data.activeUpdated} aktiivisen erän tietoja`, { variant: 'success' });
        }
        dispatch(successHandled());
      }
    }
  }, [dispatch, enqueueSnackbar, success]);

  const updateBatches = async () => {
    dispatch(api.batch.fetchFromPortal());
  };

  return (
    <Content backGroundStyles={classes.backGroundStyles}>

      <Grid container spacing={5} alignItems="flex-start">
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <PageHeader level="3" component="div">Hallit ja erät</PageHeader>
          <Box my={2}>
            <HallList halls={home.halls} />
          </Box>
          <Box m={2}>
            <Button variant="contained" color="secondary" onClick={updateBatches}>Päivitä erätiedot</Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <PageHeader level="3" component="div">Tehtävät</PageHeader>
          <Box my={2}>
            <TasksList tasks={home.tasks} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <PageHeader level="3" component="div">Ilmoitukset</PageHeader>
          <Box my={2}>
            <NotificationsList notifications={home.notifications}  />
          </Box>
        </Grid>
      </Grid>

    </Content>
  );
};

export default Main;