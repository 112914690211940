import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import commonReducer, { IUserInterface } from "./common";
import settingsReducer, { ISettings } from "./settings";
import i18nReducer, { II8n } from "./i18n";

const config: any = {
  key: "ui",
  storage: storage
};

const persistSettingsReducer: any = persistReducer(config, settingsReducer);

export interface IUi {
  settings: ISettings;
  common: IUserInterface;
  i18n: II8n;
}

export default combineReducers({
  settings: persistSettingsReducer,
  common: commonReducer,
  i18n: i18nReducer,
});
