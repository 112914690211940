import {makeStyles} from "@material-ui/core/styles";
import DownArrow from "../../../assets/images/iconmonstr-arrow-65-gray.svg";

const useStyles = makeStyles(({
  container: singleLine => ({
    marginTop: singleLine ? "8px" : "",
    marginBottom: singleLine ? "8px" : "",
  }),
  label: singleLine => ({
    fontFamily: "Avenir Next W1G Bold",
    letterSpacing: "0.28px",
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: "#535559",
    verticalAlign: singleLine ? "sub" : "",
    marginRight: singleLine ? "18px" : "",
  }),
  dropdownInput: singleLine => ({
    fontFamily: "Avenir Next W1G",
    backgroundClip: "padding-box",
    borderBottomColor: "rgba(0, 0, 0, 0.15)",
    backgroundColor: "#f8f8f8",
    color: "#6E7074",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    borderRadius: 2,
    padding: "6px 0px 7px",
    paddingLeft: "4px",
    marginTop: singleLine ? "" : "8px",
    marginBottom: singleLine ? "" : "8px",
  }),
  caretIcon: {
    paddingLeft: "8px",
    backgroundImage: `url(${DownArrow})`,
    backgroundSize: "8px 8px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center right 10px",
    appearance: "none"
  },
  formControl: {
    margin: 0,
    minWidth: "100%",
  },
  alert: {
    color: "#650000"
  },
}));

export default useStyles;