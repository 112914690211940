import React, { useEffect, useState } from "react";
import useStyles from "./ArchiveStyles";
import Content from "../../shared/components/Content/Content";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, BreedingBatchStatus } from "../../shared/models/ApiSchema";
import breedingBatchService from "../../shared/services/breedingBatchService";
import { Box, CircularProgress, Link } from "@material-ui/core";
import BatchStatus from "../../shared/components/Statuses/BatchStatus";
import { useHistory } from "react-router-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../shared/helpers/agGridHelper";

interface BatchLinkProps {
  batch: any
}

const BatchLink: React.FC<BatchLinkProps> = ({batch}) => {
  const history = useHistory();

  const navigate = (e) => {
    e.preventDefault();
    history.push(`/batch/${batch?.id}`);
  };

  return (
    <Link href="#" onClick={navigate}><strong>{batch?.breedingId}</strong></Link>
  )
};

const Archive = () => {
  const classes = useStyles();

  const [batches, setBatches] = useState<BreedingBatchDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const resp = await breedingBatchService.listBreedingBatches(1, 1000);
      setBatches(resp.data.items || []);
      setLoading(false);
    };
    fetchData();
  }, []);

  const defaultColDef = {
    cellStyle: { textAlign: "center", verticalAlign: "middle" },
    sortable: true,
    editable: false,
    suppressMenu: true,
    suppressMovable: true,
    suppressNavigable: true
  };

  return (
    <Content maxWidth="lg" backGroundStyles={classes.backGroundStyles}>
      <PageHeader level="1" component="div">Arkisto</PageHeader>
      {loading &&
        <Box my={6} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      }
      <Box mt={2}>
        {!loading && <div className="diary-grid ag-theme-balham" style={{height: "70vh", width: "99.99%"}}>
          <AgGridReact
            rowData={batches}
            defaultColDef={defaultColDef}
            suppressCellSelection={true}
            applyColumnDefOrder={true}
            immutableData={true}
            rowHeight={32}
            getRowNodeId={(row) => row.id}
            onFirstDataRendered={(e) => e.api.sizeColumnsToFit()}
            onRowDataChanged={(e) => e.api.sizeColumnsToFit()}
          >
            <AgGridColumn
              field="status"
              headerName="Tila"
              minWidth={100}
              pinned="left"
              cellRendererFramework={params => <BatchStatus status={params.value} />}
            />
            <AgGridColumn
              field="breedingId"
              headerName="Erätunniste"
              minWidth={100}
              pinned="left"
              cellRendererFramework={params => <BatchLink batch={params.data} />}
            />
            <AgGridColumn
              field="hall.number"
              headerName="Halli"
              minWidth={75}
              pinned="left"
              cellStyle={{ fontWeight: "bold" }}
            />
            <AgGridColumn
              field="plannedQuantity"
              headerName="Suunniteltu määrä"
              minWidth={100}
            />
            <AgGridColumn
              field="arrivalDate"
              headerName="Saapumispäivä"
              minWidth={100}
              valueFormatter={agGridHelper.dateFormatter}
            />
            <AgGridColumn
              field="chickQuantity"
              headerName="Aloittanut määrä"
              minWidth={100}
              valueGetter={p => p.data.status === BreedingBatchStatus.Planned ? "" : p.data.chickQuantity}
            />
            <AgGridColumn
              field="slaughterDate"
              headerName="Teurastuspäivä"
              minWidth={100}
              valueFormatter={agGridHelper.dateFormatter}
            />
            <AgGridColumn
              field="slaughterAge"
              headerName="Teurastusikä"
              minWidth={75}
            />
            <AgGridColumn
              field="birdsLeft"
              headerName="Jäljellä määrä"
              minWidth={100}
              valueGetter={p => p.data.status === BreedingBatchStatus.Planned ? "" : p.data.birdsLeft}
            />
          </AgGridReact>
        </div>}
      </Box>
    </Content>
  );
};

export default Archive;