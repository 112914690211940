import { Box, Grid, InputAdornment } from '@material-ui/core';
import { Formik, Form, FastField } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import DatePicker from '../../../../shared/components/DatePicker/DatePicker';
import { TextField } from 'formik-material-ui';
import PageHeader from '../../../../shared/components/PageHeader/PageHeader';
import { HallInfoDto } from '../../../../shared/models/ApiSchema';
import { numberEmptyToNull, dateOnly } from '../../../../shared/helpers/formHelper';
import FormikDisabler from '../../../../shared/components/FormikDisabler/FormikDisabler';

interface HallInfoFormProps {
  formId: string;
  values?: HallInfoDto | null;
  onSubmit: any;
}

const HallInfoForm: React.FC<HallInfoFormProps> = ({formId, values, onSubmit}) => {

  const initialValues = {
    litterMaterial: "",
    litterDeployedDate: null,
    litterSupplier: "",
    hallEmpty: null,
    hallCleaned: null,
    detergent: "",
    detergentAmount: "",
    detergentDate: null,
    disinfectant: "",
    disinfectantAmount: "",
    disinfectantDate: null,
    nippleLineDetergent: "",
    nippleLineDetergentAmount: "",
    nippleLineCleaned: null,
    nippleLineDisinfectant: "",
    nippleLineDisinfectantAmount: "",
    nippleLineDisinfected: null,
    feedSiloDetergent: "",
    feedSiloDetergentAmount: "",
    feedSiloCleaned: null,
    rodentControl: null,
    beetleControl: null,
    heating: null,
    airConditioning: null,
    water: null,
    aggregate: null,
    visitors: "",
    notes: "",
    pesticide: "",
    waterSampleTaken: null,
    maintenanceRoomCleaned: null,
    manureDisposal: ""
  };

  const formSchema = Yup.object().shape({
    litterMaterial: Yup
      .string()
      .nullable()
      .max(100),
    litterDeployedDate: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    litterSupplier: Yup
      .string()
      .nullable()
      .max(100),
    hallEmpty: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    hallCleaned: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    detergent: Yup
      .string()
      .nullable()
      .max(100),
    detergentAmount: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(1000000),
    detergentDate: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    disinfectant: Yup
      .string()
      .nullable()
      .max(100),
    disinfectantAmount: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(1000000),
    disinfectantDate: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    nippleLineDetergent: Yup
      .string()
      .nullable()
      .max(100),
    nippleLineDetergentAmount: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(1000000),
    nippleLineCleaned: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    nippleLineDisinfectant: Yup
      .string()
      .nullable()
      .max(100),
    nippleLineDisinfectantAmount: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(1000000),
    nippleLineDisinfected: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    feedSiloDetergent: Yup
      .string()
      .nullable()
      .max(100),
    feedSiloDetergentAmount: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(1000000),
    feedSiloCleaned: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    rodentControl: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    beetleControl: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    heating: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    airConditioning: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    water: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    aggregate: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    visitors: Yup
      .string()
      .nullable()
      .max(4000),
    notes: Yup
      .string()
      .nullable()
      .max(4000),
    waterSampleTaken: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    pesticide: Yup
      .string()
      .nullable()
      .max(100),
    maintenanceRoomCleaned: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    manureDisposal: Yup
      .string()
      .nullable()
      .max(100),
  });

  return (
      <Formik
        initialValues={values || initialValues}
        enableReinitialize={true}
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          const data = formSchema.cast(values, { stripUnknown: true });
          actions.setSubmitting(false);
          actions.resetForm({ values: initialValues });
          onSubmit(data);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form id={formId}>
            <FormikDisabler disabled={values?.isFinal === true} />
            <Box my={3}>
              <Box mb={2}>
                <PageHeader level="2" component="div">Ennen untuvikkojen tuloa ja kasvatuksen aikana:</PageHeader>
              </Box>
              <PageHeader level="4" component="div">Pesu ja desinfiointi</PageHeader>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FastField
                    component={TextField}
                    label="Hallin pesuaine"
                    name="detergent"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FastField
                    component={TextField}
                    label="Määrä"
                    name="detergentAmount"
                    fullWidth
                    margin="dense"
                    InputProps={{
                      type: "text",
                      inputMode: "numeric",
                      endAdornment: <InputAdornment position="end">litraa</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FastField
                    component={DatePicker}
                    label="Pesty"
                    name="detergentDate"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FastField
                    component={TextField}
                    label="Hallin desinfiointiaine"
                    name="disinfectant"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FastField
                    component={TextField}
                    label="Määrä"
                    name="disinfectantAmount"
                    fullWidth
                    margin="dense"
                    InputProps={{
                      type: "text",
                      inputMode: "numeric",
                      endAdornment: <InputAdornment position="end">litraa</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FastField
                    component={DatePicker}
                    label="Desinfioitu"
                    name="disinfectantDate"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FastField
                    component={TextField}
                    label="Juomalinjan puhdistusaine"
                    name="nippleLineDetergent"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FastField
                    component={TextField}
                    label="Määrä"
                    name="nippleLineDetergentAmount"
                    fullWidth
                    margin="dense"
                    InputProps={{
                      type: "text",
                      inputMode: "numeric",
                      endAdornment: <InputAdornment position="end">litraa</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FastField
                    component={DatePicker}
                    label="Puhdistettu"
                    name="nippleLineCleaned"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FastField
                    component={TextField}
                    label="Juomalinjan desinfiointiaine"
                    name="nippleLineDisinfectant"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FastField
                    component={TextField}
                    label="Määrä"
                    name="nippleLineDisinfectantAmount"
                    fullWidth
                    margin="dense"
                    InputProps={{
                      type: "text",
                      inputMode: "numeric",
                      endAdornment: <InputAdornment position="end">litraa</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FastField
                    component={DatePicker}
                    label="Desinfioitu"
                    name="nippleLineDisinfected"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FastField
                    component={TextField}
                    label="Rehusiilon puhdistusaine"
                    name="feedSiloDetergent"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FastField
                    component={TextField}
                    label="Määrä"
                    name="feedSiloDetergentAmount"
                    fullWidth
                    margin="dense"
                    InputProps={{
                      type: "text",
                      inputMode: "numeric",
                      endAdornment: <InputAdornment position="end">litraa</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <FastField
                    component={DatePicker}
                    label="Puhdistettu"
                    name="feedSiloCleaned"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box my={3}>
              <PageHeader level="4" component="div">Pehku</PageHeader>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={6} lg={6}>
                  <FastField
                    component={TextField}
                    label="Toimittaja"
                    name="litterSupplier"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <FastField
                    component={TextField}
                    label="Materiaali"
                    name="litterMaterial"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <FastField
                    component={DatePicker}
                    label="Levitys"
                    name="litterDeployedDate"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box my={3}>
              <PageHeader level="4" component="div">Hälytyskontrollit</PageHeader>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <FastField
                    component={DatePicker}
                    label="Lämmitys"
                    name="heating"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FastField
                    component={DatePicker}
                    label="Ilmastointi"
                    name="airConditioning"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FastField
                    component={DatePicker}
                    label="Vesi"
                    name="water"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FastField
                    component={DatePicker}
                    label="Aggregaatti"
                    name="aggregate"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box my={3}>
              <PageHeader level="4" component="div">Tuholaistorjunta</PageHeader>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FastField
                    component={DatePicker}
                    label="Jyrsijäkontrolli"
                    name="rodentControl"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FastField
                    component={DatePicker}
                    label="Kuoriaiskontrolli"
                    name="beetleControl"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={6}>
                  <FastField
                    component={TextField}
                    label="Käytetyt torjunta-aineet"
                    name="pesticide"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box my={3}>
              <PageHeader level="4" component="div">Lisätiedot</PageHeader>
              <FastField
                component={TextField}
                label="Vierailijat"
                name="visitors"
                fullWidth
                margin="dense"
                InputProps={{
                  multiline: true,
                  maxRows: 8
                }}
              />
              <FastField
                component={TextField}
                label="Muut huomiot"
                name="notes"
                fullWidth
                margin="dense"
                InputProps={{
                  multiline: true,
                  maxRows: 8
                }}
              />
            </Box>
            <Box my={3}>
              <Box my={2}>
                <PageHeader level="2" component="div">Erän teurastuksen jälkeen:</PageHeader>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FastField
                    component={DatePicker}
                    label="Halli tyhjennetty"
                    name="hallEmpty"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FastField
                    component={DatePicker}
                    label="Halli siivottu"
                    name="hallCleaned"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FastField
                    component={DatePicker}
                    label="Huoltotila siivottu"
                    name="maintenanceRoomCleaned"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FastField
                    component={DatePicker}
                    label="Vesinäyte otettu"
                    name="waterSampleTaken"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FastField
                    component={TextField}
                    label="Lannan loppusijoitus"
                    name="manureDisposal"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
  );
};

export default HallInfoForm;
