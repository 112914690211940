import { Box, Button, Paper } from "@material-ui/core";
import React, { Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";

export default function PrinterWrapper({ children }) {
  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Fragment>
      <Paper>
        <div ref={componentRef}>
          <Box p={6}>
            {children}
          </Box>
        </div>
      </Paper>
      <Box mt={3}>
        <Button onClick={handlePrint} variant="contained" color="secondary">Tulosta</Button>
      </Box>
    </Fragment>
  );
}
