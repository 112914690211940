import React, {Fragment} from "react";
import {Typography} from "@material-ui/core";
import useStyles from "./PageHeaderStyles";

interface PageHeaderProps {
    level: "1" | "2" | "3" | "4",
    [x:string]: any
}

const PageHeader: React.FC<PageHeaderProps> = ({level = "1", children, ...rest})=> {
    const classes = useStyles();
    return (
        <Fragment>
            {
                (level === "1") &&
                <Typography
                    variant={"h2"}
                    color={"primary"}
                    className={classes.header1}
                    {...rest}
                >
                    {children}
                </Typography>
            }

            {
                (level === "2") &&
                <Typography
                    variant={"h3"}
                    color={"primary"}
                    className={classes.header2}
                    {...rest}
                >
                    {children}
                </Typography>
            }

            {
                (level === "3") &&
                <Typography
                  variant={"h4"}
                  color={"primary"}
                  className={classes.header3}
                  {...rest}
                >
                    {children}
                </Typography>
            }

            {
                (level === "4") &&
                <Typography
                  variant={"h5"}
                  color={"primary"}
                  className={classes.header4}
                  {...rest}
                >
                    {children}
                </Typography>
            }
        </Fragment>
    );
};

export default PageHeader;
