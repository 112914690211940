import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontFamily: 'Avenir Next W1G Medium',
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    textAlign: "right"
  },
  logoContainer: {
    minWidth: "120px",
    width: "140px",
    textAlign: "left",
  },
  logo: {
    pointerEvents: "none",
    marginTop: "6px",
    height: "30px"
  },
  kivinettiLogo: {
    pointerEvents: "none",
    marginTop: "3px",
    height:"45px"
  },
  titleContainer: {
    display: "block",
    whiteSpace: "nowrap",
    marginTop: "-2px"
  },
  title: {
  },
  titleSeparator: {
    color: "#777",
    margin: theme.spacing(2)
  },
  subTitle: {
    color: "#85293F",
    fontSize: "0.8em",
    fontWeight: "bold"
  },
  menuItemContainer: {
    [theme.breakpoints.down("sm")] : {
      display: "none"
    },
    width: "100%",
    minHeight: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    padding: "0 10px",
    minHeight: "inherit"
  },
  toggleMenu: {
    [theme.breakpoints.up("md")] : {
      display: "none"
    },
    width: "100%"
  },
  /* Note! No bigger idea why these styles doesn't work in builded version without !important - DO NOT REMOVE! */
  menuButton: {
    fontFamily: "Avenir Next W1G Medium !important",
    padding: "0 10px !important",
    minHeight: "inherit",
    color: `${theme.palette.primary.main} !important`,
    borderRadius: 0,
    "& > *": {
      fontWeight: "bold !important"
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  }
}));

export default useStyles;