import React from "react";
import { Container, Grid, Hidden } from "@material-ui/core";
import { ReactComponent as Logo } from "../../../assets/images/HKFoods.svg";
import { ReactComponent as HKScanEnvironmentLeft } from "../../../assets/images/hkscan_environment_left.svg";
import { ReactComponent as HKScanEnvironmentRight } from "../../../assets/images/hkscan_environment_right.svg";
import useStyles from "./FooterStyles";

const Footer = () => {
  const classes = useStyles();

  const logo = (<Logo className={classes.logo} />);

  const environmentLeft = (<HKScanEnvironmentLeft className={classes.hkscanEnvironmentLeft} />);

  const environmentRight = (<HKScanEnvironmentRight className={classes.hkscanEnvironmentRight} />);

  return (
    <Container id="footer" maxWidth={"xl"} className={classes.footer}>
      <Grid container spacing={5} alignItems="flex-start">
        <Hidden xsDown>
          <Grid item sm={3} md={4}>
            {environmentLeft}
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.logoContainer}>{logo}</div>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={3} md={4}>
            {environmentRight}
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
};

export default Footer;
