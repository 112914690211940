import React from "react";
import { DateTimePicker as FormikDateTimePicker } from 'formik-material-ui-pickers';
import { InputAdornment } from "@material-ui/core";

interface DateTimePickerProps {
  field: any,
  form: any,
  meta: any,
  onChange: any
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({ children, ...rest }) => {
  return (
    <FormikDateTimePicker
      {...rest}
      variant="dialog"
      format="D.M.yyyy H:mm"
      minutesStep={5}
      autoOk
      invalidDateMessage="Virheellinen päivämäärä"
      ampm={false}
      clearable
      cancelLabel="Peruuta"
      okLabel="Aseta"
      clearLabel="Tyhjennä"
      InputProps={{
        endAdornment: <InputAdornment position="end">aika</InputAdornment>
      }}
    >
      {children}
    </FormikDateTimePicker>
  );
};

export default DateTimePicker;
