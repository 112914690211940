import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { Fragment } from "react";
import ApprovalStatus from "../Statuses/ApprovalStatus";
import { ApprovalStatusAuditDto } from "../../models/ApiSchema";
import Date from "../Formatters/Date";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import { Alert } from "@material-ui/lab";

interface ApprovalStatusAuditListProps {
  audits: ApprovalStatusAuditDto[] | null | undefined
}

const ApprovalStatusAuditList: React.FC<ApprovalStatusAuditListProps> = ({audits}) => {
  return (
    <Fragment>
      {/* {((!audits || audits.length === 0) &&
        <Alert severity="info">Hyväksynnän tilasiirtymiä ei ole.</Alert>
      )} */}
      {(audits && audits.length > 0 &&
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={240}>Siirtymä</TableCell>
                <TableCell width={160}>Aikaleima</TableCell>
                <TableCell>Käyttäjä</TableCell>
                <TableCell>Huomioita</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(audits || []).map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Grid container direction="row" alignItems="center">
                      <ApprovalStatus status={row.fromStatus} />
                      {" "}<ArrowForwardIcon />{" "}
                      <ApprovalStatus status={row.toStatus} />
                    </Grid>
                  </TableCell>
                  <TableCell><Date value={row.occurredAt} time={true} /></TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.comment || "-"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
};

export default ApprovalStatusAuditList;