import { AccessDto, ProducerDto } from "../models/ApiSchema";
import http from "./httpService";

const API_URL = `user`;

export const fetchOrganizations = async () => {
  const resp = await http.post<ProducerDto[]>(`${API_URL}/organizations`);
  return resp;
};

export const setLastVisitedOrganization = async (number: number) => {
  const resp = await http.put<number>(`${API_URL}/organizations/lastvisited/${number}`);
  return resp;
};

export const searchOrganizations = async (search: string) => {
  const resp = await http.post<ProducerDto[]>(`${API_URL}/organizations/${search}`);
  return resp;
};

export const getUserFilteredOrganization = async (authenticationSource: string, filter: string = "") => {
  if (authenticationSource !== "HKScanAzureAD" || filter === "") {
    return { data: [] };
  }
  const resp = await http.post<ProducerDto[]>(`${API_URL}/organizations/${filter}`);
  return resp;
};

export const listProducers = async () => {
  const resp = await http.get<ProducerDto[]>(`${API_URL}/producers`);
  return resp;
};

export const fetchAccess = async () => {
  const resp = await http.get<AccessDto[]>(`${API_URL}/access`);
  return resp;
};

const userService = {
  fetchOrganizations,
  searchOrganizations,
  setLastVisitedOrganization,
  getUserFilteredOrganization,
  listProducers,
  fetchAccess
};
export default userService;
