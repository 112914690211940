import React from "react";
import { Grid, Paper, Container, Box, useMediaQuery, useTheme } from "@material-ui/core";
import useStyles from "./ContentStyles";

interface ContentProps {
  children: any;
  top?: any;
  backGroundStyles?: string;
  gridContainerStyles?: string;
  maxWidth?: false | "xl" | "xs" | "sm" | "md" | "lg" | undefined;
  container?: boolean;
}

const Content = ({ children, top = undefined, backGroundStyles = "", gridContainerStyles = "", maxWidth = "xl", container = true } : ContentProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Paper className={`${classes.contentContainer} ${backGroundStyles}`} elevation={0}>
      <Container maxWidth={maxWidth} className={classes.widthContainer}>
        <Grid
          container
          className={`${classes.gridContainer} ${gridContainerStyles}`}
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          {top &&
            <Grid item xs={12}>
              <Box mb={isSmall ? 0 : 2}>
                {top}
              </Box>
            </Grid>
          }
          <Grid item xs={12} style={{ height: "100%" }}>
            {container && <Paper className={classes.content}>
              <Container maxWidth={false} className={classes.container}>
                {children}
              </Container>
            </Paper>}
            {!container && <Box mb={3} className={classes.content}>
              {children}
            </Box>}
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default Content;