import { Toolbar, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import useStyles from "./ProducerNameStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto } from "../../../../shared/models/ApiSchema";
import Date from "../../../../shared/components/Formatters/Date";

interface ProducerNameProps {
  batch: BreedingBatchDto;
}

const ProducerName: React.FC<ProducerNameProps> = ({batch}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Toolbar variant="dense" disableGutters className={classes.toolbar}>
      <PageHeader level="4" component="div">
        {batch.hall?.producer?.name}
        {isMedium && !isSmall ? <br/> : " - "}
        Erä {batch.breedingId}
        {!isSmall && <span>{": "}<Date value={batch.arrivalDate} year={false} /> - <Date value={batch.slaughterDate} /></span>}
      </PageHeader>
    </Toolbar>
  );
};

export default ProducerName;
