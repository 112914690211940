import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: "0",
    margin: "24px 8px 48px 8px"
  },
  scrollPaper: {
  },
  title: {
    paddingBottom: 0
  }

}));

export default useStyles;