import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { batchDeliveryFormData, feedFormData, hallFormData, healthFormData } from "../helpers/formHelper";
import { BreedingBatchDto, HallInfoDto, FeedInfoDto, PoultryDiaryDto, BatchDetailsDto, PoultryLossDto, SevenDayLossDto, BatchReportsDto, HealthInfoDto, SalmonellaSampleDto, ChainInfoDto, ChainInfoSupplementDto, BatchDatesDto, BatchDeliveryDto, ChainInfoDraftDto } from "../models/ApiSchema";

export interface IBatch {
  batch: BreedingBatchDto | null;
  deliveries: BatchDeliveryDto[];
  dates: BatchDatesDto | null;
  diaries: PoultryDiaryDto[];
  reports: BatchReportsDto;
  hallInfo: HallInfoDto | null;
  feedInfo: FeedInfoDto | null;
  healthInfo: HealthInfoDto | null;
  date: moment.Moment | null;
}

const initialState: IBatch = {
  batch: null,
  deliveries: [],
  dates: null,
  diaries: [],
  reports: {
    sevenDayLoss: null,
    salmonellaSample: null,
    chainInfoDraft: null,
    chainInfo: null,
    chainInfoSupplement: null
  },
  hallInfo: null,
  feedInfo: null,
  healthInfo: null,
  date: null
};

const slice = createSlice({
  name: "batch",
  initialState: initialState,
  reducers: {
    batchDetailsReceived: (state, action) => {
      const details: BatchDetailsDto = action.payload.data;
      state.batch = details.batch || null;
      state.deliveries = (details.deliveries || []).map(d => batchDeliveryFormData(d));
      state.dates = details.dates || null
      state.reports = details.reports || {};
      state.hallInfo = details.hallInfo ? hallFormData(details.hallInfo) : null;
      state.feedInfo = details.feedInfo ? feedFormData(details.feedInfo) : null;
      state.healthInfo = details.healthInfo ? healthFormData(details.healthInfo) : null;
      state.diaries = details.diaries || [];

      const now = state.date || moment();
      const batchStart = moment(state?.batch?.arrivalDate);
      const batchEnd = moment(state?.batch?.slaughterDate);
      if (now.isSameOrAfter(batchStart, 'day') && now.isSameOrBefore(batchEnd, 'day')) {
        state.date = now;
      }
      else if (now.isAfter(batchEnd, 'day')) {
        state.date = batchEnd;
      }
      else {
        state.date = batchStart;
      }
    },
    sevenDayLossCreated: (state, action) => {
      const sevenDayLoss: SevenDayLossDto = action.payload.data;
      state.reports.sevenDayLoss = sevenDayLoss;
    },
    salmonellaSampleCreated: (state, action) => {
      const salmonellaSample: SalmonellaSampleDto = action.payload.data;
      state.reports.salmonellaSample = salmonellaSample;
    },
    chainInfoDraftUpdated: (state, action) => {
      const chainInfoDraft: ChainInfoDraftDto = action.payload.data;
      state.reports.chainInfoDraft = chainInfoDraft;
    },
    chainInfoCreated: (state, action) => {
      const chainInfo: ChainInfoDto = action.payload.data;
      state.reports.chainInfo = chainInfo;
      state.reports.chainInfoDraft = null;
    },
    chainInfoSupplementCreated: (state, action) => {
      const chainInfoSupplement: ChainInfoSupplementDto = action.payload.data;
      state.reports.chainInfoSupplement = chainInfoSupplement;
    },
    diariesReceived: (state, action) => {
      const diaries: PoultryDiaryDto[] = action.payload.data;
      state.diaries = diaries || [];
    },
    diaryUpdated: (state, action) => {
      const diary: PoultryDiaryDto = action.payload.data;
      const index = findDiaryIndexByDay(state.diaries, diary.day);
      state.diaries[index] = diary;
    },
    hallInfoUpdated: (state, action) => {
      const hallInfo: HallInfoDto = action.payload.data;
      state.hallInfo = hallFormData(hallInfo);
    },
    hallInfoLoaded: (state, action) => {
      const hallInfo: HallInfoDto = action.payload.data;
      if (hallInfo) {
        hallInfo.modifiedBy = state.hallInfo?.modifiedBy;
        hallInfo.modifiedDate = state.hallInfo?.modifiedDate;
      }
      state.hallInfo = hallInfo ? hallFormData(hallInfo) : null;
    },
    feedInfoUpdated: (state, action) => {
      const feedInfo: FeedInfoDto = action.payload.data;
      state.feedInfo = feedFormData(feedInfo);
    },
    feedInfoLoaded: (state, action) => {
      const feedInfo: FeedInfoDto = action.payload.data;
      if (feedInfo) {
        feedInfo.modifiedBy = state.feedInfo?.modifiedBy;
        feedInfo.modifiedDate = state.feedInfo?.modifiedDate;
        feedInfo.startFeeds = feedInfo.startFeeds?.map((o, i) => ({ ...o, id: null, date: state.feedInfo?.startFeeds?.[i]?.date || null }));
        feedInfo.grow1Feeds = feedInfo.grow1Feeds?.map((o, i) => ({ ...o, id: null, date: state.feedInfo?.grow1Feeds?.[i]?.date || null }));
        feedInfo.grow2Feeds = feedInfo.grow2Feeds?.map((o, i) => ({ ...o, id: null, date: state.feedInfo?.grow2Feeds?.[i]?.date || null }));
        feedInfo.endFeeds = feedInfo.endFeeds?.map((o, i) => ({ ...o, id: null, date: state.feedInfo?.endFeeds?.[i]?.date || null }));
        feedInfo.grains = feedInfo.grains?.map((o, i) => ({ ...o, id: null, date: state.feedInfo?.grains?.[i]?.date || null }));
      }
      state.feedInfo = feedInfo ? feedFormData(feedInfo) : null;
    },
    healthInfoUpdated: (state, action) => {
      const healthInfo: HealthInfoDto = action.payload.data;
      state.healthInfo = healthFormData(healthInfo);
    },
    changeDate: (state, action) => {
      state.date = action.payload.date;
    },
    lossAdded: (state, action) => {
      if (state.date) {
        const loss: PoultryLossDto = action.payload.data;
        const index = findDiaryIndexByDate(state.diaries, state.date);
        if (state.diaries[index]) {
          state.diaries[index].poultryLosses?.push(loss);
        }
      }
    },
    lossDeleted: (state, action) => {
      if (state.date) {
        const lossId = action.payload.lossId;
        const index = findDiaryIndexByDate(state.diaries, state.date);
        if (state.diaries[index]) {
          state.diaries[index].poultryLosses = state.diaries[index].poultryLosses?.filter((loss) => {
            return loss.id !== lossId;
          });
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  batchDetailsReceived,
  sevenDayLossCreated,
  salmonellaSampleCreated,
  chainInfoDraftUpdated,
  chainInfoCreated,
  chainInfoSupplementCreated,
  diaryUpdated,
  diariesReceived,
  hallInfoUpdated,
  feedInfoUpdated,
  changeDate,
  lossAdded,
  lossDeleted,
  feedInfoLoaded,
  hallInfoLoaded,
  healthInfoUpdated
} = slice.actions;

export default slice.reducer;

export const findDiaryIndexByDay = (diaries: PoultryDiaryDto[], day: number): number =>
  (diaries || []).findIndex(d => d.day === day);

export const findDiaryIndexByDate = (diaries: PoultryDiaryDto[], date: moment.Moment): number =>
  (diaries || []).findIndex(d => date.isSame(moment(d.date), 'day'));
