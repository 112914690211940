import { Box, Chip, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PageHeader from "../../../shared/components/PageHeader/PageHeader";
import PrinterWrapper from "../../../shared/components/PrinterWrapper/PrinterWrapper";
import { SalmonellaSampleCoveringDto } from "../../../shared/models/ApiSchema";
import printService from "../../../shared/services/printService";
import SalmonellaSamplePrint from "./SalmonellaSamplePrint";

const SalmonellaSample = () => {

  const { date } = useParams<{ date: string }>();
  const history = useHistory();

  const [dates, setDates] = useState<moment.Moment[] | null>(null);
  const [sampleDate, setSampleDate] = useState<moment.Moment | null>(null);
  const [printData, setPrintData] = useState<SalmonellaSampleCoveringDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await printService.listSalmonellaSampleDates();
      setDates(resp.data.map((d) => moment(d)));
    };
    fetchData();
  }, []);

  useEffect(() => {
    setPrintData(null);
    setSampleDate(null);
    setNotFound(false);
    if (date) {
      const _date = moment(date);
      if (_date && _date.isValid()) {
        setSampleDate(_date);
        setLoading(true);
        const fetchData = async () => {
          const resp = await printService.getSalmonellaSampleCovering(_date);
          setLoading(false);
          if (resp.data.halls?.length) {
            setPrintData(resp.data);
          } else {
            setNotFound(true);
          }
        };
        fetchData();
      }
    }
  }, [date]);

  const go = (date: moment.Moment) => {
    history.push(`/print/salmonella/${date.format("YYYY-MM-DD")}`);
  };

  return (
    <Fragment>
      <PageHeader level="1" component="div">Tulosta salmonellanäytelähete</PageHeader>
      {dates && dates.length > 0 &&
        <Box mt={2}>
          <strong>Näytteenottopäivä:</strong>{" "}
          {dates.map((d, i) =>
            <Chip key={i} size="small" variant="outlined"
              color={d.isSame(sampleDate, "day") ? "primary" : "default"}
              label={d.format("D.M.YYYY")}
              onClick={() => go(d)}
              style={{margin: "4px"}} />
          )}
        </Box>
      }
      {dates && !dates.length &&
        <Box mt={2}>
          <Alert severity="warning">Aktiivisille kasvatuserille ei löydy yhtään näytteeottopäivää.</Alert>
        </Box>
      }
      <Box mt={4}>
        {loading &&
          <Box my={6} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!loading && !printData && !notFound &&
          <Alert severity="info">Valitse näytteenottopäivä tulostaaksesi lähetteen.</Alert>
        }
        {!loading && !printData && notFound &&
          <Alert severity="error">Valitulle päivälle ei löydy salmonellanäytteitä.</Alert>
        }
        {!loading && printData &&
          <Box my={3}>
            <PrinterWrapper>
              <SalmonellaSamplePrint data={printData} />
            </PrinterWrapper>
          </Box>
        }
      </Box>
    </Fragment>
  );
};

export default SalmonellaSample;