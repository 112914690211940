import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle, Hidden, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import DeleteIcon from '@material-ui/icons/Delete';
import { BreedingBatchDto, PoultryDiaryDto } from "../../../../shared/models/ApiSchema";
import * as api from "../../../../shared/store/apiActions";
import LossForm from "../LossForm/LossForm";
import { useSnackbar } from 'notistack';
import { IRootState } from "../../../../shared/store/rootReducer";
import { useConfirm } from 'material-ui-confirm';
import DiaryHeader from "../DiaryHeader/DiaryHeader";
import { successHandled } from "../../../../shared/store/api";
import Date from "../../../../shared/components/Formatters/Date";
// import DaySwitcher from "../DaySwitcher/DaySwitcher";

interface InputLossModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
  diary: PoultryDiaryDto;
}

const InputLossModal: React.FC<InputLossModalProps> = ({ open, onClose, batch, diary }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { success } = useSelector((state: IRootState) => state.api);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (success) {
      if (success.url?.indexOf('/loss') > -1)
      {
        if (success.method === "post") {
          enqueueSnackbar("Poistumakirjaus lisätty", { variant: 'success' });
          dispatch(successHandled());
        }
        if (success.method === "delete") {
          enqueueSnackbar("Poistumakirjaus tuhottu", { variant: 'success' });
          dispatch(successHandled());
        }
      }
    }
  }, [dispatch, enqueueSnackbar, success]);

  const handleClose = () => {
    onClose(changed);
  };

  const handleSubmit = (data) => {
    dispatch(api.diary.addPoultryLoss(diary.id, {
      dead: data.dead,
      culled: data.culled
    }));
    setChanged(true);
  };

  const handleDelete = (lossId: string) => {
    if (lossId) {
      confirm({ description: "Valittu kirjaus poistetaan lopullisesti." }).then(() => {
        dispatch(api.diary.deletePoultryLoss(lossId));
        setChanged(true);
      });
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
      TransitionProps={{onEnter: () => setChanged(false)}}
    >
      <DialogTitle className={classes.title}>
        <DiaryHeader title="Poistumat" batch={batch} diary={diary} />
        {/* <DaySwitcher /> */}
      </DialogTitle>
      <DialogContent>
        {diary.poultryLosses && diary.poultryLosses.length > 0 &&
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" padding="none">Kuollut</TableCell>
                  <TableCell align="center" padding="none">Karsittu</TableCell>
                  <TableCell align="center" padding="none">Yhteensä</TableCell>
                  <Hidden xsDown>
                    <TableCell align="left" padding="none">Kirjattu</TableCell>
                  </Hidden>
                  <TableCell align="right" padding="none"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {diary.poultryLosses?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center" padding="none">{row.dead}</TableCell>
                    <TableCell align="center" padding="none">{row.culled}</TableCell>
                    <TableCell align="center" padding="none">{row.dead + row.culled}</TableCell>
                    <Hidden xsDown>
                      <TableCell align="left" padding="none">
                        <small><Date value={row.date} time={true} /> {row.modifiedBy}</small>
                      </TableCell>
                    </Hidden>
                    <TableCell align="right" padding="none">
                      <IconButton aria-label="delete" onClick={() => handleDelete(row.id)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        <Box py={1}>
          <LossForm
            formId="lossForm"
            onSubmit={handleSubmit}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" tabIndex={-1}>Sulje</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InputLossModal;
