import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { ApprovalStatus, ChainInfoApprovalDto, ChainInfoListDto, ChainInfoSupplementApprovalDto, ChainInfoSupplementListDto, NotificationApprovalDto, NotificationDto, SalmonellaSampleApprovalDto, SalmonellaSampleListDto, SevenDayLossApprovalDto, SevenDayLossListDto } from "../models/ApiSchema";

export interface IApproval {
  status: ApprovalStatus | null;
  startDate: moment.Moment;
  endDate: moment.Moment;
  chainInfoList: ChainInfoListDto[];
  chainInfoApproval: ChainInfoApprovalDto | null;
  chainInfoSupplementList: ChainInfoSupplementListDto[];
  chainInfoSupplementApproval: ChainInfoSupplementApprovalDto | null;
  salmonellaSampleList: SalmonellaSampleListDto[];
  salmonellaSampleApproval: SalmonellaSampleApprovalDto | null;
  sevenDayLossList: SevenDayLossListDto[];
  sevenDayLossApproval: SevenDayLossApprovalDto | null;
  notificationsList: NotificationDto[];
  notificationApproval: NotificationApprovalDto | null;
}

const initialState: IApproval = {
  status: null,
  startDate: moment().startOf('month').subtract(2, 'months'),
  endDate: moment().endOf('month').add(2, 'month'),
  chainInfoList: [],
  chainInfoApproval: null,
  chainInfoSupplementList: [],
  chainInfoSupplementApproval: null,
  salmonellaSampleList: [],
  salmonellaSampleApproval: null,
  sevenDayLossList: [],
  sevenDayLossApproval: null,
  notificationsList: [],
  notificationApproval: null
};

const slice = createSlice({
  name: "approval",
  initialState: initialState,
  reducers: {
    dateFilterChanged: (state, action) => {
      const startDate: moment.Moment = moment(action.payload.startDate);
      const endDate: moment.Moment = moment(action.payload.endDate);
      state.startDate = startDate;
      state.endDate = endDate;
    },
    statusFilterChanged: (state, action) => {
      const status: ApprovalStatus | undefined | null = action.payload.status;
      state.status = status || null;
    },
    chainInfoListReceived: (state, action) => {
      const chainInfoList: ChainInfoListDto[] = action.payload.data;
      state.chainInfoList = chainInfoList;
    },
    chainInfoApprovalReceived: (state, action) => {
      const chainInfoApproval: ChainInfoApprovalDto = action.payload.data;
      state.chainInfoApproval = chainInfoApproval;
    },
    chainInfoSupplementListReceived: (state, action) => {
      const chainInfoSupplementList: ChainInfoSupplementListDto[] = action.payload.data;
      state.chainInfoSupplementList = chainInfoSupplementList;
    },
    chainInfoSupplementApprovalReceived: (state, action) => {
      const chainInfoSupplementApproval: ChainInfoSupplementApprovalDto = action.payload.data;
      state.chainInfoSupplementApproval = chainInfoSupplementApproval;
    },
    salmonellaSampleListReceived: (state, action) => {
      const salmonellaSampleList: SalmonellaSampleListDto[] = action.payload.data;
      state.salmonellaSampleList = salmonellaSampleList;
    },
    salmonellaSampleApprovalReceived: (state, action) => {
      const salmonellaSampleApproval: SalmonellaSampleApprovalDto = action.payload.data;
      state.salmonellaSampleApproval = salmonellaSampleApproval;
    },
    sevenDayLossListReceived: (state, action) => {
      const sevenDayLossList: SevenDayLossListDto[] = action.payload.data;
      state.sevenDayLossList = sevenDayLossList;
    },
    sevenDayLossApprovalReceived: (state, action) => {
      const sevenDayLossApproval: SevenDayLossApprovalDto = action.payload.data;
      state.sevenDayLossApproval = sevenDayLossApproval;
    },
    notificationsListReceived: (state, action) => {
      const notificationsList: NotificationDto[] = action.payload.data;
      state.notificationsList = notificationsList;
    },
    notificationApprovalReceived: (state, action) => {
      const notificationApproval: NotificationApprovalDto = action.payload.data;
      state.notificationApproval = notificationApproval;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  dateFilterChanged,
  statusFilterChanged,
  chainInfoListReceived,
  chainInfoApprovalReceived,
  chainInfoSupplementListReceived,
  chainInfoSupplementApprovalReceived,
  salmonellaSampleListReceived,
  salmonellaSampleApprovalReceived,
  sevenDayLossListReceived,
  sevenDayLossApprovalReceived,
  notificationsListReceived,
  notificationApprovalReceived
} = slice.actions;

export default slice.reducer;
