import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between"
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main
  }
}));

export default useStyles;