import { createSlice } from "@reduxjs/toolkit";
import { BatchNotificationDto, BatchTaskDto, HallBatchDto } from "../models/ApiSchema";

export interface IHome {
  halls: HallBatchDto[];
  tasks: BatchTaskDto[];
  notifications: BatchNotificationDto[];
}

const initialState: IHome = {
  halls: [],
  tasks: [],
  notifications: []
};

const slice = createSlice({
  name: "home",
  initialState: initialState,
  reducers: {
    hallsReceived: (state, action) => {
      state.halls = action.payload.data;
    },
    tasksReceived: (state, action) => {
      state.tasks = action.payload.data;
    },
    notificationsReceived: (state, action) => {
      state.notifications = action.payload.data;
    }
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", (state, action) => {
      return { ...initialState };
    });
  },
});

export const { hallsReceived, tasksReceived, notificationsReceived } = slice.actions;

export default slice.reducer;
