import React from "react";
import { TimePicker as FormikTimePicker } from 'formik-material-ui-pickers';
import { InputAdornment } from "@material-ui/core";

interface TimePickerProps {
  field: any,
  form: any,
  meta: any,
  onChange: any
}

const TimePicker: React.FC<TimePickerProps> = ({ children, ...rest }) => {
  return (
    <FormikTimePicker
      {...rest}
      variant="dialog"
      format="H:mm"
      minutesStep={5}
      autoOk
      invalidDateMessage="Virheellinen kellonaika"
      ampm={false}
      clearable
      cancelLabel="Peruuta"
      okLabel="Aseta"
      clearLabel="Tyhjennä"
      InputProps={{
        endAdornment: <InputAdornment position="end">klo</InputAdornment>
      }}
    >
      {children}
    </FormikTimePicker>
  );
};

export default TimePicker;
