import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backGroundStyles: {
    backgroundImage: `url(${theme.custom.images.Broileri})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
  },
}));

export default useStyles;