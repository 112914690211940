import moment from "moment";
import { BatchDeliveryDto, BreedingBatchDto } from "../models/ApiSchema";

export const calcTotalLoss = (batch: BreedingBatchDto) : number => {
  return batch.chickQuantity - batch.birdsLeft;
};

/* INCORRECT WAY TO CALCULATE, DO NOT USE! */
// export const calcTotalLossPercent = (batch: BreedingBatchDto) : number => {
//   return calcTotalLoss(batch) / (batch.chickQuantity || 1);
// };

export const sevenDayLossStartDate = (batch: BreedingBatchDto) : moment.Moment => {
  return moment(batch.arrivalDate).startOf('day').add(8, 'days');
};

export const sevenDayLossEndDate = (batch: BreedingBatchDto) : moment.Moment => {
  return moment(batch.arrivalDate).startOf('day').add(15, 'days');
};

export const salmonellaSampleStartDate = (batch: BreedingBatchDto, withException: boolean = false) : moment.Moment => {
  let start = moment(batch.slaughterDate).startOf('day').subtract(withException ? 21 : 18, 'days');
  if (start.day() === 5) start.add(3, 'days');
  if (start.day() === 6) start.add(2, 'days');
  if (start.day() === 0) start.add(1, 'days');
  return start;
};

export const salmonellaSampleEndDate = (batch: BreedingBatchDto) : moment.Moment => {
  let end = moment(batch.slaughterDate).startOf('day').subtract(14, 'days');
  if (end.day() === 0) end.subtract(3, 'days');
  if (end.day() === 6) end.subtract(2, 'days');
  if (end.day() === 5) end.subtract(1, 'days');
  return end;
};

export const chainInfoStartDate = (batch: BreedingBatchDto) : moment.Moment => {
  return moment(batch.slaughterDate).startOf('day').subtract(3, 'days');
};

export const chainInfoEndDate = (batch: BreedingBatchDto) : moment.Moment => {
  const date = moment(batch.slaughterDate).startOf('day');
  if (date.day() === 1)
    return date.subtract(3, 'days').add(11, 'hours');
  else
    return date.subtract(1, 'days').add(11, 'hours');
};

export const supplementStartDate = (batch: BreedingBatchDto) : moment.Moment => {
  return moment(batch.slaughterDate).startOf('day');
};

export const supplementEndDate = (batch: BreedingBatchDto) : moment.Moment => {
  const date = moment(batch.slaughterDate).startOf('day');
  if (date.day() === 5)
    return date.add(3, 'days').add(11, 'hours');
  else
    return date.add(1, 'days').add(11, 'hours');
};

export const deliveredAmountByDate = (deliveries: BatchDeliveryDto[], date: moment.Moment) : number => {
  return deliveries
    .filter((d) => d.actualArrivalTime && moment(d.actualArrivalTime).isSameOrBefore(date, 'day'))
    .map(d => d.sentQuantity - d.transportMortality)
    .reduce((p, c) => p + c, 0);
};

const batchHelper = {
  calcTotalLoss,
  // calcTotalLossPercent,
  sevenDayLossStartDate,
  sevenDayLossEndDate,
  salmonellaSampleStartDate,
  salmonellaSampleEndDate,
  chainInfoStartDate,
  chainInfoEndDate,
  supplementStartDate,
  supplementEndDate,
  deliveredAmountByDate
};
export default batchHelper;
