import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import Number from "../Formatters/Number";
import { FeedInfoGrainDto } from "../../models/ApiSchema";

interface GrainTableProps {
  grains: FeedInfoGrainDto[];
}

const GrainTable: React.FC<GrainTableProps> = ({grains}) => {

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="row">Vilja</TableCell>
            {grains.map((g, i) => <TableCell key={i} component="th" align="center">{i + 1}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">Lajike</TableCell>
            {grains.map((g, i) => <TableCell key={i} component="td" align="center">{g.strain || "-"}</TableCell>)}
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Valkuaisaine-%</TableCell>
            {grains.map((g, i) => <TableCell key={i} component="td" align="center"><Number value={g.proteinPercent} empty="-" /></TableCell>)}
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">Hehtolitrapaino</TableCell>
            {grains.map((g, i) => <TableCell key={i} component="td" align="center"><Number value={g.hectoLiterWeight} empty="-" /></TableCell>)}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GrainTable;
