import { Box, InputAdornment } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { diaryFormData, numberEmptyToNull } from '../../../../shared/helpers/formHelper';

interface WeightFormProps {
  formId: string;
  values?: any;
  onSubmit: any;
}

const WeightForm: React.FC<WeightFormProps> = ({formId, values, onSubmit}) => {

  const initialValues = {
    liveWeightGrams: ""
  }

  const formSchema = Yup.object().shape({
    liveWeightGrams: Yup
      .number()
      .transform(numberEmptyToNull)
      .integer()
      .nullable()
      .min(0)
      .max(100000)
  });

  return (
      <Formik
        enableReinitialize
        initialValues={values ? diaryFormData({...values}) : initialValues}
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          const data = formSchema.cast(values, { stripUnknown: true });
          actions.setSubmitting(false);
          actions.resetForm({ values: initialValues });
          onSubmit(data);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form id={formId}>
            <Box display="flex" flexDirection="column">
              <Field
                component={TextField}
                label="Lintujen keskipaino"
                name="liveWeightGrams"
                margin="normal"
                autoFocus={true}
                InputProps={{
                  type: "number",
                  autoComplete: "off",
                  endAdornment: <InputAdornment position="end">grammaa</InputAdornment>,
                  inputProps: { step: 1 }
                }}
              />
            </Box>
          </Form>
        )}
      </Formik>
  );
};

export default WeightForm;
