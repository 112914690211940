import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import authenticator, {b2cPolicies} from "../services/authService";
import { producerNumberUpdated } from "../store/ui/settings";
import { userUpdated } from "../store/auth";
import LoadingIndicator from "../components/LoadingIndicator/LoadingIndicator";

interface IAuth {
  isAuthenticated: boolean;
  renewIframe: boolean;
  hasError: boolean;
  error: any;
}

export const withAuth = (Component) => {
  return (props) => {
    const dispatch = useDispatch();
    const [auth, setAuth] = useState<IAuth>({
      isAuthenticated: false,
      renewIframe: false,
      hasError: false,
      error: null
    });

    const msal = async () => {
      authenticator.handleRedirectCallback(resp => {
        console.debug('MSAL RESPONSE:', resp);
        if (resp !== null) {
          if (resp.idTokenClaims.acr.match(new RegExp(b2cPolicies.names.forgotPassword, "i"))) {
            authenticator.logout();
          }
          else {
            const userAccount = authenticator.getAccount(resp);
            setAuth(prevState => ({
              ...prevState,
              isAuthenticated: true
            }));
            dispatch(userUpdated({ currentUser: userAccount, account: resp.account }));
            if (userAccount.producers && userAccount.producers.length > 0)
              dispatch(producerNumberUpdated({ producerNumber: userAccount.producers[0].Number }));
          }
        }
        else {
          const account = authenticator.getMsalAccount();
          if (!account) {
            authenticator.loginRedirect();
          } else {
            setAuth(prevState => ({
              ...prevState,
              isAuthenticated: true
            }));
          }
        }
      }, (authErr: any, accountState) => {
        console.error("MSAL ERROR", authErr, accountState);
        setAuth(prevState => ({
          ...prevState,
          hasError: true,
          error: authErr
        }));
      });
    };

    useEffect(() => {
      msal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (auth.renewIframe) {
      return (
        <div>hidden renew iframe - not visible</div>
      );
    }

    if (auth.hasError) {
      // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes

      if (auth.error.errorMessage.match("AADB2C90091"))
        authenticator.loginRedirect();

      if (auth.error.errorCode.match("server_error"))
        authenticator.logout();
    }

    if (auth.isAuthenticated) {
      return (
        <Component {...props} />
      );
    }

    return (
      <LoadingIndicator showAlwaysLoadingIndicator={true} />
    );
  };
};
