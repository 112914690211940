import React, { Fragment, useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { IconButton } from "@material-ui/core";
import ChainInfoSupplementApprovalModal from "./ChainInfoSupplementApprovalModal";
import DescriptionIcon from '@material-ui/icons/Description';
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../shared/store/apiActions";
import { IRootState } from "../../../shared/store/rootReducer";
import ApprovalStatusSetMenu from "../../../shared/components/ApprovalStatus/ApprovalStatusSetMenu";
import { ViewName } from "../../../shared/models/ApiSchema";
import agGridHelper from "../../../shared/helpers/agGridHelper";
import moment from "moment";
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { approvalStatusText } from "../../../shared/helpers/statusHelper";
import CheckIcon from '@material-ui/icons/Check';
import { ColDef } from "ag-grid-community";

interface ChainInfoSupplementApprovalProps {

}

const ChainInfoSupplementApproval: React.FC<ChainInfoSupplementApprovalProps> = () => {

  const access = useSelector((state: IRootState) => state.user.access);
  const status = useSelector((state: IRootState) => state.approval.status);
  const startDate = useSelector((state: IRootState) => state.approval.startDate);
  const endDate = useSelector((state: IRootState) => state.approval.endDate);
  const chainInfoSupplements = useSelector((state: IRootState) => state.approval.chainInfoSupplementList);
  const dispatch = useDispatch();

  const [openApprovalModal, setApprovalModalOpen] = useState<boolean>(false);
  const handleApprovalModalClose = (newStatus) => {
    setApprovalModalOpen(false);
    if (newStatus) {
      dispatch(api.approval.getChainInfoSupplementList(startDate, endDate, status || undefined));
    }
  };

  useEffect(() => {
    dispatch(api.approval.getChainInfoSupplementList(startDate, endDate, status || undefined));
  }, [status, startDate, endDate, dispatch]);

  const openApproval = (id: string) => {
    dispatch(api.approval.getChainInfoSupplementApproval(id));
    setApprovalModalOpen(true);
  }

  const redraw = (e) => {
    e.api.redrawRows();
  }

  const timeDiffFormatter = (v: any) => {
    const fastingStartTime = moment(v.data.fastingStartTime);
    const slaughterStartTime = moment(v.data.slaughterStartTime);
    const diff = slaughterStartTime.diff(fastingStartTime, "minutes");

    const days = Math.floor(diff / 1440);
    const hours = Math.floor((diff % 1440) / 60);
    const minutes = Math.floor((diff % 60));

    return `${days === 0 ? "" : days + " vrk"} ${hours === 0 ? "" : hours + " h"} ${minutes === 0 ? "" : minutes + " min"}`;
  };

  const defaultColDef: ColDef = {
    cellStyle: { textAlign: "center" },
    resizable: true,
    sortable: true,
    editable: false,
    suppressMenu: true,
    suppressMovable: true,
    suppressNavigable: true
  };

  return (
    <Fragment>
      <div className="diary-grid ag-theme-balham" style={{height: "74vh", width: "99.99%"}}>
        <AgGridReact
          rowData={chainInfoSupplements}
          defaultColDef={defaultColDef}
          suppressCellSelection={true}
          applyColumnDefOrder={true}
          immutableData={true}
          getRowNodeId={(row) => row.id}
          onRowDataChanged={redraw}
        >
          <AgGridColumn headerName="Ketjuinformaation lisäsivu">
            <AgGridColumn
              field="breedingId"
              headerName="Erä id"
              width={80}
              pinned="left"
            />
            <AgGridColumn
              field="producerName"
              headerName="Tuottaja"
              minWidth={160}
              maxWidth={320}
              pinned="left"
            />
            <AgGridColumn
              field="hallNumber"
              headerName="Halli"
              width={60}
              cellStyle={{fontWeight:600}}
              pinned="left"
            />
            {access?.approvalStatusEnabled?.ChainInfoSupplement && <AgGridColumn
              field="approvalStatus"
              headerName="Status"
              width={100}
              pinned="left"
              valueFormatter={v => approvalStatusText(v.value)}
            />}
            {access?.approvalStatusEnabled?.ChainInfoSupplement && access?.hasApprovalRole && <AgGridColumn
              width={48}
              sortable={false}
              pinned="left"
              cellRendererFramework={p => <ApprovalStatusSetMenu current={p.data.approvalStatus} view={ViewName.ChainInfoSupplement} id={p.data.id} />}
            />}
          </AgGridColumn>
          <AgGridColumn headerName="Tiedot">
            <AgGridColumn
              field="approvalAuditUser"
              headerName="Lähettäjä/tarkastaja/hyväksyjä"
              width={200}
              valueGetter={(p) => p.data.approvalAuditUser || p.data.createdBy}
            />
            <AgGridColumn
              field="reportCreateTime"
              headerName="Lähetetty"
              valueFormatter={agGridHelper.dateTimeFormatter}
              width={120}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Poistuma">
            <AgGridColumn
              field="loadingMortalityPercent"
              headerName="Lastaus-%"
              width={90}
              valueFormatter={agGridHelper.decimalFormatter}
            />
            <AgGridColumn
              field="cumulativeLossPercent"
              headerName="Kokonais-%"
              width={90}
              valueFormatter={agGridHelper.decimalFormatter}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Teurastus">
            <AgGridColumn
              field="slaughterDate"
              headerName="Päivä"
              width={90}
              valueFormatter={agGridHelper.dateFormatter}
            />
            <AgGridColumn
              headerName="Lähet. määrä"
              width={100}
              field="slaughterSentQuantity"
            />
          </AgGridColumn>
          <AgGridColumn headerName="Kokonaiskulutus">
            <AgGridColumn
              field="feedAndGrainConsumptionKg"
              headerName="Rehu+vilja kg"
              width={110}
            />
            <AgGridColumn
              field="grainPercent"
              headerName="Vilja-%"
              width={80}
              valueFormatter={(p) => agGridHelper.decimalFormatter(p, 2)}
            />
            <AgGridColumn
              field="waterConsumptionLiter"
              headerName="Vesi L"
              width={80}
            />
            <AgGridColumn
              headerName="Vesi/rehusuhde"
              width={110}
              valueGetter={(p) => p.data.waterConsumptionLiter / (p.data.feedAndGrainConsumptionKg || 1)}
              valueFormatter={(p) => agGridHelper.decimalFormatter(p, 2)}
            />
            <AgGridColumn
              headerName="Laskennallinen RHS"
              width={130}
              valueGetter={(p) => p.data.feedAndGrainConsumptionKg / (((p.data.estimatedSlaughterWeightGrams / 1000) * p.data.slaughterSentQuantity) || 1)}
              valueFormatter={(p) => agGridHelper.decimalFormatter(p, 3)}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Paasto">
            <AgGridColumn
              field="fastingStartTime"
              headerName="Aloitusaika"
              width={120}
              valueFormatter={agGridHelper.dateTimeFormatter}
            />
            <AgGridColumn
              headerName="Paastoaika"
              width={110}
              valueFormatter={timeDiffFormatter}
            />
          </AgGridColumn>
          <AgGridColumn
            headerName="Huomioita"
            width={100}
            sortable={false}
            cellRendererFramework={p => p.data.hasNotes ? <CheckIcon /> : null}
          />
          <AgGridColumn
            width={48}
            sortable={false}
            pinned="right"
            cellRendererFramework={p => <IconButton size="small" onClick={() => openApproval(p.data.id)}><DescriptionIcon style={{ fontSize: 18 }} /></IconButton>}
          />
        </AgGridReact>
      </div>
      <ChainInfoSupplementApprovalModal open={openApprovalModal} onClose={handleApprovalModalClose} />
    </Fragment>
  );
};

export default ChainInfoSupplementApproval;