import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApprovalStatus, ViewName } from "../../models/ApiSchema";
import { IRootState } from "../../store/rootReducer";
import { useConfirm } from 'material-ui-confirm';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ApprovalStatusLabel from "../Statuses/ApprovalStatus";
import * as api from "../../../shared/store/apiActions";
import { canAccessApprovalView } from "../../helpers/accessHelper";

interface ApprovalStatusSetMenuProps {
  current: ApprovalStatus,
  view: ViewName,
  id: string
}

const _ApprovalStatusSetMenu: React.FC<ApprovalStatusSetMenuProps> = ({ current, view, id }) => {

  const startDate = useSelector((state: IRootState) => state.approval.startDate);
  const endDate = useSelector((state: IRootState) => state.approval.endDate);
  const access = useSelector((state: IRootState) => state.user.access);
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [states, setStates] = useState<ApprovalStatus[]>([]);

  useEffect(() => {
    if (current && access?.approvalStatusTransitions) {
      setStates(access.approvalStatusTransitions[view][current] || []);
    } else {
      setStates([]);
    }
  }, [current, view, access]);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleState = (event, state: ApprovalStatus | null = null) => {
    setAnchorEl(null);
    if (state) {
      if (view === ViewName.ChainInfo) {
        confirm({ description: <span>Ketjuinformaatiolomake asetetaan tilaan <ApprovalStatusLabel status={state} chip={false} />.</span> }).then(() => {
          dispatch(api.approval.updateChainInfoApprovalStatusOnGrid(id, state, startDate, endDate));
        });
      }
      if (view === ViewName.ChainInfoSupplement) {
        confirm({ description: <span>Ketjuinformaation lisäsivu asetetaan tilaan <ApprovalStatusLabel status={state} chip={false} />.</span> }).then(() => {
          dispatch(api.approval.updateChainInfoSupplementApprovalStatusOnGrid(id, state, startDate, endDate));
        });
      }
      if (view === ViewName.SevenDayLoss) {
        confirm({ description: <span>7 päivän poistumailmoitus asetetaan tilaan <ApprovalStatusLabel status={state} chip={false} />.</span> }).then(() => {
          dispatch(api.approval.updateSevenDayLossApprovalStatusOnGrid(id, state, startDate, endDate));
        });
      }
      if (view === ViewName.SalmonellaSample) {
        confirm({ description: <span>Salmonellanäytteen kirjaus asetetaan tilaan <ApprovalStatusLabel status={state} chip={false} />.</span> }).then(() => {
          dispatch(api.approval.updateSalmonellaSampleApprovalStatusOnGrid(id, state, startDate, endDate));
        });
      }
      if (view === ViewName.Notification) {
        confirm({ description: <span>Ilmoitus asetetaan tilaan <ApprovalStatusLabel status={state} chip={false} />.</span> }).then(() => {
          dispatch(api.approval.updateNotificationApprovalStatusOnGrid(id, state, startDate, endDate));
        });
      }
    }
  };

  if (!access || !access?.hasApprovalRole || !canAccessApprovalView(access, view)) {
    return null;
  }

  if (!states?.length) {
    return null;
  }

  return (
    <Fragment>
      <IconButton size="small" color="primary" onClick={openMenu}>
        <MoreVertIcon style={{ fontSize: 18 }} />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted={false} open={open} onClick={(event) => handleState(event)}>
        {states.map((state, index) => (
          <MenuItem key={index} onClick={(event) => handleState(event, state)}>
            <ApprovalStatusLabel status={state} chip={false} />
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export const ApprovalStatusSetMenu = React.memo(_ApprovalStatusSetMenu);
export default ApprovalStatusSetMenu;
