import React from "react";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto } from "../../../../shared/models/ApiSchema";
import Date from "../../../../shared/components/Formatters/Date";

interface BatchHeaderProps {
  batch: BreedingBatchDto;
}

const BatchHeader: React.FC<BatchHeaderProps> = ({batch}) => {
  return (
    <PageHeader level="3" component="span">
      Halli {batch.hall?.number}: <Date value={batch.arrivalDate} year={false} /> - <Date value={batch.slaughterDate} year={true} />
    </PageHeader>
  );
};

export default BatchHeader;
