import { IconButton, Toolbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import useStyles from "./HallSwitcherStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, BreedingBatchStatus } from "../../../../shared/models/ApiSchema";

interface HallOption {
  label: string;
  id: string;
}

interface HallSwitcherProps {
  options: HallOption[];
  selected: BreedingBatchDto;
  onChange: any;
}

const HallSwitcher: React.FC<HallSwitcherProps> = ({options, onChange, selected}) => {
  const classes = useStyles();

  const [current, setCurrent] = useState(-1);

  useEffect(() => {
    if (selected) {
      const index = options.map(e => e.id).indexOf(selected.id);
      setCurrent(index);
    }
  }, [options, selected]);

  const next = () => {
    if (current < (options.length - 1)) {
      onChange(options[current + 1]);
    }
  }

  const prev = () => {
    if (current > 0) {
      onChange(options[current - 1]);
    }
  }

  if (current < 0) return (
    <Toolbar variant="dense" className={classes.toolbar}>
      <div />
      <PageHeader level="3" component="div">
        {selected.status === BreedingBatchStatus.Planned && <span>Tulossa (halli {selected.hall?.number})</span>}
        {selected.status === BreedingBatchStatus.Active && <span>Käynnissä (halli {selected.hall?.number})</span>}
        {selected.status === BreedingBatchStatus.Completed && <span>Päätetty (halli {selected.hall?.number})</span>}
      </PageHeader>
      <div />
    </Toolbar>
  );

  return (
    <Toolbar variant="dense" className={classes.toolbar}>
      <IconButton disabled={current === 0} edge="start" color="primary" onClick={prev}>
        <NavigateBeforeIcon />
      </IconButton>
      <PageHeader level="2" component="div">{options[current].label}</PageHeader>
      <IconButton disabled={current === (options.length - 1)} edge="end" color="primary" onClick={next}>
        <NavigateNextIcon />
      </IconButton>
    </Toolbar>
  );
};

export default HallSwitcher;
