import { createTheme } from '@material-ui/core/styles'
// import { createMuiTheme } from "@material-ui/core";
// import { unstable_createMuiStrictModeTheme } from "@material-ui/core";
import AvenirNextW1GBold from "../../assets/font/AvenirNextW1G-Bold.otf";
import AvenirNextW1GMedium from "../../assets/font/AvenirNextW1G-Medium.otf";
import AvenirNextW1GRegular from "../../assets/font/AvenirNextW1G-Regular.otf";
import PoultryImage from "../../assets/images/Poultry_picture.jpg";

const avenirNextW1GBold = {
  fontFamily: "Avenir Next W1G Bold",
  fontWeight: "bolder",
  fontStyle: "normal",
  src: `url(${AvenirNextW1GBold})`,
};

const avenirNextW1GMedium = {
  fontFamily: "Avenir Next W1G Medium",
  fontWeight: "500",
  fontStyle: "normal",
  src: `url(${AvenirNextW1GMedium})`,
};

const avenirNextW1GRegular = {
  fontFamily: "Avenir Next W1G",
  fontWeight: "normal",
  fontStyle: "normal",
  src: `url(${AvenirNextW1GRegular})`,
};

// https://github.com/mui-org/material-ui/issues/13394
// const createTheme = process.env.NODE_ENV === 'production' ? createMuiTheme : unstable_createMuiStrictModeTheme;

const theme = createTheme({
  props: {
    MuiPaper: {
      elevation: 1,
      square: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    }
  },
  palette: {
    primary: {
      main: "#152B62",
    },
    secondary: {
      main: "#0077C6",
    },
  },
  typography: {
    fontFamily: "Avenir Next W1G",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          avenirNextW1GRegular,
          avenirNextW1GMedium,
          avenirNextW1GBold,
        ],
        ".MuiGrid-container a, .MuiGrid-container a:hover, .MuiGrid-container a:visited": {
          color: "#0077C6",
        },
        ".MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected": {
          color: 'white',
          backgroundColor: "#152B62"
        },
        ".MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected:hover": {
          color: 'white',
          backgroundColor: "#0077C6"
        }
      },
    },
  },
  custom: {
    images: {
      Broileri: PoultryImage,
    },
  }
});

export default theme;
