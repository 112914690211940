import { Box, IconButton, Menu, MenuItem, Toolbar, useMediaQuery, useTheme } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import useStyles from "./DaySwitcherStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import moment from "moment";
import { changeDate, findDiaryIndexByDate } from "../../../../shared/store/batch";
import Date from "../../../../shared/components/Formatters/Date";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../../shared/store/rootReducer";

const DaySwitcher: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const dispatch = useDispatch();
  const selected = useSelector((state: IRootState) => state.batch.date);
  const diaries = useSelector((state: IRootState) => state.batch.diaries);

  const [current, setCurrent] = useState<number | null>(null);
  const [now, setNow] = useState<moment.Moment>(moment());

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openJump = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleJump = (event, date: moment.Moment | null = null) => {
    setAnchorEl(null);
    if (date != null) {
      dispatch(changeDate({ date: date }));
    }
  };

  useEffect(() => {
    setNow(moment());
    if (selected && diaries.length) {
      const index = findDiaryIndexByDate(diaries, selected);
      setCurrent(index >= 0 ? index : null);
    }
  }, [selected, diaries]);

  const next = () => {
    if (current != null && current < diaries.length - 1) {
      const _date = moment(diaries[current].date).add(1, 'day');
      dispatch(changeDate({ date: _date }));
    }
  }

  const prev = () => {
    if (current != null && current > 0) {
      const _date = moment(diaries[current].date).subtract(1, 'day');
      dispatch(changeDate({ date: _date }));
    }
  }

  const prevDisabled = () => current == null || current <= 0;
  const nextDisabled = () => current == null || current >= diaries.length -1;

  return (
    <Fragment>
      <Toolbar disableGutters={true} variant="dense" className={classes.toolbar}>
        <IconButton edge={isSmall ? 'start' : false} disabled={prevDisabled()} color="primary" onClick={prev}>
          <NavigateBeforeIcon />
        </IconButton>
        <Box display='flex' flexGrow={1} alignItems="center" className={classes.title}>
          {current != null && <PageHeader level="4" component="div">
            Päivä {current} / {diaries.length - 1}
          </PageHeader>}
          <IconButton color="primary" onClick={openJump}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </Box>
        <IconButton edge={isSmall ? 'end' : false} disabled={nextDisabled()} color="primary" onClick={next}>
          <NavigateNextIcon  />
        </IconButton>
      </Toolbar>
      <Menu id="day-jump-menu" anchorEl={anchorEl} keepMounted open={open} onClick={(event) => handleJump(event)}
        PaperProps={{ style: { maxHeight: 48 * 8 } }}
      >
        {diaries.map((diary, index) => (
          <MenuItem key={index} onClick={(event) => handleJump(event, moment(diary.date))}>
            <strong>{diary.day}:</strong>&nbsp;
            {now.isSame(moment(diary.date), 'day') ? <strong>Tänään</strong> : <Date value={diary.date} />}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default DaySwitcher;
