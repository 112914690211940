import { Box, IconButton, Paper, Toolbar, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { BreedingBatchDto, PoultryDiaryDto } from "../../../../shared/models/ApiSchema";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import BatchLossChart from "../../../../shared/components/Charts/BatchLossChart";
import BatchConsumptionChart from "../../../../shared/components/Charts/BatchConsumptionChart";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import useStyles from "./BatchChartStyles";

interface BatchChartsProps {
  batch: BreedingBatchDto;
  diaries: PoultryDiaryDto[];
  height?: number;
}

const charts = [
  { title: 'Erän rehun- ja vedenkulutus' },
  { title: 'Erän poistumat' }
];

const BatchCharts: React.FC<BatchChartsProps> = ({batch, diaries, height = 250}) => {

  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const [chartIndex, setChartIndex] = React.useState(0);

  const next = () => {
    setChartIndex(Math.abs(chartIndex + 1) % charts.length);
  }
  const prev = () => {
    setChartIndex(Math.abs(chartIndex - 1) % charts.length);
  }

  return (
    <Box component={Paper} p={2} pt={1} pb={1}>
      <Toolbar disableGutters={true} variant="dense" className={classes.toolbar}>
        <IconButton edge={isSmall ? 'start' : false} color="primary" onClick={prev}>
          <NavigateBeforeIcon />
        </IconButton>
        <Box display='flex' flexGrow={1} alignItems="center" className={classes.title}>
          <PageHeader level="3" component="div">
            {charts[chartIndex].title}
          </PageHeader>
        </Box>
        <IconButton edge={isSmall ? 'end' : false} color="primary" onClick={next}>
          <NavigateNextIcon  />
        </IconButton>
      </Toolbar>
      <Box height={height}>
        {chartIndex === 0 && <BatchConsumptionChart diaries={diaries} batch={batch} />}
        {chartIndex === 1 && <BatchLossChart diaries={diaries} batch={batch} />}
      </Box>
    </Box>
  )
};

export default BatchCharts;
