import { NotificationCreateDto, PoultryDiaryDto, PoultryDiaryUpdateDto, PoultryLossAddDto, PoultryLossDto, PoultryLossSetDto } from "../models/ApiSchema";
import http from "./httpService";

const API_URL = `diaries`;

export const updatePoultryDiary = async (poultryDiaryId: string, diary: PoultryDiaryUpdateDto) => {
  const resp = await http.put<PoultryDiaryDto>(`${API_URL}/${poultryDiaryId}`, diary);
  return resp;
};

export const addPoultryLoss = async (poultryDiaryId: string, loss: PoultryLossAddDto) => {
  const resp = await http.post<PoultryLossDto>(`${API_URL}/${poultryDiaryId}/loss`, loss);
  return resp;
};

export const setPoultryLoss = async (poultryDiaryId: string, loss: PoultryLossSetDto) => {
  const resp = await http.put<void>(`${API_URL}/${poultryDiaryId}/loss`, loss);
  return resp;
};

export const deletePoultryLoss = async (poultryLossId: string) => {
  const resp = await http.delete(`${API_URL}/loss/${poultryLossId}`);
  return resp;
};

export const addNotification = async (poultryDiaryId: string, notification: NotificationCreateDto) => {
  const resp = await http.post<void>(`${API_URL}/${poultryDiaryId}/notification`, notification);
  return resp;
};

const poultryDiaryService = {
  updatePoultryDiary,
  addPoultryLoss,
  setPoultryLoss,
  deletePoultryLoss,
  addNotification
};
export default poultryDiaryService;
