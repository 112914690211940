import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BatchDatesUpdateDto, BreedingBatchDto, BreedingBatchStatus } from "../../../../shared/models/ApiSchema";
import moment from "moment";
import * as Yup from 'yup';
import { Field, Form, Formik } from "formik";
import DatePicker from "../../../../shared/components/DatePicker/DatePicker";
import { dateOnly } from "../../../../shared/helpers/formHelper";
import BatchHeader from "../BatchHeader/BatchHeader";

interface UpdateBatchDatesModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
}

const UpdateBatchDatesModal: React.FC<UpdateBatchDatesModalProps> = ({ open, onClose, batch }) => {
  const classes = useStyles();

  const initialValues: BatchDatesUpdateDto = {
    arrivalDate: moment(batch.arrivalDate).toISOString(),
    slaughterDate: moment(batch.slaughterDate).toISOString()
  };

  const formSchema = Yup.object().shape({
    arrivalDate: Yup
      .date()
      .required()
      .transform(dateOnly)
      .max(Yup.ref('slaughterDate'), "Saapumispäivän oltava teuraspäivää aikaisempi"),
    slaughterDate: Yup
      .date()
      .required()
      .transform(dateOnly)
      .min(Yup.ref('arrivalDate'), "Teuraspäivän oltava saapumispäivää myöhempi"),
  });

  const handleCancel = () => {
    onClose(null);
  };

  const handleSubmit = (data) => {
    onClose(data);
  };

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="xs"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">Erän {batch.breedingId} päivät</PageHeader>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values, actions) => {
            const data = formSchema.cast(values, { stripUnknown: true });
            actions.setSubmitting(false);
            handleSubmit(data);
          }}
        >
          {({ submitForm, setFieldValue }) => (
            <Form id="updateBatchDatesForm">
              <Box display="flex" flexDirection="column">
                <Field
                  component={DatePicker}
                  label="Saapumispäivä"
                  name="arrivalDate"
                  fullWidth
                  margin="normal"
                  disabled={batch.status !== BreedingBatchStatus.Planned}
                />
                <Field
                  component={DatePicker}
                  label="Teurastuspäivä"
                  name="slaughterDate"
                  fullWidth
                  margin="normal"
                  disabled={batch.status === BreedingBatchStatus.Completed}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>Peruuta</Button>
        <Button variant="text" type="submit" form="updateBatchDatesForm">Tallenna</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateBatchDatesModal;
