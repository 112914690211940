import { Box, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, useMediaQuery, useTheme } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Content from "../../shared/components/Content/Content";
import useStyles from "./BatchStyles";
import { IRootState } from "../../shared/store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import moment from "moment";
import * as api from "../../shared/store/apiActions";
import { Alert } from "@material-ui/lab";
import HallSwitcher from "./components/HallSwitcher/HallSwitcher";
import { findDiaryIndexByDate } from "../../shared/store/batch";
import BatchDiary from "./components/BatchDiary/BatchDiary";
import BatchDetails from "./components/BatchDetails/BatchDetails";
import BatchTrend from "./components/BatchTrend/BatchTrend";
import { BreedingBatchStatus, PoultryDiaryDto } from "../../shared/models/ApiSchema";
import DiaryGridModal from "./components/DiaryGridModal/DiaryGridModal";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import ActivateBatchModal from "./components/ActivateBatchModal/ActivateBatchModal";
import BatchTasks from "./components/BatchTasks/BatchTasks";
import ProducerName from "./components/ProducerName/ProducerName";
import { highlight } from "../../shared/helpers/guiHelper";
import BatchCharts from "./components/BatchCharts/BatchCharts";

const Batch = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const now = moment();

  const dispatch = useDispatch();
  const { batchId } = useParams<{ batchId: string }>();

  const [diary, setDiary] = useState<PoultryDiaryDto | null>(null);
  const [hallList, setHallList] = useState<any[]>([]);

  const halls = useSelector((state: IRootState) => state.home.halls);
  const batch = useSelector((state: IRootState) => state.batch.batch);
  const deliveries = useSelector((state: IRootState) => state.batch.deliveries);
  const dates = useSelector((state: IRootState) => state.batch.dates);
  const reports = useSelector((state: IRootState) => state.batch.reports);
  const hall = useSelector((state: IRootState) => state.batch.hallInfo);
  const feed = useSelector((state: IRootState) => state.batch.feedInfo);
  const health = useSelector((state: IRootState) => state.batch.healthInfo);
  const date = useSelector((state: IRootState) => state.batch.date);
  const diaries = useSelector((state: IRootState) => state.batch.diaries);

  const [openDiaryGridModal, setDiaryGridModalOpen] = useState(false);
  const handleDiaryGridModalClose = (changed) => {
    setDiaryGridModalOpen(false);
    if (changed) {
      dispatch(api.batch.getBreedingBatch(batchId));
    }
  };

  const [openActivateBatchModal, setActivateBatchModalOpen] = useState(false);
  const handleActivateBatchModalClose = (data) => {
    setActivateBatchModalOpen(false);
    if (data && batch) {
      dispatch(api.batch.activateBreedingBatch(batch.id, data));
    }
  };

  useEffect(() => {
    if (!halls?.length) {
      setHallList([]);
      dispatch(api.home.getHallsWithCurrentBatch());
    }
    else {
      setHallList(halls?.filter(h => { return h.batchId != null })
        .map(h => { return { id: h.batchId, label: `Halli ${h.hallNumber}` }}) || []
      );
    }
  }, [halls, dispatch]);

  useEffect(() => {
    if (batchId) {
      dispatch(api.batch.getBreedingBatch(batchId));
    }
  }, [batchId, dispatch]);

  useEffect(() => {
    if (batch && date) {
      const index = findDiaryIndexByDate(diaries, date);
      if (index >= 0) {
        setDiary(diaries[index]);
      }
      else {
        setDiary(null);
      }
    }
  }, [dispatch, batch, diaries, date]);

  const hallChanged = (hall) => {
    if (hall && hall.id && hall.id !== batch?.id) {
      history.push(`/batch/${hall.id}`);
    }
  }

  if (!batch) {
    return <Fragment></Fragment>;
  }

  const topControls = (
    <Fragment>
      <Grid container spacing={isSmall ? 0 : 2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          {batch && <Paper>
            <HallSwitcher selected={batch} options={hallList} onChange={hallChanged}></HallSwitcher>
          </Paper>}
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={8}>
          {batch && <Paper>
            <ProducerName batch={batch} />
          </Paper>}
        </Grid>
      </Grid>
      {!isSmall && batch.status !== BreedingBatchStatus.Planned && date != null && diaries && <Box pt={isSmall ? 0 : 2}>
        <Paper>
          <BatchTrend diaries={diaries} days={3} current={date} initialFeed={batch.feedBeforeArrivalKg || 0} initialBirds={batch.chickQuantity} />
        </Paper>
      </Box>}
    </Fragment>
  );

  return (
    <Content backGroundStyles={classes.backGroundStyles} gridContainerStyles={classes.gridContainerStyles} top={topControls} container={false}>
      <div id="breeding-batch">
      <Grid container spacing={isSmall ? 0 : 2} alignItems="flex-start">
        <Grid item xs={12} sm={6} md={6} lg={4} style={{ order: isSmall ? 2 : 1}}>
          <BatchDetails batch={batch} deliveries={deliveries} diaries={diaries} hall={hall} feed={feed} health={health} reports={reports} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} style={{ order: isSmall ? 1 : 2}}>
          { batch.status !== BreedingBatchStatus.Planned && diary &&
            <Fragment>
              <BatchDiary batch={batch} diary={diary} openGrid={() => setDiaryGridModalOpen(true)} />
              <DiaryGridModal open={openDiaryGridModal} onClose={handleDiaryGridModalClose} batch={batch} diaries={diaries} initialFeed={batch.feedBeforeArrivalKg || 0} />
            </Fragment>
          }
          { batch.status !== BreedingBatchStatus.Planned && !diary &&
            <Box component={Paper} p={2} pb={1}>
              <PageHeader level="3" component="div">Päiväkirja</PageHeader>
              <Box my={1}>
                <Alert severity="error">Päiväkirjaa ei löydy!</Alert>
              </Box>
            </Box>
          }
          { batch.status === BreedingBatchStatus.Planned &&
            <Box component={Paper} p={2} pb={1}>
              <PageHeader level="3" component="div">Kasvatuspäiväkirja</PageHeader>
              { moment(batch.arrivalDate).isSameOrBefore(now, 'day') &&
                <List dense>
                  <ListItem key="activate" style={highlight(moment(batch.arrivalDate).isBefore(now, 'day'))} disableGutters>
                    <ListItemText
                      primary="Erän vastaanotto"
                      secondary="Kirjaa erä saapuneeksi"
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="Aktivointi" onClick={() => setActivateBatchModalOpen(true)}>
                        <PlayCircleFilledWhiteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              }
              { moment(batch.arrivalDate).isAfter(now, 'day') &&
                <Box py={1}>
                  <Alert severity="info">Päiväkirjan voi aloittaa saapumispäivänä</Alert>
                </Box>
              }
              <ActivateBatchModal open={openActivateBatchModal} onClose={handleActivateBatchModalClose} batch={batch} delivery={deliveries[0] || null} />
            </Box>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} style={{ order: 3}}>
          { dates && batch.status !== BreedingBatchStatus.Planned &&
          <Grid container spacing={isSmall ? 0 : 2} alignItems="flex-start">
            <Grid item xs={12}>
              <BatchCharts diaries={diaries} batch={batch} />
            </Grid>
            <Grid item xs={12}>
              <BatchTasks batch={batch} dates={dates} reports={reports} />
            </Grid>
          </Grid>
          }
          { batch.status === BreedingBatchStatus.Planned &&
            <Box component={Paper} p={2} pb={1}>
              <PageHeader level="3" component="div">Tehtävät</PageHeader>
              <Box py={1}>
                <Alert severity="info">Tehtävät aktivoituvat kun erä on käynnissä</Alert>
              </Box>
            </Box>
          }
        </Grid>
      </Grid>
      </div>
    </Content>
  );
};

export default Batch;
