import { createSlice } from "@reduxjs/toolkit";
import { feedFormData, hallFormData } from "../helpers/formHelper";
import { HallTemplateDto, FeedTemplateDto } from "../models/ApiSchema";

export interface ITemplates {
  feed: FeedTemplateDto | null;
  hall: HallTemplateDto | null;
}

const initialState: ITemplates = {
  feed: null,
  hall: null,
};

const slice = createSlice({
  name: "templates",
  initialState: initialState,
  reducers: {
    hallTemplateReceived: (state, action) => {
      const hall: HallTemplateDto = action.payload.data;
      state.hall = hall ? hallFormData(hall) : null;
    },
    feedTemplateReceived: (state, action) => {
      const feed: FeedTemplateDto = action.payload.data;
      state.feed = feed ? feedFormData(feed) : feed;
    },
    healthInfoInitialized: (state, action) => {
      // nothing...
    },
    salmonellaSampleInitialized: (state, action) => {
      // nothing...
    },
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", (state, action) => {
      return { ...initialState };
    });
  },
});

export const { hallTemplateReceived, feedTemplateReceived, healthInfoInitialized, salmonellaSampleInitialized } = slice.actions;

export default slice.reducer;
