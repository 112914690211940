import React, { Fragment, useEffect, useState } from "react";
import PageHeader from "../../../shared/components/PageHeader/PageHeader";
import { useSnackbar } from "notistack";
import { Box, Button, CircularProgress, FormControlLabel, Radio } from "@material-ui/core";
import { IRootState } from "../../../shared/store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../shared/store/apiActions";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { successHandled } from "../../../shared/store/api";
import { Formik, Field, Form } from "formik";
import * as Yup from 'yup';
import { CheckboxWithLabel, RadioGroup, TextField } from "formik-material-ui";
import moment from "moment";
import { SalmonellaSampleBatchPreviewDto } from "../../../shared/models/ApiSchema";
import reportsService from "../../../shared/services/reportsService";
import { DatePicker } from "@material-ui/pickers";
import { localizeDate } from "../../../shared/helpers/formHelper";
import Date from "../../../shared/components/Formatters/Date";

const SalmonellaSample = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { success } = useSelector((state: IRootState) => state.api);

  const [sampleDate, setSampleDate] = useState<moment.Moment | null>(null);
  const [batches, setBatches] = useState<SalmonellaSampleBatchPreviewDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (sampleDate) {
      setLoading(true);
      const fetchData = async () => {
        const resp = await reportsService.getSalmonellaSampleBatchPreview(sampleDate)
        setBatches(resp.data);
        setLoading(false);
      };
      fetchData();
    }
  }, [sampleDate]);

  useEffect(() => {
    if (success) {
      if (success.url?.indexOf('salmonellasample/batch') > -1 && success.method === "post")
      {
        enqueueSnackbar(`Salmonellanäytteet on kirjattu`, { variant: 'success' });
        dispatch(successHandled());
        history.push('/print/salmonella/' + sampleDate?.format("YYYY-MM-DD"));
      }
    }
  }, [dispatch, enqueueSnackbar, history, success, sampleDate]);

  const handleSubmit = async (data) => {
    if (sampleDate && data) {
      data.sampleDate = localizeDate(sampleDate.toDate());
      dispatch(api.templates.initSalmonellaSampleBatch(data));
    }
  };

  const handleCancel = () => history.push('/templates');

  const formSchema = Yup.object().shape({
    breedingBatchIds: Yup
      .array()
      .of(Yup.string())
      .min(1, 'Valitse vähintään yksi kasvatuserä'),
    notes: Yup
      .string()
      .nullable()
      .max(4000),
    isOfficialSample: Yup
      .string()
      .oneOf(["true", "false"])
  });

  const initialValues = {
    breedingBatchIds: [],
    notes: "",
    isOfficialSample: "false",
  }

  return (
    <Fragment>
      <PageHeader level="1" component="div">Salmonellanäytteiden kirjaaminen</PageHeader>

      <Box my={2}>
        <Alert severity="info">
          Tällä lomakkeella tallennetaan valituille kasvatuserille salmonellanäytteen kerääminen, tiedon voi kirjata eräkohtaisesti myös eränäkymässä.<br/>
          Tallennetut tiedot voi tulostaa lähetteeksi dokumentit-osiossa näytteenottopäivän perusteella.
        </Alert>
      </Box>

      <Box my={3}>
        <DatePicker
          autoOk
          label="Näytteenottopäivä"
          variant="dialog"
          format="D.M.yyyy"
          clearable={false}
          invalidDateMessage="Virheellinen päivämäärä"
          cancelLabel="Peruuta"
          okLabel="Aseta"
          value={sampleDate}
          onChange={setSampleDate}
        />
      </Box>

      {loading &&
        <Box my={6} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      }
      {sampleDate == null &&
        <Box my={2}>
          <Alert severity="info">Aloita valitsemalla näytteenottopäivä</Alert>
        </Box>
      }
      {!loading && sampleDate && batches && <Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values, actions) => {
            const data = formSchema.cast(values, { stripUnknown: true });
            handleSubmit(data);
            actions.setSubmitting(false);
          }}
        >
          {({ submitForm, isSubmitting, errors }) => (
            <Form id="salmonellaSampleForm">
              <Box my={2} display="flex" flexDirection="column">
                {batches.map((b, i) => <Box mb={1} key={i}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="breedingBatchIds"
                    value={b.breedingBatch?.id}
                    disabled={b.salmonellaSamplePreview?.errors?.length}
                    Label={{label:<span>
                      <strong>Halli {b.breedingBatch?.hall?.number} - Erä {b.breedingBatch?.breedingId}</strong>{" "}
                      (<small><Date value={b.breedingBatch?.arrivalDate} /> - <Date value={b.breedingBatch?.slaughterDate} /></small>):{" "}
                      Poistuma <Date value={b.salmonellaSamplePreview?.salmonellaSample?.lossDate} />{" "}
                      mennessä {b.salmonellaSamplePreview?.salmonellaSample?.totalLoss} kpl
                    </span>}}
                    margin="dense"
                  />
                  <Box pl={4}>
                    {b.salmonellaSamplePreview?.hasExisting && <div>Tälle erälle on aiempi kirjaus joka ylikirjoitetaan!</div>}
                    {b.salmonellaSamplePreview?.errors?.map((e, j) => <div key={j}>{e}</div>)}
                  </Box>
                </Box>)}
                {!batches.length && <Alert severity="warning">
                  Valitulle näytteenottopäivälle ei löydy aktiivisia kasvatuseriä.
                </Alert>}
              </Box>
              <Box mt={2}>
                <Field component={RadioGroup} name="isOfficialSample">
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Rutiininäyte (2 tossuparia / osasto)"
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Virallinen näyte (1 tossunäytepari + sivelypölynäyte / osasto)"
                  />
                </Field>
              </Box>
              <Field
                component={TextField}
                label="Huomioita"
                name="notes"
                fullWidth
                margin="normal"
                InputProps={{
                  multiline: true,
                  maxRows: 8
                }}
              />
              {errors.breedingBatchIds ? <Box mt={2}><Alert severity="error">{errors.breedingBatchIds}</Alert></Box> : null}
            </Form>
          )}
        </Formik>

        <Box mt={4} mb={1}>
          <Button disabled={!batches.length} variant="contained" color="primary" type="submit" form="salmonellaSampleForm">Tallenna näytteenotto valituille erille</Button>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <Button variant="contained" color="secondary" onClick={handleCancel}>Peruuta</Button>
        </Box>
      </Fragment>}
    </Fragment>
  );
};

export default SalmonellaSample;