import { ApprovalStatus, ApprovalStatusAuditDto, ApprovalStatusUpdateDto, ChainInfoApprovalDto, ChainInfoListDto, ChainInfoSupplementApprovalDto, ChainInfoSupplementListDto, NotificationApprovalDto, NotificationDto, SalmonellaSampleApprovalDto, SalmonellaSampleListDto, SevenDayLossApprovalDto, SevenDayLossListDto, ViewName } from "../models/ApiSchema";
import http from "./httpService";

const API_URL = `approval`;

export const getAudits = async (objectId: string, viewName: ViewName) => {
  const resp = await http.get<ApprovalStatusAuditDto[]>(`${API_URL}/audits/${objectId}?viewName=${viewName}`);
  return resp;
};

export const listChainInfo = async (start?: moment.Moment, end?: moment.Moment, status?: ApprovalStatus) => {
  const resp = await http.get<ChainInfoListDto[]>(`${API_URL}/chaininfo?start=${start ? start.format("YYYY-MM-DD") : ""}&end=${end ? end.format("YYYY-MM-DD") : ""}&status=${status ? status : ""}`);
  return resp;
};

export const getChainInfo = async (chainInfoId: string) => {
  const resp = await http.get<ChainInfoApprovalDto>(`${API_URL}/chaininfo/${chainInfoId}`);
  return resp;
};

export const updateChainInfo = async (chainInfoId: string, data: ApprovalStatusUpdateDto) => {
  const resp = await http.put<void>(`${API_URL}/chaininfo/${chainInfoId}`, data);
  return resp;
};

export const listChainInfoSupplement = async (start?: moment.Moment, end?: moment.Moment, status?: ApprovalStatus) => {
  const resp = await http.get<ChainInfoSupplementListDto[]>(`${API_URL}/chaininfosupplement?start=${start ? start.format("YYYY-MM-DD") : ""}&end=${end ? end.format("YYYY-MM-DD") : ""}&status=${status ? status : ""}`);
  return resp;
};

export const getChainInfoSupplement = async (chainInfoSupplementId: string) => {
  const resp = await http.get<ChainInfoSupplementApprovalDto>(`${API_URL}/chaininfosupplement/${chainInfoSupplementId}`);
  return resp;
};

export const updateChainInfoSupplement = async (chainInfoSupplementId: string, data: ApprovalStatusUpdateDto) => {
  const resp = await http.put<void>(`${API_URL}/chaininfosupplement/${chainInfoSupplementId}`, data);
  return resp;
};

export const listSalmonellaSample = async (start?: moment.Moment, end?: moment.Moment, status?: ApprovalStatus) => {
  const resp = await http.get<SalmonellaSampleListDto[]>(`${API_URL}/salmonellasample?start=${start ? start.format("YYYY-MM-DD") : ""}&end=${end ? end.format("YYYY-MM-DD") : ""}&status=${status ? status : ""}`);
  return resp;
};

export const getSalmonellaSample = async (salmonellaSampleId: string) => {
  const resp = await http.get<SalmonellaSampleApprovalDto>(`${API_URL}/salmonellasample/${salmonellaSampleId}`);
  return resp;
};

export const updateSalmonellaSample = async (salmonellaSampleId: string, data: ApprovalStatusUpdateDto) => {
  const resp = await http.put<void>(`${API_URL}/salmonellasample/${salmonellaSampleId}`, data);
  return resp;
};

export const listSevenDayLoss = async (start?: moment.Moment, end?: moment.Moment, status?: ApprovalStatus) => {
  const resp = await http.get<SevenDayLossListDto[]>(`${API_URL}/sevendayloss?start=${start ? start.format("YYYY-MM-DD") : ""}&end=${end ? end.format("YYYY-MM-DD") : ""}&status=${status ? status : ""}`);
  return resp;
};

export const getSevenDayLoss = async (sevenDayLossId: string) => {
  const resp = await http.get<SevenDayLossApprovalDto>(`${API_URL}/sevendayloss/${sevenDayLossId}`);
  return resp;
};

export const updateSevenDayLoss = async (sevenDayLossId: string, data: ApprovalStatusUpdateDto) => {
  const resp = await http.put<void>(`${API_URL}/sevendayloss/${sevenDayLossId}`, data);
  return resp;
};

export const listNotifications = async (start?: moment.Moment, end?: moment.Moment, status?: ApprovalStatus) => {
  const resp = await http.get<NotificationDto[]>(`${API_URL}/notification?start=${start ? start.format("YYYY-MM-DD") : ""}&end=${end ? end.format("YYYY-MM-DD") : ""}&status=${status ? status : ""}`);
  return resp;
};

export const getNotification = async (notificationId: string) => {
  const resp = await http.get<NotificationApprovalDto>(`${API_URL}/notification/${notificationId}`);
  return resp;
};

export const updateNotification = async (notificationId: string, data: ApprovalStatusUpdateDto) => {
  const resp = await http.put<void>(`${API_URL}/notification/${notificationId}`, data);
  return resp;
};

const approvalService = {
  getAudits,
  listChainInfo,
  getChainInfo,
  updateChainInfo,
  listChainInfoSupplement,
  getChainInfoSupplement,
  updateChainInfoSupplement,
  listSalmonellaSample,
  getSalmonellaSample,
  updateSalmonellaSample,
  listSevenDayLoss,
  getSevenDayLoss,
  updateSevenDayLoss,
  listNotifications,
  getNotification,
  updateNotification
};
export default approvalService;
