import { createSlice } from "@reduxjs/toolkit";

export interface IAutheticate {
  currentUser: any,
  account: any
};

const initialState: IAutheticate = {
  currentUser: "",
  account: null
};

const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    userUpdated: (state, action) => {
      state.currentUser = action.payload.currentUser;
      state.account = action.payload.account;
    }
  }
});

export const { userUpdated } = slice.actions;

export default slice.reducer;
