import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { NotificationType, PoultryDiaryDto } from "../../../../shared/models/ApiSchema";
import { Alert } from "@material-ui/lab";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { TextField } from "formik-material-ui";
import { useConfirm } from "material-ui-confirm";
import Percent from "../../../../shared/components/Formatters/Percent";
import { calcLossPercent } from "../../../../shared/helpers/diaryHelper";
import Date from "../../../../shared/components/Formatters/Date";

interface NotificationModalProps {
  open: boolean;
  onClose: any;
  diary: PoultryDiaryDto | null;
  type: NotificationType | null;
}

const NotificationModal: React.FC<NotificationModalProps> = ({ open, onClose, diary, type }) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    if (type && diary) {
      switch (type) {
        case NotificationType.General:
          setTitle('Tee ilmoitus');
          break;
        case NotificationType.LightDimming:
          setTitle('Ilmoita valaistuksen himmentämisestä');
          break;
        case NotificationType.DailyLoss:
          setTitle('Ilmoita päivittäisen poistuman ylityksestä');
          break;
        case NotificationType.ConsecutiveLoss:
          setTitle('Ilmoita päivittäisen poistuman ylityksestä');
          break;
        default:
          setTitle('Ilmoitus');
      }
    }
    else {
      setTitle('');
    }
  }, [type, diary]);

  const handleCancel = () => {
    onClose(null);
  };

  const formSchema = Yup.object().shape({
    notes: Yup
      .string()
      .nullable()
      .required()
      .max(4000)
  });

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="md"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">{title}</PageHeader>
      </DialogTitle>
      <DialogContent>
        {diary != null &&
          <Fragment>
            <Box mb={2}>
              {type === NotificationType.General && <Alert severity="info">
                Tee ilmoitus kasvatuspäivästä <Date value={diary.date} />, syötä viesti alle.
              </Alert>}
              {type === NotificationType.LightDimming && <Alert severity="info">
                Ilmoitus valaistuksen himmentämisestä <Date value={diary.date} />, syötä syy muutokselle alle.
              </Alert>}
              {type === NotificationType.DailyLoss && <Alert severity="error">
                Kasvatuspäivän {diary.day} poistuma (<Percent value={calcLossPercent(diary)} digits={3} />){" "}
                on ylittänyt 0,5 % tason. Lähetä ilmoitus HKScanin eläinlääkärille.
              </Alert>}
              {type === NotificationType.ConsecutiveLoss && <Alert severity="warning">
                Kasvatuspäivän {diary.day} poistuma (<Percent value={calcLossPercent(diary)} digits={3} />){" "}
                on kolmas peräkkäinen 0,2 % tason ylitys. Lähetä ilmoitus HKScanin eläinlääkärille, poikkeustilanteissa SOITA.
              </Alert>}
            </Box>
            <Formik
              initialValues={{ notes: "" }}
              validationSchema={formSchema}
              onSubmit={(values, actions) => {
                confirm({ description: "Ilmoituksen lähetystä ei voi peruuttaa." }).then(() => {
                  const data = formSchema.cast(values, { stripUnknown: true });
                  actions.setSubmitting(false);
                  onClose(data);
                }, () => {
                  actions.setSubmitting(false);
                });
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <Form id="notificationForm">
                  <Box display="flex" flexDirection="column">
                    <Field
                      component={TextField}
                      label="Viesti / selvitys"
                      name="notes"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        multiline: true,
                        maxRows: 24
                      }}
                    />
                  </Box>
                </Form>
              )}
            </Formik>
          </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>Peruuta</Button>
        <Button variant="text" type="submit" form="notificationForm">Lähetä</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationModal;