import { Box, Button, Grid, InputAdornment } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { numberEmptyToZero } from '../../../../shared/helpers/formHelper';

interface LossFormProps {
  formId: string;
  onSubmit: any;
}

const LossForm: React.FC<LossFormProps> = ({formId, onSubmit}) => {

  const initialValues = {
    dead: "",
    culled: ""
  }

  const formSchema = Yup.object().shape({
    dead: Yup
      .number()
      .nullable()
      .min(0)
      .max(100000)
      .when("culled", {
        is: (culled) => culled == null || culled === undefined || culled === "",
        then: Yup.number().integer().required().transform(numberEmptyToZero),
        otherwise: Yup.number().transform(numberEmptyToZero)
      }),
    culled: Yup
      .number()
      .nullable()
      .min(0)
      .max(100000)
      .when("dead", {
        is: (dead) => dead == null || dead === undefined || dead === "",
        then: Yup.number().integer().required().transform(numberEmptyToZero),
        otherwise: Yup.number().transform(numberEmptyToZero)
      }),
  }, [[ "dead", "culled" ]]);

  return (
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          const data = formSchema.cast(values, { stripUnknown: true });
          actions.setSubmitting(false);
          actions.resetForm({ values: initialValues });
          onSubmit(data);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form id={formId}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={6} sm={4}>
                <Field
                  component={TextField}
                  label="Kuolleet"
                  name="dead"
                  margin="normal"
                  fullWidth
                  autoFocus={true}
                  InputProps={{
                    type: "number",
                    autoComplete: "off",
                    endAdornment: <InputAdornment position="end">kpl</InputAdornment>,
                    inputProps: { step: 1 }
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field
                  component={TextField}
                  label="Karsitut"
                  name="culled"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    type: "number",
                    autoComplete: "off",
                    endAdornment: <InputAdornment position="end">kpl</InputAdornment>,
                    inputProps: { step: 1 }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box pb={1}>
                  <Button fullWidth form="lossForm" type="submit" variant="contained" size="small" color="secondary">Lisää</Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
  );
};

export default LossForm;
