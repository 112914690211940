import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, ErrorsDto } from "../../../../shared/models/ApiSchema";
import { useConfirm } from "material-ui-confirm";
import { Alert } from "@material-ui/lab";
import batchService from "../../../../shared/services/breedingBatchService";
import BatchHeader from "../BatchHeader/BatchHeader";

interface FinishBatchModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
}

const FinishBatchModal: React.FC<FinishBatchModalProps> = ({ open, onClose, batch }) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const [preview, setPreview] = useState<ErrorsDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPreview(null);
    if (open && batch) {
      setLoading(true);
      const fetchData = async () => {
        const resp = await batchService.checkFinishBatch(batch.id);
        setPreview(resp.data);
        setLoading(false);
      };
      fetchData();
    }
  }, [open, batch]);

  const handleCancel = () => {
    onClose(false);
  };

  const handleSubmit = () => {
    confirm({ description: "Erän päättämistä ei voi peruuttaa, oletko varma?" }).then(() => {
      onClose(true);
    });
  };

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="sm"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">Erän {batch.breedingId} päättäminen</PageHeader>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        {loading &&
          <Box my={6} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {!loading && preview?.errors != null && <Fragment>
          {preview.errors.length > 0 && <Box my={2}>
            <Alert severity="error">
              <div><strong>Erää ei voi päättää:</strong></div>
              {preview.errors.map((e, index) => <div key={index}>{e}</div>)}
            </Alert>
          </Box>}
          <Alert severity="warning">
            Erän päättäminen lukitsee syötetyt tiedot ja siirtää erän arkistoon.
            Päättämisen jälkeen erän tietoja ei voi enää muuttaa.
            <br/><br/>
            Jos hallille on tiedossa uusi tuleva erä, se tulee näkyviin etusivulle kun nykyinen erä on päätetty.
          </Alert>
        </Fragment>}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>Peruuta</Button>
        <Button variant="text" onClick={handleSubmit} disabled={preview?.errors == null || preview.errors.length > 0}>Päätä erä</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FinishBatchModal;
