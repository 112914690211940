import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ChainInfoSupplementApprovalDto, ChainInfoSupplementDataDto, FeedType } from "../../../../shared/models/ApiSchema";
import { Alert } from "@material-ui/lab";
import { calcGrainPercent, calcLoss, calcLossPercent, getLossStyle } from "../../../../shared/helpers/diaryHelper";
import Percent from "../../../../shared/components/Formatters/Percent";
import Number from "../../../../shared/components/Formatters/Number";
import Date from "../../../../shared/components/Formatters/Date";
import { buildFeedMap } from "../../../../shared/helpers/feedHelper";
import moment from "moment";
import { deliveredAmountByDate } from "../../../helpers/batchHelper";

interface ChainInfoSupplementDiaryProps {
  data: ChainInfoSupplementDataDto | ChainInfoSupplementApprovalDto;
}

const ChainInfoSupplementDiary: React.FC<ChainInfoSupplementDiaryProps> = ({data}) => {

  const [feedMap, setFeedMap] = useState<object>({});

  useEffect(() => {
    if (data?.feedInfo) {
      setFeedMap(buildFeedMap(data.feedInfo, moment(data.batch?.arrivalDate), moment(data.batch?.slaughterDate)));
    }
  }, [data]);

  const feedType = (type: FeedType): string => {
    switch (type) {
      case FeedType.Start: return "S";
      case FeedType.Grow1: return "1";
      case FeedType.Grow2: return "2";
      case FeedType.End: return "L";
      default: return "?"
    };
  };

  const feedPhase = (day: number): string => {
    const feed = feedMap[day];
    const next = feedMap[day + 1];

    if (!feed) return "-";

    if (next && next.type !== feed.type) {
      if (next.type === FeedType.End) {
        if (moment(next.date).isSame(moment(feed.date, 'days'))) {
          return `${feedType(feed.type)}/${feedType(next.type)}`;
        } else {
          return feedType(feed.type);
        }
      } else {
        return `${feedType(feed.type)}/${feedType(next.type)}`;
      }
    } else {
      return feedType(feed.type);
    }
  };

  return (
    <Box my={3}>
      {data.diaries != null && data.diaries.length > 0 && <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="column" align="center">Pvm</TableCell>
              <TableCell component="th" scope="column" align="center">Ikä<br/>vrk</TableCell>
              <TableCell component="th" scope="column" align="center">Kuolleet<br/>kpl</TableCell>
              <TableCell component="th" scope="column" align="center">Karsitut<br/>kpl</TableCell>
              <TableCell component="th" scope="column" align="center">Poistuma<br/>kpl</TableCell>
              <TableCell component="th" scope="column" align="center">Poistuma<br/>yht. kpl</TableCell>
              <TableCell component="th" scope="column" align="center">Jäljellä<br/>kpl</TableCell>
              <TableCell component="th" scope="column" align="center">Pv.kohtainen<br/>kuolleisuus %</TableCell>
              <TableCell component="th" scope="column" align="center">Vilja<br/>%</TableCell>
              <TableCell component="th" scope="column" align="center">Rehu-<br/>vaihe</TableCell>
              <TableCell component="th" scope="column" align="center">Elopaino<br/>g</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.diaries.map((d, i) => <TableRow key={i}>
              <TableCell align="center"><strong><Date value={d.date} /></strong></TableCell>
              <TableCell align="center"><strong>{d.day}</strong></TableCell>
              <TableCell align="center">{d.birdsDead}</TableCell>
              <TableCell align="center">{d.birdsCulled}</TableCell>
              <TableCell align="center">{calcLoss(d)}</TableCell>
              <TableCell align="center">{data.deliveries ? deliveredAmountByDate(data.deliveries, moment(d.date)) - d.birdsLeft : "-"}</TableCell>
              <TableCell align="center">{d.birdsLeft}</TableCell>
              <TableCell align="center" style={getLossStyle(d)}><Percent value={calcLossPercent(d)} digits={3} unit="" empty="-" /></TableCell>
              <TableCell align="center"><Percent value={calcGrainPercent(d)} digits={1} unit="" empty="-" /></TableCell>
              <TableCell align="center"><strong>{feedPhase(d.day)}</strong></TableCell>
              <TableCell align="center"><Number value={d.liveWeightGrams} digits={0} empty="-" /></TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>}
      {(data.diaries == null || data.diaries.length === 0) &&
        <Alert severity="error">Broileripäiväkirjan tiedot puuttuvat</Alert>
      }
    </Box>
  );
};

export default ChainInfoSupplementDiary;
