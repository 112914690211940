import { createSlice } from "@reduxjs/toolkit";

export interface ISettings {
  producerNumber?: number;
}

const initialState: ISettings = {
  producerNumber: undefined
};

const slice = createSlice({
  name: "userInterface/settings",
  initialState: initialState,
  reducers: {
    producerNumberUpdated: (state, action) => {
      state.producerNumber = Number(action.payload.producerNumber);
    }
  },
});

export const {
  producerNumberUpdated
} = slice.actions;

export default slice.reducer;
