import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, PoultryDiaryDto } from "../../../../shared/models/ApiSchema";
import Date from "../../../../shared/components/Formatters/Date";
import { notificationTypeText } from "../../../../shared/helpers/statusHelper";

interface DiaryNotesModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
  diaries: PoultryDiaryDto[];
}

const DiaryNotesModal: React.FC<DiaryNotesModalProps> = ({ open, onClose, batch, diaries }) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="md"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">Halli {batch.hall?.number} - Päiväkirjan huomiot</PageHeader>
      </DialogTitle>
      <DialogContent>
        <Box my={1}>
          {diaries && diaries.length > 0 && <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="column" align="center" width={100}>Päivä</TableCell>
                  <TableCell component="th" scope="column" align="center" width={60}>Ikä</TableCell>
                  <TableCell component="th" scope="column" align="left">Huomiot</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {diaries.map((d, i) => <TableRow key={i}>
                  <TableCell align="center" style={{ verticalAlign: 'top' }}><Date value={d.date} /></TableCell>
                  <TableCell align="center" style={{ verticalAlign: 'top' }}><strong>{d.day}</strong></TableCell>
                  <TableCell align="left">
                    <ul style={{margin:0, paddingLeft: '20px'}}>
                      {d.notes && <li style={{whiteSpace: "pre-wrap"}}><strong>Huomiot:</strong> {d.notes}</li>}
                      {(d.notificationTypes?.length || 0) > 0 &&  d.notificationTypes?.map((n, j) =>
                        <li key={j}><strong>Ilmoitus:</strong> {notificationTypeText(n)}</li>
                      )}
                    </ul>
                  </TableCell>
                </TableRow>)}
              </TableBody>
            </Table>
          </TableContainer>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>Sulje</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiaryNotesModal;
