/* eslint-disable no-template-curly-in-string */
import moment from "moment";
import "moment/locale/fi";
// import "moment/locale/sv";
import { setLocale } from 'yup';

const initMoment = (defaultLocale: string) => {

  // fi
  moment.locale("fi");
  let momentConf = moment().localeData();
  let conf: moment.LocaleSpecification = {
    invalidDate: "Päivä ei kelpaa",
    weekdaysShort: momentConf.weekdaysShort().map(v => v.charAt(0).toUpperCase() + v.slice(1)),
    weekdaysMin: momentConf.weekdaysMin().map(v => v.charAt(0).toUpperCase() + v.slice(1))
  };
  moment.updateLocale("fi", conf);

  // sv
  // moment.locale("sv");
  // momentConf = moment().localeData();
  // conf = {
  //   weekdaysShort: momentConf.weekdaysShort().map(v => v.charAt(0).toUpperCase() + v.slice(1)),
  //   weekdaysMin: momentConf.weekdaysMin().map(v => v.charAt(0).toUpperCase() + v.slice(1))
  // };
  // moment.updateLocale("sv", conf);

  moment.locale(defaultLocale);
};

const initYup = (defaultLocale: string) => {

  setLocale({
    mixed: {
      default: 'Syöte ei kelpaa',
      required: 'Kenttä on pakollinen',
      notType: 'Syöte puuttuu tai on virheellinen',
    },
    number: {
      integer: 'Syötä kokonaisluku',
      min: 'Pienin sallittu luku on ${min}',
      max: 'Suurin sallittu luku on ${max}',
    },
    string: {
      min: 'Syötteen pitää olla vähintään ${min} merkkiä',
      max: 'Syöte saa olla enintään ${max} merkkiä',
    },
    array: {
      min: 'Lisää vähintään ${min} syötettä',
      max: 'Lisää enintään ${max} syötettä',
    },
  });

}

const init = {
  initMoment,
  initYup
};
export default init;
