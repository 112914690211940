import { Box, Button, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApprovalStatus, ViewName } from "../../models/ApiSchema";
import { IRootState } from "../../store/rootReducer";
import ApprovalStatusSelect from "./ApprovalStatusSelect";
import { useConfirm } from 'material-ui-confirm';
import ApprovalStatusLabel from "../Statuses/ApprovalStatus";
import * as api from "../../../shared/store/apiActions";
import { canAccessApprovalView } from "../../helpers/accessHelper";

interface ApprovalStatusSetFormProps {
  current: ApprovalStatus,
  view: ViewName,
  id: string | null | undefined,
  onChange: any,
}

const ApprovalStatusSetForm: React.FC<ApprovalStatusSetFormProps> = ({ current, view, id, onChange }) => {

  const access = useSelector((state: IRootState) => state.user.access);
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const [newState, setNewState] = useState<ApprovalStatus | null>(null);
  const [comment, setComment] = useState<string | "">("");
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (current && access?.approvalStatusTransitions) {
      const states = access.approvalStatusTransitions[view][current];
      setShow(states && states.length > 0);
    } else {
      setShow(false);
    }
  }, [current, view, access]);


  const handleStateSelect = (state: ApprovalStatus) => {
    setNewState(state);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = () => {
    if (id && newState) {
      if (view === ViewName.ChainInfo) {
        confirm({ description: <span>Ketjuinformaatiolomake asetetaan tilaan <ApprovalStatusLabel status={newState} chip={false} />.</span> }).then(() => {
          dispatch(api.approval.updateChainInfoApprovalStatus(id, newState, comment || undefined));
          onChange(newState);
          setNewState(null);
          setComment("");
        });
      }
      if (view === ViewName.ChainInfoSupplement) {
        confirm({ description: <span>Ketjuinformaation lisäsivu asetetaan tilaan <ApprovalStatusLabel status={newState} chip={false} />.</span> }).then(() => {
          dispatch(api.approval.updateChainInfoSupplementApprovalStatus(id, newState, comment || undefined));
          onChange(newState);
          setNewState(null);
          setComment("");
        });
      }
      if (view === ViewName.SevenDayLoss) {
        confirm({ description: <span>7 päivän poistumailmoitus asetetaan tilaan <ApprovalStatusLabel status={newState} chip={false} />.</span> }).then(() => {
          dispatch(api.approval.updateSevenDayLossApprovalStatus(id, newState, comment || undefined));
          onChange(newState);
          setNewState(null);
          setComment("");
        });
      }
      if (view === ViewName.SalmonellaSample) {
        confirm({ description: <span>Salmonellanäytteen kirjaus asetetaan tilaan <ApprovalStatusLabel status={newState} chip={false} />.</span> }).then(() => {
          dispatch(api.approval.updateSalmonellaSampleApprovalStatus(id, newState, comment || undefined));
          onChange(newState);
          setNewState(null);
          setComment("");
        });
      }
      if (view === ViewName.Notification) {
        confirm({ description: <span>Ilmoitus asetetaan tilaan <ApprovalStatusLabel status={newState} chip={false} />.</span> }).then(() => {
          dispatch(api.approval.updateNotificationApprovalStatus(id, newState, comment || undefined));
          onChange(newState);
          setNewState(null);
          setComment("");
        });
      }
    }
  };

  if (!access || !access?.hasApprovalRole || !canAccessApprovalView(access, view)) {
    return null;
  }

  if (!show || !id) {
    return null; //<Alert severity="info">Ei mahdollisia tilasiirtymiä</Alert>;
  }

  return (
    <Box displayPrint="none">
      <Grid container spacing={2}>
        <Grid item sm={2} xs={12}>
          <ApprovalStatusSelect current={current} view={view} onChange={handleStateSelect} />
        </Grid>
        <Grid item sm={8} xs={12}>
          <TextField size="small" label="Huomioita" variant="outlined" fullWidth value={comment} onChange={handleCommentChange} />
        </Grid>
        <Grid item sm={2} xs={12}>
          <Button fullWidth variant="contained" color="default" disabled={!newState} onClick={handleSubmit} style={{ height: "100%" }}>Aseta tila</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ApprovalStatusSetForm;
