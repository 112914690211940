import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { BatchTaskDto } from "../../../../shared/models/ApiSchema";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from "react-router-dom";
import useStyles from "./TasksListStyles";
import Date from "../../../../shared/components/Formatters/Date";
import moment from "moment";

interface TasksListProps {
  tasks: BatchTaskDto[]
}

const TasksList: React.FC<TasksListProps> = ({tasks}) => {

  const classes = useStyles();
  const history = useHistory();

  const [now] = useState(moment().startOf('day'));

  const avatarClasses = (t: BatchTaskDto) => {
    let start = moment(t.startDate).startOf('day');
    let end = moment(t.endDate).startOf('day');

    if (now.isBefore(start, 'day')) return classes.todo;
    if (now.isSameOrAfter(end, 'day')) return classes.late;
    return classes.now;
  }

  const navigateBatch = (batchId: any) => {
    if (batchId) history.push(`/batch/${batchId}`);
  }

  if (!tasks.length) return (
    <Box py={1}>
      <Alert severity="success">Ei suorittamattomia tehtäviä</Alert>
    </Box>
  );

  return (
    <List>
      {tasks.map((t, index) =>
        <ListItem key={index} button onClick={(event) => navigateBatch(t.breedingBatchId)}>
          <ListItemAvatar>
            <Avatar className={avatarClasses(t)}>
              <strong>{t.hall}</strong>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={moment(t.startDate).isAfter(now, 'days')
              ? `Tulossa: ${t.description}`
              : t.description
            }
            secondary={moment(t.startDate).isAfter(now, 'days')
              ? <span>Suorita: <Date value={t.startDate} /> - <Date value={t.endDate} /></span>
              : <span>Tehtävän takaraja: <Date value={t.endDate} /></span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="Tehtävä" onClick={(event) => navigateBatch(t.breedingBatchId)}>
              <NavigateNextIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </List>
  );
};

export default TasksList;