import React from "react";
import { DatePicker as FormikDatePicker } from 'formik-material-ui-pickers';
import { InputAdornment } from "@material-ui/core";

interface DatePickerProps {
  field: any,
  form: any,
  meta: any,
  onChange: any
}

const DatePicker: React.FC<DatePickerProps> = ({ children, ...rest }) => {
  return (
    <FormikDatePicker
      {...rest}
      variant="dialog"
      format="D.M.yyyy"
      autoOk
      invalidDateMessage="Virheellinen päivämäärä"
      clearable
      cancelLabel="Peruuta"
      okLabel="Aseta"
      clearLabel="Tyhjennä"
      InputProps={{
        endAdornment: <InputAdornment position="end">pvä</InputAdornment>
      }}
    >
      {children}
    </FormikDatePicker>
  );
};

export default DatePicker;
