import React, {Fragment, useState} from "react";
import Navigation from "./Navigation/Navigation";
import Options from "./Options/Options";
import ProducerSelectionModal from "./ProducerSelectionModal/ProducerSelectionModal";

const Header = () => {
  const [openProducerSelectionModal, setProducerSelectionModalOpen] = useState(false);

  return (
    <Fragment>
      <Options setProducerSelectionModalOpen={setProducerSelectionModalOpen} />
      <Navigation />
      <ProducerSelectionModal open={openProducerSelectionModal} onClose={() => {setProducerSelectionModalOpen(false)}} />
    </Fragment>
  );
}

export default Header;