import { requested, requestedError, requestedSuccess } from "../api";
import { loadingBegan, loadingEnded } from "../ui/common";
import { snackActions } from '../../helpers/snackbarHelper';
// import http from "../../services/httpService";
// import auth from "../../services/authService";
import { ValidationProblemDetails } from "../../models/ApiSchema";

const createConfigObj = (resp, errResp: any = null) => {
  return {
    url: resp?.config?.url,
    method: resp?.config?.method,
    data: errResp?.data || resp?.data,
    status: errResp?.status,
  };
};

const getErrorMessages = (err) => {
  const messages: string[] = [];
  if (err.response) {
    if (err.response.data) {
      let data: ValidationProblemDetails = err.response.data;
      if (data.errors && Object.keys(data.errors).length)
      {
        for (const [, value] of Object.entries(data.errors)) {
          for (const index in value) {
            messages.push(value[index]);
          }
        }
      }
      else {
        messages.push(data.detail || data.title || "Api-virhe: " + err.response.status);
      }
    }
    else {
      messages.push("Api-virhe: " + err.response.status);
    }
  }
  else {
    messages.push("Ei yhteyttä taustajärjestelmään");
  }
  return messages;
}

// export const getPublicAsyncActionWrapper = (dispatch, fn, getState) =>
//   new Promise(async (resolve, reject) => {
//     try {
//       getPublicActionWrapper(
//         dispatch,
//         async () => {
//           const response = await fn();
//           return resolve(response);
//         },
//         getState
//       );
//     } catch (error) {
//       return reject();
//     }
//   });

// const getPublicActionWrapper = async (dispatch, fn, getState) => {
//   dispatch({ type: loadingBegan.type });
//   getActionWrapper(dispatch, fn, getState);
// };

const getActionWrapper = async (dispatch, fn, getState) => {
  try {
    /* headers set in httpService */
    // const tokenResp = await auth.getAccessTokenResponse(
    //   getState().auth.account
    // );
    // http.setRequestHeaders({
    //   authorization: `Bearer ${tokenResp.accessToken}`,
    //   "x-karinetti-currentproducer": getState().ui.settings.producerNumber,
    // });
    const resp = await fn(dispatch, getState);
    dispatch({ type: loadingEnded.type });
    dispatch(requestedSuccess(createConfigObj(resp)));
  } catch (err) {
    dispatch(requestedError(createConfigObj(err, err.response)));
    dispatch({ type: loadingEnded.type });
    snackActions.error(getErrorMessages(err).join(", "));
    console.error(err);
    throw err;
  }
};

const api = ({ dispatch, getState }) => (next) => async (action) => {
  if (action.type !== requested.type) return next(action);

  dispatch({ type: loadingBegan.type });

  next(action);

  const { fn } = action.payload;

  try {
    getActionWrapper(dispatch, fn, getState);
  } catch (error) {}
};

export default api;
