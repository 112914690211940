import moment from "moment";
import { ChainInfoFeedDto, FeedInfoDto, FeedInfoRowDto, FeedType } from "../models/ApiSchema";

export const lastFeed = (feeds: ChainInfoFeedDto[] | null | undefined, type: FeedType | null = null) : ChainInfoFeedDto | null => {
  if (!feeds || feeds.length === 0) return null;
  const byType = feeds.filter(f => type == null || f.type === type).sort((a, b) => moment(b.date).unix() - moment(a.date).unix());
  if (!byType || byType.length === 0) return null;
  return byType[0];
};

export const feeds = (feeds: ChainInfoFeedDto[], type: FeedType) : ChainInfoFeedDto[] => {
  return feeds.filter(f => f.type === type).sort((a, b) => moment(a.date).unix() - moment(b.date).unix());
};

export const buildFeedMap = (feedInfo: FeedInfoDto, start: moment.Moment, end: moment.Moment) : object => {
  const map = {};

  if (!feedInfo) return map;

  let feeds: FeedInfoRowDto[] = [];
  feeds = feeds.concat(feedInfo.startFeeds || []);
  feeds = feeds.concat(feedInfo.grow1Feeds || []);
  feeds = feeds.concat(feedInfo.grow2Feeds || []);
  feeds.sort((a, b) => moment(a.date).unix() - moment(b.date).unix());

  let endFeeds: FeedInfoRowDto[] = [];
  endFeeds = endFeeds.concat(feedInfo.endFeeds || []);
  endFeeds.sort((a, b) => moment(b.date).unix() - moment(a.date).unix());

  let date = moment(start);
  let day = 0;

  const feedMatch = (f: FeedInfoRowDto) => date.isSameOrBefore(moment(f.date), 'day');
  const endFeedMatch = (f: FeedInfoRowDto) => date.isSameOrAfter(moment(f.date), 'day');

  while (date.isSameOrBefore(end, "day"))
  {
    let feed = endFeeds.find(endFeedMatch);
    if (!feed) feed = feeds.find(feedMatch);
    map[day] = feed;

    day++;
    date = date.add(1, 'days');
  }

  return map;
};

const feedHelper = {
  lastFeed,
  feeds,
  buildFeedMap
};
export default feedHelper;
