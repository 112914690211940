import { Box, Grid, IconButton, InputAdornment, Paper, TextField as MuiTextField } from '@material-ui/core';
import { Formik, Form, FieldArray, FastField, FieldArrayRenderProps } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import DatePicker from '../../../../shared/components/DatePicker/DatePicker';
import DateTimePicker from '../../../../shared/components/DateTimePicker/DateTimePicker';
import { BreedingBatchDto, FeedInfoDto, FeedType } from '../../../../shared/models/ApiSchema';
import PageHeader from '../../../../shared/components/PageHeader/PageHeader';
import { Autocomplete } from "formik-material-ui-lab";
import { FeedCoccidiostats, FeedFactories } from '../../../../shared/constants/options';
import { TextField } from 'formik-material-ui';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { useConfirm } from 'material-ui-confirm';
import { dateOnly, numberEmptyToNull } from '../../../../shared/helpers/formHelper';
import FormikDisabler from '../../../../shared/components/FormikDisabler/FormikDisabler';
import moment from 'moment';
import { Alert } from '@material-ui/lab';

interface FeedInfoFormProps {
  formId: string;
  values?: FeedInfoDto | null;
  batch: BreedingBatchDto;
  onSubmit: any;
}

const FeedInfoForm: React.FC<FeedInfoFormProps> = ({formId, values, batch, onSubmit}) => {
  const confirm = useConfirm();

  const [feedFactory, setFeedFactory] = useState<string | null>(values?.feedFactory || null);

  const makeFeedRow = (type: FeedType) => {
    return { id: null, type: type, name: "", date: null, coccidiostat: "", co2: "", withholdingPeriod: "" };
  };

  const makeGrainRow = (percent: number | "" = "") => {
    return { id: null, strain: "", date: null, proteinPercent: "", hectoLiterWeight: "", treshingYear: "", percentOfTotal: percent };
  };

  const initialValues: FeedInfoDto = {
    feedFactory: "",
    feedProgram: "",
    startFeeds: [],
    grow1Feeds: [],
    grow2Feeds: [],
    endFeeds: [],
    grains: [],
  };

  const feedSchema = Yup.object().shape({
    id: Yup
      .string()
      .nullable(),
    type: Yup
      .string()
      .required()
      .oneOf(Object.values(FeedType)),
    name: Yup
      .string()
      .required()
      .nullable()
      .max(100),
    coccidiostat: Yup
      .string()
      .nullable()
      .max(100),
    date: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    co2: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(1000000),
    withholdingPeriod: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .integer()
      .min(0)
      .max(100),
  });

  const grow2FeedSchema = Yup.object().shape({
    id: Yup
      .string()
      .nullable(),
    type: Yup
      .string()
      .required()
      .oneOf(Object.values(FeedType)),
    name: Yup
      .string()
      .required()
      .nullable()
      .max(100),
    coccidiostat: Yup
      .string()
      .nullable()
      .max(100),
    date: Yup
      .date()
      .nullable(),
    co2: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(1000000),
    withholdingPeriod: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .integer()
      .min(0)
      .max(100),
  });

  const grainSchema = Yup.object().shape({
    id: Yup
      .string()
      .nullable(),
    strain: Yup
      .string()
      .required()
      .nullable()
      .max(100),
    date: Yup
      .date()
      .transform(dateOnly)
      .nullable(),
    proteinPercent: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(100000),
    hectoLiterWeight: Yup
      .number()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(100000),
    treshingYear: Yup
      .number()
      .integer()
      .transform(numberEmptyToNull)
      .nullable()
      .min(2000)
      .max(3000),
    percentOfTotal: Yup
      .number()
      .integer()
      .transform(numberEmptyToNull)
      .nullable()
      .min(0)
      .max(100)
  });

  const formSchema = Yup.object().shape({
    feedFactory: Yup
      .string()
      .max(100),
    feedProgram: Yup
      .string()
      .max(100),
    startFeeds: Yup
      .array().of(feedSchema),
    grow1Feeds: Yup
      .array().of(feedSchema),
    grow2Feeds: Yup
      .array().of(grow2FeedSchema),
    endFeeds: Yup
      .array().of(feedSchema),
    grains: Yup
      .array().of(grainSchema)
      .test('grains', 'Osuuksien summa ei saa ylittää 100 %', (rows = []) => {
        if (rows.length < 1) return true;
        const total = rows.map(row => row.percentOfTotal || 0).reduce((t, v) => t + v);
        return total <= 100;
      })
    });

  const removeFeedRow = (arrayHelpers: FieldArrayRenderProps, index: number) => {
    confirm({ description: "Valittu rehurivi poistetaan, mutta poisto on lopullinen vasta kun lomake tallennetaan." }).then(() => {
      arrayHelpers.remove(index);
    });
  }

  const removeGrainRow = (arrayHelpers: FieldArrayRenderProps, index: number) => {
    confirm({ description: "Valittu viljarivi poistetaan, mutta poisto on lopullinen vasta kun lomake tallennetaan." }).then(() => {
      arrayHelpers.remove(index);
    });
  }

  const handleFeedFactoryChange = (setFieldValue) => (e, v) => {
    const curr = v;
    const prev = feedFactory;
    setFieldValue('feedFactory', v);
    if (curr) {
      setFeedFactory(curr);
    }
    if (curr && prev && curr !== prev) {
      confirm({
          title: "Tyhjennetäänkö rehutiedot?",
          description: "Olet vaihtanut rehutehdasta, haluatko että syötetyt rehutiedot poistetaan?",
          confirmationText: "Tyhjennä",
          cancellationText: "Säilytä tiedot"
      }).then(() => {
        setFieldValue('startFeeds', []);
        setFieldValue('grow1Feeds', []);
        setFieldValue('grow2Feeds', []);
        setFieldValue('endFeeds', []);
      }, () => false);
    }
  }

  return (
      <Formik
        initialValues={values || initialValues}
        enableReinitialize={true}
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          const data = formSchema.cast(values, { stripUnknown: true });
          actions.setSubmitting(false);
          actions.resetForm({ values: initialValues });
          onSubmit(data);
        }}
      >
        {({ values, submitForm, isSubmitting, setFieldValue, errors }) => (
          <Form id={formId}>
            <FormikDisabler disabled={values?.isFinal === true} />
            <Box my={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <FastField
                    component={Autocomplete}
                    name="feedFactory"
                    options={FeedFactories}
                    fullWidth
                    freeSolo
                    autoSelect
                    renderInput={(params) => (
                      <MuiTextField {...params} label="Rehutehdas" margin="dense" />
                    )}
                    onChange={handleFeedFactoryChange(setFieldValue)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <FastField
                    component={Autocomplete}
                    name="feedProgram"
                    options={["Nelirehuohjelma", "Muu"]}
                    fullWidth
                    freeSolo
                    autoSelect
                    renderInput={(params) => (
                      <MuiTextField {...params} label="Rehuohjelma" margin="dense" />
                    )}
                  />
                </Grid>
              </Grid>
              <FieldArray
                name="startFeeds"
                render={arrayHelpers => (
                  <Box my={2}>
                    <Box display="flex" alignItems="center">
                      <PageHeader level="3" component="div">Aloitusrehut</PageHeader>
                      {!values?.isFinal && <IconButton onClick={() => arrayHelpers.push(makeFeedRow(FeedType.Start))}>
                        <AddCircleIcon fontSize="small" />
                      </IconButton>}
                    </Box>
                    {values.startFeeds && values.startFeeds.length > 0 && values.startFeeds.map((feed, index) => (
                      <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                        <Box my={1} flexGrow={1}>
                          <Paper variant="outlined" style={{padding: "1em"}}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  component={TextField}
                                  label="Rehun nimi"
                                  name={`startFeeds[${index}].name`}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  name={`startFeeds[${index}].coccidiostat`}
                                  component={Autocomplete}
                                  options={FeedCoccidiostats}
                                  fullWidth
                                  freeSolo
                                  autoSelect
                                  renderInput={(params) => (
                                    <MuiTextField {...params} label="Kokkidiostaatti" margin="dense" />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={2}>
                                <FastField
                                  name={`startFeeds[${index}].withholdingPeriod`}
                                  component={TextField}
                                  label="Varoaika"
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric",
                                    endAdornment: <InputAdornment position="end">vrk</InputAdornment>
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={2}>
                                <FastField
                                  name={`startFeeds[${index}].date`}
                                  label="Lopetuspäivä"
                                  component={DatePicker}
                                  fullWidth
                                  margin="dense"
                                  minDate={moment(batch.arrivalDate).startOf('day').toDate()}
                                  maxDate={moment(batch.slaughterDate).startOf('day').toDate()}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={2}>
                                <FastField
                                  name={`startFeeds[${index}].co2`}
                                  label="CO2e"
                                  component={TextField}
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric",
                                    endAdornment: <InputAdornment position="end">kg CO2-e/kg</InputAdornment>
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Box>
                        {!values?.isFinal && <IconButton onClick={() => removeFeedRow(arrayHelpers, index)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>}
                      </Box>
                    ))}
                  </Box>
                )}
              />
              <FieldArray
                name="grow1Feeds"
                render={arrayHelpers => (
                  <Box my={2}>
                    <Box display="flex" alignItems="center">
                      <PageHeader level="3" component="div">1. kasvatusrehut</PageHeader>
                      {!values?.isFinal && <IconButton onClick={() => arrayHelpers.push(makeFeedRow(FeedType.Grow1))}>
                        <AddCircleIcon fontSize="small" />
                      </IconButton>}
                    </Box>
                    {values.grow1Feeds && values.grow1Feeds.length > 0 && values.grow1Feeds.map((feed, index) => (
                      <Box key={index}  display="flex" justifyContent="space-between" alignItems="center">
                        <Box my={1} flexGrow={1}>
                          <Paper variant="outlined" style={{padding: "1em"}}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  component={TextField}
                                  label="Rehun nimi"
                                  name={`grow1Feeds[${index}].name`}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  name={`grow1Feeds[${index}].coccidiostat`}
                                  component={Autocomplete}
                                  options={FeedCoccidiostats}
                                  fullWidth
                                  freeSolo
                                  autoSelect
                                  renderInput={(params) => (
                                    <MuiTextField {...params} label="Kokkidiostaatti" margin="dense" />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={2}>
                                <FastField
                                  name={`grow1Feeds[${index}].withholdingPeriod`}
                                  component={TextField}
                                  label="Varoaika"
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric",
                                    endAdornment: <InputAdornment position="end">vrk</InputAdornment>
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={2}>
                                <FastField
                                  name={`grow1Feeds[${index}].date`}
                                  label="Lopetuspäivä"
                                  component={DatePicker}
                                  fullWidth
                                  margin="dense"
                                  minDate={moment(batch.arrivalDate).startOf('day').toDate()}
                                  maxDate={moment(batch.slaughterDate).startOf('day').toDate()}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={2}>
                                <FastField
                                  name={`grow1Feeds[${index}].co2`}
                                  label="CO2e"
                                  component={TextField}
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric",
                                    endAdornment: <InputAdornment position="end">kg CO2-e/kg</InputAdornment>
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Box>
                        {!values?.isFinal && <IconButton onClick={() => removeFeedRow(arrayHelpers, index)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>}
                      </Box>
                    ))}
                  </Box>
                )}
              />
              <FieldArray
                name="grow2Feeds"
                render={arrayHelpers => (
                  <Box my={2}>
                    <Box display="flex" alignItems="center">
                      <PageHeader level="3" component="div">2. kasvatusrehut</PageHeader>
                      {!values?.isFinal && <IconButton onClick={() => arrayHelpers.push(makeFeedRow(FeedType.Grow2))}>
                        <AddCircleIcon fontSize="small" />
                      </IconButton>}
                    </Box>
                    {values.grow2Feeds && values.grow2Feeds.length > 0 && values.grow2Feeds.map((feed, index) => (
                      <Box key={index}  display="flex" justifyContent="space-between" alignItems="center">
                        <Box my={1} flexGrow={1}>
                          <Paper variant="outlined" style={{padding: "1em"}}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  component={TextField}
                                  label="Rehun nimi"
                                  name={`grow2Feeds[${index}].name`}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  name={`grow2Feeds[${index}].coccidiostat`}
                                  component={Autocomplete}
                                  options={FeedCoccidiostats}
                                  fullWidth
                                  freeSolo
                                  autoSelect
                                  renderInput={(params) => (
                                    <MuiTextField {...params} label="Kokkidiostaatti" margin="dense" />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={2}>
                                <FastField
                                  name={`grow2Feeds[${index}].withholdingPeriod`}
                                  component={TextField}
                                  label="Varoaika"
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric",
                                    endAdornment: <InputAdornment position="end">vrk</InputAdornment>
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={2}>
                                <FastField
                                  name={`grow2Feeds[${index}].date`}
                                  label="Lopetusaika"
                                  component={DateTimePicker}
                                  fullWidth
                                  margin="dense"
                                  minDate={moment(batch.arrivalDate).startOf('day').toDate()}
                                  maxDate={moment(batch.slaughterDate).endOf('day').toDate()}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={2}>
                                <FastField
                                  name={`grow2Feeds[${index}].co2`}
                                  label="CO2e"
                                  component={TextField}
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric",
                                    endAdornment: <InputAdornment position="end">kg CO2-e/kg</InputAdornment>
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Box>
                        {!values?.isFinal && <IconButton onClick={() => removeFeedRow(arrayHelpers, index)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>}
                      </Box>
                    ))}
                  </Box>
                )}
              />
              <FieldArray
                name="endFeeds"
                render={arrayHelpers => (
                  <Box my={2}>
                    <Box display="flex" alignItems="center">
                      <PageHeader level="3" component="div">Lopetusrehut</PageHeader>
                      {!values?.isFinal && <IconButton onClick={() => arrayHelpers.push(makeFeedRow(FeedType.End))}>
                        <AddCircleIcon fontSize="small" />
                      </IconButton>}
                    </Box>
                    {values.endFeeds && values.endFeeds.length > 0 && values.endFeeds.map((feed, index) => (
                      <Box key={index}  display="flex" justifyContent="space-between" alignItems="center">
                        <Box my={1} flexGrow={1}>
                          <Paper variant="outlined" style={{padding: "1em"}}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  component={TextField}
                                  label="Rehun nimi"
                                  name={`endFeeds[${index}].name`}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  label="Ei kokkidiostaattia"
                                  disabled={true}
                                  name={`endFeeds[${index}].coccidiostat`}
                                  component={TextField}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={2}>
                                <FastField
                                  name={`endFeeds[${index}].withholdingPeriod`}
                                  disabled={true}
                                  component={TextField}
                                  label="Ei varoaikaa"
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric",
                                    endAdornment: <InputAdornment position="end">vrk</InputAdornment>
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={2}>
                                <FastField
                                  name={`endFeeds[${index}].date`}
                                  label="Aloituspäivä"
                                  component={DatePicker}
                                  fullWidth
                                  margin="dense"
                                  minDate={moment(batch.arrivalDate).startOf('day').toDate()}
                                  maxDate={moment(batch.slaughterDate).startOf('day').toDate()}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={2}>
                                <FastField
                                  name={`endFeeds[${index}].co2`}
                                  label="CO2e"
                                  component={TextField}
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric",
                                    endAdornment: <InputAdornment position="end">kg CO2-e/kg</InputAdornment>
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Box>
                        {!values?.isFinal && <IconButton onClick={() => removeFeedRow(arrayHelpers, index)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>}
                      </Box>
                    ))}
                  </Box>
                )}
              />
              <FieldArray
                name="grains"
                render={arrayHelpers => (
                  <Box my={2}>
                    <Box display="flex" alignItems="center">
                      <PageHeader level="3" component="div">Viljat</PageHeader>
                      {!values?.isFinal && <IconButton onClick={() => arrayHelpers.push(makeGrainRow(values.grains?.length ? "" : 100))}>
                        <AddCircleIcon fontSize="small" />
                      </IconButton>}
                      {errors.grains && typeof errors.grains === 'string' ? <Alert severity="error">{errors.grains.toString()}</Alert> : <span />}
                    </Box>
                    {values.grains && values.grains.length > 0 && values.grains.map((grain, index) => (
                      <Box key={index}  display="flex" justifyContent="space-between" alignItems="center">
                        <Box my={1} flexGrow={1}>
                          <Paper variant="outlined" style={{padding: "1em"}}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6} md={3}>
                                <FastField
                                  component={TextField}
                                  label="Viljan lajike"
                                  name={`grains[${index}].strain`}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={2}>
                                <FastField
                                  name={`grains[${index}].proteinPercent`}
                                  label="Valkuaisaine"
                                  component={TextField}
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric",
                                    endAdornment: <InputAdornment position="end">% kuiv.ain.</InputAdornment>
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6} sm={3} md={2}>
                                <FastField
                                  name={`grains[${index}].hectoLiterWeight`}
                                  label="Hehtolitrapaino"
                                  component={TextField}
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric",
                                    endAdornment: <InputAdornment position="end">kg</InputAdornment>
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6} sm={3} md={2}>
                                <FastField
                                  name={`grains[${index}].date`}
                                  label="Siirtopäivä"
                                  component={DatePicker}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={6} sm={3} md={1}>
                                <FastField
                                  name={`grains[${index}].treshingYear`}
                                  label="Puintivuosi"
                                  component={TextField}
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric"
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6} sm={3} md={2}>
                                <FastField
                                  name={`grains[${index}].percentOfTotal`}
                                  label="Osuus"
                                  component={TextField}
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    type: "text",
                                    inputMode: "numeric",
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Box>
                        {!values?.isFinal && <IconButton onClick={() => removeGrainRow(arrayHelpers, index)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>}
                      </Box>
                    ))}
                  </Box>
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
  );
};

export default FeedInfoForm;
