import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  todo: {
    color: theme.palette.getContrastText("#ffcc00"),
    backgroundColor: "#ffcc00"
  },
  now: {
    color: theme.palette.getContrastText("#009900"),
    backgroundColor: "#009900"
  },
  late: {
    color: theme.palette.getContrastText("#ff0000"),
    backgroundColor: "#ff0000"
  },
}));

export default useStyles;