export default class UserAccount {
  emailAddress: any;
  name: any;
  producers: any;
  authenticationSource: any;
  roles: [];

  constructor(account) {
    const idTokenClaims = account.idTokenClaims;
    this.producers = idTokenClaims.authorizationData
      ? this.extractProducerInfo(idTokenClaims.authorizationData)
      : [];
    this.emailAddress = idTokenClaims.email;
    this.name = idTokenClaims.first_name && idTokenClaims.given_name &&
      idTokenClaims.first_name !== '-' && idTokenClaims.given_name !== '-'
        ? `${idTokenClaims.first_name} ${idTokenClaims.given_name}`
        : (idTokenClaims.name || idTokenClaims.email);
    this.roles = idTokenClaims.roles;
    this.authenticationSource = idTokenClaims.authenticationSource;
  }

  private extractProducerInfo(data) {
    try {
      const json = JSON.parse(data);
      return json.Producers || [];
    } catch (err) {
      console.error("Extract producerinfo failed!", err);
    }
  }
}
