import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React, { Fragment } from "react";
import { BatchDeliveryDto, SevenDayLossDto } from "../../../models/ApiSchema";
import Date from "../../Formatters/Date";
import Percent from "../../Formatters/Percent";
import Number from "../../Formatters/Number";
import ChickQuality from "../../Statuses/ChickQuality";

interface SevenDayLossProps {
  sevenDayLoss: SevenDayLossDto | null | undefined;
  deliveries?: BatchDeliveryDto[] | null | undefined;
  isPreview?: boolean;
}

const SevenDayLoss: React.FC<SevenDayLossProps> = ({sevenDayLoss, deliveries = undefined, isPreview = false}) => {
  if (!sevenDayLoss) return <Fragment></Fragment>;

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          {sevenDayLoss.producerName && <TableRow>
            <TableCell component="th" scope="row">
              Tuottaja
            </TableCell>
            <TableCell>
              {sevenDayLoss.producerName}
            </TableCell>
          </TableRow>}
          {sevenDayLoss.hallNumber && <TableRow>
            <TableCell component="th" scope="row">
              Halli
            </TableCell>
            <TableCell>
              {sevenDayLoss.hallNumber}
            </TableCell>
          </TableRow>}
          {deliveries && deliveries.length > 0 && <TableRow>
            <TableCell component="th" scope="row">
              Saapumispäivät
            </TableCell>
            <TableCell>
              {deliveries.filter(d => !!d.actualArrivalTime).map<React.ReactNode>(d => <Date value={d.actualArrivalTime} />).reduce((p, c) => [p, ', ', c])}
            </TableCell>
          </TableRow>}
          <TableRow>
            <TableCell component="th" scope="row">
              Päivämäärä / erän ikä
            </TableCell>
            <TableCell>
              <Date value={sevenDayLoss.reportDate} /> / {sevenDayLoss.reportDay} päivää
            </TableCell>
          </TableRow>
          {!isPreview && <TableRow>
            <TableCell component="th" scope="row">
              Untuvikkojen laatu
            </TableCell>
            <TableCell>
              {sevenDayLoss.chickQuality && <ChickQuality status={sevenDayLoss.chickQuality} />}
            </TableCell>
          </TableRow>}
          <TableRow>
            <TableCell component="th" scope="row">
              Aloittanut / jäljellä 7. pvä
            </TableCell>
            <TableCell>
              {sevenDayLoss.startQuantity} / {sevenDayLoss.birdsLeft}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Kuolleet / karsitut / yhteensä
            </TableCell>
            <TableCell>
              {sevenDayLoss.birdsDead} / {sevenDayLoss.birdsCulled} / {sevenDayLoss.totalLoss}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              7. päivän kokonaispoistuma
            </TableCell>
            <TableCell>
              <Percent value={sevenDayLoss.totalLoss / sevenDayLoss.startQuantity} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              7. päivän keskipaino
            </TableCell>
            <TableCell>
              <Number value={sevenDayLoss.liveWeightGrams} unit="grammaa" digits={0} />
            </TableCell>
          </TableRow>
          {!!sevenDayLoss.notes && <TableRow>
            <TableCell colSpan={2}>
              <strong>Huomioita:</strong><br/>
              {sevenDayLoss.notes}
            </TableCell>
          </TableRow>}
          {!isPreview && <TableRow>
            <TableCell component="th" scope="row">
              Raportti luotu
            </TableCell>
            <TableCell>
              <Date value={sevenDayLoss.createdDate} time={true} /> ({sevenDayLoss.createdBy || 'tuntematon'})
            </TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SevenDayLoss;
