import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, PoultryDiaryDto } from "../../../../shared/models/ApiSchema";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import moment from "moment";
import { calcFeedTotalPerBirdGram, calcLossPercent, cumulativeTotal, lossDanger, lossWarning } from "../../../../shared/helpers/diaryHelper";
import * as api from "../../../../shared/store/apiActions";
import { useDispatch } from "react-redux";
import agGridHelper from "../../../../shared/helpers/agGridHelper";
import { ColDef, EditableCallbackParams } from "ag-grid-community";

interface DiaryGridModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
  diaries: PoultryDiaryDto[];
  initialFeed?: number;
}

const DiaryGridModal: React.FC<DiaryGridModalProps> = ({ open, onClose, batch, diaries, initialFeed = 0 }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [changed, setChanged] = useState(false);
  const [total, setTotal] = useState<any>({});
  const [now, setNow] = useState(moment());

  useEffect(() => {
    setNow(moment());
    if (diaries.length > 0) {
      setTotal(cumulativeTotal(diaries, initialFeed));
    } else {
      setTotal({});
    }
  }, [diaries, initialFeed]);

  const handleClose = () => {
    onClose(changed);
  };

  const defaultColDef: ColDef = {
    cellStyle: { textAlign: "center" },
    sortable: false,
    suppressMenu: true,
    suppressMovable: true,
  };

  const readOnlyCellStyle = {
    textAlign: 'center',
    color: 'black',
    backgroundColor: '#f3f3f3'
  };

  const getLossCellStyle = (params) => {
    const styles: any = {
      textAlign: 'center',
    }
    if (params.data.id !== "TOTAL") {
      styles.fontStyle = 'italic';
      if (lossDanger(params.data)) {
        styles.color = 'white';
        styles.backgroundColor = '#ff6666';
      }
      else if (lossWarning(params.data)) {
        styles.color = 'black';
        styles.backgroundColor = 'yellow';
      }
      else {
        return readOnlyCellStyle;
      }
    } else {
      styles.fontWeight = 'bold';
    }
    return styles;
  }

  const getRowStyle = (params) => {
    if (params.data.id !== "TOTAL") {
      let styles : any = {};

      if (moment(params.data.date).isSame(now, 'day')) {
        styles.color = 'black';
        styles.backgroundColor = '#99ee99';
      }
      else if (!_isEditable(params.data)) {
        styles.color = '#333333';
        styles.backgroundColor = '#f3f3f3';
      }

      let day = params.data.day;
      if (day > 0 && day % 7 === 0) {
        styles.borderBottom = "1px solid #999999"
        if (!styles.backgroundColor) styles.backgroundColor = '#f3f3ff';
      }

      return styles;
    } else {
      return { fontWeight: 'bold' };
    }
  };

  const _isEditable = (data: PoultryDiaryDto, loss = false) => {
    if (loss && data.isLossFinal) return false;
    if (data.isFinal) return false;
    if (moment(data.date).isAfter(now, 'day')) return false;
    return true;
  }

  const isEditable = (p: EditableCallbackParams, loss = false) => _isEditable(p.data, loss);

  const updateDiary = (field: string, p: any) => {
    if (p.newValue == null || p.newValue === "") return false;
    let value = Number(p.newValue.replace(/,/, '.'));
    if (isNaN(value) || value < 0 || value > 500000) return false;
    let data = {};
    data[field] = value;
    dispatch(api.diary.updatePoultryDiary(p.data.id, data));
    return false;
  }

  const updateLoss = (field: string, p: any) => {
    if (p.newValue == null || p.newValue === "") return false;
    let value = Number(p.newValue);
    if (isNaN(value) || value < 0 || value > 100000) return false;
    let data = {};
    data[field] = Math.trunc(value);
    if (field === "dead") data["culled"] = p.data.birdsCulled;
    if (field === "culled") data["dead"] = p.data.birdsDead;
    dispatch(api.diary.setPoultryLoss(batch.id, p.data.id, data));
    setChanged(true);
    return false;
  }

  const resize = (e) => {
    e.api.sizeColumnsToFit();
    e.api.redrawRows();
  }

  const redraw = (e) => {
    e.api.redrawRows();
  }

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="xl"
      open={open}
      TransitionProps={{onEnter: () => setChanged(false)}}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">Erän {batch.breedingId} päiväkirja (halli {batch.hall?.number})</PageHeader>
      </DialogTitle>
      <DialogContent>
        <Box mb={1}>Rehumäärä hallissa ennen lintujen saapumista (sisältyy kokonaismäärään): <strong>{initialFeed} kg</strong></Box>
        <div className="diary-grid ag-theme-balham" style={{height: "73vh", width: "99.99%"}}>
          <AgGridReact
            rowData={diaries}
            immutableData={true}
            suppressScrollOnNewData={true}
            pinnedBottomRowData={[total]}
            applyColumnDefOrder={true}
            defaultColDef={defaultColDef}
            getRowStyle={getRowStyle}
            getRowNodeId={(row) => row.id}
            onFirstDataRendered={resize}
            onRowDataUpdated={redraw}
            onRowDataChanged={redraw}
            singleClickEdit
          >
            <AgGridColumn
              pinned="left"
              width={60}
              field="date"
              headerName="Päivä"
              valueFormatter={agGridHelper.dayFormatter}
              pinnedRowCellRenderer={() => "Yht."}
              cellStyle={{ fontWeight: "bold" }}
              suppressSizeToFit
              suppressAutoSize
              suppressNavigable
            />
            <AgGridColumn
              pinned="left"
              width={50}
              field="day"
              headerName="Ikä"
              pinnedRowValueFormatter={() => ""}
              cellStyle={{ fontWeight: "bold" }}
              suppressSizeToFit
              suppressAutoSize
              suppressNavigable
            />
            <AgGridColumn
              width={70}
              field="birdsLeft"
              headerName="Jäljellä"
              cellStyle={readOnlyCellStyle}
              suppressSizeToFit
              suppressAutoSize
              suppressNavigable
            />
            <AgGridColumn
              minWidth={70}
              field="birdsDead"
              headerName="Kuollut"
              editable={p => isEditable(p, true)}
              valueGetter={(p) => p.data.id === "TOTAL" || p.data.poultryLosses?.length ? p.data.birdsDead : null}
              valueFormatter={agGridHelper.decimalFormatter}
              valueSetter={(p) => updateLoss("dead", p)}
            />
            <AgGridColumn
              minWidth={70}
              field="birdsCulled"
              headerName="Karsittu"
              editable={p => isEditable(p, true)}
              valueGetter={(p) => p.data.id === "TOTAL" || p.data.poultryLosses?.length ? p.data.birdsCulled : null}
              valueFormatter={agGridHelper.decimalFormatter}
              valueSetter={(p) => updateLoss("culled", p)}
            />
            <AgGridColumn
              width={100}
              headerName="Poistuma %"
              valueGetter={(p) => p.data.id === "TOTAL" ? p.data.totalLossPercent * 100 : calcLossPercent(p.data) * 100}
              valueFormatter={agGridHelper.decimalFormatter}
              cellStyle={getLossCellStyle}
              suppressSizeToFit
              suppressAutoSize
              suppressNavigable
            />
            <AgGridColumn
              minWidth={80}
              field="temperature"
              headerName="Lämpö C&deg;"
              cellRenderer={agGridHelper.decimalFormatter}
              pinnedRowCellRenderer={() => ""}
              editable={isEditable}
              valueSetter={(p) => updateDiary("temperature", p)}
            />
            <AgGridColumn
              minWidth={80}
              field="humidity"
              headerName="Kosteus %"
              valueFormatter={agGridHelper.decimalFormatter}
              pinnedRowCellRenderer={() => ""}
              editable={isEditable}
              valueSetter={(p) => updateDiary("humidity", p)}
            />
            <AgGridColumn
              minWidth={80}
              field="co2"
              headerName="CO2 ppm"
              valueFormatter={agGridHelper.decimalFormatter}
              pinnedRowCellRenderer={() => ""}
              editable={isEditable}
              valueSetter={(p) => updateDiary("co2", p)}
            />
            <AgGridColumn
              minWidth={80}
              field="waterConsumptionLiter"
              headerName="Vesi L"
              valueFormatter={agGridHelper.decimalFormatter}
              editable={isEditable}
              valueSetter={(p) => updateDiary("waterConsumptionLiter", p)}
            />
            <AgGridColumn
              minWidth={80}
              field="feedConsumptionKg"
              headerName="Rehu kg"
              valueFormatter={agGridHelper.decimalFormatter}
              editable={isEditable}
              valueSetter={(p) => updateDiary("feedConsumptionKg", p)}
            />
            <AgGridColumn
              minWidth={80}
              field="grainConsumptionKg"
              headerName="Vilja kg"
              valueFormatter={agGridHelper.decimalFormatter}
              editable={isEditable}
              valueSetter={(p) => updateDiary("grainConsumptionKg", p)}
            />
            <AgGridColumn
              width={100}
              headerName="R+V/lintu g"
              valueGetter={(p) => p.data.id === "TOTAL" ? p.data.feedTotalPerBirdKg : calcFeedTotalPerBirdGram(p.data)}
              valueFormatter={(p) => p.data.id === "TOTAL" ? agGridHelper.decimalFormatter(p, 2, ' kg') : agGridHelper.decimalFormatter(p, 0)}
              cellStyle={readOnlyCellStyle}
              suppressSizeToFit
              suppressAutoSize
              suppressNavigable
            />
            <AgGridColumn
              minWidth={80}
              field="liveWeightGrams"
              headerName="Paino g"
              valueFormatter={agGridHelper.decimalFormatter}
              pinnedRowCellRenderer={() => ""}
              editable={isEditable}
              valueSetter={(p) => updateDiary("liveWeightGrams", p)}
            />
          </AgGridReact>
        </div>
      </DialogContent>
      <DialogActions>
          <Button variant="text" onClick={handleClose}>Sulje</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiaryGridModal;
