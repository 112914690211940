import React from "react";

interface NumberProps {
  value: number | null | undefined;
  digits?: number
  empty?: string | '';
  unit?: string | '';
}

const _Number: React.FC<NumberProps> = ({value, digits = 2, empty = '', unit = ''}) => {
  const _unit = unit ? ' ' + unit : '';

  return (
    <span>{value === null || value === undefined
      ? empty : (value === 0 ? 0
      : value.toLocaleString('fi-FI', { maximumFractionDigits: digits, minimumFractionDigits: digits })) + _unit}
    </span>
  );
};

export const Number = React.memo(_Number);
export default Number;
