import { ChainInfoCreateDto, ChainInfoDataDto, ChainInfoDraftDto, ChainInfoDraftUpdateDto, ChainInfoPreviewDto, ChainInfoSupplementCreateDto, ChainInfoSupplementDataDto, ChainInfoSupplementPreviewDto, SalmonellaSampleBatchPreviewDto, SalmonellaSampleCreateBatchDto, SalmonellaSampleCreateDto, SalmonellaSampleDto, SalmonellaSamplePreviewDto, SevenDayLossCreateDto, SevenDayLossDto, SevenDayLossPreviewDto } from "../models/ApiSchema";
import http from "./httpService";

const API_URL = `reports`;

export const getSevenDayLossPreview = async (batchId: string) => {
  const resp = await http.get<SevenDayLossPreviewDto>(`${API_URL}/sevendayloss/${batchId}/preview`);
  return resp;
};

export const createSevenDayLossReport = async (batchId: string, data: SevenDayLossCreateDto) => {
  const resp = await http.post<SevenDayLossDto>(`${API_URL}/sevendayloss/${batchId}`, data);
  return resp;
};

export const getSalmonellaSamplePreview = async (batchId: string, sampleDate: moment.Moment) => {
  const resp = await http.get<SalmonellaSamplePreviewDto>(`${API_URL}/salmonellasample/${batchId}/${sampleDate.format('YYYY-MM-DD')}/preview`);
  return resp;
};

export const createSalmonellaSampleReport = async (batchId: string, data: SalmonellaSampleCreateDto) => {
  const resp = await http.post<SalmonellaSampleDto>(`${API_URL}/salmonellasample/${batchId}`, data);
  return resp;
};

export const getSalmonellaSampleBatchPreview = async (sampleDate: moment.Moment) => {
  const resp = await http.get<SalmonellaSampleBatchPreviewDto[]>(`${API_URL}/salmonellasample/batch/${sampleDate.format('YYYY-MM-DD')}/preview`);
  return resp;
};

export const createSalmonellaSampleBatchReport = async (data: SalmonellaSampleCreateBatchDto) => {
  const resp = await http.post(`${API_URL}/salmonellasample/batch`, data);
  return resp;
};

export const getChainInfoPreview = async (batchId: string) => {
  const resp = await http.get<ChainInfoPreviewDto>(`${API_URL}/chaininfo/${batchId}/preview`);
  return resp;
};

export const updateChainInfoDraft = async (batchId: string, data: ChainInfoDraftUpdateDto) => {
  const resp = await http.put<ChainInfoDraftDto>(`${API_URL}/chaininfo/${batchId}/draft`, data);
  return resp;
};

export const createChainInfoReport = async (batchId: string, data: ChainInfoCreateDto) => {
  const resp = await http.post<ChainInfoCreateDto>(`${API_URL}/chaininfo/${batchId}`, data);
  return resp;
};

export const getChainInfoData = async (chainInfoId: string) => {
  const resp = await http.get<ChainInfoDataDto>(`${API_URL}/chaininfo/${chainInfoId}`);
  return resp;
};

export const getChainInfoSupplementPreview = async (batchId: string) => {
  const resp = await http.get<ChainInfoSupplementPreviewDto>(`${API_URL}/supplement/${batchId}/preview`);
  return resp;
};

export const createChainInfoSupplementReport = async (batchId: string, data: ChainInfoSupplementCreateDto) => {
  const resp = await http.post<ChainInfoSupplementCreateDto>(`${API_URL}/supplement/${batchId}`, data);
  return resp;
};

export const getChainInfoSupplementData = async (batchId: string) => {
  const resp = await http.get<ChainInfoSupplementDataDto>(`${API_URL}/supplement/${batchId}`);
  return resp;
};

const reportsService = {
  getSevenDayLossPreview,
  createSevenDayLossReport,
  getSalmonellaSamplePreview,
  createSalmonellaSampleReport,
  getSalmonellaSampleBatchPreview,
  createSalmonellaSampleBatchReport,
  getChainInfoPreview,
  updateChainInfoDraft,
  createChainInfoReport,
  getChainInfoData,
  getChainInfoSupplementPreview,
  createChainInfoSupplementReport,
  getChainInfoSupplementData
};
export default reportsService;