import {
  PublicClientApplication,
  Configuration,
  RedirectRequest,
  SilentRequest,
  InteractionRequiredAuthError,
  // LogLevel,
} from "@azure/msal-browser";

import UserAccount from "../models/UserAccount";

const {
  REACT_APP_CLIENT_ID,
  REACT_APP_SIGN_IN_SIGN_UP_POLICY,
  REACT_APP_FORGOT_PASSWORD_POLICY,
  REACT_APP_APPLICATION_ID,
} = process.env;

const b2cAuthParams: RedirectRequest = {
  scopes: [
    `https://hkscanb2c.onmicrosoft.com/${REACT_APP_CLIENT_ID}/ProducerPortal`,
  ],
  extraQueryParameters: {
    appId: `${REACT_APP_APPLICATION_ID}`,
  },
};

interface IRequiredPolicies {
  signUpSignIn: string;
  forgotPassword: string;
}

interface IRequiredAuthorities {
  signUpSignIn: string;
  forgotPassword: string;
}

interface IB2cPolicy {
  names: IRequiredPolicies;
  authorities: IRequiredAuthorities;
  authorityDomain: string;
}

export const b2cPolicies: IB2cPolicy = {
  names: {
    signUpSignIn: REACT_APP_SIGN_IN_SIGN_UP_POLICY as string,
    forgotPassword: REACT_APP_FORGOT_PASSWORD_POLICY as string
  },
  authorities: {
    signUpSignIn: `https://hkscanb2c.b2clogin.com/hkscanb2c.onmicrosoft.com/${REACT_APP_SIGN_IN_SIGN_UP_POLICY}`,
    forgotPassword: `https://hkscanb2c.b2clogin.com/hkscanb2c.onmicrosoft.com/${REACT_APP_FORGOT_PASSWORD_POLICY}`,
  },
  authorityDomain: "hkscanb2c.b2clogin.com",
};

const msalConfig: Configuration = {
  auth: {
    clientId: REACT_APP_CLIENT_ID as string,
    authority: b2cPolicies.authorities.signUpSignIn,
    knownAuthorities: [b2cPolicies.authorityDomain],
    navigateToLoginRequestUrl: true,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    tokenRenewalOffsetSeconds: 300,
    // loggerOptions: {
    //   loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message);
    //         return;
    //       case LogLevel.Info:
    //         console.info(message);
    //         return;
    //       case LogLevel.Verbose:
    //         console.debug(message);
    //         return;
    //       case LogLevel.Warning:
    //         console.warn(message);
    //         return;
    //     }
    //   },
    //   piiLoggingEnabled: false
    // }
  }
};

// const msalResetPasswordConfig: Configuration = {
//   auth: {
//     clientId: REACT_APP_CLIENT_ID as string,
//     authority: b2cPolicies.authorities.forgotPassword,
//     navigateToLoginRequestUrl: false,
//     redirectUri: window.location.origin,
//   },
//   cache: {
//     cacheLocation: "sessionStorage",
//     storeAuthStateInCookie: false,
//   },
// };

const msalAuth = new PublicClientApplication(msalConfig);

const getMsalAccount = (homeAccountId: string = "") => {
  if (homeAccountId) {
    const account = msalAuth.getAccountByHomeId(homeAccountId);
    return account;
  }

  const accounts = msalAuth.getAllAccounts();
  if (accounts.length > 0) return accounts[0];
  return;
};

const loginRedirect = () => {
  msalAuth.loginRedirect(b2cAuthParams);
};

const getAccessTokenResponse = async (account: any) => {
  const msalAccount = getMsalAccount(account?.homeAccountId) || account;

  const tokenRequest: SilentRequest = {
    scopes: [
      `https://hkscanb2c.onmicrosoft.com/${REACT_APP_CLIENT_ID}/ProducerPortal`,
    ],
    account: msalAccount as any,
    extraQueryParameters: {
      appId: `${REACT_APP_APPLICATION_ID}`,
    }
  };

  try {
    const resp: any = await msalAuth.acquireTokenSilent(tokenRequest);
    return {
      accessToken: resp.accessToken,
      userAccount: new UserAccount(resp),
    };
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError)
      await msalAuth.acquireTokenRedirect(tokenRequest);

    throw err;
  }
};

const logout = () => {
  msalAuth.logoutRedirect();
};

const forgotPassword = () => {
  msalAuth.loginRedirect({
    authority: b2cPolicies.authorities.forgotPassword,
  } as RedirectRequest);
};

const handleRedirectCallback = (onSuccess, onError) => {
  msalAuth.handleRedirectPromise().then(onSuccess).catch(onError);
};

const getAccount = (resp): UserAccount => {
  return resp ? new UserAccount(resp) : {} as UserAccount;
};

const auth = {
  loginRedirect,
  logout,
  handleRedirectCallback,
  getAccount,
  getMsalAccount,
  getAccessTokenResponse,
  forgotPassword
};
export default auth;