import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../../../../shared/themes/modalStyles";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { BreedingBatchDto, ChickQuality, SevenDayLossPreviewDto } from "../../../../shared/models/ApiSchema";
import reportsService from "../../../../shared/services/reportsService";
import { Alert } from "@material-ui/lab";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { TextField } from "formik-material-ui";
import { useConfirm } from "material-ui-confirm";
import SevenDayLoss from "../../../../shared/components/Data/SevenDayLoss/SevenDayLoss";
import BatchHeader from "../BatchHeader/BatchHeader";

interface SevenDayLossPreviewModalProps {
  open: boolean;
  onClose: any;
  batch: BreedingBatchDto;
}

const SevenDayLossPreviewModal: React.FC<SevenDayLossPreviewModalProps> = ({ open, onClose, batch }) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const [lossPreview, setLossPreview] = useState<SevenDayLossPreviewDto | null>(null);

  useEffect(() => {
    setLossPreview(null);
    if (open && batch) {
      const fetchData = async () => {
        const resp = await reportsService.getSevenDayLossPreview(batch.id);
        setLossPreview(resp.data);
      };
      fetchData();
    }
  }, [open, batch, setLossPreview]);

  const handleCancel = () => {
    onClose(null);
  };

  const formSchema = Yup.object().shape({
    chickQuality: Yup
      .mixed<ChickQuality>()
      .required()
      .oneOf(Object.values(ChickQuality)),
    notes: Yup
      .string()
      .nullable()
      .max(4000)
  });

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="sm"
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">Erä {batch.breedingId}: 7 päivän poistumailmoitus</PageHeader>
      </DialogTitle>
      <DialogContent>
        <BatchHeader batch={batch} />
        {lossPreview == null &&
          <Box my={6} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {lossPreview != null &&
          <Fragment>
            {lossPreview.errors && lossPreview.errors.length > 0 && <Box my={2}>
              <Alert severity="error">
                {lossPreview.errors.map((e, index) => <div key={index}>{e}</div>)}
              </Alert>
            </Box>}
            <Box my={2}>
              <Formik
                initialValues={{ chickQuality: "" }}
                validationSchema={formSchema}
                onSubmit={(values, actions) => {
                  confirm({ description: "Lähettämällä ilmoituksen vahvistat 7 vuorokauden poistuma- ja painotiedot." }).then(() => {
                    const data = formSchema.cast(values, { stripUnknown: true });
                    actions.setSubmitting(false);
                    onClose(data);
                  }, () => {
                    actions.setSubmitting(false);
                  });
                }}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form id="sevenDayLossForm">
                    <Box display="flex" flexDirection="column">
                      <Field
                        select
                        component={TextField}
                        label="Untuvikkojen laatu"
                        name="chickQuality"
                        margin="normal"
                      >
                        <MenuItem key="Good" value="Good">Hyvä</MenuItem>
                        <MenuItem key="Fair" value="Fair">Normaali</MenuItem>
                        <MenuItem key="Poor" value="Poor">Huono</MenuItem>
                      </Field>
                      <Field
                        component={TextField}
                        label="Huomioita poistumasta"
                        name="notes"
                        fullWidth
                        margin="normal"
                        InputProps={{
                          multiline: true,
                          maxRows: 8
                        }}
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
            <SevenDayLoss sevenDayLoss={lossPreview.sevenDayLoss} isPreview={true} />
          </Fragment>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>Peruuta</Button>
        <Button variant="text" type="submit" form="sevenDayLossForm"
          disabled={lossPreview?.errors != null && lossPreview?.errors?.length > 0}>Lähetä</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SevenDayLossPreviewModal;