import React, { Fragment, useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { IconButton } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import { IRootState } from "../../../shared/store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../shared/store/apiActions";
import ApprovalStatusSetMenu from "../../../shared/components/ApprovalStatus/ApprovalStatusSetMenu";
import { ViewName } from "../../../shared/models/ApiSchema";
import agGridHelper from "../../../shared/helpers/agGridHelper";
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import SevenDayLossApprovalModal from "./SevenDayLossApprovalModal";
import { approvalStatusText, chickQualityText } from "../../../shared/helpers/statusHelper";
import { ColDef } from "ag-grid-community";
import { useLocation } from "react-router-dom";

interface SevenDayLossApprovalProps {

}

const SevenDayLossApproval: React.FC<SevenDayLossApprovalProps> = () => {

  const access = useSelector((state: IRootState) => state.user.access);
  const status = useSelector((state: IRootState) => state.approval.status);
  const startDate = useSelector((state: IRootState) => state.approval.startDate);
  const endDate = useSelector((state: IRootState) => state.approval.endDate);
  const salmonellaSamples = useSelector((state: IRootState) => state.approval.sevenDayLossList);
  const dispatch = useDispatch();
  const { hash } = useLocation();

  const [openApprovalModal, setApprovalModalOpen] = useState<boolean>(false);
  const handleApprovalModalClose = (newStatus) => {
    setApprovalModalOpen(false);
    if (newStatus) {
      dispatch(api.approval.getSevenDayLossList(startDate, endDate, /*status ||*/ undefined));
    }
  };

  useEffect(() => {
    dispatch(api.approval.getSevenDayLossList(startDate, endDate, /*status ||*/ undefined));
  }, [status, startDate, endDate, dispatch]);

  useEffect(() => {
    if (hash && hash.length === 37) {
      dispatch(api.approval.getSevenDayLossApproval(hash.substring(1)));
      setApprovalModalOpen(true);
    }
  }, [dispatch, hash]);

  const openApproval = (id: string) => {
    dispatch(api.approval.getSevenDayLossApproval(id));
    setApprovalModalOpen(true);
  }

  const redraw = (e) => {
    e.api.redrawRows();
  }

  const defaultColDef: ColDef = {
    cellStyle: { textAlign: "center" },
    resizable: true,
    sortable: true,
    editable: false,
    suppressMenu: true,
    suppressMovable: true,
    suppressNavigable: true
  };

  return (
    <Fragment>
      <div className="diary-grid ag-theme-balham" style={{height: "74vh", width: "99.99%"}}>
        <AgGridReact
          rowData={salmonellaSamples}
          defaultColDef={defaultColDef}
          suppressCellSelection={true}
          applyColumnDefOrder={true}
          immutableData={true}
          getRowNodeId={(row) => row.id}
          onRowDataChanged={redraw}
        >
          <AgGridColumn headerName="Seitsemän päivän poistumailmoitus">
            <AgGridColumn
              field="breedingId"
              headerName="Erä id"
              width={80}
              pinned="left"
            />
            <AgGridColumn
              field="producerName"
              headerName="Tuottaja"
              minWidth={160}
              maxWidth={320}
              pinned="left"
            />
            <AgGridColumn
              field="hallNumber"
              headerName="Halli"
              width={60}
              cellStyle={{fontWeight:600}}
              pinned="left"
            />
            {access?.approvalStatusEnabled?.SevenDayLoss && <AgGridColumn
              field="approvalStatus"
              headerName="Status"
              width={100}
              pinned="left"
              valueFormatter={v => approvalStatusText(v.value)}
            />}
            {access?.approvalStatusEnabled?.SevenDayLoss && access?.hasApprovalRole && <AgGridColumn
              width={48}
              sortable={false}
              pinned="left"
              cellRendererFramework={params => <ApprovalStatusSetMenu current={params.data.approvalStatus} view={ViewName.SevenDayLoss} id={params.data.id} />}
            />}
          </AgGridColumn>
          <AgGridColumn headerName="Poistuma">
            <AgGridColumn
              field="transportMortality"
              headerName="Kuljetus"
              width={90}
            />
            <AgGridColumn
              headerName="7 vrk %"
              width={90}
              valueGetter={(p) => p.data.totalLoss / p.data.startQuantity * 100}
              valueFormatter={agGridHelper.decimalFormatter}
            />
            <AgGridColumn
              field="reportDate"
              headerName="Päivä"
              width={90}
              valueFormatter={agGridHelper.dateFormatter}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Untuvikkomäärä">
            <AgGridColumn
              field="sentQuantity"
              headerName="Toimitettu kpl"
              width={120}
            />
            <AgGridColumn
              field="startQuantity"
              headerName="Aloittanut kpl"
              width={120}
            />
            <AgGridColumn
              field="chickQuality"
              headerName="Untuvikkolaatu"
              width={120}
              valueFormatter={v => chickQualityText(v.value)}
            />
          </AgGridColumn>
          <AgGridColumn
            field="notes"
            headerName="Kommentit poistumasta"
            minWidth={240}
            sortable={false}
            cellStyle={{ textAlign: 'left' }}
          />
          <AgGridColumn
            field="parentFlocks"
            headerName="Alkuperätilat"
            minWidth={140}
            valueFormatter={v => v.value.join(', ')}
          />
          <AgGridColumn
            field="liveWeightGrams"
            headerName="7 vrk paino g"
            width={120}
          />
          <AgGridColumn headerName="Erän päivämäärät">
            <AgGridColumn
              field="arrivalDates"
              headerName="Saapuminen"
              width={140}
              valueFormatter={agGridHelper.dateListFormatter}
            />
            <AgGridColumn
              field="slaughterDate"
              headerName="Teurastus"
              width={90}
              valueFormatter={agGridHelper.dateFormatter}
            />
          </AgGridColumn>
          <AgGridColumn headerName="Lähetetty">
            <AgGridColumn
              field="createdBy"
              headerName="Nimi"
              minWidth={180}
            />
            <AgGridColumn
              field="createdDate"
              headerName="Aika"
              width={120}
              valueFormatter={agGridHelper.dateTimeFormatter}
            />
          </AgGridColumn>
          <AgGridColumn
            width={48}
            sortable={false}
            pinned="right"
            cellRendererFramework={params => <IconButton size="small" onClick={() => openApproval(params.data.id)}><DescriptionIcon style={{ fontSize: 18 }} /></IconButton>}
          />
        </AgGridReact>
      </div>
      <SevenDayLossApprovalModal open={openApprovalModal} onClose={handleApprovalModalClose} />
    </Fragment>
  );
};

export default SevenDayLossApproval;