import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../store/rootReducer";
import { producerNumberUpdated } from "../../store/ui/settings";
import * as api from "../../store/apiActions";
import { withAuth } from "../../hocs/withAuth";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

const AppInit = ({ children }) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const producerNumber = useSelector((state: IRootState) => state.ui.settings.producerNumber);
  const currentUser = useSelector((state: IRootState) => state.auth.currentUser);
  const organizations = useSelector((state: IRootState) => state.user.organizations);
  const access = useSelector((state: IRootState) => state.user.access);

  const dispatch = useDispatch();

  // used to force component to render
  const [defaultOrganizationSelected, setDefaultOrganizationSelected] = useState(false);

  useEffect(() => {
    if (currentUser.authenticationSource) {
      dispatch(api.user.fetchOrganizations());
      dispatch(api.user.fetchAccess());
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (organizations.length > 0) {
      if (
        currentUser.authenticationSource === "HKScanAzureAD" &&
        !defaultOrganizationSelected
      ) {
        if (!producerNumber) {
          dispatch(producerNumberUpdated({ producerNumber: organizations[0].producerNumber }));
          setDefaultOrganizationSelected(true);
        }
      }
      setInitialized(true);
    }
  }, [organizations, currentUser, defaultOrganizationSelected, dispatch, producerNumber]);

  useEffect(() => {
    if (producerNumber) {
      dispatch(api.batch.fetchFromPortal());
    }
  }, [producerNumber, dispatch]);

  if (!initialized || !access) {
    return <LoadingIndicator showAlwaysLoadingIndicator={true} />;
  }

  return (
    <Fragment>
      <LoadingIndicator />
      {children}
    </Fragment>
  );
};

export default withAuth(AppInit);
