import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: "space-between",
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main
  },
  title: {
    flexGrow: 1,
    justifyContent: 'center',
    textAlign: 'center',
  }
}));

export default useStyles;