import React, { Fragment, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import PageHeader from "../../../shared/components/PageHeader/PageHeader";
import Date from "../../../shared/components/Formatters/Date";
import { ApprovalStatus, ViewName } from "../../../shared/models/ApiSchema";
import ChainInfo from "../../../shared/components/Data/ChainInfoData/ChainInfo";
import ChainInfoFeed from "../../../shared/components/Data/ChainInfoData/ChainInfoFeed";
import ChainInfoHealth from "../../../shared/components/Data/ChainInfoData/ChainInfoHealth";
import ApprovalStatusAuditList from "../../../shared/components/ApprovalStatus/ApprovalStatusAuditList";
import ApprovalStatusSet from "../../../shared/components/ApprovalStatus/ApprovalStatusSetForm";
import { IRootState } from "../../../shared/store/rootReducer";
import { useSelector } from "react-redux";
import ApprovalStatusChip from "../../../shared/components/Statuses/ApprovalStatus";
import { useReactToPrint } from "react-to-print";
import useStyles from "../../../shared/themes/modalStyles";

interface ChainInfoApprovalModalProps {
  open: boolean;
  onClose: any;
}

const ChainInfoApprovalModal: React.FC<ChainInfoApprovalModalProps> = ({ open, onClose }) => {
  const classes = useStyles();

  const access = useSelector((state: IRootState) => state.user.access);
  const approval = useSelector((state: IRootState) => state.approval.chainInfoApproval);

  const [newState, setNewState] = useState<ApprovalStatus | null>(null);

  const handleNewState = (state: ApprovalStatus) => {
    setNewState(state);
  };

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current || null
  });

  const handleClose = () => {
    onClose(newState);
  };

  return (
    <Dialog
      ref={printRef}
      scroll="body"
      fullWidth
      maxWidth="md"
      open={open}
      TransitionProps={{onEnter: () => setNewState(null)}}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        <PageHeader level="2" component="span">
          Erä {approval?.chainInfo?.breedingId}: Ketjuinformaatiolomake{" "}
          {approval != null && <ApprovalStatusChip status={approval?.chainInfo?.approvalStatus} />}
        </PageHeader>
      </DialogTitle>
      <DialogContent>
        {(approval != null && approval.chainInfo != null && <Fragment>
          <Box mb={2}>
            <ChainInfo chainInfo={approval.chainInfo} />
            <ChainInfoFeed chainInfo={approval.chainInfo} />
            <ChainInfoHealth chainInfo={approval.chainInfo} />
          </Box>
          <Box mt={3}>
            <strong>
              Ketjuinformaatiolomake lähetetty:{" "}
              <span>{approval.chainInfo.approveName || "[ei nimeä]"}</span>{", "}
              <span>{approval.chainInfo.approveLocation || "[ei paikkaa]"}</span>{", "}
              <Date value={approval.chainInfo.approveTime} empty="[ei päiväystä]" />
            </strong>
          </Box>
          <Box mt={3}>
            {access?.approvalStatusEnabled?.ChainInfo &&
              <PageHeader level="2" component="div">Hyväksyntä</PageHeader>}
            <Box my={2}>
              <ApprovalStatusAuditList audits={approval.audits} />
            </Box>
            {access?.approvalStatusEnabled?.ChainInfo && <Box mt={3} mb={1}>
              <ApprovalStatusSet view={ViewName.ChainInfo} current={approval.chainInfo.approvalStatus} id={approval.chainInfo.id} onChange={handleNewState} />
            </Box>}
          </Box>
        </Fragment>)}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handlePrint}>Tulosta</Button>
        <Button variant="text" onClick={handleClose}>Sulje</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChainInfoApprovalModal;