import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { BatchDeliveryDto, BatchReportsDto, BreedingBatchDto, BreedingBatchStatus, FeedInfoDto, HallInfoDto, HealthInfoDto, PoultryDiaryDto } from "../../../../shared/models/ApiSchema";
import FeedInfoModal from "../FeedInfoModal/FeedInfoModal";
import HallInfoModal from "../HallInfoModal/HallInfoModal";
import * as api from "../../../../shared/store/apiActions";
import { useSnackbar } from "notistack";
import { IRootState } from "../../../../shared/store/rootReducer";
import UpdateBatchDatesModal from "../UpdateBatchDatesModal/UpdateBatchDatesModal";
import BatchStatus from "../../../../shared/components/Statuses/BatchStatus";
import { successHandled } from "../../../../shared/store/api";
import HealthInfoModal from "../HealthInfoModal/HealthInfoModal";
import Date from "../../../../shared/components/Formatters/Date";
import BatchDeliveryModal from "../BatchDeliveryModal/BatchDeliveryModal";
import batchHelper from "../../../../shared/helpers/batchHelper";
import Percent from "../../../../shared/components/Formatters/Percent";
import TaskMenu from "../../../../shared/components/TaskMenu/TaskMenu";
import moment from "moment";
import { cumulativeLossPercent } from "../../../../shared/helpers/diaryHelper";
import DiaryNotesModal from "../DiaryNotesModal/DiaryNotesModal";

interface BatchDetailsProps {
  batch: BreedingBatchDto;
  deliveries: BatchDeliveryDto[];
  diaries: PoultryDiaryDto[];
  feed: FeedInfoDto | null;
  hall: HallInfoDto | null;
  health: HealthInfoDto | null;
  reports: BatchReportsDto | null;
}

const BatchDetails: React.FC<BatchDetailsProps> = ({batch, deliveries, diaries, feed, hall, health, reports}) => {
  const dispatch = useDispatch();

  const { success } = useSelector((state: IRootState) => state.api);
  const { enqueueSnackbar } = useSnackbar();

  const [cumulativeLoss, setCumulativeLoss] = useState(0);
  useEffect(() => {
    setCumulativeLoss(cumulativeLossPercent(diaries));
  }, [diaries]);

  useEffect(() => {
    const snackbar = (message: string) => {
      enqueueSnackbar(message, { variant: 'success' });
      dispatch(successHandled());
    };

    if (success) {
      if (success.url?.indexOf('/start') > -1 && success.method === "post") {
        snackbar(`Erä ${batch.breedingId} on aloitettu`);
      }
      if (success.url?.indexOf('/dates') > -1 && success.method === "put") {
        snackbar(`Erän ${batch.breedingId} ajankohdat on päivitetty`);
      }
      if (success.url?.indexOf('/delivery') > -1 && success.method === "post") {
        snackbar(`Erän ${batch.breedingId} toimitus on lisätty`);
      }
      if (success.url?.indexOf('/delivery') > -1 && success.method === "put") {
        snackbar(`Erän ${batch.breedingId} toimitus on päivitetty`);
      }
      if (success.url?.indexOf('/delivery') > -1 && success.method === "delete") {
        snackbar(`Erän ${batch.breedingId} toimitus on poistettu`);
      }
      if (success.url?.indexOf('/hallinfo') > -1 && success.method === "put") {
        snackbar(`Erän ${batch.breedingId} hallitiedot on päivitetty`);
      }
      if (success.url?.indexOf('/feedinfo') > -1 && success.method === "put") {
        snackbar(`Erän ${batch.breedingId} rehutiedot on päivitetty`);
      }
      if (success.url?.indexOf('/healthinfo') > -1 && success.method === "put") {
        snackbar(`Erän ${batch.breedingId} terveystiedot on päivitetty`);
      }
    }
  }, [batch.breedingId, dispatch, enqueueSnackbar, success]);

  const [openUpdateBatchDatesModal, setUpdateBatchDatesModalOpen] = useState(false);
  const handleUpdateBatchDatesModalClose = (data) => {
    setUpdateBatchDatesModalOpen(false);
    if (data) {
      dispatch(api.batch.updateBatchDates(batch.id, data));
    }
  };

  const [deliveryMenuOptions, setDeliveryMenuOptions] = useState<any[]>([]);
  useEffect(() => {
    const options: any[] = [];
    if (batch.status === BreedingBatchStatus.Active && !batch.isFinal) {
      options.push({ label: "Lisää uusi toimitus", action: { type: "add" } });
    }
    setDeliveryMenuOptions(options.concat(deliveries.map((d, i) => {
      return { label: moment(d.actualArrivalTime).format('D.M.YYYY H:mm'), action: { type: 'open', index: i } }
    })));
  }, [batch.isFinal, batch.status, deliveries]);

  const [openBatchDeliveryModal, setBatchDeliveryModalOpen] =
    useState<{ open: boolean, delivery: BatchDeliveryDto | null, preFeed: boolean}>({ open: false, delivery: null, preFeed: false });
  const handleDeliveryMenu = (action?: any) => {
    if (action?.type === "add") handleBatchDeliveryModalOpen(null);
    if (action?.type === "open") handleBatchDeliveryModalOpen(action?.index);
  };
  const handleBatchDeliveryModalOpen = (index: number | null) => {
    if (index != null && index >= 0 && index <= deliveries.length - 1) {
      setBatchDeliveryModalOpen({ open: true, delivery: deliveries[index], preFeed: index === 0 });
    } else {
      setBatchDeliveryModalOpen({ open: true, delivery: null, preFeed: false });
    }
  };
  const handleBatchDeliveryModalClose = (data, isDelete = false) => {
    const selectedDelivery = openBatchDeliveryModal.delivery;
    setBatchDeliveryModalOpen({ open: false, delivery: null, preFeed: false });
    if (data && selectedDelivery?.id) {
      dispatch(api.batch.updateBatchDelivery(batch.id, selectedDelivery.id, data));
    }
    else if (isDelete && selectedDelivery?.id) {
      dispatch(api.batch.deleteBatchDelivery(batch.id, selectedDelivery.id));
    }
    else if (data && !selectedDelivery) {
      dispatch(api.batch.addBatchDelivery(batch.id, data));
    }
  };

  const [openFeedInfoModal, setFeedInfoModalOpen] = useState(false);
  const handleFeedInfoModalClose = (data, changed: boolean = false) => {
    setFeedInfoModalOpen(false);
    if (data) {
      dispatch(api.batch.updateFeedInfo(batch.id, data));
    }
    else if (changed) {
      dispatch(api.batch.getBreedingBatch(batch.id));
    }
  };

  const [openHallInfoModal, setHallInfoModalOpen] = useState(false);
  const handleHallInfoModalClose = (data, changed: boolean = false) => {
    setHallInfoModalOpen(false);
    if (data) {
      dispatch(api.batch.updateHallInfo(batch.id, data));
    }
    else if (changed) {
      dispatch(api.batch.getBreedingBatch(batch.id));
    }
  };

  const [openHealthInfoModal, setHealthInfoModalOpen] = useState(false);
  const handleHealthInfoModalClose = (data) => {
    setHealthInfoModalOpen(false);
    if (data) {
      dispatch(api.batch.updateHealthInfo(batch.id, data));
    }
  };

  const [openDiaryNotesModal, setDiaryNotesModalOpen] = useState(false);
  const handleDiaryNotesModalClose = (data) => {
    setDiaryNotesModalOpen(false);
  };

  return (
    <Box component={Paper} p={2} pb={0}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <PageHeader level="3" component="div">Eräkohtaiset tiedot</PageHeader>
        <BatchStatus status={batch.status} />
      </Box>
      <List dense>

        {batch.status === BreedingBatchStatus.Planned &&
          <Fragment>
            <ListItem key="dates" disableGutters>
              <ListItemText
                primary="Saapuminen / teurastus"
                secondary={<span><Date value={batch.arrivalDate} /> / <Date value={batch.slaughterDate} /></span>}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="Erätiedot" onClick={() => setUpdateBatchDatesModalOpen(true)}>
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem key="quantity" disableGutters>
              <ListItemText
                primary="Suunniteltu määrä"
                secondary={`${batch.plannedQuantity} untuvikkoa`}
              />
              <ListItemSecondaryAction />
            </ListItem>
          </Fragment>
        }

        {batch.status !== BreedingBatchStatus.Planned &&
          <Fragment>
            <ListItem key="deliveries" disableGutters>
              <ListItemText
                primary="Untuvikkotoimitukset"
                secondary={deliveries.map((d, i) =>
                  <span style={{display:'block'}} key={i}>
                    <Date value={d.actualArrivalTime} time={true} />{": "}
                    {d.sentQuantity - d.transportMortality} lintua
                  </span>
                )}
              />
              <ListItemSecondaryAction>
                <TaskMenu id="deliveries" onClick={handleDeliveryMenu}
                  items={deliveryMenuOptions}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem key="amount" disableGutters>
              <ListItemText
                primary="Aloittanut / jäljellä / poistuma"
                secondary={<Fragment>
                  {batch.chickQuantity} / {batch.birdsLeft} / {batchHelper.calcTotalLoss(batch)} (<Percent value={cumulativeLoss} />)
                </Fragment>}
              />
              <ListItemSecondaryAction>
              </ListItemSecondaryAction>
            </ListItem>
          </Fragment>
        }

        <ListItem key="feedInfo" disableGutters>
          <ListItemText
            primary={`Rehutiedot${feed?.feedFactory ? (": " + feed?.feedFactory) : ""}`}
            secondary={feed
              ? <span><Date value={feed.modifiedDate} time={true} /> ({feed.modifiedBy || 'tuntematon'})</span>
              : <span>Ei tallennettu</span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="Rehutiedot" onClick={() => setFeedInfoModalOpen(true)}>
              {feed?.isFinal ? <VisibilityIcon /> : <EditIcon />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem key="hallinfo" disableGutters>
          <ListItemText
            primary="Hallia koskevat tiedot"
            secondary={hall
              ? <span><Date value={hall.modifiedDate} time={true} /> ({hall.modifiedBy || 'tuntematon'})</span>
              : <span>Ei tallennettu</span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="Hallitiedot" onClick={() => setHallInfoModalOpen(true)}>
              {hall?.isFinal ? <VisibilityIcon /> : <EditIcon />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem key="healthInfo" disableGutters>
          <ListItemText
            primary="Terveystiedot"
            secondary={health
              ? <span><Date value={health.modifiedDate} time={true} /> ({health.modifiedBy || 'tuntematon'})</span>
              : <span>Ei tallennettu</span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="Rehutiedot" onClick={() => setHealthInfoModalOpen(true)}>
              {health?.isFinal ? <VisibilityIcon /> : <EditIcon />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        {batch.status !== BreedingBatchStatus.Planned &&
          <ListItem key="notes" disableGutters>
            <ListItemText
              primary="Päiväkirjan huomiot"
              secondary="Yhteenveto päiväkirjan merkinnöistä"
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="Huomiot" onClick={() => setDiaryNotesModalOpen(true)}>
                <VisibilityIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        }

      </List>

      <UpdateBatchDatesModal open={openUpdateBatchDatesModal} onClose={handleUpdateBatchDatesModalClose} batch={batch} />
      <BatchDeliveryModal open={openBatchDeliveryModal.open} onClose={handleBatchDeliveryModalClose} batch={batch}
        delivery={openBatchDeliveryModal.delivery} preFeed={openBatchDeliveryModal.preFeed} enableDelete={deliveries?.length > 1} />
      <FeedInfoModal open={openFeedInfoModal} onClose={handleFeedInfoModalClose} batch={batch} feed={feed} />
      <HallInfoModal open={openHallInfoModal} onClose={handleHallInfoModalClose} batch={batch} hall={hall} />
      <HealthInfoModal open={openHealthInfoModal} onClose={handleHealthInfoModalClose} batch={batch} health={health}
        salmonella={reports?.salmonellaSample} />
      <DiaryNotesModal open={openDiaryNotesModal} onClose={handleDiaryNotesModalClose} batch={batch} diaries={diaries} />

    </Box>
  );
};

export default BatchDetails;
