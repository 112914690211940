import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { Fragment } from "react";
import { ChainInfoDto, FeedType } from "../../../models/ApiSchema";
import Number from "../../Formatters/Number";
import { Alert } from "@material-ui/lab";
import { feeds } from "../../../helpers/feedHelper";

interface ChainInfoFeedProps {
  chainInfo: ChainInfoDto | null | undefined;
}

const ChainInfoFeed: React.FC<ChainInfoFeedProps> = ({chainInfo}) => {
  if (!chainInfo) return <Fragment></Fragment>;

  return (
    <Fragment>
      <Box my={2}>
        {chainInfo.feeds != null && chainInfo.feeds.length > 0 && <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="column">Rehutyyppi</TableCell>
                <TableCell component="th" scope="column">Rehun nimi</TableCell>
                <TableCell component="th" scope="column">Kokkidiostaatti</TableCell>
                <TableCell component="th" scope="column">Varoaika</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeds(chainInfo.feeds, FeedType.Start).map((f, i) => <TableRow key={i}>
                <TableCell>Starttirehu</TableCell>
                <TableCell>{f.name || "-"}</TableCell>
                <TableCell>{f.coccidiostat || "-"}</TableCell>
                <TableCell><Number value={f.withholdingPeriod} digits={0} empty="Ei syötetty" unit="vrk" /></TableCell>
              </TableRow>)}
              {feeds(chainInfo.feeds, FeedType.Grow1).map((f, i) => <TableRow key={i}>
                <TableCell>1. kasvatusrehu</TableCell>
                <TableCell>{f.name || "-"}</TableCell>
                <TableCell>{f.coccidiostat || "-"}</TableCell>
                <TableCell><Number value={f.withholdingPeriod} digits={0} empty="Ei syötetty" unit="vrk" /></TableCell>
              </TableRow>)}
              {feeds(chainInfo.feeds, FeedType.Grow2).map((f, i) => <TableRow key={i}>
                <TableCell>2. kasvatusrehu</TableCell>
                <TableCell>{f.name || "-"}</TableCell>
                <TableCell>{f.coccidiostat || "-"}</TableCell>
                <TableCell><Number value={f.withholdingPeriod} digits={0} empty="Ei syötetty" unit="vrk" /></TableCell>
              </TableRow>)}
              {feeds(chainInfo.feeds, FeedType.End).map((f, i) => <TableRow key={i}>
                <TableCell>Loppurehu</TableCell>
                <TableCell>{f.name || "-"}</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>}
        {(chainInfo.feeds == null || chainInfo.feeds.length === 0) &&
          <Alert severity="error">Rehutiedot puuttuvat</Alert>}
      </Box>
    </Fragment>
  );
};

export default ChainInfoFeed;
