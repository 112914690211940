import moment from "moment";
import React from "react";

interface DateProps {
  value: moment.Moment | Date | string | number | null | undefined;
  empty?: string | '';
}

const _Time: React.FC<DateProps> = ({value, empty = ''}) => {
  return (
    <span>{value === null || value === undefined || value === "" || value === 0
      ? empty
      : moment(value).format('H:mm')}</span>
  );
};

export const Time = React.memo(_Time);
export default Time;
